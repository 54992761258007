import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import UserContext from "../context/UserContext";
import $ from 'jquery';
import Swal from 'sweetalert2';
const NavBar: React.FC = () => {
  const [user, setUser] = useContext(UserContext);
  const handleClick = () => {
    // Simulating an error
    Swal.fire('Sorry...', 'Please subscribe for access this service!', 'error');
  };



  useEffect(() => {
    $('.hamberger-button').on('click', function (e) {
      $('.popup-mobile-menu').addClass('active');
    });

    $('.close-menu').on('click', function (e) {
      $('.popup-mobile-menu').removeClass('active');
      $('.popup-mobile-menu .mainmenu .has-droupdown > a').siblings('.submenu, .mega-menu').removeClass('active').slideUp('400');
      $('.popup-mobile-menu .mainmenu .has-droupdown > a').removeClass('open');
    });

    $('.popup-mobile-menu .mainmenu .has-droupdown > a').on('click', function (e) {
      e.preventDefault();
      $(this).siblings('.submenu, .mega-menu').toggleClass('active').slideToggle('400');
      $(this).toggleClass('open');
    });

    $('.popup-mobile-menu, .splash-mobile-menu .mainmenu li a').on('click', function (e) {
      e.target === this && $('.popup-mobile-menu').removeClass('active') && $('.popup-mobile-menu .mainmenu .has-droupdown > a').siblings('.submenu, .mega-menu').removeClass('active').slideUp('400') && $('.popup-mobile-menu .mainmenu .has-droupdown > a').removeClass('open');
    });
  }, []);

  return (
    <header className="edu-header header-style-1 header-fullwidth">
      <div id="edu-sticky-placeholder"></div>
      <div className="header-mainmenu">
        <div className="container-fluid">
          <div className="header-navbar">
            <div className="header-brand">
              <div className="logo">
                <a href="/">
                  <img
                    className="logo-light img-logo"
                    src="../assets/images/logo/logo-v1.png"
                    alt="Corporate Logo"
                  />
                  <img
                    className="logo-dark img-logo"
                    src="../assets/images/logo/logo-v1.png"
                    alt="Corporate Logo"
                  />
                </a>
              </div>
            </div>

            <div className="header-mainnav">
              <nav className="mainmenu-nav">
                <ul className="mainmenu">
                  <li>
                    <a href="/home">Home</a>
                  </li>
                  <li className="has-droupdown">
                    <a href="/services">Services</a>
                    {/* {user?.subscriptionId && */}
                    <ul className="mega-menu">
                      <li>
                        <h6 className="menu-title themeColor">Insurance</h6>
                        <h4 className="subMenu-drop mb-4">Life Admin</h4>
                        <ul className="submenu mega-sub-menu-01">
                          <li>
                            <NavLink to="/customer/car-insurance">
                              Car Insurance
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/customer/home-insurance">
                              Home Insurance
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/customer/business-insurance">
                              Business Insurance
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h6 className="menu-title themeColor">Doctor Appointments</h6>
                        <h4 className="subMenu-drop mb-4">Life Admin</h4>
                        <ul className="submenu mega-sub-menu-01">
                          <li>
                            <NavLink to="/customer/gp">Group Practice</NavLink>
                          </li>
                          <li>
                            <NavLink to="/customer/private-hospital">
                              Private Hospital
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h6 className="menu-title themeColor">Car Penalty Charges</h6>
                        <h4 className="subMenu-drop mb-4">Life Admin</h4>
                        <ul className="submenu mega-sub-menu-01">
                          <li>
                            <NavLink to="/customer/parking-ticket">
                              Parking Ticket
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/customer/speeding-ticket">
                              Speeding Ticket
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/customer/congestion-charges">
                              Congestion Charges
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h6 className="menu-title themeColor">Utilities</h6>
                        <h4 className="subMenu-drop mb-4">Life Admin</h4>
                        <ul className="submenu mega-sub-menu-01">
                          <li>
                            <NavLink to="/customer/water">Water</NavLink>
                          </li>
                          <li>
                            <NavLink to="/customer/tv-license">
                              TV License
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/customer/gas">Gas</NavLink>
                          </li>
                          <li>
                            <NavLink to="/customer/electricity">
                              Electricity
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/customer/council-tax-bills">
                              Counsil Tax Bills
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <NavLink to="/customer/personal-request">
                          {" "}
                          <h6 className="menu-title themeColor">Personal Request</h6>
                          <h4 className="subMenu-drop mb-4">Life Admin</h4>
                        </NavLink>
                        <ul className="submenu mega-sub-menu-01">
                          <li>
                            <NavLink to="/customer/personal-request">
                              Submit Personal Request
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>

                  </li>

                  <li>
                    <a href="/aboutUs">About Us</a>
                  </li>

                  <li>
                    <a href="/contact">Contact Us</a>
                  </li>
                  {user?._id && (
                    <li>
                      <NavLink to="/customer/account">My Account</NavLink>
                    </li>
                  )}
                </ul>
              </nav>
            </div>

            <div className="header-right">
              <ul className="header-action">
                {!user?._id && (
                  <>
                    <li className="header-btn">
                      <a href="/signup" className="edu-btn btn-medium">
                        Signup
                      </a>
                    </li>
                    <li className="header-btn">
                      <a href="/signin" className="edu-btn btn-medium">
                        Login <i className="icon-4"></i>
                      </a>
                    </li>
                  </>
                )}
                <li className="mobile-menu-bar d-block d-xl-none">
                  <button className="hamberger-button" >
                    <i className="icon-54"></i>
                  </button>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>

      <div className="popup-mobile-menu">
        <div className="inner">
          <div className="header-top">
            <div className="logo">
              <a href="index.html">
                <img
                  className="logo-light img-logo"
                  src="../assets/images/logo/logo-v1.png"
                  alt="Corporate Logo"
                />
                <img
                  className="logo-dark img-logo"
                  src="../assets/images/logo/logo-v1.png"
                  alt="Corporate Logo"
                />
              </a>
            </div>
            <div className="close-menu">
              <button className="close-button">
                <i className="icon-73"></i>
              </button>
            </div>
          </div>
          <ul className="mainmenu">
            <li>
              <a href="/home">Home</a>
            </li>
            <li className="has-droupdown">
              <a href="/services">Services</a>
              {user?._id ? (
                <ul className="mega-menu">
                  <li>
                    <h6 className="menu-title themeColor">Insurance</h6>
                    <h4 className="subMenu-drop mb-4">Life Admin</h4>
                    <ul className="submenu mega-sub-menu-01">
                      <li>
                        <NavLink to="/customer/car-insurance">
                          Car Insurance
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/customer/home-insurance">
                          Home Insurance
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/customer/business-insurance">
                          Business Insurance
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h6 className="menu-title themeColor">Doctor Appointments</h6>
                    <h4 className="subMenu-drop mb-4">Life Admin</h4>
                    <ul className="submenu mega-sub-menu-01">
                      <li>
                        <NavLink to="/customer/gp">Group Practice</NavLink>
                      </li>
                      <li>
                        <NavLink to="/customer/private-hospital">
                          Private Hospital
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h6 className="menu-title themeColor">Car Penalty Charges</h6>
                    <h4 className="subMenu-drop mb-4">Life Admin</h4>
                    <ul className="submenu mega-sub-menu-01">
                      <li>
                        <NavLink to="/customer/parking-ticket">
                          Parking Ticket
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/customer/speeding-ticket">
                          Speeding Ticket
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/customer/congestion-charges">
                          Congestion Charges
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h6 className="menu-title themeColor">Utilities</h6>
                    <h4 className="subMenu-drop mb-4">Life Admin</h4>
                    <ul className="submenu mega-sub-menu-01">
                      <li>
                        <NavLink to="/customer/water">Water</NavLink>
                      </li>
                      <li>
                        <NavLink to="/customer/tv-license">
                          TV License
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/customer/gas">Gas</NavLink>
                      </li>
                      <li>
                        <NavLink to="/customer/electricity">
                          Electricity
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/customer/council-tax-bills">
                          Counsil Tax Bills
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NavLink to="/customer/personal-request">
                      {" "}
                      <h6 className="menu-title themeColor">Personal Request</h6>
                      <h4 className="subMenu-drop mb-4">Life Admin</h4>
                    </NavLink>
                    <ul className="submenu mega-sub-menu-01">
                      <li>
                        <NavLink to="/customer/personal-request">
                          Submit Personal Request
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              ) : (
                <ul className="mega-menu">
                  <li>
                    <h6 className="menu-title themeColor">Insurance</h6>
                    <h4 className="subMenu-drop">Life Admin</h4>
                    <ul className="submenu mega-sub-menu-01">
                      <li>
                        <h6 onClick={handleClick}>
                          Car Insurance
                        </h6>
                      </li>
                      <li>
                        <h6 onClick={handleClick}>
                          Home Insurance
                        </h6>
                      </li>
                      <li>
                        <h6 onClick={handleClick}>
                          Business Insurance
                        </h6>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h6 className="menu-title themeColor">Doctor Appointments</h6>
                    <h4 className="subMenu-drop">Life Admin</h4>
                    <ul className="submenu mega-sub-menu-01">
                      <li>
                        <h6 onClick={handleClick}>Group Practice</h6>
                      </li>
                      <li>
                        <h6 onClick={handleClick}>
                          Private Hospital
                        </h6>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h6 className="menu-title themeColor">Car Penalty Charges</h6>
                    <h4 className="subMenu-drop">Life Admin</h4>
                    <ul className="submenu mega-sub-menu-01">
                      <li>
                        <h6 onClick={handleClick}>
                          Parking Ticket
                        </h6>
                      </li>
                      <li>
                        <h6 onClick={handleClick}>
                          Speeding Ticket
                        </h6>
                      </li>
                      <li>
                        <h6 onClick={handleClick}>
                          Congestion Charges
                        </h6>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h6 className="menu-title themeColor">Utilities</h6>
                    <h4 className="subMenu-drop">Life Admin</h4>
                    <ul className="submenu mega-sub-menu-01">
                      <li>
                        <h6 onClick={handleClick}>Water</h6>
                      </li>
                      <li>
                        <h6 onClick={handleClick}>TV License</h6>
                      </li>
                      <li>
                        <h6 onClick={handleClick}>Gas</h6>
                      </li>
                      <li>
                        <h6 onClick={handleClick}>Electricity</h6>
                      </li>
                      <li>
                        <h6 onClick={handleClick}>
                          Counsil Tax Bills
                        </h6>
                      </li>
                    </ul>
                  </li>
                  <li>

                    <h6 className="menu-title themeColor">Personal Request</h6>
                    <h4 className="subMenu-drop">Life Admin</h4>
                    <ul className="submenu mega-sub-menu-01">
                      <li>
                        <h6 onClick={handleClick}>
                          Submit Personal Request
                        </h6>
                      </li>
                    </ul>
                  </li>
                </ul>
              )}
            </li>

            <li>
              <a href="/aboutUs">About Us</a>
            </li>

            <li>
              <a href="/contact">Contact Us</a>
            </li>
            {user?._id && (
              <li>
                <NavLink to="/customer/account">My Account</NavLink>
              </li>
            )}
            {!user?._id && (
              <>
                <li>
                  {" "}
                  <a href="/signup">Signup</a>
                </li>

                <li>
                  {" "}
                  <a href="/signin">Login</a>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>

      <div className="edu-search-popup">
        <div className="content-wrap">
          <div className="site-logo">
            <img
              className="logo-light"
              src="../assets/images/logo/logo-dark.png"
              alt="Corporate Logo"
            />
            <img
              className="logo-dark"
              src="../assets/images/logo/logo-white.png"
              alt="Corporate Logo"
            />
          </div>
          <div className="close-button">
            <button className="close-trigger">
              <i className="icon-73"></i>
            </button>
          </div>
          <div className="inner">
            <form className="search-form" action="#">
              <input
                type="text"
                className="edublink-search-popup-field"
                placeholder="Search Here..."
              />
              <button className="submit-button">
                <i className="icon-2"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavBar;
