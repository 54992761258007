import { useFormik } from "formik";
import React, { useState } from "react";
import { Business } from "../../models/businessInsurance";
import InsuranceService from "../../servises/InsuranceService";
import * as Yup from "yup"
import { FormFeedback, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { useContext } from "react";
import UserContext from "../context/UserContext";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const BusinessInsuranceQuote: React.FC = () => {
    const { t } = useTranslation();
    const [isLoading, setIsloading] = useState(false);
    const [user, setUser] = useContext(UserContext);

    const [phoneNumber, setPhoneNumber] = useState({ phoneNo: "" });
    const submitBusinessinfo = (values: Business) => {
        // Check if the user has a subscription plan
    if (user?.subscriptionStatus==="CANCELLED" || !user?.subscriptionId) {
        Swal.fire({
            icon: "warning",
            title: "in order to be able receive our services you will have to be subscribe",
            confirmButtonText: "Subscribe",
            confirmButtonColor: "#FD7F00",
            showCancelButton: true,
            cancelButtonText: "Cancel",
          }).then((result) => {
            if (result.isConfirmed) {
              // Navigate to subscription page
              window.location.href = "/account";
            }
          });
          return; // Stop further execution
        } else {
            setIsloading(true);
            InsuranceService.submitBusinessinfo(values).then((res: any) => {
                if (res.success) {
                    setIsloading(false);
                    Swal.fire({ title: "Informations Added" });
                } else {
                    setIsloading(false);
                    Swal.fire({ icon: "error", title: res.error, confirmButtonColor: "#FD7F00" });
                }
            });
        }
    };

    const BusinessInsurancForm = useFormik({

        initialValues: {
            name: "",
            email: "",
            industry: "",
            address: "",
            regNum: "",
            type: "",
            nameAndContactInfo: "",
            ownershipPrecentage: "",
            AnnualRevenue: "",
            numberOfEmployees: "",
            assestInfo: "",
            debtsInfo: "",
            insuranceCoverageType: "",
            liabilityLimit: "",
            deductibleAmount: "",
            specificEndorsements: "",
            insuranceClaims: "",
            securityMeasures: "",
            securityCertifications: "",
        },

        validationSchema: Yup.object({
            name: Yup.string().required(t<string>("Please Enter Business Name")),
            // phoneNo: Yup.string().required(t<string>("Please Enter phone Number")).matches(/^\+?\d{1,3}[- ]?\d{3}[- ]?\d{4}$/, { message: "Please Enter Valid Phone Number.", excludeEmptyString: false }),
            email: Yup.string().required(t<string>("Please Enter Business Email")).email(t<string>('Invalid Email')),
            industry: Yup.string().required(t<string>("Please Enter Industry")),
            address: Yup.string().required(t<string>("Please Enter Business Address")),
            regNum: Yup.string().required(t<string>("Please Enter Registration Number")),
            type: Yup.string().required(t<string>("Please Enter Business Type")),
            nameAndContactInfo: Yup.string().required(t<string>("Please enter Name and Contact Informations")),
            ownershipPrecentage: Yup.string().required(t<string>("Please EnterPercentage of Ownership for Each Owner")),
            AnnualRevenue: Yup.string().required(t<string>("Please Enter Annual Revenue")),
            numberOfEmployees: Yup.string().required(t<string>("Please Enter Number Of Employees")).matches(/^\d+$/, 'No of Employees Should be a Number'),
            assestInfo: Yup.string().required(t<string>("Please Enter Assest Info")),
            debtsInfo: Yup.string().required(t<string>("Please Enter Debts Info")),
            insuranceCoverageType: Yup.string().required(t<string>("Please Enter Insurance Coverage Type")),
            liabilityLimit: Yup.string().required(t<string>("Please Enter Liability Limit")),
            deductibleAmount: Yup.number().required(t<string>("Please Enter Deductible Amount")),
            specificEndorsements: Yup.string().required(t<string>("Please Enter Specific Endorsements")),
            insuranceClaims: Yup.string().required(t<string>("Please Enter Insurance Claims")),
            securityMeasures: Yup.string().required(t<string>("Please Enter Security Measures")),
            securityCertifications: Yup.string().required(t<string>("Please Enter Security Certifications")),

        }),



        onSubmit: (values, onSubmitProps) => {
            const dataObject: Business = {
                name: values.name,
                phoneNo: phoneNumber?.phoneNo,
                email: values.email,
                industry: values.industry,
                address: values.address,
                regNum: values.regNum,
                type: values.type,
                nameAndContactInfo: values.nameAndContactInfo,
                ownershipPrecentage: values.ownershipPrecentage,
                AnnualRevenue: values.AnnualRevenue,
                numberOfEmployees: values.numberOfEmployees,
                assestInfo: values.assestInfo,
                debtsInfo: values.debtsInfo,
                insuranceCoverageType: values.insuranceCoverageType,
                liabilityLimit: values.liabilityLimit,
                deductibleAmount: values.deductibleAmount,
                specificEndorsements: values.specificEndorsements,
                insuranceClaims: values.insuranceClaims,
                securityMeasures: values.securityMeasures,
                securityCertifications: values.securityCertifications,
              };
            submitBusinessinfo(dataObject)
            onSubmitProps.resetForm();
        }
    })

    return (
        <div className="login-page edu-footer footer-lighten bg-image footer-style-1">
            <section className="account-page-area section-gap-equal1">
                <div className="container-custom position-relative">
                    <div className="row g-5 justify-content-center">
                        <div className="col-lg-8">
                            <div className="login-form-box registration-form">
                                <div className="App">
                                    <div className="box">
                                        <div>
                                           
                                            <h3 className="title mb-20 mt-10">When obtaining business insurance in the UK, the following information is typically required:</h3>
                                            <p className="themeColor font-16 mb-20 mt-3">Insurance quotes life admin services provide assistance in obtaining and comparing insurance quotes. They handle tasks such as gathering necessary information, requesting quotes from multiple insurance providers, reviewing and presenting the options, and providing guidance to help individuals make informed decisions about their insurance coverage.
                                            </p>

                                            <h3 className="title mb-30">Business Information:</h3>

                                            <div className="row g-5 mb-20 justify-content-center">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-20">
                                                        <label htmlFor="reg-name">Business name</label>
                                                        <Input type="text" name="name" id="business-name"
                                                            value={BusinessInsurancForm.values.name}
                                                            onChange={BusinessInsurancForm.handleChange}
                                                            invalid={BusinessInsurancForm.touched.name && BusinessInsurancForm.errors.name ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessInsurancForm.errors.name}
                                                        </FormFeedback>
                                                    </div>

                                                    <div className="form-group mb-20">
                                                        <label htmlFor="reg-number">Business phone number</label>
                                                      
                                                        <PhoneInput
                                                            value={phoneNumber.phoneNo}
                                                            defaultCountry="GB" type="text" name="phoneNo" id="phoneNo"
                                                            onChange={(e: string) => setPhoneNumber({ ...phoneNumber, phoneNo: e })}
                                                        />
                                                    </div>

                                                    <div className="form-group mb-20">
                                                        <label htmlFor="log-email">Business email address</label>
                                                        <Input type="text" name="email" id="email" value={BusinessInsurancForm.values.email}
                                                            onChange={BusinessInsurancForm.handleChange}
                                                            invalid={BusinessInsurancForm.touched.email && BusinessInsurancForm.errors.email ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessInsurancForm.errors.email}
                                                        </FormFeedback>

                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="log-Industry">Business Industry</label>
                                                        <Input type="text" name="industry" id="log-Industry" value={BusinessInsurancForm.values.industry}
                                                            onChange={BusinessInsurancForm.handleChange}
                                                            invalid={BusinessInsurancForm.touched.industry && BusinessInsurancForm.errors.industry ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessInsurancForm.errors.industry}
                                                        </FormFeedback>

                                                    </div>
                                                </div>
                                                <div className="col-lg-6">

                                                    <div className="form-group mb-20">
                                                        <label htmlFor="reg-number">Business address</label>
                                                        <Input type="textarea" name="address" id="log-Driving" value={BusinessInsurancForm.values.address}
                                                            onChange={BusinessInsurancForm.handleChange}
                                                            invalid={BusinessInsurancForm.touched.address && BusinessInsurancForm.errors.address ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessInsurancForm.errors.address}
                                                        </FormFeedback>

                                                    </div>

                                                    <div className="form-group mb-20">
                                                        <label htmlFor="log-registration">Business registration number</label>
                                                        <Input type="text" name="regNum" id="log-registration" value={BusinessInsurancForm.values.regNum}
                                                            onChange={BusinessInsurancForm.handleChange}
                                                            invalid={BusinessInsurancForm.touched.regNum && BusinessInsurancForm.errors.regNum ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessInsurancForm.errors.regNum}
                                                        </FormFeedback>

                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="log-type">Business type</label>
                                                        <Input type="text" name="type" id="log-type" value={BusinessInsurancForm.values.type}
                                                            onChange={BusinessInsurancForm.handleChange}
                                                            invalid={BusinessInsurancForm.touched.type && BusinessInsurancForm.errors.type ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessInsurancForm.errors.type}
                                                        </FormFeedback>

                                                    </div>
                                                </div>
                                            </div>

                                            <hr className="mb-30 mt-40" />

                                            <h3 className="title mb-30">Ownership Information:</h3>

                                            <div className="row g-5 mb-20 justify-content-center">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label htmlFor="reg-contact">Names and contact information of all business owners and partners</label>
                                                        <Input type="text" name="nameAndContactInfo" id="reg-contact" value={BusinessInsurancForm.values.nameAndContactInfo}
                                                            onChange={BusinessInsurancForm.handleChange}
                                                            invalid={BusinessInsurancForm.touched.nameAndContactInfo && BusinessInsurancForm.errors.nameAndContactInfo ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessInsurancForm.errors.nameAndContactInfo}
                                                        </FormFeedback>

                                                    </div>
                                                </div>
                                                <div className="col-lg-6">

                                                    <div className="form-group">
                                                        <label htmlFor="log-Percentage">Percentage of ownership for each owner</label>
                                                        <Input type="number" name="ownershipPrecentage" id="log-Percentage" value={BusinessInsurancForm.values.ownershipPrecentage}
                                                            onChange={BusinessInsurancForm.handleChange}
                                                            invalid={BusinessInsurancForm.touched.ownershipPrecentage && BusinessInsurancForm.errors.ownershipPrecentage ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessInsurancForm.errors.ownershipPrecentage}
                                                        </FormFeedback>

                                                    </div>
                                                </div>
                                            </div>

                                            <hr className="mb-30 mt-40" />
                                            <h3 className="title mb-20">Financial Information:</h3>

                                            <div className="row g-5 mb-20 justify-content-center">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-20">
                                                        <label htmlFor="reg-revenue">Annual revenue</label>
                                                        <Input type="number" name="AnnualRevenue" id="reg-revenue" value={BusinessInsurancForm.values.AnnualRevenue}
                                                            onChange={BusinessInsurancForm.handleChange}
                                                            invalid={BusinessInsurancForm.touched.AnnualRevenue && BusinessInsurancForm.errors.AnnualRevenue ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessInsurancForm.errors.AnnualRevenue}
                                                        </FormFeedback>

                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="reg-employees">Number of employees</label>
                                                        <Input type="number" name="numberOfEmployees" id="reg-employees" value={BusinessInsurancForm.values.numberOfEmployees}
                                                            onChange={BusinessInsurancForm.handleChange}
                                                            invalid={BusinessInsurancForm.touched.numberOfEmployees && BusinessInsurancForm.errors.numberOfEmployees ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessInsurancForm.errors.numberOfEmployees}
                                                        </FormFeedback>

                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-20">
                                                        <label htmlFor="log-assets">Information about assets, inventory, and property</label>
                                                        <Input type="text" name="assestInfo" id="log-assets" value={BusinessInsurancForm.values.assestInfo}
                                                            onChange={BusinessInsurancForm.handleChange}
                                                            invalid={BusinessInsurancForm.touched.assestInfo && BusinessInsurancForm.errors.assestInfo ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessInsurancForm.errors.assestInfo}
                                                        </FormFeedback>

                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="log-liabilities">Information about debts, liabilities, and credit history</label>
                                                        <Input type="text" name="debtsInfo" id="log-liabilities" value={BusinessInsurancForm.values.debtsInfo}
                                                            onChange={BusinessInsurancForm.handleChange}
                                                            invalid={BusinessInsurancForm.touched.debtsInfo && BusinessInsurancForm.errors.debtsInfo ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessInsurancForm.errors.debtsInfo}
                                                        </FormFeedback>

                                                    </div>
                                                </div>
                                            </div>

                                            <hr className="mb-30 mt-40" />
                                            <h3 className="title mb-30">Insurance Information:</h3>

                                            <div className="row g-5 mb-20 justify-content-center">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-20">
                                                        <label htmlFor="reg-coverage">Type of insurance coverage needed (e.g. liability, property, workers' compensation, etc.)</label>
                                                        <Input type="text" name="insuranceCoverageType" id="reg-coverage" value={BusinessInsurancForm.values.insuranceCoverageType}
                                                            onChange={BusinessInsurancForm.handleChange}
                                                            invalid={BusinessInsurancForm.touched.insuranceCoverageType && BusinessInsurancForm.errors.insuranceCoverageType ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessInsurancForm.errors.insuranceCoverageType}
                                                        </FormFeedback>

                                                    </div>

                                                    <div className="form-group mb-20">
                                                        <label htmlFor="reg-amount">Deductible amount</label>
                                                        <Input type="number" name="deductibleAmount" id="reg-amount" value={BusinessInsurancForm.values.deductibleAmount}
                                                            onChange={BusinessInsurancForm.handleChange}
                                                            invalid={BusinessInsurancForm.touched.deductibleAmount && BusinessInsurancForm.errors.deductibleAmount ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessInsurancForm.errors.deductibleAmount}
                                                        </FormFeedback>

                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="reg-claims">Information about any prior insurance claims</label>
                                                        <Input type="text" name="insuranceClaims" id="reg-claims" value={BusinessInsurancForm.values.insuranceClaims}
                                                            onChange={BusinessInsurancForm.handleChange}
                                                            invalid={BusinessInsurancForm.touched.insuranceClaims && BusinessInsurancForm.errors.insuranceClaims ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessInsurancForm.errors.insuranceClaims}
                                                        </FormFeedback>

                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-20">
                                                        <label htmlFor="log-required">Limit of liability required</label>
                                                        <Input type="text" name="liabilityLimit" id="log-required" value={BusinessInsurancForm.values.liabilityLimit}
                                                            onChange={BusinessInsurancForm.handleChange}
                                                            invalid={BusinessInsurancForm.touched.liabilityLimit && BusinessInsurancForm.errors.liabilityLimit ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessInsurancForm.errors.liabilityLimit}
                                                        </FormFeedback>

                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="log-specific">Any specific coverage needs or endorsements</label>
                                                        <Input type="text" name="specificEndorsements" id="log-specific" value={BusinessInsurancForm.values.specificEndorsements}
                                                            onChange={BusinessInsurancForm.handleChange}
                                                            invalid={BusinessInsurancForm.touched.specificEndorsements && BusinessInsurancForm.errors.specificEndorsements ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessInsurancForm.errors.specificEndorsements}
                                                        </FormFeedback>

                                                    </div>
                                                </div>
                                            </div>

                                            <hr className="mb-30 mt-40" />
                                            <h3 className="title mb-30">Additional information:</h3>

                                            <div className="row g-5 mb-20 justify-content-center">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-20">
                                                        <label htmlFor="reg-security">Information about any safety and security measures in place</label>
                                                        <Input type="text" name="securityMeasures" id="reg-security" value={BusinessInsurancForm.values.securityMeasures}
                                                            onChange={BusinessInsurancForm.handleChange}
                                                            invalid={BusinessInsurancForm.touched.securityMeasures && BusinessInsurancForm.errors.securityMeasures ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessInsurancForm.errors.securityMeasures}
                                                        </FormFeedback>

                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label htmlFor="log-about">Information about any safety and security certifications</label>
                                                        <Input type="text" name="securityCertifications" id="log-about" value={BusinessInsurancForm.values.securityCertifications}
                                                            onChange={BusinessInsurancForm.handleChange}
                                                            invalid={BusinessInsurancForm.touched.securityCertifications && BusinessInsurancForm.errors.securityCertifications ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessInsurancForm.errors.securityCertifications}
                                                        </FormFeedback>

                                                    </div>
                                                </div>
                                            </div>

                                            <button className="btn colorchangeLog leadMargin edu-btn btn-medium mb-20" onClick={(e) => BusinessInsurancForm.handleSubmit()}>
                                                <div>Submit</div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    );
};

export default BusinessInsuranceQuote;
