export enum RouteName {
  ROOT = "/",
  SIGNIN = "/signin/",
  SIGNUP = "/signup",
  FORGETPASSWORD = "/forget_password",
  NAVBAR = "/navbar",
  HOME_PAGE = "/home/",
  SERVICES = "/services",
  INSURANCE_QUOTE_COMMON = "/insurace-quote",
  INSURANCE_QUOTE = "/customer/insurace-quote",
  CAR_INSURANCE_COMMON = "/car-insurance",
  CAR_INSURANCE = "/customer/car-insurance",
  HOME_INSURANCE_COMMON = "/home-insurance",
  HOME_INSURANCE = "/customer/home-insurance",
  BUSINESS_INSURANCE_COMMON = "/business-insurance",
  BUSINESS_INSURANCE = "/customer/business-insurance",
  BUSINESS_INSURANCE_QUOTE_COMMON = "/business-insurance-quote",
  BUSINESS_INSURANCE_QUOTE = "/customer/business-insurance-quote",
  BUSINESS_INSURANCE_PERSONAL_REQUEST = "/customer/business-insurance-personal-request",
  GP = "/customer/gp",
  GP_COMMON = "/gp",
  PRIVATE_HOSPITAL_COMMON = "/private-hospital",
  PRIVATE_HOSPITAL = "/customer/private-hospital",
  PARKING_TICKET = "/customer/parking-ticket",
  PARKING_TICKET_COMMON = "/parking-ticket",
  SPEEDING_TICKET = "/customer/speeding-ticket",
  SPEEDING_TICKET_COMMON = "/speeding-ticket",
  CONGESTION_CHARGES = "/customer/congestion-charges",
  CONGESTION_CHARGES_COMMON = "/congestion-charges",
  WATER = "/customer/water",
  WATER_COMMON = "/water",
  TV_LICENCE = "/customer/tv-license",
  TV_LICENCE_COMMON = "/tv-license",
  ELECTRICITY = "/customer/electricity",
  ELECTRICITY_COMMON = "/electricity",
  GAS = "/customer/gas",
  GAS_COMMON = "/gas",
  COUNCIL_TAX_BILLS = "/customer/council-tax-bills",
  COUNCIL_TAX_BILLS_COMMON = "/council-tax-bills",
  ACCOUNT = "/customer/account",
  PROFILE = "/customer/profile",
  CHANGE_PASSWORD = "/customer/change-password",
  PERSONAL_REQUEST_COMMON = "/personal-request",
  PERSONAL_REQUEST = "/customer/personal-request",
  CAR_INSURANCE_PERSONAL_REQUEST_COMMON = "/car-insurance-personal-request-submissions",
  CAR_INSURANCE_PERSONAL_REQUEST = "/customer/car-insurance-personal-request-submissions",
  HOME_INSURANCE_QUOTE = "/customer/home-insurance-quote",
  ADMIN_DASHBOARD = "/dashboard",
  ADMIN_USER_MANAGEMENT = "/user-management",
  ADMIN_VEHICLE_INSURANCE = "/vehicle-insurance",
  ADMIN_DOCTOR_APPOINTMENTS = "/doctor-appointments",
  ADMIN_PENALTY_CHARGES = "/penalty-charges",
  ADMIN_UTILIES = "/utilities",
  ADMIN_PERSONAL_REQUEST = "/personal-request-insurance",
  ADMIN_BUSINESS_INSURANCE = "/business-insurance-quotes",
  ADMIN_PERSONAL_REQUEST_SUBMISSIONS = "/personal-request-submissions",
  ADMIN_HOME_INSURANCE = "/business-home-quotes",
  ADMIN_COMPLETED_INSURANCE_PAYMENTS = "/completed-insurance-payments",
  ADMIN_COMPLETED_DOCTOR_APPOINTMENTS = "/completed-doctor-appointments",
  ADMIN_COMPLETED_PENALTY_CHARGES = "/completed-penalty-charges",
  ADMIN_COMPLETED_VEHICLE_INSURANCE = "/completed-vehicle-insurance",
  ADMIN_COMPLETED_UTILIES = "/completed-utilities",
  ADMIN_COMPLETED_PERSONAL_REQUEST = "/completed-personal-request-insurance",
  ADMIN_COMPLETED_PERSONAL_REQUEST_SUBMISSIONS = "/completed-personal-request-submissions",
  ADMIN_COMPLETED_BUSINESS_INSURANCE = "/completed-business-insurance-quote",
  ADMIN_COMPLETED_HOME_INSURANCE = "/completed-home-insurance-quote",
  HOME_INSURANCE_PERSONAL_REQUEST = "/customer/home-insurance-personal-request",
  ADMIN_EDIT_USER = "/edit-user/:customerId",
  ADMIN_SUBSCRIPTION_USERS = "/subscription-users",
  ADMIN_SINGLE_PAYMENT = "/single-payments" 
}
