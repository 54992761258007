import React, { useState, useContext, useEffect } from "react";
import img1 from "../assets/images/about/shape-13.png";
import img2 from "../assets/images/about/shape-15.png";
import img3 from "../assets/images/about/shape-07.png";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";
import { Util } from "../../Util";
import Swal from "sweetalert2";
import { AdminService } from "../../servises/AdminService";
import { DcotorAppointmentRequestDataObject } from "../../models/DoctorAppointment";
import Modal1 from "../popup/Modal1";
import { Row, Col, FormGroup, Input } from "reactstrap";
import moment from "moment";
import DoctorAppointmentsModel from "../popup/DoctorAppointmentsModal";

const AdminDoctorAppointments: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [offset, setOffset] = useState(1);
    const limit = 10;
    const [appointments, setAppointments] = useState<DcotorAppointmentRequestDataObject[]>([]);
    const [dataSingle, setDataSingle] = useState<DcotorAppointmentRequestDataObject>({} as DcotorAppointmentRequestDataObject);
    const [isOpenData, setIsOpenData] = React.useState(false);
    const [count, setCount] = useState(10);
    const [isSearchableString, setIsSearchableString] = useState({ searchableString: null });
    const [counts, setCounts] = useState(0);
    useEffect(() => {
        getAllDoctorAppointments();
        getAllPendingPayments();
    }, []);


    const getAllDoctorAppointments = () => {
        const data = {
            appointmentType: "ALL",
            paymentStatus: "PENDING"
        };

        setIsLoading(true);
        AdminService.getAllAppointments(data, limit, 1).then(res => {
            if (res.success) {
                setAppointments(res.data);
                setCount(res.data?.length);
                setIsLoading(false);
            } else {
                setAppointments([]);
                setIsLoading(false);
            }
        });
    };

    const getAllPendingPayments = () => {
        AdminService.getAllPendingPayments().then(res => {
            if (res.success) {
                setCounts(res.data?.doctorAppoinmentsCount)
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        });
    };

    const filterDoctorAppointments = (value: any) => {
        const data = {
            appointmentType: value,
            paymentStatus: "PENDING"
        };

        setIsLoading(true);
        AdminService.getAllAppointments(data, limit, 1).then(res => {
            if (res.success) {
                setAppointments(res.data);
                setCount(res.data?.length);
                setIsLoading(false);
            } else {
                setAppointments([]);
                setIsLoading(false);
            }
        });
    };

    const seeMore = () => {
        const updatedSkip = offset + 1;

        setOffset(updatedSkip);

        const data = {
            appointmentType: "ALL",
            paymentStatus: "PENDING"
        };


        setIsLoading(true);

        AdminService.getAllAppointments(data, 10, updatedSkip).then(res => {
            if (res.success) {
                setAppointments(res.data)
            }

            setIsLoading(false);
        });
    };

    const previous = () => {
        const updatedSkip = offset - 1;

        setOffset(updatedSkip);

        const data = {
            appointmentType: "ALL",
            paymentStatus: "PENDING"
        };


        setIsLoading(true);

        AdminService.getAllAppointments(data, 10, updatedSkip).then(res => {
            if (res.success) {
                setAppointments(res.data)
            }

            setIsLoading(false);
        });
    };



    const setShowData = (ap: any) => {
        setDataSingle(ap);
        setIsOpenData(!isOpenData);
    }

    const completeDoctorAppoinmentPayment = (e: any) => {
        const doctorAppoinmentId = e.currentTarget.getAttribute("data-value1");
        Swal.fire({
            icon: "info",
            title: "Are you sure want to approve this payment?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#50a5f1",
            cancelButtonText: "No",
            cancelButtonColor: "#f46a6a",
        }).then(result => {
            if (result.isConfirmed) {
                const data = {
                    appointmentId: doctorAppoinmentId,
                    paymentStatusType: "COMPLETED"
                }

                AdminService.updatePaymentStatusAppointmentPayments(data).then(res => {
                    getAllDoctorAppointments();
                });
            }
        });
    }

    const searchCustomers = (onload: boolean) => {
        const data = {
            searchableString: isSearchableString.searchableString,
            paymentStatus: "PENDING"
        }

        setIsLoading(true);

        AdminService.searchDoctorAppointmentsByAdmin(data, limit, 0).then(res => {
            if (res.success) {
                setAppointments(res.data.userSet);
                setCounts(res.data.userSet.length);
                setOffset(1);
            }
            setIsLoading(false);
        });
    }

    return (
        <div>
            <div className="edu-breadcrumb-area breadcrumb-style-3">
                <div className="container">
                    <div className="breadcrumb-inner accHegiht">
                    </div>
                </div>
                <ul className="shape-group">
                    <li className="shape-1">
                        <span></span>
                    </li>
                    <li className="shape-2 scene"><img data-depth="2" src={img1} alt="shape" /></li>
                    <li className="shape-3 scene"><img data-depth="-2" src={img2} alt="shape" /></li>
                    <li className="shape-4">
                        <span></span>
                    </li>
                    <li className="shape-5 scene"><img data-depth="2" src={img3} alt="shape" /></li>
                </ul>
            </div>

            <section className="edu-section-gap course-details-area">
                <div className="container">
                    <div className="row row--30">

                        <div className="col-lg-12">
                            <div className="course-sidebar-3 sidebar-top-position">
                                <div className="edu-course-widget widget-course-summery">
                                    <div className="row row--30 mb-5">
                                        <div className="col-lg-7 ">
                                            <h3 className="acc mb-4 pl-2 mt-5">Doctor Appointments</h3>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="edu-sorting-area">
                                                <div className="sorting-right">
                                                    <div className="edu-sorting">
                                                        <div className="icon"><i className="icon-55"></i></div>
                                                        <select className="edu-select searchTermDrop" onChange={(e) => filterDoctorAppointments(e.target.value)}>
                                                            <option value="ALL" className="options">All</option>
                                                            <option value="GP" className="options">GP</option>
                                                            <option value="PRIVATE_HOSPITAL" className="options">Private Hospital</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="wrap">
                                                <div className="search">
                                                    <input type="text" className="searchTerm" placeholder="Search Customer" onChange={(e: any) => setIsSearchableString({ ...isSearchableString, searchableString: e.target.value })} />
                                                    <button type="submit" className="searchButton" onClick={() => searchCustomers(false)}>
                                                        <i className="fa fa-search"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {appointments !== undefined && appointments.length > 0 ? (
                                        <>
                                            <div className="table-responsive">
                                                <table className="table cart-table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="product-title">Patient Name</th>
                                                            <th scope="col" className="product-price">Appointment Type</th>
                                                            <th scope="col" className="product-subtotal">Date</th>
                                                            <th scope="col" className="product-subtotal">Phone</th>
                                                            <th scope="col" className="product-subtotal">Payment Status</th>
                                                            <th scope="col" className="product-subtotal">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {appointments &&
                                                            appointments.map((ap: DcotorAppointmentRequestDataObject, index: number) => (
                                                                <tr key={index}>
                                                                    <td className="product-price" data-title="Level">
                                                                        <div className="d-flex align-items-center mr-2">
                                                                            {!ap?.userId?.profileImageId || ap?.userId?.profileImageId == undefined ? (
                                                                                <div className="avatar-md-table rounded-circle bg-soft card-avatar relative">
                                                                                    <span className="avatar-title text-size-avatr-table text-center-div">{ap?.patientName?.charAt(0)}</span>
                                                                                </div>
                                                                            ) : (
                                                                                <div >
                                                                                    <div
                                                                                        className="img-thumbnail imageFit rounded-circle imageFit avatar-2xl-table3 profile-user-wid-pro items-center margin_center profileImageShow"
                                                                                        style={{
                                                                                            backgroundImage: `url("${Util.fileURL(ap?.userId?.profileImageId?._id)}")`,
                                                                                            borderRadius: "10px",
                                                                                            position: "relative",
                                                                                        }}
                                                                                    ></div>
                                                                                </div>
                                                                            )}
                                                                            <span className="pl-1">{ap?.patientName}</span></div></td>
                                                                    <td className="product-price" data-title="Level">{ap?.appointmentType}</td>
                                                                    <td className="product-price" data-title="Amount">{moment(ap?.appointmentDate).format('MMMM Do YYYY')}</td>
                                                                    <td className="product-price" data-title="Amount">{ap?.patientPhoneNumber}</td>
                                                                    <td className="product-price" data-title="Status">
                                                                        <span className="newtag">{ap?.paymentStatus}</span></td>
                                                                    <td className="product-price" data-title="Level">
                                                                        <button className="btn btn-success mr-10" onClick={completeDoctorAppoinmentPayment} data-value1={ap._id} >Approve</button>
                                                                        <button className="btn btn-primary" onClick={() => setShowData(ap)} data-value1={ap._id} >View</button>
                                                                    </td>
                                                                </tr>

                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <DoctorAppointmentsModel
                                                trigger={isOpenData}
                                                setTrigger={setIsOpenData}
                                                data={dataSingle}
                                            />
                                            <div className="pagination">
                                                <button className="prev" onClick={previous} disabled={offset >= 2 ? false : true}>Previous</button>
                                                <div className="page-count">Page {offset}/{Math.ceil(counts / 10)}</div>
                                                <button className="next" onClick={seeMore} disabled={offset === Math.ceil(counts / 10) ? true : false}>Next</button>
                                            </div>
                                        </>
                                    ) : (
                                        <>{isLoading ? <div /> : <div>No Doctor Appointments</div>}</>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    );
};

export default AdminDoctorAppointments;
