import { Util } from "../Util";
import { AppResponse } from "../models/Response";
import axios from "axios";
import { User } from "../models/User";
import { MedicalProfile } from "../models/MedicalProfile";
import { CarProfile } from "../models/CarProfile";
export class CommonService {
  public static async addCustomer(data: Partial<User>): Promise<AppResponse<User>> {
    const url = Util.apiPublicUrl("register/customer");
    return await axios.post<Partial<User>, AppResponse<User>>(url, data);
  }

  public static async forgotPassword1(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiPublicUrl("forgot-password");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async forgotPassword2(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiPublicUrl("confirm/otp");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }


  public static async customerVerification(data: any): Promise<AppResponse<any>> {
    const url = Util.apiPublicUrl("verifyByCode");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }


  public static async newPassword(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiPublicUrl("update/password");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async updateCustomer(data: Partial<User>): Promise<AppResponse<User>> {
    const url = Util.apiAuthUrl("update/customer");
    return await axios.post<Partial<User>, AppResponse<User>>(url, data);
  }

  public static async changePassword(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("reset/password");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async addMedicalProfileInfo(data: Partial<MedicalProfile>): Promise<AppResponse<MedicalProfile>> {
    const url = Util.apiAuthUrl("update/medical-profile");
    return await axios.post<Partial<MedicalProfile>, AppResponse<MedicalProfile>>(url, data);
  }

  public static async addCarProfileInfo(data: Partial<CarProfile>): Promise<AppResponse<CarProfile>> {
    const url = Util.apiAuthUrl("update/car-profile");
    return await axios.post<Partial<CarProfile>, AppResponse<CarProfile>>(url, data);
  }

  public static async getCarProfileInfo(limit?: number, offset?: number): Promise<AppResponse<any>>{
    const url = Util.apiAuthUrl("car-info/" + limit + "/" + offset);
    return await axios.get<Partial<CarProfile>, AppResponse<any>>(url);
  }

  public static async updateFiles(
    fileToUpload: any
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("upload/identification-info");
    const formData: FormData = new FormData();

    for (const file of fileToUpload) {
      formData.append("identicationInfo", file);
    }

    return await axios.post<FormData, AppResponse<any>>(url, formData);
  }
}
