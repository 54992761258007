import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import img1 from "../assets/images/about/shape-13.png";
import img2 from "../assets/images/about/shape-15.png";
import img3 from "../assets/images/about/shape-07.png";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";
import { UserDetails } from "../../models/User";
import Swal from "sweetalert2";
import { AdminService } from "../../servises/AdminService";
import { Row, Col, FormGroup, Input } from "reactstrap";

const AdminUserManagement: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState<UserDetails[]>([]);
    const [offset, setOffset] = useState(1);
    const limit = 10;
    const [count, setCount] = useState(10);
    const [userCounts, setUserCounts] = useState(0);
    const [isSearchableString, setIsSearchableString] = useState({ searchableString: null });
    useEffect(() => {
        getAllUsers();
        getAllUserCounts();
    }, []);

    const getAllUsers = () => {
        AdminService.getAllUsers(limit, 1).then(res => {
            if (res.success) {
                setUsers(res.data);
                setCount(res.data?.length);
                setIsLoading(false);
            } else {
                setUsers([]);
                setIsLoading(false);
            }
        });
    };

    const getAllUserCounts = () => {
        AdminService.getAllUserCounts().then(res => {
            if (res.success) {
                setUserCounts(res.data)
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        });
    };
    const deleteUser = (userId: string | undefined) => {
        if (userId) {
            Swal.fire({
                icon: "warning",
                title: "Are you sure you want to block?",
                showCancelButton: true,
                confirmButtonText: "Block",
                confirmButtonColor: "#FD7F00",
                cancelButtonColor: "#FD7F00",
            }).then(result => {
                if (result.isConfirmed) {
                    AdminService.blockUser(userId).then(res => {
                        if (res.success) {
                            Swal.fire({
                                icon: "success",
                                title: "User blocked!",
                                confirmButtonColor: "#FD7F00",
                            });
                            getAllUsers();
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: "Failed to block the user.",
                                confirmButtonColor: "#FD7F00",
                            });
                        }
                    });
                }
            });
        }
    };


    const seeMore = () => {
        const updatedSkip = offset + 1;

        setOffset(updatedSkip);


        setIsLoading(true);

        AdminService.getAllUsers(10, updatedSkip).then(res => {
            if (res.success) {
                setUsers(res.data)
            }

            setIsLoading(false);
        });
    };


    const previous = () => {
        const updatedSkip = offset - 1;

        setOffset(updatedSkip);


        setIsLoading(true);

        AdminService.getAllUsers(10, updatedSkip).then(res => {
            if (res.success) {
                setUsers(res.data)
            }

            setIsLoading(false);
        });
    };


    const searchCustomers = (onload: boolean) => {
        const data = {
            searchableString: isSearchableString.searchableString,
        }

        setIsLoading(true);

        AdminService.searchUsersByAdmin(data, limit, 0).then(res => {
            if (res.success) {
                setUsers(res.data.userSet);
                setCount(res.data.userSet.length);
                setOffset(1);
            }
            setIsLoading(false);
        });
    }

    return (
        <div>
            <div className="edu-breadcrumb-area breadcrumb-style-3">
                <div className="container">
                    <div className="breadcrumb-inner accHegiht">

                    </div>
                </div>
                <ul className="shape-group">
                    <li className="shape-1">
                        <span></span>
                    </li>
                    <li className="shape-2 scene"><img data-depth="2" src={img1} alt="shape" /></li>
                    <li className="shape-3 scene"><img data-depth="-2" src={img2} alt="shape" /></li>
                    <li className="shape-4">
                        <span></span>
                    </li>
                    <li className="shape-5 scene"><img data-depth="2" src={img3} alt="shape" /></li>
                </ul>
            </div>

            <section className="edu-section-gap course-details-area">
                <div className="container">
                    <div className="row row--30">

                        <div className="col-lg-12">
                            <div className="course-sidebar-3 sidebar-top-position">
                                <div className="edu-course-widget widget-course-summery">
                                    <Row className="mt-4">
                                        <Col lg={9}>
                                            {/* <h3 className="acc mb-4 pl-2">Users</h3> */}
                                        </Col>
                                        <Col lg={3}>
                                            <div className="wrap mb-5">
                                                <div className="search">
                                                    <input type="text" className="searchTerm" placeholder="Search Users " onChange={(e: any) => setIsSearchableString({ ...isSearchableString, searchableString: e.target.value })} />
                                                    <button type="submit" className="searchButton" onClick={() => searchCustomers(false)}>
                                                        <i className="fa fa-search"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    {users !== undefined && users.length > 0 ? (
                                        <>
                                            <div className="table-responsive">
                                                <table className="table cart-table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="product-title">Name</th>
                                                            <th scope="col" className="product-price">Email</th>
                                                            <th scope="col" className="product-subtotal">Post Code</th>
                                                            <th scope="col" className="product-subtotal">Address</th>
                                                            <th scope="col" className="product-subtotal">Phone</th>
                                                            <th scope="col" className="product-subtotal">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {users &&
                                                            users.map((us: UserDetails, index: number) => (
                                                                <tr key={index}>
                                                                    <td className="product-price" data-title="Date">{us?.firstName} {us?.lastName}</td>
                                                                    <td className="product-price" data-title="Level">{us?.email}</td>
                                                                    <td className="product-price" data-title="Status">{us?.postCode}</td>
                                                                    <td className="product-price" data-title="Date">{us?.address} </td>
                                                                    <td className="product-price" data-title="Level">{us?.phoneNumber}</td>
                                                                    <td className="product-price" data-title="Level">
                                                                        <div className="btn" >
                                                                            <NavLink to={"/edit-user/" + us?._id} className="btn btn-sm EdBtn" title="Edit">
                                                                                <i className="ri-edit-2-line" style={{ fontSize: "20px" }}></i>
                                                                            </NavLink>
                                                                        </div>
                                                                        <button
                                                                            className="btn btn-sm EdBtn"
                                                                            onClick={() => deleteUser(us?._id)}
                                                                            title="Delete">
                                                                            <i className="ri-delete-bin-line" style={{ fontSize: "20px", color: "#F45656" }}></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                                <div className="pagination">
                                                    <button className="prev" onClick={previous} disabled={offset >= 2 ? false : true}>Previous</button>
                                                    <div className="page-count">Page {offset}/{Math.ceil(userCounts / 10)}</div>
                                                    <button className="next" onClick={seeMore} disabled={offset === Math.ceil(userCounts / 10) ? true : false}>Next</button>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>{isLoading ? <div /> : <div>No users Available</div>}</>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
};

export default AdminUserManagement;
