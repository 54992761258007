import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";
import { CommonService } from "../../servises/CommonService";
import swal from "sweetalert";
import { RouteName } from "../../RouteName";

const ChangePassword: React.FC = () => {
    const initialState = { currentPassword: "", newPassword: "", confirmNewPassword: "" };
    const [userData, setUserData] = useState(initialState);
    const history = useHistory();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordVisible1, setPasswordVisible1] = useState(false);
    const [passwordVisible2, setPasswordVisible2] = useState(false);
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const togglePasswordVisibility1 = () => {
        setPasswordVisible1(!passwordVisible1);
    };
    const togglePasswordVisibility2 = () => {
        setPasswordVisible2(!passwordVisible2);
    };
    const updateClientDetails = () => {

        if (!userData?.currentPassword) {
            swal({ icon: "error", title: "Please enter Name !" });
            return;
        } else if (!userData?.newPassword) {
            swal({ icon: "error", title: "Please enter User Name !" });
            return;
        } else if (userData.newPassword !== userData.confirmNewPassword) {
            swal({ icon: "error", title: "Passwords do not match!" });
            return;
        }
        else {

            const updatedUser: any = {
                currentPassword: userData?.currentPassword,
                newPassword: userData?.newPassword,
            };

            CommonService.changePassword(updatedUser).then((res) => {
                if (res.success) {
                    swal({ icon: "success", title: res.message });
                    localStorage.clear();
                    sessionStorage.clear();
                    history.push(RouteName.SIGNIN);
                    window.location.reload();
                } else {
                    swal({ icon: "error", title: res.error });
                }
            });
            return;
        }
    };


    return (
        <div className="login-page edu-footer footer-lighten bg-image footer-style-1">
            <section className="account-page-area section-gap-equal1">
                <div className="container-custom position-relative">
                    <div className="row g-5 justify-content-center">
                        <div className="col-lg-8">
                            <div className="row ">
                                <div className="login-form-box registration-form ">
                                    <div className="App">
                                        <div className="box">
                                            <h3 className="title mb-30 mt-10">Change Password</h3>
                                            <div className="row g-5 mb-20 justify-content-center">
                                                <div className="col-lg-12">
                                                    <div className="form-group mb-4">
                                                        <label >Current Password</label>
                                                        <input type={passwordVisible ? 'text' : 'password'} name="current-log-password" id="loginPassword"
                                                            placeholder="Password" onChange={(e) => setUserData({ ...userData, currentPassword: e.target.value })}  />
                                                        <span className="password-show" id="togglePassword" onClick={togglePasswordVisibility}><i className="icon-76"></i></span>
                                                    </div>

                                                    <div className="form-group mb-4">
                                                        <label >New Password</label>
                                                        <input type={passwordVisible1 ? 'text' : 'password'} name="current-log-password" id="loginPassword" onChange={(e) => setUserData({ ...userData, newPassword: e.target.value })}
                                                            placeholder="Password" />
                                                        <span className="password-show" id="togglePassword" onClick={togglePasswordVisibility1}><i className="icon-76"></i></span>
                                                    </div>
                                                    
                                                    <p className="mb-20">Hint: The password should be at least twelve characters long. To make it stronger, use upper and lower case letters, numbers, and symbols like ! " ? $ % ^ & ).</p>


                                                    <div className="form-group">
                                                        <label >Confirm New Password</label>
                                                        <input type={passwordVisible2 ? 'text' : 'password'} name="current-log-password" id="loginPassword"  onChange={(e) => setUserData({ ...userData, confirmNewPassword: e.target.value })}
                                                            placeholder="Password" />
                                                        <span className="password-show" id="togglePassword" onClick={togglePasswordVisibility2}><i className="icon-76"></i></span>
                                                    </div>


                                                </div>

                                            </div>


                                            <div className="header-right mb-3 leadMargin float-right">
                                                <ul className="header-action ">
                                                    <li className="header-btn ">
                                                        <button className="edu-btn btn-medium edu-btnColor" onClick={updateClientDetails}>Change Password</button>
                                                    </li>
                                                    <li className="header-btn">
                                                        <a href="/account" className="cancel-btn btn-medium">Cancel </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </div >
    );
};

export default ChangePassword;
