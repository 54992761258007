import React from "react";
import { Col, Row, Modal, Input } from "reactstrap";

const Modal2: React.FC<{ trigger?: boolean; setTrigger?: any; data: any }> = (props) => {
    const { data } = props;
    const { updatedAt, createdAt, __v, ...otherData } = data;
    const formattedCreatedAt = new Date(createdAt).toLocaleDateString();
    const entries = Object.entries(otherData);
    return (props.trigger) ? (
        <>
            <Modal isOpen={props.trigger} centered >
                <div className="modal-header"> 
                    <button
                        type="button"
                        onClick={() => props.setTrigger(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span>&times;</span>
                    </button>
                </div>
                <div className="modal-body mt-4">
                    <h4 className="title mb-30 mt-10 font-24 themeColor">Car Information:</h4>
                    <div className="row g-5 mb-20 justify-content-center">
                        <div className="col-lg-6">
                            <div className="form-group mb-20">
                                <label htmlFor="reg-Name">Make and model of the car:</label>
                                <Input
                                    type="text"
                                    name="makeAndModel"
                                    className="input-boarder"
                                    id="makeAndModel"
                                    value={data?.makeAndModel}
                                    disabled

                                />
                            </div>

                            <div className="form-group mb-20">
                                <label htmlFor="reg-Name">Color:</label>
                                <Input
                                    type="text"
                                    name="color"
                                    className="input-boarder"
                                    id="color"
                                    value={data?.color}
                                    disabled
                                />
                            </div>

                            <div className="form-group mb-20">
                                <label htmlFor="reg-Name">Current milage reading:</label>
                                <Input
                                    type="text"
                                    name="milage"
                                    className="input-boarder"
                                    id="milage"
                                    value={data?.milage}
                                    disabled

                                />
                            </div>

                        </div>
                        <div className="col-lg-6">

                            <div className="form-group mb-20">
                                <label htmlFor="log-manufacture">Year of manufacture:</label>
                                <Input
                                    type="text"
                                    name="milage"
                                    className="input-boarder"
                                    id="milage"
                                    value={data?.manufacturedYear}
                                    disabled

                                />

                            </div>
                            <div className="form-group mb-20">
                                <label htmlFor="log-identification">VIN (vehicle identification number):</label>
                                <Input
                                    type="text"
                                    name="vehicleIdentificationNumber"
                                    className="input-boarder"
                                    id="vehicleIdentificationNumber"
                                    value={data?.vehicleIdentificationNumber}
                                    disabled

                                />
                            </div>

                            <div className="form-group mb-20">
                                <label htmlFor="log-identification">License Plate:</label>
                                <Input
                                    type="text"
                                    name="licensePlate"
                                    className="input-boarder"
                                    id="licensePlate"
                                    value={data?.licensePlate}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>


                    <h4 className="title mb-30 mt-10 font-24 themeColor">Ownership Information:</h4>


                    <div className="row g-5 mb-20 justify-content-center">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label htmlFor="reg-owner">Name of the registered owner:</label>
                                <Input
                                    type="text"
                                    name="nameOfTheRegisteredOwner"
                                    className="input-boarder"
                                    id="nameOfTheRegisteredOwner"
                                    value={data?.nameOfTheRegisteredOwner}
                                    disabled

                                />
                            </div>
                        </div>
                    </div>
                    <hr className="mb-30 mt-40" />
                    <h4 className="title mb-30 mt-10 font-24 themeColor">Insurance Information:</h4>


                    <div className="row g-5 mb-20 justify-content-center">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="reg-Insurance ">Insurance company name:</label>
                                <Input
                                    type="text"
                                    name="insuranceCompanyName"
                                    className="input-boarder"
                                    id="insuranceCompanyName"
                                    value={data?.insuranceCompanyName}
                                    disabled

                                />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="reg-Policy">Policy number:</label>
                                <Input
                                    type="number"
                                    name="policyNumber"
                                    className="input-boarder"
                                    id="policyNumber"
                                    value={data?.policyNumber}
                                    disabled

                                />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="reg-owner">Expiration date:</label>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="Date of birth"
                                    value={data?.expirationData}
                                    disabled
                                    required
                                />
                            </div>
                        </div>
                    </div>


                    <hr className="mb-30 mt-40" />
                    <h4 className="title mb-30 mt-10 font-24 themeColor">Additional Information:</h4>


                    <div className="row g-5 mb-20 justify-content-center">
                        <div className="col-lg-6">
                            <div className="form-group mb-20">
                                <label htmlFor="reg-Name">Any special features or modifications:</label>
                                <Input
                                    type="textarea"
                                    name="specialFeaturesOrModifications"
                                    className="input-boarder"
                                    id="specialFeaturesOrModifications"
                                    value={data?.specialFeaturesOrModifications}
                                    disabled

                                />
                            </div>
                            <div className="form-group mb-20">
                                <label htmlFor="reg-Name">Any warranties:</label>
                                <Input
                                    type="textarea"
                                    name="warranties"
                                    className="input-boarder"
                                    id="warranties"
                                    value={data?.warranties}
                                    disabled

                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group mb-20">
                                <label htmlFor="reg-Name">Any accident history:</label>
                                <Input
                                    type="textarea"
                                    name="accidentHistory"
                                    className="input-boarder"
                                    id="accidentHistory"
                                    value={data?.accidentHistory}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </Modal>
        </>
    ) : null;
}

export default Modal2;