import React, { useState, useContext, useRef, useEffect } from "react";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";
import "react-phone-number-input/style.css";
import moment from "moment";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Form, Input } from "reactstrap";
import InsuranceService from "../../servises/InsuranceService";
import Swal from "sweetalert2";
import { vanInsuranceObject } from "../../models/VanInsurance";
import { BikeInsuranceObject } from "../../models/BikeInsurance";
import { CarProfile } from "../../models/CarProfile";
import { CommonService } from "../../servises/CommonService";
import PhoneInput from "react-phone-number-input";
import UserContext from "../context/UserContext";
import { useHistory } from "react-router";

const InsuranceQuote: React.FC = () => {
    const [stepNumber, setStepNumber] = useState(1);
    const [stepNumber1, setStepNumber1] = useState(1);
    const [stepNumber2, setStepNumber2] = useState(1);
    const [phoneNumber, setPhoneNumber] = useState({ phoneNo: "" });
    const [phoneNumber1, setPhoneNumber1] = useState({ phoneNo: "" });
    const { t } = useTranslation();
    const [vehicalData, setVehicalData] = useState<{}>({} as {});
    const vehicalDataRef = useRef<any>();
    vehicalDataRef.current = vehicalData;
    const limit = 10;
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({})
    const [categoryImage, setCategoryImage] = useState({ raw: "" });
    const [carProfile, setCarProfile] = useState<CarProfile[]>([]);
    const [user, setUser] = useContext(UserContext);
    const history = useHistory();

    const imageUpload = (e: any): void => {
        setCategoryImage({
            raw: e.target.files[0],
        });
    };


    // Van insurance
    const submitVanInsuranceQuotetInfo = (values: vanInsuranceObject) => {
        // Check if the confirmation checkbox is checked
        const rememberMeCheckbox = document.getElementById("remember-me") as HTMLInputElement;
        if (!rememberMeCheckbox.checked) {
            Swal.fire({
                icon: "warning",
                title: "Please confirm that you have read and understood our Terms & Conditions and Privacy Policy.",
                confirmButtonColor: "#FD7F00",
            });
            return; // Stop further execution
        }
        // Check if the user has a subscription plan
        else if (user?.subscriptionStatus === "CANCELLED" || !user?.subscriptionId) {
            Swal.fire({
                icon: "warning",
                title: "in order to be able receive our services you will have to be subscribe",
                confirmButtonText: "Subscribe",
                confirmButtonColor: "#FD7F00",
                showCancelButton: true,
                cancelButtonText: "Cancel",
            }).then((result) => {
                if (result.isConfirmed) {
                    // Navigate to subscription page
                    window.location.href =  "/account";
                }
            });
            return; // Stop further execution
        } else {
            setIsLoading(true);
            InsuranceService.submitVanInsuranceQuotetInfo(values).then((res: any) => {
                if (res.success) {
                    setIsLoading(false);
                    Swal.fire({ title: "Informations Added" })
                    history.go(0)
                    
                } else {
                    setIsLoading(false);
                    Swal.fire({ icon: "error", title: res.error, confirmButtonColor: "#FD7F00" });
                }
            });
        }
    };

    //Bike insurance 
    const submitBikeInsuranceQuotetInfo = (values: BikeInsuranceObject) => {
        const rememberMeCheckbox = document.getElementById("remember-me") as HTMLInputElement;
        if (!rememberMeCheckbox.checked) {
            Swal.fire({
                icon: "warning",
                title: "Please confirm that you have read and understood our Terms & Conditions and Privacy Policy.",
                confirmButtonColor: "#FD7F00",
            });
            return; // Stop further execution
        }
        // Check if the user has a subscription plan
        else if (user?.subscriptionStatus === "CANCELLED" || !user?.subscriptionId) {
            Swal.fire({
                icon: "warning",
                title: "in order to be able receive our services you will have to be subscribe",
                confirmButtonText: "Subscribe",
                confirmButtonColor: "#FD7F00",
                showCancelButton: true,
                cancelButtonText: "Cancel",
            }).then((result) => {
                if (result.isConfirmed) {
                    // Navigate to subscription page
                    window.location.href = "/account";
                }
            });
            return; // Stop further execution
        } else {

            setIsLoading(true);
            InsuranceService.submitBikeInsuranceQuotetInfo(values).then((res: any) => {
                if (res.success) {
                    setIsLoading(false);
                    Swal.fire({ title: "Informations Added" });
                    history.go(0)
                } else {
                    setIsLoading(false);
                    Swal.fire({ icon: "error", title: res.error, confirmButtonColor: "#FD7F00" });
                }
            });
        }
    };

    useEffect(() => {
        setVehicalData({
            ...vehicalDataRef.current,
            services_or_personal_request: `car`,
            color: "#1293E3",
            colors: "#ced4da",
            colorss: "#ced4da",
        });
    }, []);

    useEffect(() => {
        getAllUsers();
    }, []);

    const getAllUsers = () => {
        CommonService.getCarProfileInfo(limit, 1).then(res => {
            if (res.success) {
                setCarProfile(res.data);
                setIsLoading(false);
            } else {
                setCarProfile([]);
                setIsLoading(false);
            }
        });
    };
    const setInsuranceBtn = (value: any) => {
        if (value == `car`) {
            setVehicalData({
                ...vehicalDataRef.current,
                services_or_personal_request: `car`,
                color: "#1293E3",
                colors: "#ced4da",
                colorss: "#ced4da",
            });
        } else if (value == `van`) {
            setVehicalData({
                ...vehicalDataRef.current,
                services_or_personal_request: `van`,
                color: "#ced4da",
                colors: "#1293E3",
                colorss: "#ced4da",
            });
        }
        else {
            setVehicalData({
                ...vehicalDataRef.current,
                services_or_personal_request: `bike`,
                color: "#ced4da",
                colors: "#ced4da",
                colorss: "#1293E3",
            });
        }
    };

    const validationStep2 = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            regNum: "",
            carModel: "",
            doors: "4",
            engineSize: "",
            insuranceInfo: "",
            drivingHistory: "",
            carMake: "",
            yearOfManufacture: "",
            transmission: "",
            fuelType: "",
            insuranceContractCoppy: "",
            carValue: "",
            drivingSide: "",
            seatCount: "",
            isCarImport: "",
            alarmOrImmoblizer: "",
            doesCarHaveTracker: "",
            modifications: "",
            doesHaveDashcam: "",

        },
        // validationSchema: Yup.object({
        //     cc_name: Yup.string().required(t<string>("Please Enter Name")),
        //     cc_email: Yup.string().required(t<string>("Please Enter Email")),
        //     cc_phone_number: Yup.number().required(
        //       t<string>("Please Enter Phone Number")
        //     ),
        //     cc_vehicle_registration_number: Yup.string().required(
        //       t<string>("Please Enter vehicle registration number")
        //     ),
        //     cc_postal_code: Yup.string().required(
        //       t<string>("Please Enter the Postal Code")
        //     ),
        //     cc_address: Yup.string().required(t<string>("Please Enter the Address")),
        //   }),
        onSubmit: (values, onSubmitProps) => {
            setStepNumber(stepNumber + 1);
            setFormData(pre => ({
                ...pre,
                ...values
            }))
        },

    });


    const validationStep3 = useFormik({
        enableReinitialize: true,

        initialValues: {
            coverDate: "",
            levelOfCover: "",
            voluntaryExcess: "",
            payforCarInsurancePolicy: "",
        },
        onSubmit: (values, onSubmitProps) => {
            setStepNumber(stepNumber + 1);
            setFormData(pre => ({
                ...pre,
                ...values
            }))
        },
    });

    const validationStep4 = useFormik({
        enableReinitialize: true,

        initialValues: {
            title: "",
            firstName: "",
            lastName: "",
            dob: "",
            gender: "",
            maritalStatus: "",
            driverOwnorNot: "",
            isBirthPlaceUK: "",
            month: "",
            year: "",
        },
        onSubmit: (values, onSubmitProps) => {
            setStepNumber(stepNumber + 1);
            setFormData(pre => ({
                ...pre,
                ...values
            }))
        },
    });

    const validationStep5 = useFormik({
        enableReinitialize: true,

        initialValues: {
            employeementStatus: "",
            licenceType: "",
        },
        onSubmit: (values, onSubmitProps) => {
            setStepNumber(stepNumber + 1);
            setFormData(pre => ({
                ...pre,
                ...values
            }))
        },
    });

    const validationStep6 = useFormik({
        enableReinitialize: true,

        initialValues: {
            // phoneNo: "",
            postalCode: "",
            address: "",
            homeOwner: "",
            // required : "",
            isCarKeptAtHome: "",
            childrenUnderSixteen: "",
            YearsofNoClaimBonus: "",
            NoOfCarsKeptAtHome: "",
            currentlyProtected: "",
            driverVehicalOrNot: "",
            boghtYet: "",
            boughtDate: "",
            carRegisteredTo: "",
            carOwner: "",
            carUsedFor: "",
            accidentClaims: "",
            incidentType: "",
            dvlaMedicalConditionsType: "",
            medicalCondition: "",
            declinedCancelledImposed: "",
        },
        onSubmit: (values, onSubmitProps) => {
            var bodyFormData = new FormData();
            const payload: any = {
                ...formData,
                ...values,
                insuranceContractCoppy: categoryImage,
                driverPhone: phoneNumber?.phoneNo,
                phoneNo: phoneNumber1?.phoneNo,
            }
            const dataString = JSON.stringify(payload);
            bodyFormData.append("insurancecontractDetails", dataString);

            bodyFormData.append("insurancecontractcoppy", categoryImage.raw);
            if (categoryImage.raw !== undefined) {
                onSubmitProps.resetForm();
            }
         
            const rememberMeCheckbox = document.getElementById("remember-me") as HTMLInputElement;
            if (!rememberMeCheckbox.checked) {
                Swal.fire({
                    icon: "warning",
                    title: "Please confirm that you have read and understood our Terms & Conditions and Privacy Policy.",
                    confirmButtonColor: "#FD7F00",
                });
                return; // Stop further execution
            } else if (user?.subscriptionStatus === "CANCELLED" || !user?.subscriptionId) {
                Swal.fire({
                    icon: "warning",
                    title: "in order to be able receive our services you will have to be subscribe",
                    confirmButtonText: "Subscribe",
                    confirmButtonColor: "#FD7F00",
                    showCancelButton: true,
                    cancelButtonText: "Cancel",
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Navigate to subscription page
                        window.location.href = "/account";
                    }
                });
                return; // Stop further execution
            } else {
                var result = InsuranceService.submitCarInsuranceQuotetInfo(bodyFormData).then((res: any) => {
                    if (res.success) {
                        setIsLoading(false);
                        Swal.fire({ title: "Informations Added" });
                        onSubmitProps.resetForm();
                        setFormData(pre => ({
                            ...pre, undefined
                        }));
                        history.go(0)
                        setStepNumber(1);
                    } else {
                        setIsLoading(false);
                        Swal.fire({ icon: "error", title: res.error, confirmButtonColor: "#FD7F00" });
                    }
                });
            }
        },
    });



    const registerSteps = () => {
        return (
            <div
                className="d-flex nav-dots-container justify-content-between align-items-center"
                style={{ width: "70px" }}
            >
                <span className={`${stepNumber == 1 && "bg-secondary"}`}></span>
                <span className={`${stepNumber == 2 && "bg-secondary"}`}></span>
                <span className={`${stepNumber == 3 && "bg-secondary"}`}></span>
                <span className={`${stepNumber == 4 && "bg-secondary"}`}></span>
                <span className={`${stepNumber == 5 && "bg-secondary"}`}></span>
            </div>
        );
    };



    const validationStepvan2 = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            // gender: "",
            make: "",
            vanModel: "",
            manufactureYear: "",
            transmission: "",
            fuelType: "",
            engineSize: "",
            grossWeight: "",

        },
        onSubmit: (values) => {
            setStepNumber1(stepNumber1 + 1);
            setFormData(pre => ({
                ...pre,
                ...values
            }))
        },
    });


    const validationStepvan3 = useFormik({
        enableReinitialize: true,

        initialValues: {
            isImported: "",
            isRightHandDrive: "",
            worth: "",
            seats: "",
            immobiliserOrTracker: "",
            dashCam: "",
            modifications: "",
            bodyType: "",


        },
        onSubmit: (values) => {
            setStepNumber1(stepNumber1 + 1);
            setFormData(pre => ({
                ...pre,
                ...values
            }))
        },
    });



    const validationStepvan4 = useFormik({
        enableReinitialize: true,

        initialValues: {
            vanTitle: "",
            vanfirstName: "",
            sureName: "",
            DOB: "",
            vanPhoneNo: "",
            email: "",
        },
        onSubmit: (values) => {
            setStepNumber1(stepNumber1 + 1);
            setFormData(pre => ({
                ...pre,
                ...values
            }))
        },
    });

    const validationStepvan5 = useFormik({
        enableReinitialize: true,

        initialValues: {

            vanMaritalStatus: "",
            livingInUkSinceBirth: "",
            childUnderSixteen: "",
            employementStatus: "",
            industy: "",
            occupation: "",
            vanHomeOwner: "",
            postCode: "",
            licenType: "",
            licencePeriod: "",
            gbLicenceNumber: "",
            additionalDrivingQualifications: "",
            vanMedicalCondition: "",
        },
        onSubmit: (values) => {
            setStepNumber1(stepNumber1 + 1);
            setFormData(pre => ({
                ...pre,
                ...values
            }))
        },
    });

    const validationStepvan6 = useFormik({
        enableReinitialize: true,

        initialValues: {

            title: "",
            firstName: "",
            lastName: "",
            // driverPhone: "",
            dob: "",
            isBirthPlaceUK: "",
            month: "",
            year: "",
            gender: "",
            maritalStatus: "",
            driverOwnorNot: "",
        },
        onSubmit: (values) => {
            setStepNumber1(stepNumber1 + 1);
            setFormData(pre => ({
                ...pre,
                ...values
            }))
        },
    });

    const validationStepvan7 = useFormik({
        enableReinitialize: true,

        initialValues: {
            employeementStatus: "",
            licenceType: "",

        },
        onSubmit: (values) => {
            setStepNumber1(stepNumber1 + 1);
            setFormData(pre => ({
                ...pre,
                ...values
            }))
        },
    });



    const validationStepvan8 = useFormik({
        enableReinitialize: true,

        initialValues: {
            // phoneNo: "",
            postalCode: "",
            address: "",
            homeOwner: "",
            required: "",
            isCarKeptAtHome: "",
            childrenUnderSixteen: "",
            YearsofNoClaimBonus: "",
            NoOfCarsKeptAtHome: "",
            currentlyProtected: "",
            driverVehicalOrNot: "",
            boghtYet: "",
            boughtDate: "",
            carRegisteredTo: "",
            carOwner: "",
            carUsedFor: "",
            accidentClaims: "",
            incidentType: "",
            dvlaMedicalConditionsType: "",
            medicalCondition: "",
            declinedCancelledImposed: "",
        },
        onSubmit: (values, onSubmitProps) => {
            // setStepNumber(stepNumber + 1);
            const payload: any = {
                ...formData,
                ...values,
                driverPhone: phoneNumber?.phoneNo,
                phoneNo: phoneNumber1?.phoneNo,
            }
            submitVanInsuranceQuotetInfo(payload)
            onSubmitProps.resetForm();
            setStepNumber1(1)
        },
    });

    const registerSteps1 = () => {
        return (
            <div
                className="d-flex nav-dots-container justify-content-between align-items-center"
                style={{ width: "70px" }}
            >
                <span className={`${stepNumber1 == 1 && "bg-secondary"}`}></span>
                <span className={`${stepNumber1 == 2 && "bg-secondary"}`}></span>
                <span className={`${stepNumber1 == 3 && "bg-secondary"}`}></span>
                <span className={`${stepNumber1 == 4 && "bg-secondary"}`}></span>
                <span className={`${stepNumber1 == 5 && "bg-secondary"}`}></span>
                <span className={`${stepNumber1 == 6 && "bg-secondary"}`}></span>
                <span className={`${stepNumber1 == 7 && "bg-secondary"}`}></span>
            </div>
        );
    };



    const validationStepBike2 = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            bikeTitle: "",
            bikeFirstName: "",
            sureName: "",
            email: "",
            bikePhoneNo: "",
            bikeDob: "",
            martialStatus: "",
            postCode: "",
            bikeHomeOwner: "",
            residenceSince: "",
            value: "",
            isImported: "",
            modifications: "",
            // grossWeight:"",

        },
        onSubmit: (values) => {
            setStepNumber2(stepNumber2 + 1);
            setFormData(pre => ({
                ...pre,
                ...values
            }))
        },
    });


    const validationStepBike3 = useFormik({
        enableReinitialize: true,

        initialValues: {
            whenWouldYouCover: "",
            levelOfCover: "",
            voluntaryExcess: "",
            payForCarInsurance: ""
        },
        onSubmit: (values) => {
            setStepNumber2(stepNumber2 + 1);
            setFormData(pre => ({
                ...pre,
                ...values
            }))
        },
    });

    const validationStepBike4 = useFormik({
        enableReinitialize: true,

        initialValues: {
            title: "",
            firstName: "",
            lastName: "",
            dob: "",
            isBirthPlaceUK: "",
            month: "",
            year: "",
            gender: "",
            maritalStatus: "",
            driverOwnorNot: "",


        },
        onSubmit: (values) => {
            setStepNumber2(stepNumber2 + 1);
            setFormData(pre => ({
                ...pre,
                ...values
            }))
        },
    });

    const validationStepBike5 = useFormik({
        enableReinitialize: true,

        initialValues: {
            employeementStatus: "",
            licenceType: "",
        },
        onSubmit: (values) => {
            setStepNumber2(stepNumber2 + 1);
            setFormData(pre => ({
                ...pre,
                ...values
            }))
        },
    });



    const validationStepBike6 = useFormik({
        enableReinitialize: true,

        initialValues: {
            // phoneNo: "",
            postalCode: "",
            address: "",
            homeOwner: "",
            required: "",
            isCarKeptAtHome: "",
            childrenUnderSixteen: "",
            YearsofNoClaimBonus: "",
            NoOfCarsKeptAtHome: "",
            currentlyProtected: "",
            driverVehicalOrNot: "",
            boghtYet: "",
            boughtDate: "",
            carRegisteredTo: "",
            carOwner: "",
            carUsedFor: "",
            accidentClaims: "",
            incidentType: "",
            dvlaMedicalConditionsType: "",
            medicalCondition: "",
            declinedCancelledImposed: "",
        },
        onSubmit: (values, onSubmitProps) => {
            // setStepNumber(stepNumber + 1);
            const payload: any = {
                ...formData,
                ...values,
                driverPhone: phoneNumber?.phoneNo,
                phoneNo: phoneNumber1?.phoneNo,
            }
            submitBikeInsuranceQuotetInfo(payload)
            onSubmitProps.resetForm();
            setStepNumber2(1)
        },
    });

    const registerSteps2 = () => {
        return (
            <div
                className="d-flex nav-dots-container justify-content-between align-items-center"
                style={{ width: "70px" }}
            >
                <span className={`${stepNumber2 == 1 && "bg-secondary"}`}></span>
                <span className={`${stepNumber2 == 2 && "bg-secondary"}`}></span>
                <span className={`${stepNumber2 == 3 && "bg-secondary"}`}></span>
                <span className={`${stepNumber2 == 4 && "bg-secondary"}`}></span>
                <span className={`${stepNumber2 == 5 && "bg-secondary"}`}></span>
            </div>
        );
    };


    return (
        <div className="login-page edu-footer footer-lighten bg-image footer-style-1">
            <section className="account-page-area section-gap-equal1">
                <div className="container-custom position-relative">
                    <div className="row g-5 justify-content-center">
                        <div className="col-lg-8">
                            <div className="selectOption mb-10">
                                <button
                                    className="selectOptions textss"
                                    style={{ borderColor: vehicalDataRef.current.color, color: vehicalDataRef.current.color }}
                                    onClick={() => {
                                        setInsuranceBtn(`car`);
                                    }}
                                >
                                    Car Insurance
                                </button>
                                <button
                                    className="selectOptions textss"
                                    style={{ borderColor: vehicalDataRef.current.colors, color: vehicalDataRef.current.colors }}
                                    onClick={() => {
                                        setInsuranceBtn(`van`);
                                    }}
                                >
                                    Van Insurance
                                </button>
                                <button
                                    className="selectOptions textss"
                                    style={{ borderColor: vehicalDataRef.current.colorss, color: vehicalDataRef.current.colorss }}
                                    onClick={() => {
                                        setInsuranceBtn(`bike`);
                                    }}
                                >
                                    Bike Insurance
                                </button>
                            </div>
                            <div className="login-form-box registration-form">
                                <div className="App">
                                    <div className="box">
                                        {vehicalDataRef.current.services_or_personal_request == "car" &&
                                            <>
                                                {stepNumber == 1 && (
                                                    <div>
                                                        <Form
                                                            className="form-horizontal"
                                                            onSubmit={(e) => {
                                                                e.preventDefault();
                                                                validationStep2.handleSubmit();
                                                                return false;
                                                            }}
                                                        >

                                                            <h3 className="title mb-20 mt-10">01. Car Details</h3>

                                                            <p className="themeColor  mb-30 font-16 mt-3">Insurance quotes life admin services provide assistance in obtaining and comparing insurance quotes. They handle tasks such as gathering necessary information, requesting quotes from multiple insurance providers, reviewing and presenting the options, and providing guidance to help individuals make informed decisions about their insurance coverage.
                                                            </p>
                      

                                                            <div className="row g-5 mb-20 justify-content-center">
                                                                <div className="col-lg-8">
                                                                    <div className="form-group">
                                                                        <label htmlFor="reg-car">Car Registration Number</label>
                                                                        <Input type="text" name="regNum" id="regNum"
                                                                            value={validationStep2.values.regNum}
                                                                            onChange={validationStep2.handleChange}
                                                                            invalid={validationStep2.touched.regNum && validationStep2.errors.regNum ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="log-Make">Car Make</label>
                                                                        <Input type="text" name="carMake" id="carMake"
                                                                            value={validationStep2.values.carMake}
                                                                            onChange={validationStep2.handleChange}
                                                                            invalid={validationStep2.touched.carMake && validationStep2.errors.carMake ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="row g-5 mb-20 justify-content-center">
                                                                <div className="col-lg-8">
                                                                    <div className="form-group">
                                                                        <label htmlFor="reg-Model">Car Model</label>
                                                                        <Input type="text" name="carModel" id="carModel"
                                                                            value={validationStep2.values.carModel}
                                                                            onChange={validationStep2.handleChange}
                                                                            invalid={validationStep2.touched.carModel && validationStep2.errors.carModel ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="log-manufacture">Year of manufacture</label>
                                                                        <Input
                                                                            type="date"
                                                                            className="form-control"
                                                                            id="yearOfManufacture"
                                                                            placeholder="Date of birth"
                                                                            name="yearOfManufacture"
                                                                            max={moment().format("YYYY-MM-DD")}
                                                                            // required
                                                                            invalid={validationStep2.touched.yearOfManufacture && validationStep2.errors.yearOfManufacture ? true : false}
                                                                            value={validationStep2.values.yearOfManufacture}
                                                                            onChange={validationStep2.handleChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row g-5 mb-20 justify-content-center">
                                                                <div className="col-lg-8">
                                                                    <div className="form-group">
                                                                        <label htmlFor="log-Doors">Doors</label>
                                                                    </div>
                                                                    <div className="card-heading mb-2">
                                                                        <div className="row">
                                                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="doors"
                                                                                        id="doors-4"
                                                                                        value="4"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.doors && validationStep2.errors.doors ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="doors-4">
                                                                                        4
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="doors"
                                                                                        id="doors-5"
                                                                                        value="5"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.doors && validationStep2.errors.doors ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="doors-5">
                                                                                        5
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="log-Transmission">Transmission</label>
                                                                    </div>
                                                                    <div className="card-heading mb-2">
                                                                        <div className="row">
                                                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="transmission"
                                                                                        id="MANUAL"
                                                                                        value="MANUAL"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.transmission && validationStep2.errors.transmission ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="MANUAL">
                                                                                        MANUAL
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="transmission"
                                                                                        id="AUTOMATIC"
                                                                                        value="AUTOMATIC"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.transmission && validationStep2.errors.transmission ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="AUTOMATIC">
                                                                                        AUTOMATIC
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="row g-5 mb-20 justify-content-center">
                                                                <div className="col-lg-8">
                                                                    <div className="form-group">
                                                                        <label htmlFor="reg-Engine">Engine Size</label>
                                                                        <Input type="text" name="engineSize" id="reg-Engine"
                                                                            value={validationStep2.values.engineSize}
                                                                            onChange={validationStep2.handleChange}
                                                                            invalid={validationStep2.touched.carMake && validationStep2.errors.carMake ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="log-Fuel">Fuel Type</label>
                                                                    </div>
                                                                    <div className="card-heading mb-2">
                                                                        <div className="row">
                                                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="fuelType"
                                                                                        id="fuelType"
                                                                                        value="PETROL"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.transmission && validationStep2.errors.transmission ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="fuelType">
                                                                                        PETROL
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="fuelType"
                                                                                        id="fuelType1"
                                                                                        value="DIESEL"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.transmission && validationStep2.errors.transmission ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="fuelType1">
                                                                                        DIESEL
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>




                                                            <div className="row g-5 mb-20 justify-content-center">
                                                                <div className="col-lg-8">
                                                                    <div className="form-group">
                                                                        <label htmlFor="log-Insurance-information">Insurance information</label>
                                                                        <Input type="text" name="insuranceInfo" id="log-Insurance-information"
                                                                            value={validationStep2.values.insuranceInfo}
                                                                            onChange={validationStep2.handleChange}
                                                                            invalid={validationStep2.touched.insuranceInfo && validationStep2.errors.insuranceInfo ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="insuranceContractCoppy">Insurance contract copy upload</label>
                                                                        <Input type="file" className="inputFile"
                                                                            onChange={(e) => imageUpload(e)}

                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="row g-5 mb-20 justify-content-center">
                                                                <div className="col-lg-8">
                                                                    <div className="form-group">
                                                                        <label htmlFor="log-Driving">Driving history</label>
                                                                        <Input type="textarea" name="drivingHistory" id="log-Driving"
                                                                            value={validationStep2.values.drivingHistory}
                                                                            onChange={validationStep2.handleChange}
                                                                            invalid={validationStep2.touched.drivingHistory && validationStep2.errors.drivingHistory ? true : false}
                                                                        />
                                                                    </div>
                                                                    <p className="mb-20">Insurance companies will often ask about your driving history, including any accidents or traffic violations you have had in the past.</p>
                                                                </div>

                                                                <div className="col-lg-4">
                                                                    <h1></h1>
                                                                </div>
                                                            </div>


                                                            <hr className="mb-30 mt-40" />
                                                            <h3 className="title mb-30 mt-10">02. Car specification</h3>

                                                            <div className="row g-5 mb-20 justify-content-center">
                                                                <div className="col-lg-8">
                                                                    <div className="form-group mb-20">
                                                                        <label htmlFor="reg-value">What's the value of the car?</label>
                                                                        <Input type="number" name="carValue" id="reg-value"
                                                                            value={validationStep2.values.carValue}
                                                                            onChange={validationStep2.handleChange}
                                                                            invalid={validationStep2.touched.carValue && validationStep2.errors.carValue ? true : false}
                                                                        />
                                                                    </div>

                                                                    <div className="form-group ">
                                                                        <label htmlFor="log-drive">Is the car a right or left-hand drive?</label>
                                                                    </div>
                                                                    <div className="card-heading mb-2">
                                                                        <div className="row">
                                                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="drivingSide"
                                                                                        id="LEFT1"
                                                                                        value="LEFT"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.drivingSide && validationStep2.errors.drivingSide ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="LEFT1">
                                                                                        LEFT
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 ">
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="drivingSide"
                                                                                        id="RIGHT"
                                                                                        value="RIGHT"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.drivingSide && validationStep2.errors.drivingSide ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="RIGHT">
                                                                                        RIGHT
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label htmlFor="log-drive">How many seats does the car have?</label>
                                                                    </div>
                                                                    <div className="card-heading mb-2">
                                                                        <div className="row">
                                                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="seatCount"
                                                                                        id="1"
                                                                                        value="1"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.seatCount && validationStep2.errors.seatCount ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="1">
                                                                                        1
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="seatCount"
                                                                                        id="2"
                                                                                        value="2"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.seatCount && validationStep2.errors.seatCount ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="2">
                                                                                        2
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="seatCount"
                                                                                        id="3"
                                                                                        value="3"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.seatCount && validationStep2.errors.seatCount ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="3">
                                                                                        3
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="seatCount"
                                                                                        id="4"
                                                                                        value="4"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.seatCount && validationStep2.errors.seatCount ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="4">
                                                                                        4
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="seatCount"
                                                                                        id="5"
                                                                                        value="5"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.seatCount && validationStep2.errors.seatCount ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="5">
                                                                                        5
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="seatCount"
                                                                                        id="6"
                                                                                        value="6"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.seatCount && validationStep2.errors.seatCount ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="6">
                                                                                        6
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="seatCount"
                                                                                        id="7"
                                                                                        value="7"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.seatCount && validationStep2.errors.seatCount ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="7">
                                                                                        7
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="seatCount"
                                                                                        id="8"
                                                                                        value="8"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.seatCount && validationStep2.errors.seatCount ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="8">
                                                                                        8
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="seatCount"
                                                                                        id="9"
                                                                                        value="9"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.seatCount && validationStep2.errors.seatCount ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="9">
                                                                                        9
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="seatCount"
                                                                                        id="10"
                                                                                        value="10"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.seatCount && validationStep2.errors.seatCount ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="10">
                                                                                        10
                                                                                    </label>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="log-import">Is the car an import?</label>
                                                                    </div>
                                                                    <div className="card-heading mb-2">
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <div className="form-check mb-3 ">
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="isCarImport"
                                                                                        id="STANDARD"
                                                                                        value="NO - STANDARD UK MODEL"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.seatCount && validationStep2.errors.isCarImport ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="STANDARD">
                                                                                        NO - STANDARD UK MODEL
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 ">
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="isCarImport"
                                                                                        id="UK"
                                                                                        value="YES - EURO IMPORT - UK SPEC"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.seatCount && validationStep2.errors.isCarImport ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="UK">
                                                                                        YES - EURO IMPORT - UK SPEC
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 ">
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="isCarImport"
                                                                                        id="NON"
                                                                                        value="YES - EURO IMPORT - NON UK SPEC"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.seatCount && validationStep2.errors.isCarImport ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="NON">
                                                                                        YES - EURO IMPORT - NON UK SPEC
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 ">
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="isCarImport"
                                                                                        id="JAPENESE"
                                                                                        value="YES - JAPENESE IMPORT"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.isCarImport && validationStep2.errors.isCarImport ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="JAPENESE">
                                                                                        YES - JAPENESE IMPORT
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 ">
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="isCarImport"
                                                                                        id="US"
                                                                                        value="YES - US IMPORT"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.isCarImport && validationStep2.errors.isCarImport ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="US">
                                                                                        YES - US IMPORT
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className="form-group">
                                                                        <label htmlFor="log-import">Alarm or Immobiliser</label>
                                                                    </div>
                                                                    <div className="card-heading mb-2">
                                                                        <div className="row">
                                                                            <div className="col">

                                                                                <div className="form-check mb-3 ">
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="alarmOrImmoblizer"
                                                                                        id="MANUFACTURER"
                                                                                        value="YES - MANUFACTURER FITTED"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.alarmOrImmoblizer && validationStep2.errors.alarmOrImmoblizer ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="MANUFACTURER">
                                                                                        YES - MANUFACTURER FITTED
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 ">
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="alarmOrImmoblizer"
                                                                                        id="THATCHAM"
                                                                                        value="YES - THATCHAM DEVICE"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.alarmOrImmoblizer && validationStep2.errors.alarmOrImmoblizer ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="THATCHAM">
                                                                                        YES - THATCHAM DEVICE
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 ">
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="alarmOrImmoblizer"
                                                                                        id="IMMOBILISER"
                                                                                        value="YES - OTHER ALARM OR IMMOBILISER"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.alarmOrImmoblizer && validationStep2.errors.alarmOrImmoblizer ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="IMMOBILISER">
                                                                                        YES - OTHER ALARM OR IMMOBILISER
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 ">
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="alarmOrImmoblizer"
                                                                                        id="SECURITY"
                                                                                        value=" NO - SECURITY DEVICE"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.alarmOrImmoblizer && validationStep2.errors.alarmOrImmoblizer ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="SECURITY">
                                                                                        NO - SECURITY DEVICE
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className="form-group">
                                                                        <label htmlFor="log-drive">Does the car have a tracker?</label>
                                                                    </div>
                                                                    <div className="card-heading mb-2">
                                                                        <div className="row">
                                                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="doesCarHaveTracker"
                                                                                        id="YES"
                                                                                        value="YES"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.doesCarHaveTracker && validationStep2.errors.doesCarHaveTracker ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="YES">
                                                                                        YES
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check mb-3 ">
                                                                                    <Input
                                                                                        className="form-check-Input"
                                                                                        type="radio"
                                                                                        name="doesCarHaveTracker"
                                                                                        id="NO"
                                                                                        value="NO"
                                                                                        onChange={validationStep2.handleChange}
                                                                                        invalid={validationStep2.touched.doesCarHaveTracker && validationStep2.errors.doesCarHaveTracker ? true : false}
                                                                                    />

                                                                                    <label className="form-check-label" htmlFor="NO">
                                                                                        NO
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <hr className="mb-30 mt-40" />
                                                            <h3 className="title mb-30 mt-10">03. Further details</h3>

                                                            <div className="row g-5 mb-20 justify-content-center">
                                                                <div className="col-lg-8">
                                                                    <div className="form-group">
                                                                        <label htmlFor="reg-modifications">Does the car have any modifications?</label>
                                                                        <select className="form-group f-14"
                                                                            name="modifications"
                                                                            value={validationStep2.values.modifications}
                                                                            onChange={validationStep2.handleChange}
                                                                        // invalid={validationStep2.touched.modifications && validationStep2.errors.modifications ? true : false}             
                                                                        >
                                                                            <option value="YES">YES</option>
                                                                            <option value="NO">NO</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="log-Make">Does the car have a dashcam?</label>
                                                                        <select className="form-group f-14"
                                                                            name="doesHaveDashcam"
                                                                            value={validationStep2.values.doesHaveDashcam}
                                                                            onChange={validationStep2.handleChange} 
                                                                        >
                                                                            <option value="YES">YES</option>
                                                                            <option value="NO">NO</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="d-flex justify-content-between mt-4 mb-4">
                                                                {registerSteps()}
                                                                <button
                                                                    className="btn colorchangeLog leadMargin edu-btn btn-medium"
                                                                    type="submit"
                                                                >
                                                                    {t<string>("Next")}
                                                                </button>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                )
                                                }


                                                {
                                                    stepNumber == 2 && (
                                                        <div>
                                                            <Form
                                                                className="form-horizontal"
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    validationStep3.handleSubmit();
                                                                    return false;
                                                                }}
                                                            >
                                                                <h3 className="title mb-30 mt-10">04. Cover</h3>

                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-8">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-manufacture">When would you like the cover to begin, within the next 30 days?</label>
                                                                            <Input
                                                                                type="date"
                                                                                className="form-control"
                                                                                id="formrow-firstname-Input"
                                                                                name="coverDate"
                                                                                placeholder="Date of birth"
                                                                                max={moment().format("YYYY-MM-DD")}
                                                                                value={validationStep3.values.coverDate}
                                                                                onChange={validationStep3.handleChange}
                                                                                invalid={validationStep3.touched.coverDate && validationStep3.errors.coverDate ? true : false}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                    </div>
                                                                </div>


                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-8">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">What level of cover do you need?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="levelOfCover"
                                                                                            id="COMPREHENSIVE"
                                                                                            value="COMPREHENSIVE"
                                                                                            onChange={validationStep3.handleChange}
                                                                                            invalid={validationStep3.touched.levelOfCover && validationStep3.errors.levelOfCover ? true : false}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="COMPREHENSIVE">
                                                                                            COMPREHENSIVE
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="exampleRadioss"
                                                                                            id="THEFT"
                                                                                            value="THIRD PARTY, FIRE & THEFT"
                                                                                            onChange={validationStep3.handleChange}
                                                                                            invalid={validationStep3.touched.levelOfCover && validationStep3.errors.levelOfCover ? true : false}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="THEFT">
                                                                                            THIRD PARTY, FIRE & THEFT
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="exampleRadioss"
                                                                                            id="PARTY"
                                                                                            value="THIRD PARTY ONLY"
                                                                                            onChange={validationStep3.handleChange}
                                                                                            invalid={validationStep3.touched.levelOfCover && validationStep3.errors.levelOfCover ? true : false}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="PARTY">
                                                                                            THIRD PARTY ONLY
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Choose a voluntary excess</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="voluntaryExcess"
                                                                                            id="voluntaryExcess"
                                                                                            value="150"
                                                                                            onChange={validationStep3.handleChange}
                                                                                            invalid={validationStep3.touched.voluntaryExcess && validationStep3.errors.voluntaryExcess ? true : false}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="voluntaryExcess">
                                                                                            £150
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="voluntaryExcess"
                                                                                            id="250"
                                                                                            value="250"
                                                                                            onChange={validationStep3.handleChange}
                                                                                            invalid={validationStep3.touched.voluntaryExcess && validationStep3.errors.voluntaryExcess ? true : false}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="250">
                                                                                            £250
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="voluntaryExcess"
                                                                                            id="350"
                                                                                            value="350"
                                                                                            onChange={validationStep3.handleChange}
                                                                                            invalid={validationStep3.touched.voluntaryExcess && validationStep3.errors.voluntaryExcess ? true : false}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="350">
                                                                                            £350
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="voluntaryExcess"
                                                                                            id="0"
                                                                                            value="0"
                                                                                            onChange={validationStep3.handleChange}
                                                                                            invalid={validationStep3.touched.voluntaryExcess && validationStep3.errors.voluntaryExcess ? true : false}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="0">
                                                                                            £0
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="voluntaryExcess"
                                                                                            id="100"
                                                                                            value="100"
                                                                                            onChange={validationStep3.handleChange}
                                                                                            invalid={validationStep3.touched.voluntaryExcess && validationStep3.errors.voluntaryExcess ? true : false}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="100">
                                                                                            £100
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="voluntaryExcess"
                                                                                            id="500"
                                                                                            value="500"
                                                                                            onChange={validationStep3.handleChange}
                                                                                            invalid={validationStep3.touched.voluntaryExcess && validationStep3.errors.voluntaryExcess ? true : false}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="500">
                                                                                            £500
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="voluntaryExcess"
                                                                                            id="750"
                                                                                            value="750"
                                                                                            onChange={validationStep3.handleChange}
                                                                                            invalid={validationStep3.touched.voluntaryExcess && validationStep3.errors.voluntaryExcess ? true : false}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="750">
                                                                                            £750
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="voluntaryExcess"
                                                                                            id="10000"
                                                                                            value="10000"
                                                                                            onChange={validationStep3.handleChange}
                                                                                            invalid={validationStep3.touched.voluntaryExcess && validationStep3.errors.voluntaryExcess ? true : false}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="10000">
                                                                                            £10000
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">How would you want to pay for the car insurance policy?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="payforCarInsurancePolicy"
                                                                                            id="ANNUAL"
                                                                                            value="ANNUAL PAYMENT"
                                                                                            onChange={validationStep3.handleChange}
                                                                                            invalid={validationStep3.touched.payforCarInsurancePolicy && validationStep3.errors.payforCarInsurancePolicy ? true : false}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="ANNUAL">
                                                                                            ANNUAL PAYMENT
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="payforCarInsurancePolicy"
                                                                                            id="MONTHLY"
                                                                                            value="MONTHLY PAYMENTS"
                                                                                            onChange={validationStep3.handleChange}
                                                                                            invalid={validationStep3.touched.payforCarInsurancePolicy && validationStep3.errors.payforCarInsurancePolicy ? true : false}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="MONTHLY">
                                                                                            MONTHLY PAYMENTS
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex justify-content-between mt-4 mb-4">
                                                                    <button
                                                                        className="btn colorchangeback back-btn btn-medium"
                                                                        type="submit"
                                                                        onClick={() => setStepNumber(stepNumber - 1)}
                                                                    >
                                                                        {t<string>("Previous")}
                                                                    </button>
                                                                    {registerSteps()}

                                                                    <button
                                                                        className="btn colorchangeLog leadMargin edu-btn btn-medium"
                                                                        type="submit"
                                                                    >
                                                                        {t<string>("Next")}
                                                                    </button>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    stepNumber == 3 && (
                                                        <div>
                                                            <Form
                                                                className="form-horizontal"
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    validationStep4.handleSubmit();
                                                                    return false;
                                                                }}
                                                            >
                                                                <h3 className="title mb-30 mt-10">05. Drivers</h3>
                                                                <h5 className="title mb-30 mt-10">Add a new driver or select one of your saved profile</h5>
                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-6">
                                                                        <label htmlFor="log-Make">Title</label>
                                                                        <select className="form-group f-14 mb-20"
                                                                            name="title"
                                                                            id="value={validationStep2.values.doesHaveDashcam}"
                                                                            value={validationStep4.values.title}
                                                                            onChange={validationStep4.handleChange}
                                                                        >
                                                                            <option value="MR">MR</option>
                                                                            <option value="MRS">MRS</option>
                                                                            <option value="MISS">MISS</option>
                                                                            <option value="MS">MS</option>
                                                                            <option value="DR (MALE)">DR (MALE)</option>
                                                                            <option value="DR (FEMALE)">DR (FEMALE)</option>
                                                                        </select>
                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Name">First Name *</label>
                                                                            <Input type="text" name="firstName" id="firstName"
                                                                                value={validationStep4.values.firstName}
                                                                                onChange={validationStep4.handleChange}
                                                                                invalid={validationStep4.touched.firstName && validationStep4.errors.firstName ? true : false}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Last">Last Name *</label>
                                                                            <Input type="text" name="lastName" id="lastName"
                                                                                value={validationStep4.values.lastName}
                                                                                onChange={validationStep4.handleChange}
                                                                                invalid={validationStep4.touched.lastName && validationStep4.errors.lastName ? true : false}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Phone">Phone *</label>
                                                                            <PhoneInput
                                                                                value={phoneNumber.phoneNo}
                                                                                defaultCountry="GB" type="text" name="phoneNo" id="phoneNo"
                                                                                onChange={(e: string) => setPhoneNumber({ ...phoneNumber, phoneNo: e })}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Date">Date of birth *</label>
                                                                            <Input
                                                                                type="date"
                                                                                className="form-control"
                                                                                id="formrow-firstname-Input"
                                                                                name="dob"
                                                                                placeholder="Date of birth"
                                                                                max={moment().format("YYYY-MM-DD")}
                                                                                value={validationStep4.values.dob}
                                                                                onChange={validationStep4.handleChange}
                                                                                invalid={validationStep4.touched.dob && validationStep4.errors.dob ? true : false}
                                                                            />
                                                                        </div>


                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Has the driver lived in the UK since birth? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="isBirthPlaceUK"
                                                                                            id="birth"
                                                                                            value="YES"
                                                                                            onChange={validationStep4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="birth">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="isBirthPlaceUK"
                                                                                            id="birthNO"
                                                                                            value="NO"
                                                                                            onChange={validationStep4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="birthNO">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <h5 className="title mb-20 mt-10">When did the driver start continuously living in the UK?</h5>
                                                                        <h6 className="title mb-20 mt-10">Insurers need to know how long the driver has continuously lived in the UK as a permanent resident. This means without any gaps of 6 months or longer.</h6>
                                                                        <div className="form-group">
                                                                            <label htmlFor="month">Month</label>
                                                                            <Input type="number" name="month" id="month"
                                                                                value={validationStep4.values.month}
                                                                                onChange={validationStep4.handleChange}
                                                                                invalid={validationStep4.touched.month && validationStep4.errors.month ? true : false}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="reg-Year">Year</label>
                                                                            <Input type="number" name="year" id="year"
                                                                                value={validationStep4.values.year}
                                                                                onChange={validationStep4.handleChange}
                                                                                invalid={validationStep4.touched.year && validationStep4.errors.year ? true : false}
                                                                            />
                                                                        </div>



                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Gender</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="gender"
                                                                                            id="MALE"
                                                                                            value="MALE"
                                                                                            onChange={validationStep4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="MALE">
                                                                                            MALE
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="gender"
                                                                                            id="FEMALE"
                                                                                            value="FEMALE"
                                                                                            onChange={validationStep4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="FEMALE">
                                                                                            FEMALE
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Marital status</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="maritalStatus"
                                                                                            id="SINGLE"
                                                                                            value="SINGLE"
                                                                                            onChange={validationStep4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="SINGLE">
                                                                                            SINGLE
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="maritalStatus"
                                                                                            id="MARRIED"
                                                                                            value="MARRIED"
                                                                                            onChange={validationStep4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="MARRIED">
                                                                                            MARRIED
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="maritalStatus"
                                                                                            id="LIVING"
                                                                                            value="LIVING WITH PARTNER"
                                                                                            onChange={validationStep4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="LIVING">
                                                                                            LIVING WITH PARTNER
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="maritalStatus"
                                                                                            id="WIDOWED"
                                                                                            value="WIDOWED"
                                                                                            onChange={validationStep4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="WIDOWED">
                                                                                            WIDOWED
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="exampleRadioss"
                                                                                            id="CIVIL"
                                                                                            value="CIVIL PARTNERSHIP"
                                                                                            onChange={validationStep4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="CIVIL">
                                                                                            CIVIL PARTNERSHIP
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="maritalStatus"
                                                                                            id="DIVORCED"
                                                                                            value="DIVORCED"
                                                                                            onChange={validationStep4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="DIVORCED">
                                                                                            DIVORCED
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="maritalStatus"
                                                                                            id="SEPERATED"
                                                                                            value="SEPERATED"
                                                                                            onChange={validationStep4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="SEPERATED">
                                                                                            SEPERATED
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Does the driver own or use another vehicle? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="driverOwnorNot"
                                                                                            id="YESa"
                                                                                            value="YES"
                                                                                            onChange={validationStep4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="YESa">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="driverOwnorNot"
                                                                                            id="NOs"
                                                                                            value="NO"
                                                                                            onChange={validationStep4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="NOs">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p className="mb-20">Some insurers could offer a discount if the driver uses another vehicle, as they’ll have a better idea of driving habits.</p>

                                                                    </div>
                                                                </div>

                                                                <div className="d-flex justify-content-between mt-4 mb-4" >
                                                                    <button
                                                                        className="btn colorchangeback back-btn btn-medium"
                                                                        type="submit"
                                                                        onClick={() => setStepNumber(stepNumber - 1)}
                                                                    >
                                                                        {t<string>("Previous")}
                                                                    </button>
                                                                    {registerSteps()}

                                                                    <button
                                                                        className="btn colorchangeLog leadMargin edu-btn btn-medium"
                                                                        type="submit"
                                                                    >
                                                                        {t<string>("Next")}
                                                                    </button>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    stepNumber == 4 && (
                                                        <div>
                                                            <Form
                                                                className="form-horizontal"
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    validationStep5.handleSubmit();
                                                                    return false;
                                                                }}
                                                            >
                                                                <h3 className="title mb-30 mt-10">06. Occupation</h3>

                                                                <div className="form-group">
                                                                    <label htmlFor="log-Doors">What's the driver's employment status?</label>
                                                                </div>
                                                                <div className="card-heading mb-2">
                                                                    <div className="row">
                                                                        <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="employeementStatus"
                                                                                    id="EMPLOYED"
                                                                                    value="EMPLOYED"
                                                                                    onChange={validationStep5.handleChange}

                                                                                />

                                                                                <label className="form-check-label" htmlFor="EMPLOYED">
                                                                                    EMPLOYED
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="employeementStatus"
                                                                                    id="SELE-EMPLOYED"
                                                                                    value="SELE-EMPLOYED"
                                                                                    onChange={validationStep5.handleChange}
                                                                                />

                                                                                <label className="form-check-label" htmlFor="SELE-EMPLOYED">
                                                                                    SELE-EMPLOYED
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="employeementStatus"
                                                                                    id="RETIRED"
                                                                                    value="RETIRED"
                                                                                    onChange={validationStep5.handleChange}
                                                                                />

                                                                                <label className="form-check-label" htmlFor="RETIRED">
                                                                                    RETIRED
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <hr className="mb-30 mt-40" />
                                                                <h3 className="title mb-30 mt-10">07. Licence</h3>

                                                                <div className="form-group">
                                                                    <label htmlFor="log-Doors">What type of license does the driver currently have?</label>
                                                                </div>
                                                                <div className="card-heading mb-2">
                                                                    <div className="row">
                                                                        <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="licenceType"
                                                                                    id="FULL UK-MANUAL"
                                                                                    value="FULL UK-MANUAL"
                                                                                    onChange={validationStep5.handleChange}
                                                                                />

                                                                                <label className="form-check-label" htmlFor="FULL UK-MANUAL">
                                                                                    FULL UK-MANUAL
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="licenceType"
                                                                                    id="EEC"
                                                                                    value="FULL EURO (EEC)"
                                                                                    onChange={validationStep5.handleChange}
                                                                                />

                                                                                <label className="form-check-label" htmlFor="EEC">
                                                                                    FULL EURO (EEC)
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="licenceType"
                                                                                    id="OPTIONS"
                                                                                    value="SHOW MORE OPTIONS"
                                                                                    onChange={validationStep5.handleChange}
                                                                                />

                                                                                <label className="form-check-label" htmlFor="OPTIONS">
                                                                                    SHOW MORE OPTIONS
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex justify-content-between mt-4 mb-4">
                                                                    <button
                                                                        className="btn colorchangeback back-btn btn-medium"
                                                                        type="submit"
                                                                        onClick={() => setStepNumber(stepNumber - 1)}
                                                                    >
                                                                        {t<string>("Previous")}
                                                                    </button>
                                                                    {registerSteps()}
                                                                    <button
                                                                        className="btn colorchangeLog leadMargin edu-btn btn-medium"
                                                                        type="submit"
                                                                    >
                                                                        {t<string>("Next")}
                                                                    </button>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    )
                                                }



                                                {
                                                    stepNumber == 5 && (
                                                        <div>
                                                            <Form
                                                                className="form-horizontal"
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    validationStep6.handleSubmit();
                                                                    return false;
                                                                }}
                                                            >
                                                                <h3 className="title mb-30 mt-10">08. Further policyholder details</h3>

                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <label htmlFor="reg-number">Phone number</label>
                                                                            <PhoneInput
                                                                                value={phoneNumber1.phoneNo}
                                                                                defaultCountry="GB" type="text" name="phoneNo" id="phoneNo"
                                                                                onChange={(e: string) => setPhoneNumber1({ ...phoneNumber1, phoneNo: e })}
                                                                            />
                                                                        </div>
                                                                        <p className="mb-20">Insurers need this to be able to create the quotes, but they won't phone unless you agree to being contacted.</p>

                                                                        <div className="form-group">
                                                                            <label htmlFor="reg-Postal">Postal Code</label>
                                                                            <Input type="text" name="postalCode" id="reg-Postal"
                                                                                value={validationStep6.values.postalCode}
                                                                                onChange={validationStep6.handleChange}
                                                                                invalid={validationStep6.touched.postalCode && validationStep6.errors.postalCode ? true : false}
                                                                            />
                                                                        </div>
                                                                        <p className="mb-20">Insurers need this to be able to create the quotes, but they won't phone unless you agree to be contacted.</p>

                                                                        <div className="form-group">
                                                                            <label htmlFor="reg-Address">Address</label>
                                                                            <Input type="textarea" name="address" id="address"
                                                                                value={validationStep6.values.address}
                                                                                onChange={validationStep6.handleChange}
                                                                                invalid={validationStep6.touched.address && validationStep6.errors.address ? true : false}
                                                                            />
                                                                        </div>
                                                                        <p className="mb-20">Insurers need this to be able to create the quotes, but they won't phone unless you agree to be contacted.</p>
                                                                    </div>
                                                                </div>

                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Is you are a homeowner? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="homeOwner"
                                                                                            id="homeowner"
                                                                                            value="YES"
                                                                                            onChange={validationStep6.handleChange}

                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="homeowner">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="homeOwner"
                                                                                            id="homeownerNo"
                                                                                            value="NO"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="homeownerNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p className="mb-20">We need this to get more accurate prices</p>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Will the car be kept at home during the day? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="isCarKeptAtHome"
                                                                                            id="during"
                                                                                            value="YES"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="during">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="isCarKeptAtHome"
                                                                                            id="duringNo"
                                                                                            value="NO"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="duringNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">By the time the policy begins, will Awais have any children under 16 years old? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="childrenUnderSixteen"
                                                                                            id="policy"
                                                                                            value="YES"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="policy">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="childrenUnderSixteen"
                                                                                            id="policyNo"
                                                                                            value="NO"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="policyNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <label htmlFor="log-policy">By the time the policy begins, how many years of no-claims bonus (NCB) will Awais have? *</label>
                                                                        <select className="form-group f-14" name="YearsofNoClaimBonus"
                                                                            value={validationStep6.values.YearsofNoClaimBonus}
                                                                            onChange={validationStep6.handleChange}
                                                                        >
                                                                            <option value="NO NCB">NO NCB</option>
                                                                            <option value="1 Year">1 Year</option>
                                                                            <option value="2 Year">2 Year</option>
                                                                            <option value="3 Year">3 Year</option>
                                                                            <option value="4 Year">4 Year</option>
                                                                            <option value="5 Year">5 Year</option>
                                                                            <option value="6 Year">6 Year</option>
                                                                            <option value="7 Year">7 Year</option>
                                                                            <option value="8 Year">8 Year</option>
                                                                            <option value="9 Year">9 Year</option>
                                                                            <option value="10 Year">10 Year</option>
                                                                            <option value="11 Year">11 Year</option>
                                                                            <option value="12 Year">12 Year</option>
                                                                            <option value="13 Year">13 Year</option>
                                                                            <option value="14 Year">14 Year</option>
                                                                            <option value="15 Year">15 Year</option>
                                                                            <option value="No NCB in last 2 years">No NCB in last 2 years</option>
                                                                        </select>
                                                                        <p className="mb-20">Most insurers ask for evidence of NCB. You should be able to get this from the current or previous insurer.</p>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Is Awais's no-claims bonus (NCB) currently protected? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="currentlyProtected"
                                                                                            id="bonus"
                                                                                            value="YES"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="bonus">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="currentlyProtected"
                                                                                            id="bonusNo"
                                                                                            value="NO"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="bonusNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Does the driver own or use another vehicle? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="driverVehicalOrNot"
                                                                                            id="vehicle"
                                                                                            value="YES"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="vehicle">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="driverVehicalOrNot"
                                                                                            id="vehicleNo"
                                                                                            value="NO"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="vehicleNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p className="mb-20">Some insurers could offer a discount if the driver uses another vehicle, as they’ll have a better idea of driving habits.</p>

                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-drive">How many cars are kept at home?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="11"
                                                                                            value="1"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="11">
                                                                                            1
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="12"
                                                                                            value="2"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="12">
                                                                                            2
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="13"
                                                                                            value="3"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="13">
                                                                                            3
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="14"
                                                                                            value="4"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="14">
                                                                                            4
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="15"
                                                                                            value="5"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="15">
                                                                                            5
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="16"
                                                                                            value="6"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="16">
                                                                                            6
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="17"
                                                                                            value="
                                                                                            onChange={validationStep6.handleChange}7"
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="17">
                                                                                            7
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="18"
                                                                                            value="8"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="18">
                                                                                            8
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="19"
                                                                                            value="9"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="19">
                                                                                            9
                                                                                        </label>
                                                                                    </div>
                                                                                    <p className="mb-20">Some insurers offer a discount if there's more than one car at home. If the car you’re getting a quote for will be in addition to cars already at the home, include it in the number.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className="mb-30 mt-40" />
                                                                <h3 className="title mb-30 mt-10">Car Usage</h3>
                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group ">
                                                                            <label htmlFor="log-Doors">Has the car been bought yet? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="boghtYet"
                                                                                            id="bought"
                                                                                            value="YES"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="bought">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="boghtYet"
                                                                                            id="boughtNo"
                                                                                            value="NO"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="boughtNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="log-manufacture">When was the car bought? *</label>
                                                                            <Input
                                                                                type="date"
                                                                                className="form-control"
                                                                                id="formrow-firstname-Input"
                                                                                name="boughtDate"
                                                                                placeholder="Date of birth"
                                                                                max={moment().format("YYYY-MM-DD")}
                                                                                value={validationStep6.values.boughtDate}
                                                                                onChange={validationStep6.handleChange}
                                                                                invalid={validationStep6.touched.boughtDate && validationStep6.errors.boughtDate ? true : false}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="log-manufacture">Who is the car registered to? *</label>

                                                                            <Input type="text" name="carRegisteredTo" id="carRegisteredTo"
                                                                                value={validationStep6.values.carRegisteredTo}
                                                                                onChange={validationStep6.handleChange}
                                                                                invalid={validationStep6.touched.carRegisteredTo && validationStep6.errors.carRegisteredTo ? true : false}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-manufacture">Who owns the car? *</label>

                                                                            <Input type="text" name="carOwner" id="carOwner"
                                                                                value={validationStep6.values.carOwner}
                                                                                onChange={validationStep6.handleChange}
                                                                                invalid={validationStep6.touched.carOwner && validationStep6.errors.carOwner ? true : false}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">What will the car be used for?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="carUsedFor"
                                                                                            id="carUsedFor1"
                                                                                            value="SOCIAL ONLY"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="carUsedFor1">
                                                                                            SOCIAL ONLY
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="carUsedFor"
                                                                                            id="carUsedFor2"
                                                                                            value="SOCIAL & COMMUTING"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="carUsedFor2">
                                                                                            SOCIAL & COMMUTING
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="carUsedFor"
                                                                                            id="carUsedFor3"
                                                                                            value="BUSINESS USE"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="carUsedFor3">
                                                                                            BUSINESS USE
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className="mb-30 mt-40" />
                                                                <h3 className="title mb-30 mt-10">Claims</h3>
                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Include all claims and convictions as accurately as possible to avoid affecting your level of cover and future claims. Failure to report a claim would make your insurance invalid and could result in getting extra charges from your insurer or having your car seized.</label>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Have any drivers had any motor accidents or claims in the last 5 years? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="accidentClaims"
                                                                                            id="accidents"
                                                                                            value="YES"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="accidents">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="accidentClaims"
                                                                                            id="accidentsNo"
                                                                                            value="NO"
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="accidentsNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p className="mb-20">This includes any incidents, losses, thefts or claims involving a vehicle regardless of fault or whether you claimed or not.</p>

                                                                        <label htmlFor="log-policy">What type of incident was it? *</label>
                                                                        <select className="form-group f-14"
                                                                            name="incidentType"
                                                                            value={validationStep6.values.incidentType}
                                                                            onChange={validationStep6.handleChange}
                                                                        >
                                                                            <option value="Accident">Accident</option>
                                                                            <option value="Windscreen Glass">Windscreen Glass</option>
                                                                            <option value="Theft">Theft</option>
                                                                            <option value="Malicious Damage">Malicious Damage</option>
                                                                            <option value="Strom or flood">Strom or flood</option>
                                                                            <option value="Fire">Fire</option>
                                                                        </select>

                                                                    </div>
                                                                </div>

                                                                <hr className="mb-30 mt-40" />
                                                                <h3 className="title mb-30 mt-10">Medical conditions & disabilities</h3>
                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Do any drivers on this policy have any medical conditions or disabilities that are on the DVLA’s list? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="medicalCondition"
                                                                                            id="conditions"
                                                                                            value="YES"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="conditions">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="medicalCondition"
                                                                                            id="conditionsNo"
                                                                                            value="NO"
                                                                                            onChange={validationStep6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="conditionsNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p className="mb-20">This includes any incidents, losses, thefts or claims involving a vehicle regardless of fault or whether you claimed or not.</p>

                                                                        <label htmlFor="log-policy">Select medical condition *</label>
                                                                        <select className="form-group f-14"
                                                                            name="dvlaMedicalConditionsType"
                                                                            value={validationStep6.values.dvlaMedicalConditionsType}
                                                                            onChange={validationStep6.handleChange}
                                                                        >
                                                                            <option value="DVLA aware - advised no to drive">DVLA aware - advised no to drive</option>
                                                                            <option value="DVLA aware - no restrictions">DVLA aware - no restrictions</option>
                                                                            <option value="DVLA aware - 1yr resticted licence">DVLA aware - 1yr resticted licence</option>
                                                                            <option value="DVLA aware - 2yr resticted licence">DVLA aware - 2yr resticted licence</option>
                                                                            <option value="DVLA aware - 3yr resticted licence">DVLA aware - 3yr resticted licence</option>
                                                                            <option value="DVLA unaware">DVLA unaware</option>
                                                                        </select>

                                                                    </div>
                                                                </div>
                                                                <hr className="mb-30 mt-40" />
                                                                <h3 className="title mb-30 mt-10">Medical conditions & disabilities</h3>
                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-12">
                                                                        <label htmlFor="declinedCancelledImposed">Have any of the drivers ever had insurance declined, cancelled or special terms imposed?</label>
                                                                        <select className="form-group f-14"
                                                                            name="declinedCancelledImposed"
                                                                            value={validationStep6.values.declinedCancelledImposed}
                                                                            onChange={validationStep6.handleChange}
                                                                        >
                                                                            <option value="Yes">Yes</option>
                                                                            <option value="No">No</option>
                                                                        </select>

                                                                        <div className="form-group chekbox-area">
                                                                            <div className="edu-form-check flex">
                                                                                <Input type="checkbox" id="remember-me" className="form-check-inputs" style={{ width: "auto" }} />
                                                                                <label >I CONFIRM THAT I HAVE READ AND UNDERSTOOD YOUR TERMS & CONDITIONS AND PRIVACY POLICY.</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="d-flex justify-content-between mt-4 mb-4">
                                                                    <button
                                                                        className="btn colorchangeback back-btn btn-medium"
                                                                        type="submit"
                                                                        onClick={() => setStepNumber(stepNumber - 1)}
                                                                    >
                                                                        {t<string>("Previous")}
                                                                    </button>
                                                                    {registerSteps()}
                                                                    <button
                                                                        className="btn colorchangeLog leadMargin edu-btn btn-medium"
                                                                        type="submit"
                                                                    >
                                                                        {t<string>("Finish")}
                                                                    </button>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        }



                                        {vehicalDataRef.current.services_or_personal_request == "van" &&
                                            <>
                                                {
                                                    stepNumber1 == 1 && (
                                                        <div>
                                                            <Form
                                                                className="form-horizontal"
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    validationStepvan2.handleSubmit();
                                                                    return false;
                                                                }}
                                                            >

                                                                <h3 className="title mb-20 mt-10">01. Van Details</h3>

                                                                <p className="themeColor  mb-30  font-16">Insurance quotes life admin services provide assistance in obtaining and comparing insurance quotes. They handle tasks such as gathering necessary information, requesting quotes from multiple insurance providers, reviewing and presenting the options, and providing guidance to help individuals make informed decisions about their insurance coverage.
                                                                </p>

                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Van">Van Make</label>
                                                                            <Input type="text" name="make" id="reg-Van"
                                                                                value={validationStepvan2.values.make}
                                                                                onChange={validationStepvan2.handleChange}
                                                                                invalid={validationStepvan2.touched.make && validationStepvan2.errors.make ? true : false}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Model">Van Model</label>
                                                                            <Input type="text" name="vanModel" id="reg-Model"
                                                                                value={validationStepvan2.values.vanModel}
                                                                                onChange={validationStepvan2.handleChange}
                                                                                invalid={validationStepvan2.touched.vanModel && validationStepvan2.errors.vanModel ? true : false}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="log-manufacture">Year of manufacture</label>
                                                                            <Input
                                                                                type="date"
                                                                                className="form-control"
                                                                                id="formrow-firstname-Input"
                                                                                name="manufactureYear"
                                                                                placeholder="Year of manufacture"
                                                                                max={moment().format("YYYY-MM-DD")}
                                                                                value={validationStepvan2.values.manufactureYear}
                                                                                onChange={validationStepvan2.handleChange}
                                                                                invalid={validationStepvan2.touched.manufactureYear && validationStepvan2.errors.manufactureYear ? true : false}

                                                                            />
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Transmission">Transmission</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="transmission"
                                                                                            id="exampleRadios1"
                                                                                            value="MANUAL"
                                                                                            onChange={validationStepvan2.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="exampleRadios1">
                                                                                            MANUAL
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="transmission"
                                                                                            id="exampleRadios2"
                                                                                            value="AUTOMATIC"
                                                                                            onChange={validationStepvan2.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="exampleRadios2">
                                                                                            AUTOMATIC
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Fuel">Fuel Type</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="fuelType"
                                                                                            id="fuelType"
                                                                                            value="PETROL"
                                                                                            onChange={validationStepvan2.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="fuelType">
                                                                                            PETROL
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="fuelType"
                                                                                            id="fuelType1"
                                                                                            value="DIESEL"
                                                                                            onChange={validationStepvan2.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="fuelType1">
                                                                                            DIESEL
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Engine">Engine Size</label>
                                                                            <Input type="text" name="engineSize" id="reg-Engine"
                                                                                value={validationStepvan2.values.engineSize}
                                                                                onChange={validationStepvan2.handleChange}
                                                                                invalid={validationStepvan2.touched.engineSize && validationStepvan2.errors.engineSize ? true : false}

                                                                            />
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="reg-Gross">Gross Weight (KG)</label>
                                                                            <Input type="number" name="grossWeight" id="reg-Gross"
                                                                                value={validationStepvan2.values.grossWeight}
                                                                                onChange={validationStepvan2.handleChange}
                                                                                invalid={validationStepvan2.touched.grossWeight && validationStepvan2.errors.grossWeight ? true : false}
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                </div>


                                                                <div className="d-flex justify-content-between mt-4 mb-4">

                                                                    {registerSteps1()}
                                                                    <button
                                                                        className="btn colorchangeLog leadMargin edu-btn btn-medium"
                                                                        type="submit"
                                                                    >
                                                                        {t<string>("Next")}
                                                                    </button>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    )
                                                }



                                                {
                                                    stepNumber1 == 2 && (
                                                        <div>
                                                            <Form
                                                                className="form-horizontal"
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    validationStepvan3.handleSubmit();
                                                                    return false;
                                                                }}
                                                            >

                                                                <h3 className="title mb-30 mt-10">02. About The Van  </h3>

                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Transmission">Has the van been imported?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="isImported"
                                                                                            id="imported"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="imported">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="isImported"
                                                                                            id="imported2"
                                                                                            value="NO"
                                                                                            onChange={validationStepvan3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="imported2">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Transmission">Is the van right-hand drive?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-20">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="isRightHandDrive"
                                                                                            id="right"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="right">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="isRightHandDrive"
                                                                                            id="right2"
                                                                                            value="NO"
                                                                                            onChange={validationStepvan3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="right2">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="worth">How Much is the VAN Worth</label>
                                                                            <Input type="number" name="worth" id="worth"
                                                                                value={validationStepvan3.values.worth}
                                                                                onChange={validationStepvan3.handleChange}
                                                                                invalid={validationStepvan3.touched.worth && validationStepvan3.errors.worth ? true : false}
                                                                            />
                                                                        </div>

                                                                        <label htmlFor="log-policy">How many seats does the VAN have?</label>
                                                                        <select className="form-group f-14 mb-20"
                                                                            name="seats"
                                                                            value={validationStepvan3.values.seats}
                                                                            onChange={validationStepvan3.handleChange}
                                                                        >
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                            <option value="4">4</option>
                                                                            <option value="5">5</option>
                                                                            <option value="6">6</option>
                                                                            <option value="7">7</option>
                                                                            <option value="8">8</option>
                                                                            <option value="9">9</option>
                                                                            <option value="10">10</option>
                                                                        </select>


                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Transmission">Does the van have an alarm, immobiliser or tracker?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="immobiliserOrTracker"
                                                                                            id="FACTORY"
                                                                                            value="FACTORY FITTED IMMOBILISER"
                                                                                            onChange={validationStepvan3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="FACTORY">
                                                                                            FACTORY FITTED IMMOBILISER
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="immobiliserOrTracker"
                                                                                            id="PROFESSIONALLY"
                                                                                            value="PROFESSIONALLY FITTED IMMOBILISER"
                                                                                            onChange={validationStepvan3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="PROFESSIONALLY">
                                                                                            PROFESSIONALLY FITTED IMMOBILISER
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="immobiliserOrTracker"
                                                                                            id="THATCHAM"
                                                                                            value="THATCHAM CATEGORY 1 IMMOBILISER"
                                                                                            onChange={validationStepvan3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="THATCHAM">
                                                                                            THATCHAM CATEGORY 1 IMMOBILISER
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="immobiliserOrTracker"
                                                                                            id="TRACKER"
                                                                                            value="TRACKER"
                                                                                            onChange={validationStepvan3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="TRACKER">
                                                                                            TRACKER
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="immobiliserOrTracker"
                                                                                            id="NONE"
                                                                                            value="NONE"
                                                                                            onChange={validationStepvan3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="NONE">
                                                                                            NONE
                                                                                        </label>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Transmission">Do you have a dashcam fitted in your van?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="dashCam"
                                                                                            id="dashcam"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="dashcam">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="dashCam"
                                                                                            id="dashcam2"
                                                                                            value="NO"
                                                                                            onChange={validationStepvan3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="dashcam2">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Transmission">Does the van have any modifications?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="modifications"
                                                                                            id="modifications"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="modifications">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="modifications"
                                                                                            id="modifications2"
                                                                                            value="NO"
                                                                                            onChange={validationStepvan3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="modifications2">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <label htmlFor="log-policy">How would you describe the van's body type?</label>
                                                                        <select className="form-group f-14"
                                                                            name="bodyType"
                                                                            value={validationStepvan3.values.bodyType}
                                                                            onChange={validationStepvan3.handleChange}
                                                                        >
                                                                            <option value="Van">Van</option>
                                                                            <option value="Pick-up">Pick-up</option>
                                                                            <option value="Tipper">Tipper</option>
                                                                            <option value="Dropside">Dropside</option>
                                                                            <option value="Van-Referigerated">Van-Referigerated</option>
                                                                            <option value="Vehicle Transporter">Vehicle Transporter</option>
                                                                            <option value="Flatbed">Flatbed</option>
                                                                            <option value="Luton">Luton</option>
                                                                            <option value="Box van">Box van</option>
                                                                            <option value="Curtainside van">Curtainside van</option>
                                                                        </select>

                                                                    </div>
                                                                </div>

                                                                <div className="d-flex justify-content-between mt-4 mb-4">
                                                                    <button
                                                                        className="btn colorchangeback back-btn btn-medium"
                                                                        type="submit"
                                                                        onClick={() => setStepNumber1(stepNumber1 - 1)}
                                                                    >
                                                                        {t<string>("Previous")}
                                                                    </button>
                                                                    {registerSteps1()}

                                                                    <button
                                                                        className="btn colorchangeLog leadMargin edu-btn btn-medium"
                                                                        type="submit"
                                                                    >
                                                                        {t<string>("Next")}
                                                                    </button>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    )
                                                }


                                                {
                                                    stepNumber1 == 3 && (
                                                        <div>
                                                            <Form
                                                                className="form-horizontal"
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    validationStepvan4.handleSubmit();
                                                                    return false;
                                                                }}
                                                            >
                                                                <h3 className="title mb-30 mt-10">03. Your details (policy holder)</h3>
                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-12">
                                                                        <label htmlFor="log-Make">Title *</label>
                                                                        <select className="form-group f-14 mb-20"
                                                                            name="vanTitle"
                                                                            value={validationStepvan4.values.vanTitle}
                                                                            onChange={validationStepvan4.handleChange}
                                                                        >
                                                                            <option value="MR">MR</option>
                                                                            <option value="MRS">MRS</option>
                                                                            <option value="MISS">MISS</option>
                                                                            <option value="MS">MS</option>
                                                                            <option value="DR (MALE)">DR (MALE)</option>
                                                                        </select>
                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Name">First Name </label>
                                                                            <Input type="text" name="vanfirstName" id="vanfirstName"
                                                                                value={validationStepvan4.values.vanfirstName}
                                                                                onChange={validationStepvan4.handleChange}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Surname">Surname</label>
                                                                            <Input type="text" name="sureName" id="reg-Surname"
                                                                                value={validationStepvan4.values.sureName}
                                                                                onChange={validationStepvan4.handleChange}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Date">Date of birth *</label>
                                                                            <Input
                                                                                type="date"
                                                                                className="form-control"
                                                                                id="formrow-firstname-Input"
                                                                                placeholder="Date of birth"
                                                                                max={moment().format("YYYY-MM-DD")}
                                                                                name="DOB"
                                                                                value={validationStepvan4.values.DOB}
                                                                                onChange={validationStepvan4.handleChange}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="reg-Phone">Phone Number*</label>
                                                                            <Input type="number" name="vanPhoneNo" id="reg-Phone"
                                                                                value={validationStepvan4.values.vanPhoneNo}
                                                                                onChange={validationStepvan4.handleChange}
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-between mt-4 mb-4">
                                                                    <button
                                                                        className="btn colorchangeback back-btn btn-medium"
                                                                        type="submit"
                                                                        onClick={() => setStepNumber1(stepNumber1 - 1)}
                                                                    >
                                                                        {t<string>("Previous")}
                                                                    </button>
                                                                    {registerSteps1()}

                                                                    <button
                                                                        className="btn colorchangeLog leadMargin edu-btn btn-medium"
                                                                        type="submit"
                                                                    >
                                                                        {t<string>("Next")}
                                                                    </button>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    stepNumber1 == 4 && (
                                                        <div>
                                                            <Form
                                                                className="form-horizontal"
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    validationStepvan5.handleSubmit();
                                                                    return false;
                                                                }}
                                                            >
                                                                <h3 className="title mb-30 mt-10">04. About you</h3>
                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Marital status</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="vanMaritalStatus"
                                                                                            id="SINGLE"
                                                                                            value="SINGLE"
                                                                                            onChange={validationStepvan5.handleChange}

                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="SINGLE">
                                                                                            SINGLE
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="vanMaritalStatus"
                                                                                            id="MARRIED"
                                                                                            value="MARRIED"
                                                                                            onChange={validationStepvan5.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="MARRIED">
                                                                                            MARRIED
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="vanMaritalStatus"
                                                                                            id="LIVING"
                                                                                            value="LIVING WITH PARTNER"
                                                                                            onChange={validationStepvan5.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="LIVING">
                                                                                            LIVING WITH PARTNER
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="vanMaritalStatus"
                                                                                            id="WIDOWED"
                                                                                            value="WIDOWED"
                                                                                            onChange={validationStepvan5.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="WIDOWED">
                                                                                            WIDOWED
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="vanMaritalStatus"
                                                                                            id="CIVIL"
                                                                                            value="CIVIL PARTNERSHIP"
                                                                                            onChange={validationStepvan5.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="CIVIL">
                                                                                            CIVIL PARTNERSHIP
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="vanMaritalStatus"
                                                                                            id="DIVORCED"
                                                                                            value="DIVORCED"
                                                                                            onChange={validationStepvan5.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="DIVORCED">
                                                                                            DIVORCED
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="vanMaritalStatus"
                                                                                            id="SEPERATED"
                                                                                            value="SEPERATED"
                                                                                            onChange={validationStepvan5.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="SEPERATED">
                                                                                            SEPERATED
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Transmission">Are you living in UK since birth?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="livingInUkSinceBirth"
                                                                                            id="since"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan5.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="since">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="livingInUkSinceBirth"
                                                                                            id="since2"
                                                                                            value="NO"
                                                                                            onChange={validationStepvan5.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="since2">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Transmission">Do you have any children under 16 years old?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="childUnderSixteen"
                                                                                            id="under"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan5.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="under">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="childUnderSixteen"
                                                                                            id="under2"
                                                                                            value="NO"
                                                                                            onChange={validationStepvan5.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="under2">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <label htmlFor="log-Make">What's your employement status?</label>
                                                                        <select className="form-group f-14 mb-20"
                                                                            name="employementStatus"
                                                                            value={validationStepvan5.values.employementStatus}
                                                                            onChange={validationStepvan5.handleChange}
                                                                        >
                                                                            <option value="Employed">Employed</option>
                                                                            <option value="Self-employed">Self-employed</option>
                                                                            <option value="Retired">Retired</option>
                                                                            <option value="Full-time education (student)">Full-time education (student)</option>
                                                                            <option value="Unemployed">Unemployed</option>
                                                                            <option value="House person/parent">House person/parent</option>
                                                                            <option value="Not employed due to disability">Not employed due to disability</option>
                                                                            <option value="Independent means">Independent means</option>
                                                                            <option value="Voluntary">Voluntary</option>
                                                                        </select>


                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-occupation">What's your main occupation?</label>
                                                                            <Input type="text" name="occupation" id="reg-occupation"
                                                                                value={validationStepvan5.values.occupation}
                                                                                onChange={validationStepvan5.handleChange}
                                                                                invalid={validationStepvan5.touched.occupation && validationStepvan5.errors.occupation ? true : false}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-industry">What industry is this occupation in?</label>
                                                                            <Input type="text" name="industy" id="reg-industry"
                                                                                value={validationStepvan5.values.industy}
                                                                                onChange={validationStepvan5.handleChange}
                                                                                invalid={validationStepvan5.touched.industy && validationStepvan5.errors.industy ? true : false}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Transmission">Do you have another occupation?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="occupation"
                                                                                            id="occupation"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan5.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="occupation">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="occupation"
                                                                                            id="occupation2"
                                                                                            value="NO"
                                                                                            onChange={validationStepvan5.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="occupation2">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Transmission">Are you a homeowner?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="vanHomeOwner"
                                                                                            id="homeowner"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan5.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="homeowner">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="vanHomeOwner"
                                                                                            id="homeowner2"
                                                                                            value="NO"
                                                                                            onChange={validationStepvan5.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="homeowner2">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-postcode">Please enter a postcode</label>
                                                                            <Input type="text" name="postCode" id="reg-postcode"
                                                                                value={validationStepvan5.values.postCode}
                                                                                onChange={validationStepvan5.handleChange}
                                                                                invalid={validationStepvan5.touched.postCode && validationStepvan5.errors.postCode ? true : false}
                                                                            />
                                                                        </div>


                                                                        <label htmlFor="log-Make">What is the licence type?</label>
                                                                        <select className="form-group f-14 mb-20">
                                                                            <option value="">Plese choose</option>
                                                                            <option value="Full Euro (EEC)">Full Euro (EEC)</option>
                                                                            <option value="UK provisional">UK provisional</option>
                                                                            <option value="Full UK-automatic">Full UK-automatic</option>
                                                                            <option value="International licence (none-exchangeable)">International licence (none-exchangeable)</option>
                                                                            <option value="International licence (exchangeable)">International licence (exchangeable)</option>
                                                                        </select>

                                                                        <label htmlFor="log-Make">HOW LONG HAS THE LICENCE BEEN HELD?</label>
                                                                        <select className="form-group f-14 mb-20"
                                                                            name="licencePeriod"
                                                                            value={validationStepvan5.values.licencePeriod}
                                                                            onChange={validationStepvan5.handleChange}
                                                                        >
                                                                            <option value="Less than a year">Less than a year</option>
                                                                            <option value="1 year">1 year</option>
                                                                            <option value="2 year">2 year</option>
                                                                            <option value="3 year">3 year</option>
                                                                            <option value="4 year">4 year</option>
                                                                            <option value="5 year">5 year</option>
                                                                            <option value="5+ year">5+ year</option>
                                                                        </select>


                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Transmission">Would you like to provide your GB driving licence number? Some insurers may provide a discount if you provide this.</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="gbLicenceNumber"
                                                                                            id="GB"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan5.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="GB">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="gbLicenceNumber"
                                                                                            id="GB2"
                                                                                            value="NO"
                                                                                            onChange={validationStepvan5.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="GB2">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Transmission">Do you have any additional driving qualifications?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="additionalDrivingQualifications"
                                                                                            id="qualifications"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan5.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="qualifications">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="additionalDrivingQualifications"
                                                                                            id="qualifications2"
                                                                                            value="NO"
                                                                                            onChange={validationStepvan5.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="qualifications2">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Transmission">Do you have any DVLA-reportable medical conditions or disabilities?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="vanMedicalCondition"
                                                                                            id="DVLA"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan5.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="DVLA">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="vanMedicalCondition"
                                                                                            id="DVLA2"
                                                                                            value="NO"
                                                                                            onChange={validationStepvan5.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="DVLA2">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>


                                                                <div className="d-flex justify-content-between mt-4 mb-4">
                                                                    <button
                                                                        className="btn colorchangeback back-btn btn-medium"
                                                                        type="submit"
                                                                        onClick={() => setStepNumber1(stepNumber1 - 1)}
                                                                    >
                                                                        {t<string>("Previous")}
                                                                    </button>
                                                                    {registerSteps1()}

                                                                    <button
                                                                        className="btn colorchangeLog leadMargin edu-btn btn-medium"
                                                                        type="submit"
                                                                    >
                                                                        {t<string>("Next")}
                                                                    </button>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    )
                                                }


                                                {
                                                    stepNumber1 == 5 && (
                                                        <div>
                                                            <Form
                                                                className="form-horizontal"
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    validationStepvan6.handleSubmit();
                                                                    return false;
                                                                }}
                                                            >
                                                                <h3 className="title mb-30 mt-10">05. Drivers</h3>
                                                                <h5 className="title mb-30 mt-10">Add a new driver or select one of your saved profile</h5>
                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-6">
                                                                        <label htmlFor="log-Make">Title</label>
                                                                        <select className="form-group f-14 mb-20"
                                                                            name="title"
                                                                            id="value={validationStep2.values.doesHaveDashcam}"
                                                                            value={validationStepvan6.values.title}
                                                                            onChange={validationStepvan6.handleChange}
                                                                        >
                                                                            <option value="MR">MR</option>
                                                                            <option value="MRS">MRS</option>
                                                                            <option value="MISS">MISS</option>
                                                                            <option value="MS">MS</option>
                                                                            <option value="DR (MALE)">DR (MALE)</option>
                                                                            <option value="DR (FEMALE)">DR (FEMALE)</option>
                                                                        </select>
                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Name">First Name *</label>
                                                                            <Input type="text" name="firstName" id="firstName"
                                                                                value={validationStepvan6.values.firstName}
                                                                                onChange={validationStepvan6.handleChange}
                                                                                invalid={validationStepvan6.touched.firstName && validationStepvan6.errors.firstName ? true : false}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Last">Last Name *</label>
                                                                            <Input type="text" name="lastName" id="lastName"
                                                                                value={validationStepvan6.values.lastName}
                                                                                onChange={validationStepvan6.handleChange}
                                                                                invalid={validationStepvan6.touched.lastName && validationStepvan6.errors.lastName ? true : false}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Phone">Phone *</label>
                                                                            <PhoneInput
                                                                                value={phoneNumber.phoneNo}
                                                                                defaultCountry="GB" type="text" name="phoneNo" id="phoneNo"
                                                                                onChange={(e: string) => setPhoneNumber({ ...phoneNumber, phoneNo: e })}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Date">Date of birth *</label>
                                                                            <Input
                                                                                type="date"
                                                                                className="form-control"
                                                                                id="formrow-firstname-Input"
                                                                                name="dob"
                                                                                placeholder="Date of birth"
                                                                                max={moment().format("YYYY-MM-DD")}
                                                                                value={validationStepvan6.values.dob}
                                                                                onChange={validationStepvan6.handleChange}
                                                                                invalid={validationStepvan6.touched.dob && validationStepvan6.errors.dob ? true : false}
                                                                            />
                                                                        </div>


                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Has the driver lived in the UK since birth? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="isBirthPlaceUK"
                                                                                            id="birth"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="birth">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="isBirthPlaceUK"
                                                                                            id="birthNO"
                                                                                            value="NO"
                                                                                            onChange={validationStepvan6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="birthNO">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <h5 className="title mb-20 mt-10">When did the driver start continuously living in the UK?</h5>
                                                                        <h6 className="title mb-20 mt-10">Insurers need to know how long the driver has continuously lived in the UK as a permanent resident. This means without any gaps of 6 months or longer.</h6>
                                                                        <div className="form-group">
                                                                            <label htmlFor="month">Month</label>
                                                                            <Input type="number" name="month" id="month"
                                                                                value={validationStepvan6.values.month}
                                                                                onChange={validationStepvan6.handleChange}
                                                                                invalid={validationStepvan6.touched.month && validationStepvan6.errors.month ? true : false}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="reg-Year">Year</label>
                                                                            <Input type="number" name="year" id="year"
                                                                                value={validationStepvan6.values.year}
                                                                                onChange={validationStepvan6.handleChange}
                                                                                invalid={validationStepvan6.touched.year && validationStepvan6.errors.year ? true : false}
                                                                            />
                                                                        </div>



                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Gender</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="gender"
                                                                                            id="MALE"
                                                                                            value="MALE"
                                                                                            onChange={validationStepvan6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="MALE">
                                                                                            MALE
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="gender"
                                                                                            id="FEMALE"
                                                                                            value="FEMALE"
                                                                                            onChange={validationStepvan6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="FEMALE">
                                                                                            FEMALE
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Marital status</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="maritalStatus"
                                                                                            id="SINGLE"
                                                                                            value="SINGLE"
                                                                                            onChange={validationStepvan6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="SINGLE">
                                                                                            SINGLE
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="maritalStatus"
                                                                                            id="MARRIED"
                                                                                            value="MARRIED"
                                                                                            onChange={validationStepvan6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="MARRIED">
                                                                                            MARRIED
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="maritalStatus"
                                                                                            id="LIVING"
                                                                                            value="LIVING WITH PARTNER"
                                                                                            onChange={validationStepvan6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="LIVING">
                                                                                            LIVING WITH PARTNER
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="maritalStatus"
                                                                                            id="WIDOWED"
                                                                                            value="WIDOWED"
                                                                                            onChange={validationStepvan6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="WIDOWED">
                                                                                            WIDOWED
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="exampleRadioss"
                                                                                            id="CIVIL"
                                                                                            value="CIVIL PARTNERSHIP"
                                                                                            onChange={validationStepvan6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="CIVIL">
                                                                                            CIVIL PARTNERSHIP
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="maritalStatus"
                                                                                            id="DIVORCED"
                                                                                            value="DIVORCED"
                                                                                            onChange={validationStepvan6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="DIVORCED">
                                                                                            DIVORCED
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="maritalStatus"
                                                                                            id="SEPERATED"
                                                                                            value="SEPERATED"
                                                                                            onChange={validationStepvan6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="SEPERATED">
                                                                                            SEPERATED
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Does the driver own or use another vehicle? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="driverOwnorNot"
                                                                                            id="YESa"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="YESa">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="driverOwnorNot"
                                                                                            id="NOs"
                                                                                            value="NO"
                                                                                            onChange={validationStepvan6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="NOs">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p className="mb-20">Some insurers could offer a discount if the driver uses another vehicle, as they’ll have a better idea of driving habits.</p>

                                                                    </div>
                                                                </div>

                                                                <div className="d-flex justify-content-between mt-4 mb-4" >
                                                                    <button
                                                                        className="btn colorchangeback back-btn btn-medium"
                                                                        type="submit"
                                                                        onClick={() => setStepNumber1(stepNumber1 - 1)}
                                                                    >
                                                                        {t<string>("Previous")}
                                                                    </button>
                                                                    {registerSteps1()}

                                                                    <button
                                                                        className="btn colorchangeLog leadMargin edu-btn btn-medium"
                                                                        type="submit"
                                                                    >
                                                                        {t<string>("Next")}
                                                                    </button>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    )
                                                }


                                                {
                                                    stepNumber1 == 6 && (
                                                        <div>
                                                            <Form
                                                                className="form-horizontal"
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    validationStepvan7.handleSubmit();
                                                                    return false;
                                                                }}
                                                            >
                                                                <h3 className="title mb-30 mt-10">06. Occupation</h3>

                                                                <div className="form-group">
                                                                    <label htmlFor="log-Doors">What's the driver's employment status?</label>
                                                                </div>
                                                                <div className="card-heading mb-2">
                                                                    <div className="row">
                                                                        <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="employeementStatus"
                                                                                    id="EMPLOYED"
                                                                                    value="EMPLOYED"
                                                                                    onChange={validationStepvan7.handleChange}

                                                                                />

                                                                                <label className="form-check-label" htmlFor="EMPLOYED">
                                                                                    EMPLOYED
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="employeementStatus"
                                                                                    id="SELE-EMPLOYED"
                                                                                    value="SELE-EMPLOYED"
                                                                                    onChange={validationStepvan7.handleChange}
                                                                                />

                                                                                <label className="form-check-label" htmlFor="SELE-EMPLOYED">
                                                                                    SELE-EMPLOYED
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="employeementStatus"
                                                                                    id="RETIRED"
                                                                                    value="RETIRED"
                                                                                    onChange={validationStepvan7.handleChange}
                                                                                />

                                                                                <label className="form-check-label" htmlFor="RETIRED">
                                                                                    RETIRED
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <hr className="mb-30 mt-40" />
                                                                <h3 className="title mb-30 mt-10">07. Licence</h3>

                                                                <div className="form-group">
                                                                    <label htmlFor="log-Doors">What type of license does the driver currently have?</label>
                                                                </div>
                                                                <div className="card-heading mb-2">
                                                                    <div className="row">
                                                                        <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="licenceType"
                                                                                    id="FULL UK-MANUAL"
                                                                                    value="FULL UK-MANUAL"
                                                                                    onChange={validationStepvan7.handleChange}
                                                                                />

                                                                                <label className="form-check-label" htmlFor="FULL UK-MANUAL">
                                                                                    FULL UK-MANUAL
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="licenceType"
                                                                                    id="EEC"
                                                                                    value="FULL EURO (EEC)"
                                                                                    onChange={validationStepvan7.handleChange}
                                                                                />

                                                                                <label className="form-check-label" htmlFor="EEC">
                                                                                    FULL EURO (EEC)
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="licenceType"
                                                                                    id="OPTIONS"
                                                                                    value="SHOW MORE OPTIONS"
                                                                                    onChange={validationStepvan7.handleChange}
                                                                                />

                                                                                <label className="form-check-label" htmlFor="OPTIONS">
                                                                                    SHOW MORE OPTIONS
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex justify-content-between mt-4 mb-4">
                                                                    <button
                                                                        className="btn colorchangeback back-btn btn-medium"
                                                                        type="submit"
                                                                        onClick={() => setStepNumber1(stepNumber1 - 1)}
                                                                    >
                                                                        {t<string>("Previous")}
                                                                    </button>
                                                                    {registerSteps1()}
                                                                    <button
                                                                        className="btn colorchangeLog leadMargin edu-btn btn-medium"
                                                                        type="submit"
                                                                    >
                                                                        {t<string>("Next")}
                                                                    </button>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    )
                                                }


                                                {
                                                    stepNumber1 == 7 && (
                                                        <div>
                                                            <Form
                                                                className="form-horizontal"
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    validationStepvan8.handleSubmit();
                                                                    return false;
                                                                }}
                                                            >
                                                                <h3 className="title mb-30 mt-10">08. Further policyholder details</h3>

                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <label htmlFor="reg-number">Phone number</label>
                                                                            <PhoneInput
                                                                                value={phoneNumber1.phoneNo}
                                                                                defaultCountry="GB" type="text" name="phoneNo" id="phoneNo"
                                                                                onChange={(e: string) => setPhoneNumber1({ ...phoneNumber1, phoneNo: e })}
                                                                            />
                                                                        </div>
                                                                        <p className="mb-20">Insurers need this to be able to create the quotes, but they won't phone unless you agree to being contacted.</p>

                                                                        <div className="form-group">
                                                                            <label htmlFor="reg-Postal">Postal Code</label>
                                                                            <Input type="text" name="postalCode" id="reg-Postal"
                                                                                value={validationStepvan8.values.postalCode}
                                                                                onChange={validationStepvan8.handleChange}
                                                                                invalid={validationStepvan8.touched.postalCode && validationStepvan8.errors.postalCode ? true : false}
                                                                            />
                                                                        </div>
                                                                        <p className="mb-20">Insurers need this to be able to create the quotes, but they won't phone unless you agree to be contacted.</p>

                                                                        <div className="form-group">
                                                                            <label htmlFor="reg-Address">Address</label>
                                                                            <Input type="textarea" name="address" id="address"
                                                                                value={validationStepvan8.values.address}
                                                                                onChange={validationStepvan8.handleChange}
                                                                                invalid={validationStepvan8.touched.address && validationStepvan8.errors.address ? true : false}
                                                                            />
                                                                        </div>
                                                                        <p className="mb-20">Insurers need this to be able to create the quotes, but they won't phone unless you agree to be contacted.</p>
                                                                    </div>
                                                                </div>

                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Is you are a homeowner? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="homeOwner"
                                                                                            id="homeowner"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan8.handleChange}

                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="homeowner">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="homeOwner"
                                                                                            id="homeownerNo"
                                                                                            value="NO"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="homeownerNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p className="mb-20">We need this to get more accurate prices</p>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Will the car be kept at home during the day? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="isCarKeptAtHome"
                                                                                            id="during"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="during">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="isCarKeptAtHome"
                                                                                            id="duringNo"
                                                                                            value="NO"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="duringNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">By the time the policy begins, will Awais have any children under 16 years old? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="childrenUnderSixteen"
                                                                                            id="policy"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="policy">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="childrenUnderSixteen"
                                                                                            id="policyNo"
                                                                                            value="NO"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="policyNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <label htmlFor="log-policy">By the time the policy begins, how many years of no-claims bonus (NCB) will Awais have? *</label>
                                                                        <select className="form-group f-14" name="YearsofNoClaimBonus"
                                                                            value={validationStepvan8.values.YearsofNoClaimBonus}
                                                                            onChange={validationStepvan8.handleChange}
                                                                        >
                                                                            <option value="NO NCB">NO NCB</option>
                                                                            <option value="1 Year">1 Year</option>
                                                                            <option value="2 Year">2 Year</option>
                                                                            <option value="3 Year">3 Year</option>
                                                                            <option value="4 Year">4 Year</option>
                                                                            <option value="5 Year">5 Year</option>
                                                                            <option value="6 Year">6 Year</option>
                                                                            <option value="7 Year">7 Year</option>
                                                                            <option value="8 Year">8 Year</option>
                                                                            <option value="9 Year">9 Year</option>
                                                                            <option value="10 Year">10 Year</option>
                                                                            <option value="11 Year">11 Year</option>
                                                                            <option value="12 Year">12 Year</option>
                                                                            <option value="13 Year">13 Year</option>
                                                                            <option value="14 Year">14 Year</option>
                                                                            <option value="15 Year">15 Year</option>
                                                                            <option value="No NCB in last 2 years">No NCB in last 2 years</option>
                                                                        </select>
                                                                        <p className="mb-20">Most insurers ask for evidence of NCB. You should be able to get this from the current or previous insurer.</p>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Is Awais's no-claims bonus (NCB) currently protected? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="currentlyProtected"
                                                                                            id="bonus"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="bonus">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="currentlyProtected"
                                                                                            id="bonusNo"
                                                                                            value="NO"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="bonusNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Does the driver own or use another vehicle? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="driverVehicalOrNot"
                                                                                            id="vehicle"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="vehicle">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="driverVehicalOrNot"
                                                                                            id="vehicleNo"
                                                                                            value="NO"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="vehicleNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p className="mb-20">Some insurers could offer a discount if the driver uses another vehicle, as they’ll have a better idea of driving habits.</p>

                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-drive">How many cars are kept at home?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="11"
                                                                                            value="1"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="11">
                                                                                            1
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="12"
                                                                                            value="2"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="12">
                                                                                            2
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="13"
                                                                                            value="3"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="13">
                                                                                            3
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="14"
                                                                                            value="4"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="14">
                                                                                            4
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="15"
                                                                                            value="5"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="15">
                                                                                            5
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="16"
                                                                                            value="6"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="16">
                                                                                            6
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="17"
                                                                                            value="7"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="17">
                                                                                            7
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="18"
                                                                                            value="8"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="18">
                                                                                            8
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="19"
                                                                                            value="9"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="19">
                                                                                            9
                                                                                        </label>
                                                                                    </div>
                                                                                    <p className="mb-20">Some insurers offer a discount if there's more than one car at home. If the car you’re getting a quote for will be in addition to cars already at the home, include it in the number.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className="mb-30 mt-40" />
                                                                <h3 className="title mb-30 mt-10">Car Usage</h3>
                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group ">
                                                                            <label htmlFor="log-Doors">Has the car been bought yet? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="boghtYet"
                                                                                            id="bought"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="bought">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="boghtYet"
                                                                                            id="boughtNo"
                                                                                            value="NO"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="boughtNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="log-manufacture">When was the car bought? *</label>
                                                                            <Input
                                                                                type="date"
                                                                                className="form-control"
                                                                                id="formrow-firstname-Input"
                                                                                name="boughtDate"
                                                                                placeholder="Date of birth"
                                                                                max={moment().format("YYYY-MM-DD")}
                                                                                value={validationStepvan8.values.boughtDate}
                                                                                onChange={validationStepvan8.handleChange}
                                                                                invalid={validationStepvan8.touched.boughtDate && validationStepvan8.errors.boughtDate ? true : false}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="log-manufacture">Who is the car registered to? *</label>
                                                                            <Input type="text" name="carRegisteredTo" id="carRegisteredTo"
                                                                                value={validationStepvan8.values.carRegisteredTo}
                                                                                onChange={validationStepvan8.handleChange}
                                                                                invalid={validationStepvan8.touched.carRegisteredTo && validationStepvan8.errors.carRegisteredTo ? true : false}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-manufacture">Who owns the car? *</label>
                                                                            <Input type="text" name="carOwner" id="carOwner"
                                                                                value={validationStepvan8.values.carOwner}
                                                                                onChange={validationStepvan8.handleChange}
                                                                                invalid={validationStepvan8.touched.carOwner && validationStepvan8.errors.carOwner ? true : false}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">What will the car be used for?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="carUsedFor"
                                                                                            id="ONLY"
                                                                                            value="SOCIAL ONLY"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="ONLY">
                                                                                            SOCIAL ONLY
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="carUsedFor"
                                                                                            id="COMMUTING"
                                                                                            value="SOCIAL & COMMUTING"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="COMMUTING">
                                                                                            SOCIAL & COMMUTING
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="carUsedFor"
                                                                                            id="BUSINESS"
                                                                                            value="BUSINESS USE"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="BUSINESS">
                                                                                            BUSINESS USE
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className="mb-30 mt-40" />
                                                                <h3 className="title mb-30 mt-10">Claims</h3>
                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Include all claims and convictions as accurately as possible to avoid affecting your level of cover and future claims. Failure to report a claim would make your insurance invalid and could result in getting extra charges from your insurer or having your car seized.</label>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Have any drivers had any motor accidents or claims in the last 5 years? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="accidentClaims"
                                                                                            id="accidents"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="accidents">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="accidentClaims"
                                                                                            id="accidentsNo"
                                                                                            value="NO"
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="accidentsNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p className="mb-20">This includes any incidents, losses, thefts or claims involving a vehicle regardless of fault or whether you claimed or not.</p>

                                                                        <label htmlFor="log-policy">What type of incident was it? *</label>
                                                                        <select className="form-group f-14"
                                                                            name="incidentType"
                                                                            value={validationStepvan8.values.incidentType}
                                                                            onChange={validationStepvan8.handleChange}
                                                                        >
                                                                            <option value="Accident">Accident</option>
                                                                            <option value="Windscreen Glass">Windscreen Glass</option>
                                                                            <option value="Theft">Theft</option>
                                                                            <option value="Malicious Damage">Malicious Damage</option>
                                                                            <option value="Strom or flood">Strom or flood</option>
                                                                            <option value="Fire">Fire</option>
                                                                        </select>

                                                                    </div>
                                                                </div>

                                                                <hr className="mb-30 mt-40" />
                                                                <h3 className="title mb-30 mt-10">Medical conditions & disabilities</h3>
                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Do any drivers on this policy have any medical conditions or disabilities that are on the DVLA’s list? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="medicalCondition"
                                                                                            id="conditions"
                                                                                            value="YES"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="conditions">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="medicalCondition"
                                                                                            id="conditionsNo"
                                                                                            value="NO"
                                                                                            onChange={validationStepvan8.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="conditionsNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p className="mb-20">This includes any incidents, losses, thefts or claims involving a vehicle regardless of fault or whether you claimed or not.</p>

                                                                        <label htmlFor="log-policy">Select medical condition *</label>
                                                                        <select className="form-group f-14"
                                                                            name="dvlaMedicalConditionsType"
                                                                            value={validationStepvan8.values.dvlaMedicalConditionsType}
                                                                            onChange={validationStepvan8.handleChange}
                                                                        >
                                                                            <option value="DVLA aware - advised no to drive">DVLA aware - advised no to drive</option>
                                                                            <option value="DVLA aware - no restrictions">DVLA aware - no restrictions</option>
                                                                            <option value="DVLA aware - 1yr resticted licence">DVLA aware - 1yr resticted licence</option>
                                                                            <option value="DVLA aware - 2yr resticted licence">DVLA aware - 2yr resticted licence</option>
                                                                            <option value="DVLA aware - 3yr resticted licence">DVLA aware - 3yr resticted licence</option>
                                                                            <option value="DVLA unaware">DVLA unaware</option>
                                                                        </select>

                                                                    </div>
                                                                </div>
                                                                <hr className="mb-30 mt-40" />
                                                                <h3 className="title mb-30 mt-10">Medical conditions & disabilities</h3>
                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-12">
                                                                        <label htmlFor="declinedCancelledImposed">Have any of the drivers ever had insurance declined, cancelled or special terms imposed?</label>
                                                                        <select className="form-group f-14"
                                                                            name="declinedCancelledImposed"
                                                                            value={validationStepvan8.values.declinedCancelledImposed}
                                                                            onChange={validationStepvan8.handleChange}
                                                                        >
                                                                            <option value="Yes">Yes</option>
                                                                            <option value="No">No</option>
                                                                        </select>

                                                                        <div className="form-group chekbox-area">
                                                                            <div className="edu-form-check flex">
                                                                                <Input type="checkbox" className="form-check-inputs" id="remember-me" style={{ width: "auto" }} />
                                                                                <label >I CONFIRM THAT I HAVE READ AND UNDERSTOOD YOUR TERMS & CONDITIONS AND PRIVACY POLICY.</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="d-flex justify-content-between mt-4 mb-4">
                                                                    <button
                                                                        className="btn colorchangeback back-btn btn-medium"
                                                                        type="submit"
                                                                        onClick={() => setStepNumber1(stepNumber1 - 1)}
                                                                    >
                                                                        {t<string>("Previous")}
                                                                    </button>
                                                                    {registerSteps1()}
                                                                    <button
                                                                        className="btn colorchangeLog leadMargin edu-btn btn-medium"
                                                                        type="submit"
                                                                    >
                                                                        {t<string>("Finish")}
                                                                    </button>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        }


                                        {vehicalDataRef.current.services_or_personal_request == "bike" &&
                                            <>
                                                {
                                                    stepNumber2 == 1 && (
                                                        <div>
                                                            <Form
                                                                className="form-horizontal"
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    validationStepBike2.handleSubmit();
                                                                    return false;
                                                                }}
                                                            >

                                                                <h3 className="title mb-20 mt-10">01. About You</h3>

                                                                <p className="themeColor mb-30 font-16 mt-3">Insurance quotes life admin services provide assistance in obtaining and comparing insurance quotes. They handle tasks such as gathering necessary information, requesting quotes from multiple insurance providers, reviewing and presenting the options, and providing guidance to help individuals make informed decisions about their insurance coverage.
                                                                </p>
                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Van">Title *</label>
                                                                            <Input type="text" name="bikeTitle" id="reg-Van"
                                                                                value={validationStepBike2.values.bikeTitle}
                                                                                onChange={validationStepBike2.handleChange}
                                                                                invalid={validationStepBike2.touched.bikeTitle && validationStepBike2.errors.bikeTitle ? true : false}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Model">First name *</label>
                                                                            <Input type="text" name="bikeFirstName" id="reg-Model"
                                                                                value={validationStepBike2.values.bikeFirstName}
                                                                                onChange={validationStepBike2.handleChange}
                                                                                invalid={validationStepBike2.touched.bikeFirstName && validationStepBike2.errors.bikeFirstName ? true : false}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Model">Surname *</label>
                                                                            <Input type="text" name="sureName" id="reg-Model"
                                                                                value={validationStepBike2.values.sureName}
                                                                                onChange={validationStepBike2.handleChange}
                                                                                invalid={validationStepBike2.touched.sureName && validationStepBike2.errors.sureName ? true : false}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Model">Email Address *</label>
                                                                            <Input type="text" name="email" id="reg-Model"
                                                                                value={validationStepBike2.values.email}
                                                                                onChange={validationStepBike2.handleChange}
                                                                                invalid={validationStepBike2.touched.email && validationStepBike2.errors.email ? true : false}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Model">Mobile Phone Number *</label>
                                                                            <Input type="number" name="bikePhoneNo" id="reg-Model"
                                                                                value={validationStepBike2.values.bikePhoneNo}
                                                                                onChange={validationStepBike2.handleChange}
                                                                                invalid={validationStepBike2.touched.bikePhoneNo && validationStepBike2.errors.bikePhoneNo ? true : false}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="log-manufacture">Date of birth *</label>
                                                                            <Input
                                                                                type="date"
                                                                                className="form-control"
                                                                                name="bikeDob"
                                                                                id="formrow-firstname-Input"
                                                                                placeholder="Date of birth"
                                                                                max={moment().format("YYYY-MM-DD")}
                                                                                value={validationStepBike2.values.bikeDob}
                                                                                onChange={validationStepBike2.handleChange}
                                                                                invalid={validationStepBike2.touched.bikeDob && validationStepBike2.errors.bikeDob ? true : false}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Marital status *</label>
                                                                        </div>
                                                                        <select className="form-group f-14 mb-20"
                                                                            name="martialStatus"
                                                                            value={validationStepBike2.values.martialStatus}
                                                                            onChange={validationStepBike2.handleChange}
                                                                        >
                                                                            <option value="Married">Married</option>
                                                                            <option value="Common Law/Living with partner">Common Law/Living with partner</option>
                                                                            <option value="Divorced">Divorced</option>
                                                                        </select>



                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Engine">Post Code * </label>
                                                                            <Input type="text" name="postCode" id="reg-Engine"
                                                                                value={validationStepBike2.values.postCode}
                                                                                onChange={validationStepBike2.handleChange}
                                                                                invalid={validationStepBike2.touched.postCode && validationStepBike2.errors.postCode ? true : false}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Transmission">Home Owner*</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="bikeHomeOwner"
                                                                                            id="Owner"
                                                                                            value="YES"
                                                                                            onChange={validationStepBike2.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="Owner">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="bikeHomeOwner"
                                                                                            id="Owner2"
                                                                                            value="NO"
                                                                                            onChange={validationStepBike2.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="Owner2">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="log-manufacture">UK resident since *</label>
                                                                            <Input
                                                                                type="date"
                                                                                className="form-control"
                                                                                name="residenceSince"
                                                                                id="formrow-firstname-Input"
                                                                                placeholder="Date of birth"
                                                                                max={moment().format("YYYY-MM-DD")}
                                                                                value={validationStepBike2.values.residenceSince}
                                                                                onChange={validationStepBike2.handleChange}
                                                                                invalid={validationStepBike2.touched.residenceSince && validationStepBike2.errors.residenceSince ? true : false}
                                                                            />
                                                                        </div>


                                                                        <div className="form-group chekbox-area">
                                                                            <div className="edu-form-check flex">
                                                                                <Input type="checkbox" className="form-check-inputs2" id="remember-me" style={{ width: "auto" }} />
                                                                                <label >TO CONFIRM THAT YOU CONSENT TO YOUR PERSONAL DATA (INCLUDING ANY SENSITIVE PERSONAL DATA) BEING USED FOR THE PURPOSE OF OBTAINING INSURANCE QUOTES, AND CONFIRM THAT YOU HAVE READ AND AGREE TO THE TERMS AND CONDITIONS OF USE, ASSUMPTIONS AND PRIVACY POLICY, PLEASE TICK THIS BOX</label>
                                                                            </div>
                                                                        </div>


                                                                        <hr className="mb-30 mt-40" />
                                                                        <h3 className="title mb-30 mt-10">02.Bike specification  </h3>
                                                                        <div className="row g-5 mb-20 justify-content-center">
                                                                            <div className="col-lg-6">
                                                                                <div className="form-group mb-20">
                                                                                    <label htmlFor="reg-Phone">What's the value of the bike?</label>
                                                                                    <Input type="number" name="value" id="reg-Phone"
                                                                                        value={validationStepBike2.values.value}
                                                                                        onChange={validationStepBike2.handleChange}
                                                                                        invalid={validationStepBike2.touched.value && validationStepBike2.errors.value ? true : false}
                                                                                    />
                                                                                </div>

                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="log-Transmission">Is the bike an import?
                                                                                    </label>
                                                                                </div>
                                                                                <div className="card-heading mb-2">
                                                                                    <div className="row">
                                                                                        <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                                <Input
                                                                                                    className="form-check-input"
                                                                                                    type="radio"
                                                                                                    name="isImported"
                                                                                                    id="MODEL"
                                                                                                    value="NO-STANDARD UK MODEL"
                                                                                                    onChange={validationStepBike2.handleChange}
                                                                                                />

                                                                                                <label className="form-check-label" htmlFor="MODEL">
                                                                                                    NO - STANDARD UK MODEL
                                                                                                </label>
                                                                                            </div>

                                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                                <Input
                                                                                                    className="form-check-input"
                                                                                                    type="radio"
                                                                                                    name="isImported"
                                                                                                    id="SPEC"
                                                                                                    value="YES - EURO IMPORT-UK SPEC"
                                                                                                    onChange={validationStepBike2.handleChange}
                                                                                                />

                                                                                                <label className="form-check-label" htmlFor="SPEC">
                                                                                                    YES - EURO IMPORT-UK SPEC
                                                                                                </label>
                                                                                            </div>

                                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                                <Input
                                                                                                    className="form-check-input"
                                                                                                    type="radio"
                                                                                                    name="isImported"
                                                                                                    id="EURO"
                                                                                                    value="YES - EURO IMPORT- NON UK SPEC"
                                                                                                    onChange={validationStepBike2.handleChange}
                                                                                                />

                                                                                                <label className="form-check-label" htmlFor="EURO">
                                                                                                    YES - EURO IMPORT- NON UK SPEC
                                                                                                </label>
                                                                                            </div>

                                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                                <Input
                                                                                                    className="form-check-input"
                                                                                                    type="radio"
                                                                                                    name="isImported"
                                                                                                    id="JAPANESEIMPORT"
                                                                                                    value="YES - JAPANESE IMPORT"
                                                                                                    onChange={validationStepBike2.handleChange}
                                                                                                />

                                                                                                <label className="form-check-label" htmlFor="JAPANESEIMPORT">
                                                                                                    YES - JAPANESE IMPORT
                                                                                                </label>
                                                                                            </div>

                                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                                <Input
                                                                                                    className="form-check-input"
                                                                                                    type="radio"
                                                                                                    name="isImported"
                                                                                                    id="USIMPORT"
                                                                                                    value="YES - US IMPORT"
                                                                                                    onChange={validationStepBike2.handleChange}
                                                                                                />

                                                                                                <label className="form-check-label" htmlFor="USIMPORT">
                                                                                                    YES - US IMPORT
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <hr className="mb-30 mt-40" />
                                                                        <h3 className="title mb-30 mt-10">03. Further details</h3>
                                                                        <div className="row g-5 mb-20 justify-content-center">
                                                                            <div className="col-lg-6">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="log-Doors">Does the Bike have any modifications?</label>
                                                                                </div>
                                                                                <select className="form-group f-14 mb-20"
                                                                                    name="modifications"
                                                                                    value={validationStepBike2.values.modifications}
                                                                                    onChange={validationStepBike2.handleChange}
                                                                                >


                                                                                    <option value="No">No</option>
                                                                                    <option value="Yes">Yes</option>
                                                                                </select>

                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex justify-content-between mt-4 mb-4">

                                                                    {registerSteps2()}
                                                                    <button
                                                                        className="btn colorchangeLog leadMargin edu-btn btn-medium"
                                                                        type="submit"
                                                                    >
                                                                        {t<string>("Next")}
                                                                    </button>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    )
                                                }



                                                {
                                                    stepNumber2 == 2 && (
                                                        <div>
                                                            <Form
                                                                className="form-horizontal"
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    validationStepBike3.handleSubmit();
                                                                    return false;
                                                                }}
                                                            >
                                                                <h3 className="title mb-30 mt-10">04. Cover</h3>

                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-8">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-manufacture">When would you like the cover to begin, within the next 30 days?</label>
                                                                            <Input
                                                                                type="date"
                                                                                className="form-control"
                                                                                name="whenWouldYouCover"
                                                                                id="formrow-firstname-Input"
                                                                                placeholder="Date of birth"
                                                                                max={moment().format("YYYY-MM-DD")}
                                                                                required
                                                                                value={validationStepBike3.values.whenWouldYouCover}
                                                                                onChange={validationStepBike3.handleChange}
                                                                                invalid={validationStepBike3.touched.whenWouldYouCover && validationStepBike3.errors.whenWouldYouCover ? true : false}

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                    </div>
                                                                </div>


                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-8">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">What level of cover do you need?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="levelOfCover"
                                                                                            id="COMPREHENSIVE"
                                                                                            value="COMPREHENSIVE"
                                                                                            onChange={validationStepBike3.handleChange}

                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="COMPREHENSIVE">
                                                                                            COMPREHENSIVE
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="levelOfCover"
                                                                                            id="THEFT"
                                                                                            value="THIRD PARTY, FIRE & THEFT"
                                                                                            onChange={validationStepBike3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="THEFT">
                                                                                            THIRD PARTY, FIRE & THEFT
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name="levelOfCover"
                                                                                            id="PARTY"
                                                                                            value="THIRD PARTY ONLY"
                                                                                            onChange={validationStepBike3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="PARTY">
                                                                                            THIRD PARTY ONLY
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Choose a voluntary excess</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="voluntaryExcess"
                                                                                            id="150"
                                                                                            value="150"
                                                                                            onChange={validationStepBike3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="150">
                                                                                            £150
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="voluntaryExcess"
                                                                                            id="250"
                                                                                            value="250"
                                                                                            onChange={validationStepBike3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="250">
                                                                                            £250
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name="voluntaryExcess"
                                                                                            id="350"
                                                                                            value="350"
                                                                                            onChange={validationStepBike3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="350">
                                                                                            £350
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name="voluntaryExcess"
                                                                                            id="0"
                                                                                            value="0"
                                                                                            onChange={validationStepBike3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="0">
                                                                                            £0
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name="voluntaryExcess"
                                                                                            id="100"
                                                                                            value="100"
                                                                                            onChange={validationStepBike3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="100">
                                                                                            £100
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name="voluntaryExcess"
                                                                                            id="500"
                                                                                            value="500"
                                                                                            onChange={validationStepBike3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="500">
                                                                                            £500
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name="voluntaryExcess"
                                                                                            id="750"
                                                                                            value="750"
                                                                                            onChange={validationStepBike3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="750">
                                                                                            £750
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name="voluntaryExcess"
                                                                                            id="10000"
                                                                                            value="10000"
                                                                                            onChange={validationStepBike3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="10000">
                                                                                            £10000
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">How would you want to pay for the car insurance policy?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="payForCarInsurance"
                                                                                            id="ANNUAL"
                                                                                            value="ANNUAL PAYMENT"
                                                                                            onChange={validationStepBike3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="ANNUAL">
                                                                                            ANNUAL PAYMENT
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="payForCarInsurance"
                                                                                            id="MONTHLY"
                                                                                            value="MONTHLY PAYMENTS"
                                                                                            onChange={validationStepBike3.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="MONTHLY">
                                                                                            MONTHLY PAYMENTS
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="d-flex justify-content-between mt-4 mb-4">
                                                                    <button
                                                                        className="btn colorchangeback back-btn btn-medium"
                                                                        type="submit"
                                                                        onClick={() => setStepNumber2(stepNumber2 - 1)}
                                                                    >
                                                                        {t<string>("Previous")}
                                                                    </button>
                                                                    {registerSteps2()}

                                                                    <button
                                                                        className="btn colorchangeLog leadMargin edu-btn btn-medium"
                                                                        type="submit"
                                                                    >
                                                                        {t<string>("Next")}
                                                                    </button>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    stepNumber2 == 3 && (
                                                        <div>
                                                            <Form
                                                                className="form-horizontal"
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    validationStepBike3.handleSubmit();
                                                                    return false;
                                                                }}
                                                            >
                                                                <h3 className="title mb-30 mt-10">05. Drivers</h3>
                                                                <h5 className="title mb-30 mt-10">Add a new driver or select one of your saved profile</h5>
                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-6">
                                                                        <label htmlFor="log-Make">Title</label>
                                                                        <select className="form-group f-14 mb-20"
                                                                            name="title"
                                                                            id="value={validationStep2.values.doesHaveDashcam}"
                                                                            value={validationStepBike4.values.title}
                                                                            onChange={validationStepBike4.handleChange}
                                                                        >
                                                                            <option value="MR">MR</option>
                                                                            <option value="MRS">MRS</option>
                                                                            <option value="MISS">MISS</option>
                                                                            <option value="MS">MS</option>
                                                                            <option value="DR (MALE)">DR (MALE)</option>
                                                                            <option value="DR (FEMALE)">DR (FEMALE)</option>
                                                                        </select>
                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Name">First Name *</label>
                                                                            <Input type="text" name="firstName" id="firstName"
                                                                                value={validationStepBike4.values.firstName}
                                                                                onChange={validationStepBike4.handleChange}
                                                                                invalid={validationStepBike4.touched.firstName && validationStepBike4.errors.firstName ? true : false}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Last">Last Name *</label>
                                                                            <Input type="text" name="lastName" id="lastName"
                                                                                value={validationStepBike4.values.lastName}
                                                                                onChange={validationStepBike4.handleChange}
                                                                                invalid={validationStepBike4.touched.lastName && validationStepBike4.errors.lastName ? true : false}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Phone">Phone *</label>
                                                                            <PhoneInput
                                                                                value={phoneNumber.phoneNo}
                                                                                defaultCountry="GB" type="text" name="phoneNo" id="phoneNo"
                                                                                onChange={(e: string) => setPhoneNumber({ ...phoneNumber, phoneNo: e })}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="reg-Date">Date of birth *</label>
                                                                            <Input
                                                                                type="date"
                                                                                className="form-control"
                                                                                id="formrow-firstname-Input"
                                                                                name="dob"
                                                                                placeholder="Date of birth"
                                                                                max={moment().format("YYYY-MM-DD")}
                                                                                value={validationStepBike4.values.dob}
                                                                                onChange={validationStepBike4.handleChange}
                                                                                invalid={validationStepBike4.touched.dob && validationStepBike4.errors.dob ? true : false}
                                                                            />
                                                                        </div>


                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Has the driver lived in the UK since birth? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="isBirthPlaceUK"
                                                                                            id="birth"
                                                                                            value="YES"
                                                                                            onChange={validationStepBike4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="birth">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="isBirthPlaceUK"
                                                                                            id="birthNO"
                                                                                            value="NO"
                                                                                            onChange={validationStepBike4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="birthNO">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <h5 className="title mb-20 mt-10">When did the driver start continuously living in the UK?</h5>
                                                                        <h6 className="title mb-20 mt-10">Insurers need to know how long the driver has continuously lived in the UK as a permanent resident. This means without any gaps of 6 months or longer.</h6>
                                                                        <div className="form-group">
                                                                            <label htmlFor="month">Month</label>
                                                                            <Input type="number" name="month" id="month"
                                                                                value={validationStepBike4.values.month}
                                                                                onChange={validationStepBike4.handleChange}
                                                                                invalid={validationStepBike4.touched.month && validationStepBike4.errors.month ? true : false}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="reg-Year">Year</label>
                                                                            <Input type="number" name="year" id="year"
                                                                                value={validationStepBike4.values.year}
                                                                                onChange={validationStepBike4.handleChange}
                                                                                invalid={validationStepBike4.touched.year && validationStepBike4.errors.year ? true : false}
                                                                            />
                                                                        </div>



                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Gender</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="gender"
                                                                                            id="MALE"
                                                                                            value="MALE"
                                                                                            onChange={validationStepBike4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="MALE">
                                                                                            MALE
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="gender"
                                                                                            id="FEMALE"
                                                                                            value="FEMALE"
                                                                                            onChange={validationStepBike4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="FEMALE">
                                                                                            FEMALE
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Marital status</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="maritalStatus"
                                                                                            id="SINGLE"
                                                                                            value="SINGLE"
                                                                                            onChange={validationStepBike4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="SINGLE">
                                                                                            SINGLE
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="maritalStatus"
                                                                                            id="MARRIED"
                                                                                            value="MARRIED"
                                                                                            onChange={validationStepBike4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="MARRIED">
                                                                                            MARRIED
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="maritalStatus"
                                                                                            id="LIVING"
                                                                                            value="LIVING WITH PARTNER"
                                                                                            onChange={validationStepBike4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="LIVING">
                                                                                            LIVING WITH PARTNER
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="maritalStatus"
                                                                                            id="WIDOWED"
                                                                                            value="WIDOWED"
                                                                                            onChange={validationStepBike4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="WIDOWED">
                                                                                            WIDOWED
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="exampleRadioss"
                                                                                            id="CIVIL"
                                                                                            value="CIVIL PARTNERSHIP"
                                                                                            onChange={validationStepBike4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="CIVIL">
                                                                                            CIVIL PARTNERSHIP
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="maritalStatus"
                                                                                            id="DIVORCED"
                                                                                            value="DIVORCED"
                                                                                            onChange={validationStepBike4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="DIVORCED">
                                                                                            DIVORCED
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="maritalStatus"
                                                                                            id="SEPERATED"
                                                                                            value="SEPERATED"
                                                                                            onChange={validationStepBike4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="SEPERATED">
                                                                                            SEPERATED
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Does the driver own or use another vehicle? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="driverOwnorNot"
                                                                                            id="YESa"
                                                                                            value="YES"
                                                                                            onChange={validationStepBike4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="YESa">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="driverOwnorNot"
                                                                                            id="NOs"
                                                                                            value="NO"
                                                                                            onChange={validationStepBike4.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="NOs">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p className="mb-20">Some insurers could offer a discount if the driver uses another vehicle, as they’ll have a better idea of driving habits.</p>

                                                                    </div>
                                                                </div>

                                                                <div className="d-flex justify-content-between mt-4 mb-4" >
                                                                    <button
                                                                        className="btn colorchangeback back-btn btn-medium"
                                                                        type="submit"
                                                                        onClick={() => setStepNumber2(stepNumber2 - 1)}
                                                                    >
                                                                        {t<string>("Previous")}
                                                                    </button>
                                                                    {registerSteps2()}

                                                                    <button
                                                                        className="btn colorchangeLog leadMargin edu-btn btn-medium"
                                                                        type="submit"
                                                                    >
                                                                        {t<string>("Next")}
                                                                    </button>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    )
                                                }


                                                {
                                                    stepNumber2 == 4 && (
                                                        <div>
                                                            <Form
                                                                className="form-horizontal"
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    validationStepBike5.handleSubmit();
                                                                    return false;
                                                                }}
                                                            >
                                                                <h3 className="title mb-30 mt-10">06. Occupation</h3>

                                                                <div className="form-group">
                                                                    <label htmlFor="log-Doors">What's the driver's employment status?</label>
                                                                </div>
                                                                <div className="card-heading mb-2">
                                                                    <div className="row">
                                                                        <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="employeementStatus"
                                                                                    id="EMPLOYED"
                                                                                    value="EMPLOYED"
                                                                                    onChange={validationStepBike5.handleChange}

                                                                                />

                                                                                <label className="form-check-label" htmlFor="EMPLOYED">
                                                                                    EMPLOYED
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="employeementStatus"
                                                                                    id="SELE-EMPLOYED"
                                                                                    value="SELE-EMPLOYED"
                                                                                    onChange={validationStepBike5.handleChange}
                                                                                />

                                                                                <label className="form-check-label" htmlFor="SELE-EMPLOYED">
                                                                                    SELE-EMPLOYED
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="employeementStatus"
                                                                                    id="RETIRED"
                                                                                    value="RETIRED"
                                                                                    onChange={validationStepBike5.handleChange}
                                                                                />

                                                                                <label className="form-check-label" htmlFor="RETIRED">
                                                                                    RETIRED
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <hr className="mb-30 mt-40" />
                                                                <h3 className="title mb-30 mt-10">07. Licence</h3>

                                                                <div className="form-group">
                                                                    <label htmlFor="log-Doors">What type of license does the driver currently have?</label>
                                                                </div>
                                                                <div className="card-heading mb-2">
                                                                    <div className="row">
                                                                        <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="licenceType"
                                                                                    id="FULL UK-MANUAL"
                                                                                    value="FULL UK-MANUAL"
                                                                                    onChange={validationStepBike5.handleChange}
                                                                                />

                                                                                <label className="form-check-label" htmlFor="FULL UK-MANUAL">
                                                                                    FULL UK-MANUAL
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="licenceType"
                                                                                    id="EEC"
                                                                                    value="FULL EURO (EEC)"
                                                                                    onChange={validationStepBike5.handleChange}
                                                                                />

                                                                                <label className="form-check-label" htmlFor="EEC">
                                                                                    FULL EURO (EEC)
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="licenceType"
                                                                                    id="OPTIONS"
                                                                                    value="SHOW MORE OPTIONS"
                                                                                    onChange={validationStepBike5.handleChange}
                                                                                />

                                                                                <label className="form-check-label" htmlFor="OPTIONS">
                                                                                    SHOW MORE OPTIONS
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex justify-content-between mt-4 mb-4">
                                                                    <button
                                                                        className="btn colorchangeback back-btn btn-medium"
                                                                        type="submit"
                                                                        onClick={() => setStepNumber2(stepNumber2 - 1)}
                                                                    >
                                                                        {t<string>("Previous")}
                                                                    </button>
                                                                    {registerSteps2()}
                                                                    <button
                                                                        className="btn colorchangeLog leadMargin edu-btn btn-medium"
                                                                        type="submit"
                                                                    >
                                                                        {t<string>("Next")}
                                                                    </button>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    )
                                                }


                                                {
                                                    stepNumber2 == 5 && (
                                                        <div>
                                                            <Form
                                                                className="form-horizontal"
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    validationStepBike6.handleSubmit();
                                                                    return false;
                                                                }}
                                                            >
                                                                <h3 className="title mb-30 mt-10">08. Further policyholder details</h3>

                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <label htmlFor="reg-number">Phone number</label>
                                                                            <PhoneInput
                                                                                value={phoneNumber1.phoneNo}
                                                                                defaultCountry="GB" type="text" name="phoneNo" id="phoneNo"
                                                                                onChange={(e: string) => setPhoneNumber1({ ...phoneNumber1, phoneNo: e })}
                                                                            />
                                                                        </div>
                                                                        <p className="mb-20">Insurers need this to be able to create the quotes, but they won't phone unless you agree to being contacted.</p>

                                                                        <div className="form-group">
                                                                            <label htmlFor="reg-Postal">Postal Code</label>
                                                                            <Input type="text" name="postalCode" id="reg-Postal"
                                                                                value={validationStepBike6.values.postalCode}
                                                                                onChange={validationStepBike6.handleChange}
                                                                                invalid={validationStepBike6.touched.postalCode && validationStepBike6.errors.postalCode ? true : false}
                                                                            />
                                                                        </div>
                                                                        <p className="mb-20">Insurers need this to be able to create the quotes, but they won't phone unless you agree to be contacted.</p>

                                                                        <div className="form-group">
                                                                            <label htmlFor="reg-Address">Address</label>
                                                                            <Input type="textarea" name="address" id="address"
                                                                                value={validationStepBike6.values.address}
                                                                                onChange={validationStepBike6.handleChange}
                                                                                invalid={validationStepBike6.touched.address && validationStepBike6.errors.address ? true : false}
                                                                            />
                                                                        </div>
                                                                        <p className="mb-20">Insurers need this to be able to create the quotes, but they won't phone unless you agree to be contacted.</p>
                                                                    </div>
                                                                </div>

                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Is you are a homeowner? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="homeOwner"
                                                                                            id="homeowner"
                                                                                            value="YES"
                                                                                            onChange={validationStepBike6.handleChange}

                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="homeowner">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="homeOwner"
                                                                                            id="homeownerNo"
                                                                                            value="NO"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="homeownerNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p className="mb-20">We need this to get more accurate prices</p>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Will the car be kept at home during the day? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="isCarKeptAtHome"
                                                                                            id="during"
                                                                                            value="YES"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="during">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="isCarKeptAtHome"
                                                                                            id="duringNo"
                                                                                            value="NO"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="duringNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">By the time the policy begins, will Awais have any children under 16 years old? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="childrenUnderSixteen"
                                                                                            id="policy"
                                                                                            value="YES"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="policy">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="childrenUnderSixteen"
                                                                                            id="policyNo"
                                                                                            value="NO"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="policyNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <label htmlFor="log-policy">By the time the policy begins, how many years of no-claims bonus (NCB) will Awais have? *</label>
                                                                        <select className="form-group f-14" name="YearsofNoClaimBonus"
                                                                            value={validationStepBike6.values.YearsofNoClaimBonus}
                                                                            onChange={validationStepBike6.handleChange}
                                                                        >
                                                                            <option value="NO NCB">NO NCB</option>
                                                                            <option value="1 Year">1 Year</option>
                                                                            <option value="2 Year">2 Year</option>
                                                                            <option value="3 Year">3 Year</option>
                                                                            <option value="4 Year">4 Year</option>
                                                                            <option value="5 Year">5 Year</option>
                                                                            <option value="6 Year">6 Year</option>
                                                                            <option value="7 Year">7 Year</option>
                                                                            <option value="8 Year">8 Year</option>
                                                                            <option value="9 Year">9 Year</option>
                                                                            <option value="10 Year">10 Year</option>
                                                                            <option value="11 Year">11 Year</option>
                                                                            <option value="12 Year">12 Year</option>
                                                                            <option value="13 Year">13 Year</option>
                                                                            <option value="14 Year">14 Year</option>
                                                                            <option value="15 Year">15 Year</option>
                                                                            <option value="No NCB in last 2 years">No NCB in last 2 years</option>
                                                                        </select>
                                                                        <p className="mb-20">Most insurers ask for evidence of NCB. You should be able to get this from the current or previous insurer.</p>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Is Awais's no-claims bonus (NCB) currently protected? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="currentlyProtected"
                                                                                            id="bonus"
                                                                                            value="YES"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="bonus">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="currentlyProtected"
                                                                                            id="bonusNo"
                                                                                            value="NO"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="bonusNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Does the driver own or use another vehicle? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="driverVehicalOrNot"
                                                                                            id="vehicle"
                                                                                            value="YES"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="vehicle">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="driverVehicalOrNot"
                                                                                            id="vehicleNo"
                                                                                            value="NO"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="vehicleNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p className="mb-20">Some insurers could offer a discount if the driver uses another vehicle, as they’ll have a better idea of driving habits.</p>

                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-drive">How many cars are kept at home?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="11"
                                                                                            value="1"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="11">
                                                                                            1
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="12"
                                                                                            value="2"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="12">
                                                                                            2
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="13"
                                                                                            value="3"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="13">
                                                                                            3
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="14"
                                                                                            value="4"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="14">
                                                                                            4
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="15"
                                                                                            value="5"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="15">
                                                                                            5
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="16"
                                                                                            value="6"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="16">
                                                                                            6
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="17"
                                                                                            value="7"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="17">
                                                                                            7
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="18"
                                                                                            value="8"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="18">
                                                                                            8
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="NoOfCarsKeptAtHome"
                                                                                            id="19"
                                                                                            value="9"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="19">
                                                                                            9
                                                                                        </label>
                                                                                    </div>
                                                                                    <p className="mb-20">Some insurers offer a discount if there's more than one car at home. If the car you’re getting a quote for will be in addition to cars already at the home, include it in the number.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className="mb-30 mt-40" />
                                                                <h3 className="title mb-30 mt-10">Car Usage</h3>
                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group ">
                                                                            <label htmlFor="log-Doors">Has the car been bought yet? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="boghtYet"
                                                                                            id="bought"
                                                                                            value="YES"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="bought">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="boghtYet"
                                                                                            id="boughtNo"
                                                                                            value="NO"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="boughtNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="log-manufacture">When was the car bought? *</label>
                                                                            <Input
                                                                                type="date"
                                                                                className="form-control"
                                                                                id="formrow-firstname-Input"
                                                                                name="boughtDate"
                                                                                placeholder="Date of birth"
                                                                                max={moment().format("YYYY-MM-DD")}
                                                                                value={validationStepBike6.values.boughtDate}
                                                                                onChange={validationStepBike6.handleChange}
                                                                                invalid={validationStepBike6.touched.boughtDate && validationStepBike6.errors.boughtDate ? true : false}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group mb-20">
                                                                            <label htmlFor="log-manufacture">Who is the car registered to? *</label>
                                                                            <Input type="text" name="carRegisteredTo" id="carRegisteredTo"
                                                                                value={validationStepBike6.values.carRegisteredTo}
                                                                                onChange={validationStepBike6.handleChange}
                                                                                invalid={validationStepBike6.touched.carRegisteredTo && validationStepBike6.errors.carRegisteredTo ? true : false}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-manufacture">Who owns the car? *</label>
                                                                            <Input type="text" name="carOwner" id="carOwner"
                                                                                value={validationStepBike6.values.carOwner}
                                                                                onChange={validationStepBike6.handleChange}
                                                                                invalid={validationStepBike6.touched.carOwner && validationStepBike6.errors.carOwner ? true : false}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">What will the car be used for?</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="carUsedFor"
                                                                                            id="ONLY"
                                                                                            value="SOCIAL ONLY"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="ONLY">
                                                                                            SOCIAL ONLY
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="carUsedFor"
                                                                                            id="COMMUTING"
                                                                                            value="SOCIAL & COMMUTING"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="COMMUTING">
                                                                                            SOCIAL & COMMUTING
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="carUsedFor"
                                                                                            id="BUSINESS"
                                                                                            value="BUSINESS USE"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="BUSINESS">
                                                                                            BUSINESS USE
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className="mb-30 mt-40" />
                                                                <h3 className="title mb-30 mt-10">Claims</h3>
                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Include all claims and convictions as accurately as possible to avoid affecting your level of cover and future claims. Failure to report a claim would make your insurance invalid and could result in getting extra charges from your insurer or having your car seized.</label>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Have any drivers had any motor accidents or claims in the last 5 years? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="accidentClaims"
                                                                                            id="accidents"
                                                                                            value="YES"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="accidents">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="accidentClaims"
                                                                                            id="accidentsNo"
                                                                                            value="NO"
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="accidentsNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p className="mb-20">This includes any incidents, losses, thefts or claims involving a vehicle regardless of fault or whether you claimed or not.</p>

                                                                        <label htmlFor="log-policy">What type of incident was it? *</label>
                                                                        <select className="form-group f-14"
                                                                            name="incidentType"
                                                                            value={validationStepBike6.values.incidentType}
                                                                            onChange={validationStepBike6.handleChange}
                                                                        >
                                                                            <option value="Accident">Accident</option>
                                                                            <option value="Windscreen Glass">Windscreen Glass</option>
                                                                            <option value="Theft">Theft</option>
                                                                            <option value="Malicious Damage">Malicious Damage</option>
                                                                            <option value="Strom or flood">Strom or flood</option>
                                                                            <option value="Fire">Fire</option>
                                                                        </select>

                                                                    </div>
                                                                </div>

                                                                <hr className="mb-30 mt-40" />
                                                                <h3 className="title mb-30 mt-10">Medical conditions & disabilities</h3>
                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <label htmlFor="log-Doors">Do any drivers on this policy have any medical conditions or disabilities that are on the DVLA’s list? *</label>
                                                                        </div>
                                                                        <div className="card-heading mb-2">
                                                                            <div className="row">
                                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="medicalCondition"
                                                                                            id="conditions"
                                                                                            value="YES"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="conditions">
                                                                                            YES
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                        <Input
                                                                                            className="form-check-Input"
                                                                                            type="radio"
                                                                                            name="medicalCondition"
                                                                                            id="conditionsNo"
                                                                                            value="NO"
                                                                                            onChange={validationStepBike6.handleChange}
                                                                                        />

                                                                                        <label className="form-check-label" htmlFor="conditionsNo">
                                                                                            NO
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p className="mb-20">This includes any incidents, losses, thefts or claims involving a vehicle regardless of fault or whether you claimed or not.</p>

                                                                        <label htmlFor="log-policy">Select medical condition *</label>
                                                                        <select className="form-group f-14"
                                                                            name="dvlaMedicalConditionsType"
                                                                            value={validationStepBike6.values.dvlaMedicalConditionsType}
                                                                            onChange={validationStepBike6.handleChange}
                                                                        >
                                                                            <option value="DVLA aware - advised no to drive">DVLA aware - advised no to drive</option>
                                                                            <option value="DVLA aware - no restrictions">DVLA aware - no restrictions</option>
                                                                            <option value="DVLA aware - 1yr resticted licence">DVLA aware - 1yr resticted licence</option>
                                                                            <option value="DVLA aware - 2yr resticted licence">DVLA aware - 2yr resticted licence</option>
                                                                            <option value="DVLA aware - 3yr resticted licence">DVLA aware - 3yr resticted licence</option>
                                                                            <option value="DVLA unaware">DVLA unaware</option>
                                                                        </select>

                                                                    </div>
                                                                </div>
                                                                <hr className="mb-30 mt-40" />
                                                                <h3 className="title mb-30 mt-10">Medical conditions & disabilities</h3>
                                                                <div className="row g-5 mb-20 justify-content-center">
                                                                    <div className="col-lg-12">
                                                                        <label htmlFor="log-policy">Have any of the drivers ever had insurance declined, cancelled or special terms imposed?</label>
                                                                        <select className="form-group f-14"
                                                                            name="declinedCancelledImposed"
                                                                            value={validationStepBike6.values.declinedCancelledImposed}
                                                                            onChange={validationStepBike6.handleChange}
                                                                        >
                                                                            <option value="Yes">Yes</option>
                                                                            <option value="No">No</option>
                                                                        </select>

                                                                        <div className="form-group chekbox-area">
                                                                            <div className="edu-form-check flex">
                                                                                <Input type="checkbox" className="form-check-inputs" id="remember-me" style={{ width: "auto" }} />
                                                                                <label >I CONFIRM THAT I HAVE READ AND UNDERSTOOD YOUR TERMS & CONDITIONS AND PRIVACY POLICY.</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="d-flex justify-content-between mt-4 mb-4">
                                                                    <button
                                                                        className="btn colorchangeback back-btn btn-medium"
                                                                        type="submit"
                                                                        onClick={() => setStepNumber2(stepNumber2 - 1)}
                                                                    >
                                                                        {t<string>("Previous")}
                                                                    </button>
                                                                    {registerSteps2()}
                                                                    <button
                                                                        className="btn colorchangeLog leadMargin edu-btn btn-medium"
                                                                        type="submit"
                                                                    >
                                                                        {t<string>("Finish")}
                                                                    </button>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    );
};

export default InsuranceQuote;


