import axios from 'axios';
import { AppResponse } from "../models/Response";
import { Util } from "../Util";
import { Admin, CustomerAdminResponseModel } from './../models/Admin';
import { DcotorAppointmentRequestDataObject } from '../models/DoctorAppointment';
import { PendingCounts, User, UserDetails } from '../models/User';
import { PenaltyCharges } from '../models/PenaltyCharges';
import { Utilities } from '../models/Utility';
import { VehicleInsurance } from '../models/VehicleInsurance';
import { PersonalRequestInsurance, PersonalRequestSubmissions } from '../models/PersonalRequest';
import { Business } from '../models/businessInsurance';
import { HomeQuote } from '../models/homeInsuranceQuote';

export class AdminService {

    public static async getAllAppointments(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`fetch/doctor-appointments/` + limit + "/" + offset);
        return await axios.post<Partial<DcotorAppointmentRequestDataObject>, AppResponse<any>>(url, data);
    }

    public static async getAllVehicleInsurance(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`fetch/vehicle-insurances/` + limit + "/" + offset);
        return await axios.post<Partial<VehicleInsurance>, AppResponse<any>>(url, data);
    }

    public static async getAllPersonalRequestInsurance(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`fetch/personal-request-insurances/` + limit + "/" + offset);
        return await axios.post<Partial<PersonalRequestInsurance>, AppResponse<any>>(url, data);
    }

    public static async getAllPersonalRequestSubmission(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`fetch/personal-request-submissions/` + limit + "/" + offset);
        return await axios.post<Partial<PersonalRequestSubmissions>, AppResponse<any>>(url, data);
    }

    public static async getAllBusinessInsurance(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`fetch/business-insurances/` + limit + "/" + offset);
        return await axios.post<Partial<Business>, AppResponse<any>>(url, data);
    }


    public static async getAllHomeInsurance(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`fetch/home-insurances/` + limit + "/" + offset);
        return await axios.post<Partial<HomeQuote>, AppResponse<any>>(url, data);
    }

    public static async getAllSubscriptionUsers( limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`get/all-subscribed-users/` + limit + "/" + offset);
        return await axios.get<Partial<UserDetails>, AppResponse<any>>(url);
    }


    public static async getAllPenaltyCharges(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`fetch/penalty-charges/` + limit + "/" + offset);
        return await axios.post<Partial<DcotorAppointmentRequestDataObject>, AppResponse<any>>(url, data);
    }

    public static async getAllUtilityPayments(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`fetch/utility-payments/` + limit + "/" + offset);
        return await axios.post<Partial<DcotorAppointmentRequestDataObject>, AppResponse<any>>(url, data);
    }

    public static async getAllUsers(limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`get/all-users/` + limit + "/" + offset);
        return await axios.get<Partial<UserDetails>, AppResponse<any>>(url);
    }

    public static async updatePaymentStatusUtitliyPayments(data: any): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`update/up-payment/status`);
        return await axios.post<Partial<Utilities>, AppResponse<any>>(url, data);
    }

    public static async updatePaymentStatusPenaltyPayments(data: any): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`update/pc-payment/status`);
        return await axios.post<Partial<PenaltyCharges>, AppResponse<any>>(url, data);
    }
    public static async updatePaymentStatusAppointmentPayments(data: any): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`update/da-payment/status`);
        return await axios.post<Partial<DcotorAppointmentRequestDataObject>, AppResponse<any>>(url, data);
    }

    public static async updatePaymentStatusInsurancePayments(data: any): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`update/vi-payment/status`);
        return await axios.post<Partial<VehicleInsurance>, AppResponse<any>>(url, data);
    }

    public static async updatePaymentStatusPersonalRequestPayments(data: any): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`update/pri-payment/status`);
        return await axios.post<Partial<PersonalRequestInsurance>, AppResponse<any>>(url, data);
    }

    public static async updatePaymentStatusPersonalRequestSubmisiionsPayments(data: any): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`update/prs-payment/status`);
        return await axios.post<Partial<PersonalRequestSubmissions>, AppResponse<any>>(url, data);
    }

    public static async updatePaymentStatusBusinessInsurancePayments(data: any): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`update/bi-payment/status`);
        return await axios.post<Partial<PersonalRequestInsurance>, AppResponse<any>>(url, data);
    }

    public static async updatePaymentStatusHomeInsurancePayments(data: any): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`update/hi-payment/status`);
        return await axios.post<Partial<HomeQuote>, AppResponse<any>>(url, data);
    }

    public static async getAllPendingPayments(): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`get/new-payments-count`);
        return await axios.get<Partial<PendingCounts>, AppResponse<any>>(url);
    }

    public static async getAllCompletedPayments(): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`get/completed-payments-count`);
        return await axios.get<Partial<PendingCounts>, AppResponse<any>>(url);
    }


    public static async getAllUserCounts(): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`get/all-user-count`);
        return await axios.get<Partial<User>, AppResponse<any>>(url);
    }

    public static async getAllPayments(): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`get/all-payments-count`);
        return await axios.get<Partial<PendingCounts>, AppResponse<any>>(url);
    }


    public static async blockUser(userId: string): Promise<AppResponse<string>> {
        const url = Util.apiAuthUrl(`blockUserByAdmin/${userId}`);
        return await axios.post<void, AppResponse<string>>(url);
    }

    public static async getCustomerById(customerId: string): Promise<AppResponse<UserDetails>> {
        const url = Util.apiAuthUrl("customer/" + customerId);
        return await axios.get<void, AppResponse<UserDetails>>(url);
    }
    public static async updateCustomerByAdmin(data: Partial<UserDetails>, customerId: string): Promise<AppResponse<UserDetails>> {
        const url = Util.apiAuthUrl("update/customer/" + customerId);
        return await axios.post<Partial<UserDetails>, AppResponse<UserDetails>>(url, data);
    }
    public static async searchUsersByAdmin(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`searchUsersByAdmin/` + limit + "/" + offset);
        return await axios.post<Partial<any>, AppResponse<any>>(url, data);
    }

    public static async searchPersonalSubmissionsByAdmin(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`searchPersonalSubmissionsByAdmin/` + limit + "/" + offset);
        return await axios.post<Partial<any>, AppResponse<any>>(url, data);
    }
    public static async searchVehicleInsuracnceByAdmin(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`searchVehicleInsuranceByAdmin/` + limit + "/" + offset);
        return await axios.post<Partial<any>, AppResponse<any>>(url, data);
    }
    public static async searchPersonalRequestByAdmin(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`searchPersonalRequestByAdmin/` + limit + "/" + offset);
        return await axios.post<Partial<any>, AppResponse<any>>(url, data);
    }
    public static async searchBusinessInsuranceByAdmin(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`searchBusinessInsuranceByAdmin/` + limit + "/" + offset);
        return await axios.post<Partial<any>, AppResponse<any>>(url, data);
    }
    public static async searchHomeInsuranceByAdmin(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`searchHomeInsuranceByAdmin/` + limit + "/" + offset);
        return await axios.post<Partial<any>, AppResponse<any>>(url, data);
    }
    public static async searchSubscriptionByAdmin(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`searchSubscriptionByAdmin/` + limit + "/" + offset);
        return await axios.post<Partial<any>, AppResponse<any>>(url, data);
    }
    public static async searchDoctorAppointmentsByAdmin(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`searchDoctorAppointmentsByAdmin/` + limit + "/" + offset);
        return await axios.post<Partial<any>, AppResponse<any>>(url, data);
    }
    public static async searchPenaltyChargesByAdmin(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`searchPenaltyChargesByAdmin/` + limit + "/" + offset);
        return await axios.post<Partial<any>, AppResponse<any>>(url, data);
    }
    public static async searchByUtilitiesAdmin(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`searchUtilitiesByAdmin/` + limit + "/" + offset);
        return await axios.post<Partial<any>, AppResponse<any>>(url, data);
    }
}