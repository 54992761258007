import React, { useState, useContext } from "react";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";
import { Form, FormFeedback, Input } from "reactstrap";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { UtilityService } from "../../servises/UtilityService";
import { WaterBillObject } from "../../models/WaterBillObject";
import UserContext from "../context/UserContext";
import moment from "moment";
import { UserDetails } from "../../models/User";

const Water: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [accountHolder, setAccountHolder] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedOptions([...selectedOptions, value]);
    } else {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    }
  };
  const [userdata, setUserdata] = useState<UserDetails | undefined>({} as UserDetails);

  const autoFill = () => {
    setUserdata(user);
  }


  const waterBillPaymentValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      wb_name: "",
      wb_email: "",
      wb_phone_number: "",
      wb_date_of_birth: "",
      wb_postalCode: "",
      wb_address: "",
      wb_property_type: "",
      wb_move_in_date: "",
      wb_number_of_bedrooms: "",
      wb_MPRN: "",
      wb_billing_frequency: "",
      wb_proof_of_eligibilty: "",
      wb_account_holder_name: "",
      wb_signature: "",
    },
    validationSchema: Yup.object({
      // wb_name: Yup.string().required(t<string>("Please Enter Name")),
      // wb_email: Yup.string().required(t<string>("Please Enter Email")),
      // wb_phone_number: Yup.number().required(
      //   t<string>("Please Enter Phone Number")
      // ),
      // wb_date_of_birth: Yup.date().required(
      //   t<string>("Please Select the date of birth")
      // ),
      // wb_postalCode: Yup.string().required(
      //   t<string>("Please Enter postal Code")
      // ),
      // wb_address: Yup.string().required(t<string>("Please Enter the Address")),

      wb_property_type: Yup.string().required(
        t<string>("Please Enter the Propery Type")
      ),
      wb_move_in_date: Yup.date().required(
        t<string>("Please Enter the Move in Date")
      ),
      wb_number_of_bedrooms: Yup.number().required(
        t<string>("Please Enter the number of bedrooms")
      ),
      wb_MPRN: Yup.string().required(t<string>("Please Enter the MPRN Number")),
      wb_billing_frequency: Yup.string().required(
        t<string>("Please Select an Option")
      ),
      wb_signature: Yup.string().required(
        t<string>("Please Enter the signature")
      ),
    }),
    onSubmit: (values, onSubmitProps) => {

      if (
        values.wb_proof_of_eligibilty.length === 0
      ) {
        Swal.fire({
          icon: "error",
          title: "proof of eligibilty cannot be empty",
          confirmButtonColor: "#FD7F00",
        });
      } else if (
        accountHolder === false &&
        values.wb_account_holder_name.length === 0
      ) {
        Swal.fire({
          icon: "error",
          title: "account holder name cannot be empty",
          confirmButtonColor: "#FD7F00",
        });
      } else {
        const dataObject: WaterBillObject = {
          name: values.wb_name ||  (userdata?.firstName + " " + userdata?.lastName),
          phoneNumber: parseInt(values.wb_phone_number) || userdata?.phoneNumber,
          postCode: values.wb_postalCode || userdata?.postCode,
          address: values.wb_address || userdata?.address,
          email: values.wb_email || userdata?.email,
          dateOfBirth: values.wb_date_of_birth || userdata?.dateOfBirth as any,
          propertyType: values.wb_property_type,
          numberOfBedrooms: parseInt(values.wb_number_of_bedrooms),
          moveInDate: values.wb_move_in_date,
          waterMPRN: values.wb_MPRN,
          paymentMethod: "CREDIT_CARD",
          billingFrequency: values.wb_billing_frequency,
          eligibilityForDiscountsOrBenefits: selectedOptions,
          proofOfEligibility: values.wb_proof_of_eligibilty,
          accountHolderOrNot: accountHolder,
          accountHolderName: values.wb_account_holder_name,
          signature: values.wb_signature,
        };

        if (user?.subscriptionStatus === "CANCELLED" || !user?.subscriptionId) {
          Swal.fire({
            icon: "warning",
            title: "in order to be able receive our services you will have to be subscribe",
            confirmButtonText: "Subscribe",
            confirmButtonColor: "#FD7F00",
            showCancelButton: true,
            cancelButtonText: "Cancel",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/account";
            }
          });
          return;
        } else {
          setIsLoading(true);

          UtilityService.payWaterBill(dataObject).then((res) => {
            if (res.success) {
              setIsLoading(false);
              Swal.fire({ title: "Water Bill Payment Successful" });
              setUserdata({ firstName: "", lastName: "", phoneNumber: "" as any, postCode: "", address: "", email: "", dateOfBirth: undefined, });
              onSubmitProps.resetForm();
              setSelectedOptions([]);

            } else {
              setIsLoading(false);
              Swal.fire({
                icon: "error",
                title: res.error,
                confirmButtonColor: "#FD7F00",
              });
            }
          });
        }
      }
    },
  });

  return (
    <div className="login-page edu-footer footer-lighten bg-image footer-style-1">
      <section className="account-page-area section-gap-equal1">
        <div className="container-custom position-relative">
          <div className="row g-5 justify-content-center">
            <div className="col-lg-8">

              <div className="login-form-box registration-form">
                <div className="App">
                  <div className="box">
                    <div>
                      <h3 className="title mb-20 mt-10">
                        Personal Information:
                      </h3>
                      <p className="themeColor mb-30 font-16 mt-3">Utilities life admin services help individuals manage their utility services efficiently. They handle tasks such as setting up or transferring utility accounts, arranging bill payments, addressing service issues, monitoring usage, and providing guidance on optimizing energy consumption and cost savings.</p>

                      <button
                        className="btn colorchangeLog edu-btn btn-medium mb-20"
                        type="submit"
                        onClick={autoFill}
                      >AutoFill</button>

                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          waterBillPaymentValidation.handleSubmit();
                          return false;
                        }}
                      ><>
                          <div className="row g-5 mb-20 justify-content-center">
                            <div className="col-lg-6">
                              <div className="form-group mb-20">
                                <label htmlFor="reg-name">Name:</label>
                                <Input
                                  type="text"
                                  name="wb_name"
                                  id="wb_name"
                                  value={
                                    waterBillPaymentValidation.values.wb_name ||  userdata?.firstName == undefined ? "" : `${userdata?.firstName} ${userdata?.lastName}`}
                                  onChange={
                                    waterBillPaymentValidation.handleChange
                                  }
                                  invalid={
                                    waterBillPaymentValidation.touched.wb_name &&
                                      waterBillPaymentValidation.errors.wb_name
                                      ? true
                                      : false
                                  }
                                />

                                {waterBillPaymentValidation.touched.wb_name &&
                                  waterBillPaymentValidation.errors.wb_name ? (
                                  <FormFeedback type="invalid">
                                    {waterBillPaymentValidation.errors.wb_name}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="form-group mb-20">
                                <label htmlFor="reg-Email">Email:</label>
                                <Input
                                  type="text"
                                  name="wb_email"
                                  id="wb_email"
                                  value={
                                    waterBillPaymentValidation.values.wb_email || userdata?.email
                                  }
                                  onChange={
                                    waterBillPaymentValidation.handleChange
                                  }
                                  invalid={
                                    waterBillPaymentValidation.touched.wb_email &&
                                      waterBillPaymentValidation.errors.wb_email
                                      ? true
                                      : false
                                  }
                                />

                                {waterBillPaymentValidation.touched.wb_email &&
                                  waterBillPaymentValidation.errors.wb_email ? (
                                  <FormFeedback type="invalid">
                                    {waterBillPaymentValidation.errors.wb_email}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="form-group mb-20">
                                <label htmlFor="reg-Number">Phone Number:</label>
                                <Input
                                  type="number"
                                  name="wb_phone_number"
                                  id="wb_phone_number"
                                  value={
                                    waterBillPaymentValidation.values.wb_phone_number || userdata?.phoneNumber
                                  }
                                  onChange={
                                    waterBillPaymentValidation.handleChange
                                  }
                                  invalid={
                                    waterBillPaymentValidation.touched
                                      .wb_phone_number &&
                                      waterBillPaymentValidation.errors
                                        .wb_phone_number
                                      ? true
                                      : false
                                  }
                                />

                                {waterBillPaymentValidation.touched
                                  .wb_phone_number &&
                                  waterBillPaymentValidation.errors
                                    .wb_phone_number ? (
                                  <FormFeedback type="invalid">
                                    {
                                      waterBillPaymentValidation.errors
                                        .wb_phone_number
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="form-group">
                                <label htmlFor="reg-Vehicle">
                                  Date of Birth:
                                </label>
                                <Input
                                  className="form-control"
                                  type="date"
                                  id="wb_date_of_birth"
                                  name="wb_date_of_birth"
                                  placeholder="Select Date"
                                  onChange={
                                    waterBillPaymentValidation.handleChange
                                  }
                                  onBlur={waterBillPaymentValidation.handleBlur}
                                  value={
                                    waterBillPaymentValidation.values.wb_date_of_birth || moment(userdata?.dateOfBirth).format("YYYY-MM-DD")
                                  }
                                  invalid={
                                    waterBillPaymentValidation.touched
                                      .wb_date_of_birth &&
                                      waterBillPaymentValidation.errors
                                        .wb_date_of_birth
                                      ? true
                                      : false
                                  }
                                />
                                {waterBillPaymentValidation.touched
                                  .wb_date_of_birth &&
                                  waterBillPaymentValidation.errors
                                    .wb_date_of_birth ? (
                                  <FormFeedback type="invalid">
                                    {
                                      waterBillPaymentValidation.errors
                                        .wb_date_of_birth
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group mb-20">
                                <label htmlFor="reg-Postcode">Postcode:</label>
                                <Input
                                  type="text"
                                  name="wb_postalCode"
                                  id="wb_postalCode"
                                  value={
                                    waterBillPaymentValidation.values
                                      .wb_postalCode || userdata?.postCode
                                  }
                                  onChange={
                                    waterBillPaymentValidation.handleChange
                                  }
                                  invalid={
                                    waterBillPaymentValidation.touched
                                      .wb_postalCode &&
                                      waterBillPaymentValidation.errors
                                        .wb_postalCode
                                      ? true
                                      : false
                                  }
                                />

                                {waterBillPaymentValidation.touched
                                  .wb_postalCode &&
                                  waterBillPaymentValidation.errors
                                    .wb_postalCode ? (
                                  <FormFeedback type="invalid">
                                    {
                                      waterBillPaymentValidation.errors
                                        .wb_postalCode
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="form-group">
                                <label htmlFor="log-Address">Address:</label>
                                <Input
                                  type="textarea"
                                  rows="4"
                                  name="wb_address"
                                  id="wb_address"
                                  value={
                                    waterBillPaymentValidation.values.wb_address || userdata?.address
                                  }
                                  onChange={
                                    waterBillPaymentValidation.handleChange
                                  }
                                  invalid={
                                    waterBillPaymentValidation.touched
                                      .wb_address &&
                                      waterBillPaymentValidation.errors.wb_address
                                      ? true
                                      : false
                                  }
                                />
                                {waterBillPaymentValidation.touched.wb_address &&
                                  waterBillPaymentValidation.errors.wb_address ? (
                                  <FormFeedback type="invalid">
                                    {waterBillPaymentValidation.errors.wb_address}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <hr className="mb-30 mt-40" />
                          <h3 className="title mb-30">Property Information:</h3>

                          <div className="row g-5 mb-20 justify-content-center">
                            <div className="col-lg-6">
                              <div className="form-group mb-20">
                                <label htmlFor="reg-Property">
                                  Property Type:
                                </label>
                                <Input
                                  type="text"
                                  name="wb_property_type"
                                  id="wb_property_type"
                                  value={
                                    waterBillPaymentValidation.values
                                      .wb_property_type
                                  }
                                  onChange={
                                    waterBillPaymentValidation.handleChange
                                  }
                                  invalid={
                                    waterBillPaymentValidation.touched
                                      .wb_property_type &&
                                      waterBillPaymentValidation.errors
                                        .wb_property_type
                                      ? true
                                      : false
                                  }
                                />

                                {waterBillPaymentValidation.touched
                                  .wb_property_type &&
                                  waterBillPaymentValidation.errors
                                    .wb_property_type ? (
                                  <FormFeedback type="invalid">
                                    {
                                      waterBillPaymentValidation.errors
                                        .wb_property_type
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="form-group mb-20">
                                <label htmlFor="reg-Move">Move-in date:</label>
                                <Input
                                  className="form-control"
                                  type="date"
                                  id="wb_move_in_date"
                                  name="wb_move_in_date"
                                  placeholder="Select Date"
                                  onChange={
                                    waterBillPaymentValidation.handleChange
                                  }
                                  onBlur={waterBillPaymentValidation.handleBlur}
                                  value={
                                    waterBillPaymentValidation.values
                                      .wb_move_in_date
                                  }
                                  invalid={
                                    waterBillPaymentValidation.touched
                                      .wb_move_in_date &&
                                      waterBillPaymentValidation.errors
                                        .wb_move_in_date
                                      ? true
                                      : false
                                  }
                                />
                                {waterBillPaymentValidation.touched
                                  .wb_move_in_date &&
                                  waterBillPaymentValidation.errors
                                    .wb_move_in_date ? (
                                  <FormFeedback type="invalid">
                                    {
                                      waterBillPaymentValidation.errors
                                        .wb_move_in_date
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group mb-20">
                                <label htmlFor="reg-Bedrooms">
                                  Number of Bedrooms:
                                </label>
                                <Input
                                  type="number"
                                  name="wb_number_of_bedrooms"
                                  id="wb_number_of_bedrooms"
                                  value={
                                    waterBillPaymentValidation.values
                                      .wb_number_of_bedrooms
                                  }
                                  onChange={
                                    waterBillPaymentValidation.handleChange
                                  }
                                  invalid={
                                    waterBillPaymentValidation.touched
                                      .wb_number_of_bedrooms &&
                                      waterBillPaymentValidation.errors
                                        .wb_number_of_bedrooms
                                      ? true
                                      : false
                                  }
                                />

                                {waterBillPaymentValidation.touched
                                  .wb_number_of_bedrooms &&
                                  waterBillPaymentValidation.errors
                                    .wb_number_of_bedrooms ? (
                                  <FormFeedback type="invalid">
                                    {
                                      waterBillPaymentValidation.errors
                                        .wb_number_of_bedrooms
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="form-group">
                                <label htmlFor="reg-Water">
                                  Water meter Point Reference Number (MPRN):
                                </label>
                                <Input
                                  type="text"
                                  name="wb_MPRN"
                                  id="wb_MPRN"
                                  value={
                                    waterBillPaymentValidation.values.wb_MPRN
                                  }
                                  onChange={
                                    waterBillPaymentValidation.handleChange
                                  }
                                  invalid={
                                    waterBillPaymentValidation.touched.wb_MPRN &&
                                      waterBillPaymentValidation.errors.wb_MPRN
                                      ? true
                                      : false
                                  }
                                />

                                {waterBillPaymentValidation.touched.wb_MPRN &&
                                  waterBillPaymentValidation.errors.wb_MPRN ? (
                                  <FormFeedback type="invalid">
                                    {waterBillPaymentValidation.errors.wb_MPRN}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <hr className="mb-30 mt-40" />
                          <h3 className="title mb-30">Payment Information:</h3>

                          <div className="row g-5 mb-20 justify-content-center">
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label htmlFor="log-drive">
                                  Billing frequency:
                                </label>
                              </div>
                              <div className="card-heading mb-2">
                                <div className="row">
                                  <div
                                    className="col"
                                    style={{ display: "flex", flexWrap: "wrap" }}
                                  >
                                    <div
                                      className="form-check mb-3"
                                      style={{ marginRight: "1.2rem" }}
                                    >
                                      <Input
                                        className="form-check-input"
                                        type="radio"
                                        name="wb_billing_frequency"
                                        id="MONTHLY"
                                        value="MONTHLY"
                                        defaultChecked={
                                          waterBillPaymentValidation.values
                                            .wb_billing_frequency === "MONTHLY"
                                        }
                                        onChange={
                                          waterBillPaymentValidation.handleChange
                                        }
                                        invalid={
                                          waterBillPaymentValidation.touched
                                            .wb_billing_frequency &&
                                            waterBillPaymentValidation.errors
                                              .wb_billing_frequency
                                            ? true
                                            : false
                                        }
                                      />

                                      <label
                                        className="form-check-label"
                                        htmlFor="MONTHLY"
                                      >
                                        Monthly
                                      </label>
                                    </div>

                                    <div
                                      className="form-check mb-3 "
                                      style={{ marginRight: "1.2rem" }}
                                    >
                                      <Input
                                        className="form-check-input"
                                        type="radio"
                                        name="wb_billing_frequency"
                                        id="QUARTERLY"
                                        value="QUARTERLY"
                                        defaultChecked={
                                          waterBillPaymentValidation.values
                                            .wb_billing_frequency === "QUARTERLY"
                                        }
                                        onChange={
                                          waterBillPaymentValidation.handleChange
                                        }
                                        invalid={
                                          waterBillPaymentValidation.touched
                                            .wb_billing_frequency &&
                                            waterBillPaymentValidation.errors
                                              .wb_billing_frequency
                                            ? true
                                            : false
                                        }
                                      />

                                      <label
                                        className="form-check-label"
                                        htmlFor="QUARTERLY"
                                      >
                                        Quarterly
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <hr className="mb-30 mt-40" />
                          <div className="row g-5 mb-20 justify-content-center">
                            <h3 className="title ">Additional Information:</h3>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label htmlFor="log-drive">
                                  Are you eligible for any discounts or benefits?
                                </label>
                              </div>
                              <div className="card-heading mb-2">
                                <div className="row">
                                  <div
                                    className="col"
                                    style={{ display: "flex", flexWrap: "wrap" }}
                                  >
                                    <div
                                      className="form-check check mb-3 "
                                      style={{ marginRight: "1.2rem" }}
                                    >
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="eligible_for_discounts_one"
                                        name="eligible_for_discounts"
                                        onChange={handleCheckboxChange}
                                        checked={selectedOptions.includes('PENSION_CREDIT')}
                                        value="PENSION_CREDIT"
                                      />

                                      <label
                                        className="form-check-label checkLable"
                                        htmlFor="credit"
                                      >
                                        Pension credit
                                      </label>
                                    </div>

                                    <div
                                      className="form-check check mb-3 "
                                      style={{ marginRight: "1.2rem" }}
                                    >

                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="eligible_for_discounts_two"
                                        name="eligible_for_discounts"
                                        onChange={handleCheckboxChange}
                                        checked={selectedOptions.includes('INCOME_SUPPORT')}
                                        value="INCOME_SUPPORT"
                                      />

                                      <label
                                        className="form-check-label checkLable"
                                        htmlFor="support"
                                      >
                                        Income support
                                      </label>
                                    </div>

                                    <div className="form-check check mb-3 ">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="eligible_for_discounts_three"
                                        name="eligible_for_discounts"
                                        onChange={handleCheckboxChange}
                                        checked={selectedOptions.includes('JOBSEEKER_ALLOWANCE')}
                                        value="JOBSEEKER_ALLOWANCE"
                                      />

                                      <label
                                        className="form-check-label checkLable"
                                        htmlFor="Allowance"
                                      >
                                        Jobseeker's Allowance
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="form-group">
                                <label htmlFor="log-drive">
                                  Are you the account holder?
                                </label>
                              </div>
                              <div className="card-heading mb-2">
                                <div className="row">
                                  <div
                                    className="col"
                                    style={{ display: "flex", flexWrap: "wrap" }}
                                  >
                                    <div
                                      className="form-check mb-3 "
                                      style={{ marginRight: "1.2rem" }}
                                    >
                                      <Input
                                        className="form-check-input"
                                        type="radio"
                                        name="gbAccountHolderOrNot"
                                        id="YES"
                                        value="YES"
                                        checked={accountHolder}
                                        invalid={
                                          waterBillPaymentValidation.touched
                                            .wb_account_holder_name &&
                                            waterBillPaymentValidation.errors
                                              .wb_account_holder_name
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          setAccountHolder(true);
                                        }}
                                      />

                                      <label
                                        className="form-check-label"
                                        htmlFor="YES"
                                      >
                                        Yes
                                      </label>
                                    </div>

                                    <div className="form-check mb-3 ">
                                      <Input
                                        className="form-check-input"
                                        type="radio"
                                        name="gbAccountHolderOrNot"
                                        id="NO"
                                        value="NO"
                                        invalid={
                                          waterBillPaymentValidation.touched
                                            .wb_account_holder_name &&
                                            waterBillPaymentValidation.errors
                                              .wb_account_holder_name
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          setAccountHolder(false);
                                        }}
                                      />

                                      <label
                                        className="form-check-label"
                                        htmlFor="NO"
                                      >
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row g-5 mb-20 justify-content-center">
                            <div className="col-lg-6">
                              <div className="form-group">
                                {selectedOptions ? (
                                  <div className="form-group">
                                    <label htmlFor="log-provide">
                                      If yes, please provide proof of eligibility:
                                    </label>
                                    <Input
                                      type="textarea"
                                      rows="4"
                                      name="wb_proof_of_eligibilty"
                                      id="wb_proof_of_eligibilty"
                                      value={
                                        waterBillPaymentValidation.values
                                          .wb_proof_of_eligibilty
                                      }
                                      onChange={
                                        waterBillPaymentValidation.handleChange
                                      }
                                      invalid={
                                        waterBillPaymentValidation.touched
                                          .wb_proof_of_eligibilty &&
                                          waterBillPaymentValidation.errors
                                            .wb_proof_of_eligibilty
                                          ? true
                                          : false
                                      }
                                    />
                                    {waterBillPaymentValidation.touched
                                      .wb_proof_of_eligibilty &&
                                      waterBillPaymentValidation.errors
                                        .wb_proof_of_eligibilty ? (
                                      <FormFeedback type="invalid">
                                        {
                                          waterBillPaymentValidation.errors
                                            .wb_proof_of_eligibilty
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                ) : (
                                  <div> </div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                {accountHolder ? (
                                  <div></div>
                                ) : (
                                  <div className="form-group mb-30">
                                    <label htmlFor="reg-Postcode">
                                      If no, please provide the name of the
                                      account holder:
                                    </label>
                                    <Input
                                      type="text"
                                      name="wb_account_holder_name"
                                      id="wb_account_holder_name"
                                      value={
                                        waterBillPaymentValidation.values
                                          .wb_account_holder_name
                                      }
                                      onChange={
                                        waterBillPaymentValidation.handleChange
                                      }
                                      invalid={
                                        waterBillPaymentValidation.touched
                                          .wb_account_holder_name &&
                                          waterBillPaymentValidation.errors
                                            .wb_account_holder_name
                                          ? true
                                          : false
                                      }
                                    />
                                    {waterBillPaymentValidation.touched
                                      .wb_account_holder_name &&
                                      waterBillPaymentValidation.errors
                                        .wb_account_holder_name ? (
                                      <FormFeedback type="invalid">
                                        {
                                          waterBillPaymentValidation.errors
                                            .wb_account_holder_name
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                )}
                              </div>
                              <div className="form-group mb-20">
                                <label htmlFor="reg-Amount">Signature:</label>
                                <Input
                                  type="text"
                                  name="wb_signature"
                                  id="wb_signature"
                                  value={
                                    waterBillPaymentValidation.values.wb_signature
                                  }
                                  onChange={
                                    waterBillPaymentValidation.handleChange
                                  }
                                  invalid={
                                    waterBillPaymentValidation.touched
                                      .wb_signature &&
                                      waterBillPaymentValidation.errors.wb_signature
                                      ? true
                                      : false
                                  }
                                />

                                {waterBillPaymentValidation.touched
                                  .wb_signature &&
                                  waterBillPaymentValidation.errors.wb_signature ? (
                                  <FormFeedback type="invalid">
                                    {
                                      waterBillPaymentValidation.errors
                                        .wb_signature
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <button
                            className="btn colorchangeLog leadMargin edu-btn btn-medium mb-20"
                            type="submit"
                          >
                            <div>Submit</div>
                          </button>
                        </>
                      </Form>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Water;
