import React, { useState, useEffect } from "react";
import img1 from "../assets/images/about/shape-13.png";
import img2 from "../assets/images/about/shape-15.png";
import img3 from "../assets/images/about/shape-07.png";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";
import "react-toastify/dist/ReactToastify.css";
import { Util } from "../../Util";
import { AdminService } from "../../servises/AdminService";
import { HomeQuote } from "../../models/homeInsuranceQuote";
import { Row, Col, FormGroup, Input } from "reactstrap";
import { User, UserDetails } from "../../models/User";
import SubscibeUserseModel from "../popup/subscribeUsersModal";

const AdminSubscriptionUsers: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [offset, setOffset] = useState(1);
    const limit = 10;
    const [dataSingle, setDataSingle] = useState<User>({} as User);
    const [isOpenData, setIsOpenData] = React.useState(false);
    const [count, setCount] = useState(10);
    const [isSearchableString, setIsSearchableString] = useState({ searchableString: null });
    const [subscriptionUsers, setsubscriptionUsers] = useState<User[]>([]);

    useEffect(() => {
        getSubscriptionUsers();
    }, []);


    const getSubscriptionUsers = () => {
        setIsLoading(true);
        AdminService.getAllSubscriptionUsers(limit, 1).then(res => {
            if (res.success) {
                setsubscriptionUsers(res.data.users);
                setCount(res.data?.SubscribedUsersCount);
                setIsLoading(false);
            } else {
                setsubscriptionUsers([]);
                setIsLoading(false);
            }
        });
    };

    const seeMore = () => {
        const updatedSkip = offset + 1;

        setOffset(updatedSkip);


        setIsLoading(true);

        AdminService.getAllSubscriptionUsers(limit, 1).then(res => {
            if (res.success) {
                setsubscriptionUsers(res.data.users);
                setCount(res.data?.SubscribedUsersCount);
            }

            setIsLoading(false);
        });
    };

    const previous = () => {
        const updatedSkip = offset - 1;

        setOffset(updatedSkip);


        setIsLoading(true);

        
        AdminService.getAllSubscriptionUsers(limit, 1).then(res => {
            if (res.success) {
                setsubscriptionUsers(res.data.users);
                setCount(res.data?.SubscribedUsersCount);
            }

            setIsLoading(false);
        });
    };

    const setShowData = (ap: any) => {
        setDataSingle(ap);
        setIsOpenData(!isOpenData);
    }



    const searchCustomers = (onload: boolean) => {
        const data = {
            searchableString: isSearchableString.searchableString,

        }

        setIsLoading(true);

        AdminService.searchSubscriptionByAdmin(data, limit, 0).then(res => {
            if (res.success) {
                setsubscriptionUsers(res.data.userSet);
                setCount(res.data.userSet.length);
                setOffset(1);
            }
            setIsLoading(false);
        });
    }
    return (
        <div>
            <div className="edu-breadcrumb-area breadcrumb-style-3">
                <div className="container">
                    <div className="breadcrumb-inner accHegiht">

                    </div>
                </div>
                <ul className="shape-group">
                    <li className="shape-1">
                        <span></span>
                    </li>
                    <li className="shape-2 scene"><img data-depth="2" src={img1} alt="shape" /></li>
                    <li className="shape-3 scene"><img data-depth="-2" src={img2} alt="shape" /></li>
                    <li className="shape-4">
                        <span></span>
                    </li>
                    <li className="shape-5 scene"><img data-depth="2" src={img3} alt="shape" /></li>
                </ul>
            </div>

            <section className="edu-section-gap course-details-area">
                <div className="container">
                    <div className="row row--30">

                        <div className="col-lg-12">
                            <div className="course-sidebar-3 sidebar-top-position">
                                <div className="edu-course-widget widget-course-summery">
                                    <div className="row row--30 mb-5">
                                        <div className="col-lg-9">
                                            <h3 className="acc mb-4 pl-2 mt-5">Active Subscibe Users</h3>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="wrap">
                                                <div className="search">
                                                    <input type="text" className="searchTerm" placeholder="Search Customer" onChange={(e: any) => setIsSearchableString({ ...isSearchableString, searchableString: e.target.value })} />
                                                    <button type="submit" className="searchButton" onClick={() => searchCustomers(false)}>
                                                        <i className="fa fa-search"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {subscriptionUsers !== undefined && subscriptionUsers.length > 0 ? (
                                        <>
                                            <div className="table-responsive">
                                                <table className="table cart-table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="product-price">Customer Name</th>
                                                            <th scope="col" className="product-subtotal">Email</th>
                                                            <th scope="col" className="product-subtotal">Subscription Id</th>
                                                            <th scope="col" className="product-subtotal">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {subscriptionUsers &&
                                                            subscriptionUsers.map((ap: UserDetails, index: number) => (
                                                                <tr key={index}>
                                                                    <td className="product-price" data-title="Level">
                                                                        <div className="d-flex align-items-center mr-2">
                                                                            {!ap?.profileImageId || ap?.profileImageId == undefined ? (
                                                                                <div className="avatar-md-table rounded-circle bg-soft card-avatar relative">
                                                                                    <span className="avatar-title text-size-avatr-table text-center-div">{ap?.firstName?.charAt(0)}</span>
                                                                                </div>
                                                                            ) : (
                                                                                <div >
                                                                                    <div
                                                                                        className="img-thumbnail imageFit rounded-circle imageFit avatar-2xl-table3 profile-user-wid-pro items-center margin_center profileImageShow"
                                                                                        style={{
                                                                                            backgroundImage: `url("${Util.fileURL(ap?.profileImageId?._id)}")`,
                                                                                            borderRadius: "10px",
                                                                                            position: "relative",
                                                                                        }}
                                                                                    ></div>
                                                                                </div>
                                                                            )}
                                                                            <span className="pl-1">{ap?.firstName} {ap?.lastName}</span></div></td>
                                                                    {/* <td className="product-price" data-title="Amount">{moment(ap?.createdAt).format('MMMM Do YYYY')}</td> */}
                                                                    <td className="product-price" data-title="Email">
                                                                        <span className="newtag">{ap?.email}</span>
                                                                    </td>
                                                                    <td className="product-price" data-title="Email">
                                                                        <span className="newtag">{ap?.subscriptionId}</span>
                                                                    </td>
                                                                    <td className="product-price" data-title="Level">
                                                                        {/* <button className="btn btn-success mr-10" onClick={completeHomeInsurancePayment} data-value1={ap._id} >Approve</button> */}
                                                                        <button className="btn btn-primary" onClick={() => {
                                                                            setShowData(ap);
                                                                            setDataSingle(ap);
                                                                            setIsOpenData(true);
                                                                        }} data-value1={ap._id} >View</button>
                                                                    </td>
                                                                </tr>

                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <SubscibeUserseModel
                                                trigger={isOpenData}
                                                setTrigger={setIsOpenData}
                                                data={dataSingle}
                                            />
                                            <div className="pagination">
                                                <button className="prev" onClick={previous} disabled={offset >= 2 ? false : true}>Previous</button>
                                                <div className="page-count">Page {offset}/{Math.ceil(count / 10)}</div>
                                                <button className="next" onClick={seeMore} disabled={offset === Math.ceil(count / 10) ? true : false}>Next</button>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {isLoading ? <div /> : <div>No subscribers</div>}</>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    );
};

export default AdminSubscriptionUsers;
