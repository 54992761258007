import React, { useState, useContext, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import logo from "../assets/images/logo/logo-v1.png";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";
import { Input, } from "reactstrap";
import moment from "moment";
import { UserDetails } from "../../models/User";
import UserContext from "../context/UserContext";
import swal from "sweetalert";
import { CommonService } from "../../servises/CommonService";
import { RouteName } from "../../RouteName";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const Profile: React.FC = () => {
    const [userdata, setUserdata] = useState<UserDetails | undefined>({} as UserDetails);
    const [user, setUser] = useContext(UserContext);

    const history = useHistory();
    useEffect(() => {
        setUserdata(user);
    }, []);

    const updateClientDetails = () => {

        if (!userdata?.firstName) {
            swal({ icon: "error", title: "Please enter First Name !" });
            return;
        } else if (!userdata?.userName) {
            swal({ icon: "error", title: "Please enter User Name !" });
            return;
        }  else if (!userdata?.gender) {
            swal({ icon: "error", title: "Please select your Gender !" });
            return;
        } else if (!userdata?.phoneNumber) {
            swal({ icon: "error", title: "Phone number is required !" });
            return;
        } else {

            const updatedUser: UserDetails = {
                firstName: userdata?.firstName,
                lastName: userdata?.lastName,
                userName: userdata?.userName,
                surName: userdata?.surName,
                gender: userdata?.gender,
                phoneNumber: userdata?.phoneNumber,
                address: userdata?.address,

            };

            CommonService.updateCustomer(updatedUser).then((res) => {
                if (res.success) {
                    swal({ icon: "success", title: res.message });
                    setUser(res.data);
                    history.push(RouteName.ACCOUNT);
                } else {
                    swal({ icon: "error", title: res.error });
                }
            });
            return;
        }
    };

    return (
        <div className="login-page edu-footer footer-lighten bg-image footer-style-1">
            <section className="account-page-area section-gap-equal1">
                <div className="container-custom position-relative">
                    <div className="row g-5 justify-content-center">
                        <div className="col-lg-10">
                            <div className="row ">
                                <div className="login-form-box registration-form ">
                                    <div className="App">
                                        <div className="box">

                                            <h3 className="title mb-20 mt-10">Profile Infomations</h3>
                                            <h6 style={{color:"#C62907"}}> in order to update profile, you should have to fill all the following fields. </h6>
                                            <div className="row g-5 mb-20 justify-content-center">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-4">
                                                        <label htmlFor="reg-Name">First Name</label>
                                                        <input type="text" name="reg-Name" id="reg-Name" value={userdata?.firstName || ""}
                                                            onChange={e => setUserdata({ ...userdata, firstName: e.target.value })} />
                                                    </div>

                                                    <div className="form-group mb-4">
                                                        <label htmlFor="reg-Name">Last Name</label>
                                                        <input type="text" name="reg-Name" id="reg-Name" value={userdata?.lastName || ""}
                                                            onChange={e => setUserdata({ ...userdata, lastName: e.target.value })} />
                                                    </div>

                                                    <div className="form-group mb-4">
                                                        <label htmlFor="reg-Name">User Name</label>
                                                        <input type="text" name="reg-Name" id="reg-Name" value={userdata?.userName || ""}
                                                            onChange={e => setUserdata({ ...userdata, userName: e.target.value })} />
                                                    </div>

                                                    <div className="form-group mb-4">
                                                        <label htmlFor="reg-Name">Email</label>
                                                        <input type="text" name="reg-Name" id="reg-Name" value={userdata?.email || ""}
                                                            disabled />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="log-GENDER">Gender</label>
                                                    </div>
                                                    <div className="card-heading mb-2">
                                                        <div className="row">
                                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="exampleRadioss"
                                                                        id="exampleRadios1"
                                                                        value="Female"
                                                                        checked={userdata?.gender == "Female"}
                                                                        onChange={e => setUserdata({ ...userdata, gender: e.target.value })}
                                                                    />

                                                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                                                        Female
                                                                    </label>
                                                                </div>

                                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="exampleRadioss"
                                                                        id="exampleRadios2"
                                                                        value="Male"
                                                                        checked={userdata?.gender == "Male"}
                                                                        onChange={e => setUserdata({ ...userdata, gender: e.target.value })}
                                                                    />

                                                                    <label className="form-check-label" htmlFor="exampleRadios2">
                                                                        Male
                                                                    </label>
                                                                </div>

                                                                <div className="form-check mb-3">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="exampleRadioss"
                                                                        id="exampleRadios3"
                                                                        value="Other"
                                                                        checked={userdata?.gender == "Other"}
                                                                        onChange={e => setUserdata({ ...userdata, gender: e.target.value })}
                                                                    />

                                                                    <label className="form-check-label" htmlFor="exampleRadios3">
                                                                        Other
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group mb-4">
                                                        <label htmlFor="reg-Name ">Phone Number</label>

                                                        <PhoneInput
                                                            placeholder="Phone Number"
                                                            value={"+" +
                                                            (user?.phoneNumber?.toString()) || userdata?.phoneNumber?.toString()}
                                                            defaultCountry="GB"
                                                            onChange={(e: any) => setUserdata({ ...userdata, phoneNumber: e })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-4">
                                                        <label htmlFor="log-Phone">Surname</label>
                                                        <input type="text" name="log-Phone" id="log-Phone" value={userdata?.surName || ""}
                                                            onChange={e => setUserdata({ ...userdata, surName: e.target.value })} />
                                                    </div>



                                                    <div className="form-group mb-4">
                                                        <label htmlFor="reg-Name">Address</label>
                                                        <textarea name="reg-Name" id="reg-Name" value={userdata?.address || ""}
                                                            onChange={e => setUserdata({ ...userdata, address: e.target.value })} />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="header-right mb-3 leadMargin float-right">
                                                <ul className="header-action ">
                                                    <li className="header-btn ">
                                                        <button className="edu-btn btn-medium edu-btnColor" onClick={() => updateClientDetails()}>Update Profile</button>
                                                    </li>
                                                    <li className="header-btn">
                                                        <a href="/account" className="cancel-btn btn-medium">Cancel </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </div >
    );
};

export default Profile;
