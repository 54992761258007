import axios from "axios";
import { AppResponse } from "../models/Response";
import { Util } from "../Util";
import { PersonalRequest } from "../models/PersonalRequestSubmissions";



export default class InsuranceService{
    public static async submitBusinessinfo(data: any): Promise<AppResponse<any>> {
        const ep = Util.apiAuthUrl("business");
    
        const res = await axios.post<any, AppResponse<any>>(ep, data);
    
        return res;
      }

    public static async submitCarPersonalRequestInfo(data: any): Promise<AppResponse<any>> {
        const ep = Util.apiAuthUrl("add/personalRequest");
    
        const res = await axios.post<any, AppResponse<any>>(ep, data);
    
        return res;
      }
    public static async submitBusinessPersonalRequestInfo(data: any): Promise<AppResponse<any>> {
        const ep = Util.apiAuthUrl("add/businessPersonalRequest");
    
        const res = await axios.post<any, AppResponse<any>>(ep, data);
    
        return res;
      }
    public static async submitHomePersonalRequestInfo(data: any): Promise<AppResponse<any>> {
        const ep = Util.apiAuthUrl("add/HomePersonalRequest");
    
        const res = await axios.post<any, AppResponse<any>>(ep, data);
    
        return res;
      }

      public static async submitPersonalRequestInfo(data: any): Promise<AppResponse<any>> {
        const ep = Util.apiAuthUrl("add/personalRequestSubmission");
    
        const res = await axios.post<any, AppResponse<any>>(ep, data);
    
        return res;
      }
      public static async getPersonalRequestInfo(userId: any): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`get/personalRequestSubmission/${userId}`);
        return await axios.get<Partial<PersonalRequest>,AppResponse<any>>(url);  
      }


    public static async submitHomeInsuranceQuotetInfo(data: any): Promise<AppResponse<any>> {
        const ep = Util.apiAuthUrl("add/homeInsurance");
    
        const res = await axios.post<any, AppResponse<any>>(ep, data);
    
        return res;
      }
    public static async submitCarInsuranceQuotetInfo(data: any): Promise<AppResponse<any>> {
        const ep = Util.apiAuthUrl("submit/carInsurance");
    
        const res = await axios.post<any, AppResponse<any>>(ep, data);
    
        return res;
      }
    public static async submitVanInsuranceQuotetInfo(data: any): Promise<AppResponse<any>> {
        const ep = Util.apiAuthUrl("submit/vanInsurance");
    
        const res = await axios.post<any, AppResponse<any>>(ep, data);
    
        return res;
      }
    public static async submitBikeInsuranceQuotetInfo(data: any): Promise<AppResponse<any>> {
        const ep = Util.apiAuthUrl("submit/BikeInsurance");
    
        const res = await axios.post<any, AppResponse<any>>(ep, data);
    
        return res;
      }
    
}




// user Resource
// GET /api/user - get all users
// GET /api/user/:id - get user details by id
// POST /api/user - new user
// UPDATE /api/user/:id - update user record
// DELETE /api/user/:id - delte user record

// user -> insuarance
// GET /api/user/:id/insuarance
// GET /api/user/:id/insuarance/:id
// POST /api/user/:id/insuarance
// UPDATE /api/user/:id/insuarance/:id
// DELETE /api/user/:id/insuarance/:id

