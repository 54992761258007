import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Input, } from "reactstrap";
import moment from "moment";
import { UserDetails } from "../../models/User";
import { CommonService } from "../../servises/CommonService";
import swal from "sweetalert";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { AuthService } from "../../servises/AuthService";

const SignUp: React.FC = () => {
    const [leadData, setLeadData] = useState<{}>({} as {});
    const leadDataRef = useRef<any>();
    const [dateValue, setDateValue] = useState(null);
    leadDataRef.current = leadData;

    const verified = { userId: "", verificationCode: "", medium: "EMAIL" };
    const [verifyData, setVerifyData] = useState(verified);
    const [verification, setVerification] = useState(false);

    const [userData, setUserData] = useState<UserDetails>({} as UserDetails);
    const firstData = useRef<any>();
    firstData.current = userData;

    const finalData = useRef<any>();
    finalData.current = userData;

    const [confirm_password, setConfirmPassword] = useState("");

    const [confirm_email, setConfirmEmail] = useState("");

    function handleDateUpdate(e: any) {
        const dateValue = e.target.value;
        setDateValue(dateValue);
    }
    useEffect(() => {
        setLeadData({
            ...leadDataRef.current,
            services_or_personal_request: `services`,
            color: "#233a6c",
            colors: "#ced4da",
            box: "0px 10px 45px 0px rgb(0 0 0 / 10%)"
        });
    }, []);

    const db = moment(dateValue).format("YYYY-MM-DD");
    const dateData = useRef<any>();
    dateData.current = db;

    const verifyAccount = () => {
        if (!verifyData.verificationCode) {
            swal({ icon: "error", title: "Please enter Verification code!" });
            return;
        }
        if (!verifyData.userId) {
            swal({ icon: "error", title: "UserId Invalid!" });
            return;
        }
        const data = {
            email: firstData.current.email,
            password: firstData.current.password,
            loginMethod: "EMAIL",
            remember: true
        }

        CommonService.customerVerification(verifyData).then((res) => {
            if (res.success) {

                AuthService.userLogin(data)
                    .then((res) => {
                        if (res.success) {
                            window.location.href = "/";
                        } else {
                            swal({ icon: "error", title: res.error });
                        }
                    })
            } else {
                swal({ icon: "error", title: res.error });
            }
        });
    }



    const secondComponent = () => {
        return (
            <div>
                <h3 className="title mb-20">Account Information</h3>

                <div className="row g-5 mb-20 justify-content-center">
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label htmlFor="reg-name">Username*</label>
                            <input type="text" name="reg-name" id="reg-name" placeholder="Username"
                                value={firstData.current.userName || ""}
                                onChange={(e) => setUserData({ ...firstData.current, userName: e.target.value })} />

                        </div>

                    </div>
                </div>

                <div className="row g-5 mb-20 justify-content-center">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="log-password">Password*</label>
                            <input type="password" name="log-password" id="log-password" placeholder="Password"
                                value={firstData.current.password || ""}
                                onChange={(e) => setUserData({ ...firstData.current, password: e.target.value })} />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="log-password">Confirm Password*</label>
                            <input type="password" name="log-password" id="log-password" placeholder="Confirm Password"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                    </div>

                </div>


                <div className="row g-5 mb-20 justify-content-center">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="log-email">Email Address*</label>
                            <input type="email" name="log-email" id="log-email" placeholder="Email Address"
                                value={firstData.current.email || ""}
                                onChange={(e) => setUserData({ ...firstData.current, email: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="log-email">Confirm Email Address*</label>
                            <input type="email" name="log-email" id="log-email" placeholder="Confirm Email Address"
                                onChange={(e) => setConfirmEmail(e.target.value)} />
                        </div>
                    </div>

                </div>
            </div>
        );
    };


    const finalComponent = () => {
        return (
            <div>
                <h3 className="title mb-20">Profile Information</h3>
                <div className="row g-5 mb-20 justify-content-center">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="reg-name">First Name*</label>
                            <input type="text" name="reg-name" id="reg-name" placeholder="First Name"
                                value={firstData.current.firstName || ""}
                                onChange={(e) => setUserData({ ...firstData.current, firstName: e.target.value })} />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="reg-name">Last Name*</label>
                            <input type="text" name="reg-name" id="reg-name" placeholder="Last Name"
                                value={firstData.current.lastName || ""}
                                onChange={(e) => setUserData({ ...firstData.current, lastName: e.target.value })} />
                        </div>
                    </div>
                </div>

                <div className="row g-5 mb-20 justify-content-center">
                    {/* <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="log-Surname">Surname*</label>
                            <input type="text" name="log-Surname" id="log-Surname" placeholder="Surname"
                                value={firstData.current.surName || ""}
                                onChange={(e) => setUserData({ ...firstData.current, surName: e.target.value })}
                            />
                        </div>
                    </div> */}
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="log-GENDER">Gender*</label>
                        </div>
                        <div className="card-heading mb-2">
                            <div className="row">
                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="exampleRadioss"
                                            id="exampleRadios1"
                                            value="Female"
                                            onChange={(e) => setUserData({ ...firstData.current, gender: e.target.value })}
                                        />

                                        <label className="form-check-label" htmlFor="exampleRadios1">
                                            Female
                                        </label>
                                    </div>

                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="exampleRadioss"
                                            id="exampleRadios2"
                                            value="Male"
                                            onChange={(e) => setUserData({ ...firstData.current, gender: e.target.value })}
                                        />

                                        <label className="form-check-label" htmlFor="exampleRadios2">
                                            Male
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="log-Phone">Phone Number*</label>
                            <PhoneInput
                                placeholder="Home Phone"
                                value={firstData.current.phoneNumber || ""}
                                defaultCountry="GB"
                                onChange={(e) => setUserData({ ...firstData.current, phoneNumber: e })}
                            />
                        </div>
                    </div>
                </div>


                <div className="row g-5 mb-20 justify-content-center">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="log-email">Date of Birth*</label>
                            <Input
                                type="date"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Date of birth"
                                value={dateData.current || ""}
                                onChange={e => handleDateUpdate(e)}
                                max={moment().format("YYYY-MM-DD")}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-lg-6"></div>
                </div>
            </div>
        );
    };

    const [steps, setSteps] = useState([
        { key: "secondStep", label: "STEP", isDone: false, component: secondComponent },
        { key: "finalStep", label: "STEP", isDone: false, component: finalComponent },
    ]);


    const [activeStep, setActiveStep] = useState(steps[0]);

    const history = useHistory();

    const handleNext = () => {
        if (steps[steps.length - 2].key === activeStep.key) {

            if (!firstData.current.userName) {
                swal({ icon: "error", title: "Please enter first name !" });
                return;
            }
            if (!firstData.current.password) {
                swal({ icon: "error", title: "Please enter password !" });
                return;
            }

            if (firstData.current.password !== confirm_password) {
                swal({ icon: "error", title: "Passwords do not match!" });
                return;
            }

            if (!firstData.current.email) {
                swal({ icon: "error", title: "Please enter email !" });
                return;
            }

            if (firstData.current.email !== confirm_email) {
                swal({ icon: "error", title: "Email do not match !" });
                return;
            }

            const index = steps.findIndex((x) => x.key === activeStep.key);

            setSteps((prevStep) =>
                prevStep.map((x) => {
                    if (x.key === activeStep.key) x.isDone = true;
                    return x;
                })
            );

            setActiveStep(steps[index + 1]);
            return;
        }

        if (steps[steps.length - 1].key === activeStep.key) {
            if (!firstData.current.firstName) {
                swal({ icon: "error", title: "Please enter first name !" });
                return;
            } else

                if (!firstData.current.gender) {
                    swal({ icon: "error", title: "Please select gender !" });
                    return;
                } else

                    if (!firstData.current.phoneNumber) {
                        swal({ icon: "error", title: "Please enter phone number !" });
                        return;
                    } else {
                        const user = {
                            loginMethod: "EMAIL",
                            gender: firstData.current.gender,
                            email: firstData.current.email,
                            firstName: firstData.current.firstName,
                            lastName: firstData.current.lastName,
                            userName: firstData.current.userName,
                            dateOfBirth: db as any,
                            password: firstData.current.password,
                            phoneNumber: firstData.current.phoneNumber
                        }
                        CommonService.addCustomer(user).then((res) => {
                            if (res.success) {
                                setVerifyData({ ...verifyData, userId: res.data._id as any })
                                setVerification(true);
                            } else {
                                swal({ icon: "error", title: res.error });
                            }
                        });
                        return;
                    }
        }
    };

    const handleBack = () => {
        const index = steps.findIndex((x) => x.key === activeStep.key);

        if (index === 0) return;

        setSteps((prevStep) =>
            prevStep.map((x) => {
                if (x.key === activeStep.key) x.isDone = false;
                return x;
            })
        );

        setActiveStep(steps[index - 1]);
    };





    return (
        <div className="login-page edu-footer footer-lighten bg-image footer-style-1">
            <section className="account-page-area section-gap-equal1">
                <div className="row g-5 justify-content-center">
                    <h2 className="title text-center">Registration</h2>
                </div>

                <div className="container position-relative">
                    <div className="row g-5 justify-content-center">
                        <div className="col-lg-8">
                            <div className="login-form-box registration-form">

                                <div className="App">
                                    <div className="box">

                                        {verification == false ? (
                                            <>
                                                <div className="step-component">{activeStep.component()}</div>
                                                <div className="btn-component">
                                                    {steps[0].key !== activeStep.key && (
                                                        <button className="btn colorchangeback back-btn btn-medium" onClick={handleBack}>
                                                            Back
                                                        </button>
                                                    )}

                                                    <button
                                                        className={steps[steps.length - 1].key !== activeStep.key ? "btn colorchangeLog leadMargin edu-btn btn-medium" : "btn colorchangeLog edu-btn btn-medium"}
                                                        value={steps[steps.length - 1].key !== activeStep.key ? "Next" : "Get"}
                                                        onClick={handleNext}
                                                    >
                                                        {" "}

                                                        <div className="width-100">  {steps[steps.length - 1].key !== activeStep.key ? "Next" : "Submit and Check Out"} <i className="dw dw-right-arrow right_arrow_icon"></i>{" "}</div>
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <h3 className="title mt-30">Verify Your Account</h3>
                                                <p>Fill verification code to verify your Account.</p>
                                                <div className="row g-5 mb-20 justify-content-center">
                                                    <div className="form-group mb-20">
                                                        <label htmlFor="reg-Name">Enter Verification Code</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Verification Code"
                                                            onChange={(e) => setVerifyData({ ...verifyData, verificationCode: e.target.value })}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <button type="submit" className="edu-btn btn-medium" id="ajaxButton" onClick={() => verifyAccount()}>Verify <i
                                                            className="icon-4"></i></button>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div >
    );
};

export default SignUp;
