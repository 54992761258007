import React, { useState, useContext, useRef } from "react";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";
import { Form, FormFeedback, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import UserContext from "../context/UserContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SpeedingTicketObject } from "../../models/SpeedingTicket";
import { PenaltyChargesService } from "../../servises/Penalty-Charges-Service";
import Swal from "sweetalert2";
import moment from "moment";
import { UserDetails } from "../../models/User";
const SpeedingTicket: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [speedingTicketImage, setSpeedingTicketImage] = useState({ raw: "" });
  const inputRef = useRef(null);
  const imageUpload = (e: any): void => {
    setSpeedingTicketImage({
      raw: e.target.files[0],
    });
  };

  const [userdata, setUserdata] = useState<UserDetails | undefined>({} as UserDetails);

  const autoFill = () => {
    setUserdata(user);
  }


  const speedingTicketValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      st_name: "",
      st_phone_number: "",
      st_postal_code: "",
      st_address: "",
      st_dateOfBirth: "",
      st_driver_license_number: "",
      st_make_of_vehicle: "",
      st_model_of_vehicle: "",
      st_registration_number: "",
      st_offense_location: "",
      st_travelling_speed: "",
      pt_offense_datetime: "",
      st_speed_limit: "",
      st_fine_amount: "",
      st_how_to_contest: "",
      st_duedate: "",
      st_signature: "",
      st_ticketId: "",
    },
    validationSchema: Yup.object({
      // st_name: Yup.string().required(t<string>("Please Enter Name")),
      // st_phone_number: Yup.number().required(
      //   t<string>("Please Enter Phone Number")
      // ),

      // st_postal_code: Yup.string().required(
      //   t<string>("Please Enter the Postal Code")
      // ),
      // st_address: Yup.string().required(t<string>("Please Enter the Address")),
      // st_dateOfBirth: Yup.date().required(
      //   t<string>("Please Select the Date & Time of the offense")
      // ),
      st_driver_license_number: Yup.string().required(
        t<string>("Please Enter the Driver License Number")
      ),
      st_make_of_vehicle: Yup.string().required(
        t<string>("Please Enter the Make of the Vehicle")
      ),
      st_model_of_vehicle: Yup.string().required(
        t<string>("Please Enter the model of the Vehicle")
      ),
      st_registration_number: Yup.string().required(
        t<string>("Please Enter the registration number of the Vehicle")
      ),
      st_offense_location: Yup.string().required(
        t<string>("Please Enter the location of the offense")
      ),
      st_travelling_speed: Yup.string().required(
        t<string>(
          "Please Enter the travelling speed at the time of the offense"
        )
      ),
      pt_offense_datetime: Yup.date().required(
        t<string>("Please Enter the date and time of the offense")
      ),
      st_speed_limit: Yup.string().required(
        t<string>("Please Enter the valid speed limit")
      ),
      st_fine_amount: Yup.number().required(
        t<string>("Please Enter the fine amount")
      ),
      st_how_to_contest: Yup.string().required(
        t<string>(
          "Please Enter the details regarding how to contest the ticket"
        )
      ),
      st_duedate: Yup.date().required(t<string>("Please Enter the due date")),
      st_signature: Yup.string().required(
        t<string>("Please Enter the Signature")
      ),
      st_ticketId: Yup.string().required(
        t<string>("Please Enter the ticket Id")
      ),
    }),
    onSubmit: (values, onSubmitProps) => {
      var bodyFormData = new FormData();

      const finalData: SpeedingTicketObject = {
        name: values.st_name ||  (userdata?.firstName + " " + userdata?.lastName),
        phoneNumber: parseInt(values.st_phone_number) || userdata?.phoneNumber,
        postCode: values.st_postal_code || userdata?.postCode,
        address: values.st_address || userdata?.address,
        vehicleRegistrationNumber: values.st_registration_number,
        drivingLicenseNumber: values.st_driver_license_number,
        makeOfVehicle: values.st_make_of_vehicle,
        modelOfVehicle: values.st_model_of_vehicle,
        dateOfBirth: values.st_dateOfBirth || userdata?.dateOfBirth as any,
        offenseDate: values.pt_offense_datetime,
        offenseLocation: values.st_offense_location,
        travellingSpeedOfTheTimeOftheOffense: values.st_travelling_speed,
        legitSpeedLimit: values.st_speed_limit,
        fineAmount: parseFloat(values.st_fine_amount),
        dueDate: values.st_duedate,
        paymentMethod: "CREDIT_CARD",
        howToContestTicketInfo: values.st_how_to_contest,
        signature: values.st_signature,
        ticketId: values.st_ticketId,
      };
      const dataString = JSON.stringify(finalData);

      bodyFormData.append("speedingTicketDetails", dataString);

      bodyFormData.append("speedingticket", speedingTicketImage.raw);

      if (speedingTicketImage.raw !== undefined) {
        onSubmitProps.resetForm();
      }

      if (user?.subscriptionStatus === "CANCELLED" || !user?.subscriptionId) {
        Swal.fire({
          icon: "warning",
          title: "in order to be able receive our services you will have to be subscribe",
          confirmButtonText: "Subscribe",
          confirmButtonColor: "#FD7F00",
          showCancelButton: true,
          cancelButtonText: "Cancel",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/account";
          }
        });
        return;
      } else {
        setIsLoading(true);

        PenaltyChargesService.paySpeedingTicket(bodyFormData).then((res) => {
          if (res.success) {
            setIsLoading(false);
            Swal.fire({ title: "Speed Ticket Payement is Valid" });
            if (inputRef.current !== null) {
              (inputRef.current as HTMLInputElement).value = "";
            }
            setUserdata({ firstName: "", lastName: "", phoneNumber: "" as any, postCode: "", address: "", email: "", dateOfBirth: undefined, });
            onSubmitProps.resetForm();
          } else {
            setIsLoading(false);
            Swal.fire({
              icon: "error",
              title: res.error,
              confirmButtonColor: "#FD7F00",
            });
          }
        });
      }
    },
  });

  return (
    <div className="login-page edu-footer footer-lighten bg-image footer-style-1">
      <section className="account-page-area section-gap-equal1">
        <div className="container-custom position-relative">
          <div className="row g-5 justify-content-center">
            <div className="col-lg-8">

              <div className="login-form-box registration-form">
                <div className="App">
                  <div className="box">
                    <div>
                      <h3 className="title mb-20 mt-10">
                        Personal Information:
                      </h3>

                      <p className="themeColor  mb-30 font-16 mt-3">Speeding ticket life admin services assist individuals in managing speeding violations. They handle tasks such as reviewing ticket details, assisting with fine payment or contesting the ticket.</p>
                      <button
                        className="btn colorchangeLog edu-btn btn-medium mb-20"
                        type="submit"
                        onClick={autoFill}
                      >AutoFill</button>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          speedingTicketValidation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="row g-5 mb-20 justify-content-center">
                          <div className="col-lg-6">
                            <div className="form-group  mb-20">
                              <label htmlFor="reg-name">Name:</label>
                              <Input
                                type="text"
                                name="st_name"
                                id="st_name"
                                value={speedingTicketValidation.values.st_name ||  userdata?.firstName == undefined ? "" : `${userdata?.firstName} ${userdata?.lastName}`}
                                onChange={speedingTicketValidation.handleChange}
                                invalid={
                                  speedingTicketValidation.touched.st_name &&
                                    speedingTicketValidation.errors.st_name
                                    ? true
                                    : false
                                }
                              />

                              {speedingTicketValidation.touched.st_name &&
                                speedingTicketValidation.errors.st_name ? (
                                <FormFeedback type="invalid">
                                  {speedingTicketValidation.errors.st_name}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group  mb-20">
                              <label htmlFor="reg-Number">Phone Number:</label>
                              <Input
                                type="number"
                                name="st_phone_number"
                                id="st_phone_number"
                                value={
                                  speedingTicketValidation.values
                                    .st_phone_number || userdata?.phoneNumber
                                }
                                onChange={speedingTicketValidation.handleChange}
                                invalid={
                                  speedingTicketValidation.touched
                                    .st_phone_number &&
                                    speedingTicketValidation.errors
                                      .st_phone_number
                                    ? true
                                    : false
                                }
                              />

                              {speedingTicketValidation.touched
                                .st_phone_number &&
                                speedingTicketValidation.errors
                                  .st_phone_number ? (
                                <FormFeedback type="invalid">
                                  {
                                    speedingTicketValidation.errors
                                      .st_phone_number
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group  mb-20">
                              <label htmlFor="reg-Vehicle">
                                Date of Birth:
                              </label>
                              <Input
                                className="form-control"
                                type="date"
                                defaultValue="2019-08-19"
                                id="st_dateOfBirth"
                                name="st_dateOfBirth"
                                placeholder="Select Date"
                                onChange={speedingTicketValidation.handleChange}
                                onBlur={speedingTicketValidation.handleBlur}
                                value={
                                  speedingTicketValidation.values.st_dateOfBirth || moment(userdata?.dateOfBirth).format("YYYY-MM-DD")
                                }
                                invalid={
                                  speedingTicketValidation.touched
                                    .st_dateOfBirth &&
                                    speedingTicketValidation.errors.st_dateOfBirth
                                    ? true
                                    : false
                                }
                              />
                              {speedingTicketValidation.touched
                                .st_dateOfBirth &&
                                speedingTicketValidation.errors.st_dateOfBirth ? (
                                <FormFeedback type="invalid">
                                  {
                                    speedingTicketValidation.errors
                                      .st_dateOfBirth
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <label htmlFor="reg-License">
                                Driver's License Number:
                              </label>
                              <Input
                                type="text"
                                name="st_driver_license_number"
                                id="st_driver_license_number"
                                value={
                                  speedingTicketValidation.values
                                    .st_driver_license_number
                                }
                                onChange={speedingTicketValidation.handleChange}
                                invalid={
                                  speedingTicketValidation.touched
                                    .st_driver_license_number &&
                                    speedingTicketValidation.errors
                                      .st_driver_license_number
                                    ? true
                                    : false
                                }
                              />

                              {speedingTicketValidation.touched
                                .st_driver_license_number &&
                                speedingTicketValidation.errors
                                  .st_driver_license_number ? (
                                <FormFeedback type="invalid">
                                  {
                                    speedingTicketValidation.errors
                                      .st_driver_license_number
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group  mb-20">
                              <label htmlFor="reg-Postcode">Postcode:</label>
                              <Input
                                type="text"
                                name="st_postal_code"
                                id="st_postal_code"
                                value={
                                  speedingTicketValidation.values.st_postal_code || userdata?.postCode
                                }
                                onChange={speedingTicketValidation.handleChange}
                                invalid={
                                  speedingTicketValidation.touched
                                    .st_postal_code &&
                                    speedingTicketValidation.errors.st_postal_code
                                    ? true
                                    : false
                                }
                              />

                              {speedingTicketValidation.touched
                                .st_postal_code &&
                                speedingTicketValidation.errors.st_postal_code ? (
                                <FormFeedback type="invalid">
                                  {
                                    speedingTicketValidation.errors
                                      .st_postal_code
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <label htmlFor="log-Address">Address:</label>
                              <Input
                                type="textarea"
                                rows="4"
                                name="st_address"
                                id="st_address"
                                value={
                                  speedingTicketValidation.values.st_address || userdata?.address
                                }
                                onChange={speedingTicketValidation.handleChange}
                                invalid={
                                  speedingTicketValidation.touched.st_address &&
                                    speedingTicketValidation.errors.st_address
                                    ? true
                                    : false
                                }
                              />
                              {speedingTicketValidation.touched.st_address &&
                                speedingTicketValidation.errors.st_address ? (
                                <FormFeedback type="invalid">
                                  {speedingTicketValidation.errors.st_address}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <hr className="mb-30 mt-40" />
                        <h3 className="title mb-30">Vehicle Information:</h3>

                        <div className="row g-5 mb-20 justify-content-center">
                          <div className="col-lg-6">
                            <div className="form-group  mb-20">
                              <div className="form-group">
                                <label htmlFor="reg-Postcode">
                                  Make of the vehicle:
                                </label>
                                <Input
                                  type="text"
                                  name="st_make_of_vehicle"
                                  id="st_make_of_vehicle"
                                  value={
                                    speedingTicketValidation.values
                                      .st_make_of_vehicle
                                  }
                                  onChange={
                                    speedingTicketValidation.handleChange
                                  }
                                  invalid={
                                    speedingTicketValidation.touched
                                      .st_make_of_vehicle &&
                                      speedingTicketValidation.errors
                                        .st_make_of_vehicle
                                      ? true
                                      : false
                                  }
                                />

                                {speedingTicketValidation.touched
                                  .st_make_of_vehicle &&
                                  speedingTicketValidation.errors
                                    .st_make_of_vehicle ? (
                                  <FormFeedback type="invalid">
                                    {
                                      speedingTicketValidation.errors
                                        .st_make_of_vehicle
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="reg-Postcode">
                                Model of the vehicle:
                              </label>
                              <Input
                                type="text"
                                name="st_model_of_vehicle"
                                id="st_model_of_vehicle"
                                value={
                                  speedingTicketValidation.values
                                    .st_model_of_vehicle
                                }
                                onChange={speedingTicketValidation.handleChange}
                                invalid={
                                  speedingTicketValidation.touched
                                    .st_model_of_vehicle &&
                                    speedingTicketValidation.errors
                                      .st_model_of_vehicle
                                    ? true
                                    : false
                                }
                              />

                              {speedingTicketValidation.touched
                                .st_model_of_vehicle &&
                                speedingTicketValidation.errors
                                  .st_model_of_vehicle ? (
                                <FormFeedback type="invalid">
                                  {
                                    speedingTicketValidation.errors
                                      .st_model_of_vehicle
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="log-Registration">
                                Registration Number:
                              </label>
                              <Input
                                type="text"
                                name="st_registration_number"
                                id="st_registration_number"
                                value={
                                  speedingTicketValidation.values
                                    .st_registration_number
                                }
                                onChange={speedingTicketValidation.handleChange}
                                invalid={
                                  speedingTicketValidation.touched
                                    .st_registration_number &&
                                    speedingTicketValidation.errors
                                      .st_registration_number
                                    ? true
                                    : false
                                }
                              />

                              {speedingTicketValidation.touched
                                .st_registration_number &&
                                speedingTicketValidation.errors
                                  .st_registration_number ? (
                                <FormFeedback type="invalid">
                                  {
                                    speedingTicketValidation.errors
                                      .st_registration_number
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <hr className="mb-30 mt-40" />

                        <h3 className="title mb-30">Offense Information:</h3>

                        <div className="row g-5 mb-20 justify-content-center">
                          <div className="col-lg-6">
                            <div className="form-group  mb-20">
                              <label htmlFor="log-Date">
                                Date and Time of the offense:
                              </label>
                              <Input
                                className="form-control"
                                type="datetime-local"
                                defaultValue="2019-08-19"
                                id="pt_offense_datetime"
                                name="pt_offense_datetime"
                                placeholder="Select Date"
                                onChange={speedingTicketValidation.handleChange}
                                onBlur={speedingTicketValidation.handleBlur}
                                value={
                                  speedingTicketValidation.values
                                    .pt_offense_datetime
                                }
                                invalid={
                                  speedingTicketValidation.touched
                                    .pt_offense_datetime &&
                                    speedingTicketValidation.errors
                                      .pt_offense_datetime
                                    ? true
                                    : false
                                }
                              />
                              {speedingTicketValidation.touched
                                .pt_offense_datetime &&
                                speedingTicketValidation.errors
                                  .pt_offense_datetime ? (
                                <FormFeedback type="invalid">
                                  {
                                    speedingTicketValidation.errors
                                      .pt_offense_datetime
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="form-group">
                              <label htmlFor="reg-Speed">
                                Speed at which the vehicle was traveling:
                              </label>
                              <Input
                                type="text"
                                name="st_travelling_speed"
                                id="st_travelling_speed"
                                value={
                                  speedingTicketValidation.values
                                    .st_travelling_speed
                                }
                                onChange={speedingTicketValidation.handleChange}
                                invalid={
                                  speedingTicketValidation.touched
                                    .st_travelling_speed &&
                                    speedingTicketValidation.errors
                                      .st_travelling_speed
                                    ? true
                                    : false
                                }
                              />

                              {speedingTicketValidation.touched
                                .st_travelling_speed &&
                                speedingTicketValidation.errors
                                  .st_travelling_speed ? (
                                <FormFeedback type="invalid">
                                  {
                                    speedingTicketValidation.errors
                                      .st_travelling_speed
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group  mb-20">
                              <label htmlFor="log-offense">
                                Location of the offense:
                              </label>
                              <Input
                                type="textarea"
                                rows="4"
                                name="st_offense_location"
                                id="st_offense_location"
                                value={
                                  speedingTicketValidation.values
                                    .st_offense_location
                                }
                                onChange={speedingTicketValidation.handleChange}
                                invalid={
                                  speedingTicketValidation.touched
                                    .st_offense_location &&
                                    speedingTicketValidation.errors
                                      .st_offense_location
                                    ? true
                                    : false
                                }
                              />
                              {speedingTicketValidation.touched
                                .st_offense_location &&
                                speedingTicketValidation.errors
                                  .st_offense_location ? (
                                <FormFeedback type="invalid">
                                  {
                                    speedingTicketValidation.errors
                                      .st_offense_location
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <label htmlFor="log-Limit">
                                Legal Speed Limit for the location:
                              </label>
                              <Input
                                type="text"
                                name="st_speed_limit"
                                id="st_speed_limit"
                                value={
                                  speedingTicketValidation.values.st_speed_limit
                                }
                                onChange={speedingTicketValidation.handleChange}
                                invalid={
                                  speedingTicketValidation.touched
                                    .st_speed_limit &&
                                    speedingTicketValidation.errors.st_speed_limit
                                    ? true
                                    : false
                                }
                              />

                              {speedingTicketValidation.touched
                                .st_speed_limit &&
                                speedingTicketValidation.errors.st_speed_limit ? (
                                <FormFeedback type="invalid">
                                  {
                                    speedingTicketValidation.errors
                                      .st_speed_limit
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <hr className="mb-30 mt-40" />
                        <h3 className="title mb-30">Fine Information:</h3>

                        <div className="row g-5 mb-20 justify-content-center">
                          <div className="col-lg-6">
                            <div className="form-group  mb-20">
                              <label htmlFor="reg-Amount">Fine Amount:</label>
                              <Input
                                type="number"
                                name="st_fine_amount"
                                id="st_fine_amount"
                                value={
                                  speedingTicketValidation.values.st_fine_amount
                                }
                                onChange={speedingTicketValidation.handleChange}
                                invalid={
                                  speedingTicketValidation.touched
                                    .st_fine_amount &&
                                    speedingTicketValidation.errors.st_fine_amount
                                    ? true
                                    : false
                                }
                              />

                              {speedingTicketValidation.touched
                                .st_fine_amount &&
                                speedingTicketValidation.errors.st_fine_amount ? (
                                <FormFeedback type="invalid">
                                  {
                                    speedingTicketValidation.errors
                                      .st_fine_amount
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group mb-20">
                              <label htmlFor="reg-revenue">Due Date:</label>
                              <Input
                                className="form-control"
                                type="date"
                                defaultValue="2019-08-19"
                                id="st_duedate"
                                name="st_duedate"
                                placeholder="Select Date"
                                onChange={speedingTicketValidation.handleChange}
                                onBlur={speedingTicketValidation.handleBlur}
                                value={
                                  speedingTicketValidation.values.st_duedate
                                }
                                invalid={
                                  speedingTicketValidation.touched.st_duedate &&
                                    speedingTicketValidation.errors.st_duedate
                                    ? true
                                    : false
                                }
                              />
                              {speedingTicketValidation.touched.st_duedate &&
                                speedingTicketValidation.errors.st_duedate ? (
                                <FormFeedback type="invalid">
                                  {speedingTicketValidation.errors.st_duedate}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group mb-20">
                              <label htmlFor="reg-Information">
                                Information on how to contest the ticket:
                              </label>
                              <Input
                                type="text"
                                name="st_how_to_contest"
                                id="st_how_to_contest"
                                value={
                                  speedingTicketValidation.values
                                    .st_how_to_contest
                                }
                                onChange={speedingTicketValidation.handleChange}
                                invalid={
                                  speedingTicketValidation.touched
                                    .st_how_to_contest &&
                                    speedingTicketValidation.errors
                                      .st_how_to_contest
                                    ? true
                                    : false
                                }
                              />

                              {speedingTicketValidation.touched
                                .st_how_to_contest &&
                                speedingTicketValidation.errors
                                  .st_how_to_contest ? (
                                <FormFeedback type="invalid">
                                  {
                                    speedingTicketValidation.errors
                                      .st_fine_amount
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>


                          </div>
                          <div className="col-lg-6">
                            <div className="form-group mb-20">
                              <label htmlFor="log-Insurance-contract-copy-upload">
                                Picture of the Speeding ticket
                              </label>
                              <input
                                type="file"
                                className="inputFile"
                                ref={inputRef}
                                onChange={(e) => imageUpload(e)}
                              />
                            </div>

                            <div className="form-group mb-20">
                              <label htmlFor="reg-Amount">Ticket Id:</label>
                              <Input
                                type="text"
                                name="st_ticketId"
                                id="st_ticketId"
                                value={
                                  speedingTicketValidation.values.st_ticketId
                                }
                                onChange={speedingTicketValidation.handleChange}
                                invalid={
                                  speedingTicketValidation.touched
                                    .st_ticketId &&
                                    speedingTicketValidation.errors.st_ticketId
                                    ? true
                                    : false
                                }
                              />

                              {speedingTicketValidation.touched.st_ticketId &&
                                speedingTicketValidation.errors.st_ticketId ? (
                                <FormFeedback type="invalid">
                                  {speedingTicketValidation.errors.st_ticketId}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="form-group mb-20">
                              <label htmlFor="log-Registration">
                                Signature:
                              </label>
                              <Input
                                type="text"
                                name="st_signature"
                                id="st_signature"
                                value={
                                  speedingTicketValidation.values.st_signature
                                }
                                onChange={speedingTicketValidation.handleChange}
                                invalid={
                                  speedingTicketValidation.touched
                                    .st_signature &&
                                    speedingTicketValidation.errors.st_signature
                                    ? true
                                    : false
                                }
                              />

                              {speedingTicketValidation.touched.st_signature &&
                                speedingTicketValidation.errors.st_signature ? (
                                <FormFeedback type="invalid">
                                  {speedingTicketValidation.errors.st_signature}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <button
                          className="btn colorchangeLog leadMargin edu-btn btn-medium mb-20"
                          type="submit"
                        >
                          <div>Submit</div>
                        </button>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SpeedingTicket;
