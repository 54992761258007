import moment from "moment";
import React from "react";
import { Col, Row, Modal, Input } from "reactstrap";
import img1 from "../assets/images/about/shape-13.png";
const PanaltyChargesModal: React.FC<{ trigger?: boolean; setTrigger?: any; data: any }> = (props) => {
    const { data } = props;
    return (props.trigger) ? (
        <>
            <Modal isOpen={props.trigger} centered >
                <div className="modal-header">
                    <h5 className="text-center mt-2 mb-0 font-size-20">Customer Name : {data.userId?.firstName == undefined ? "-" : data.userId?.firstName}</h5>
                    <button
                        type="button"
                        onClick={() => props.setTrigger(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span>&times;</span>
                    </button>
                </div>
                <div className="modal-body mt-4">
                    {data?.penaltyType == "PARKING_TICKET" &&
                        <Row>
                            <h3 className="title mb-20 mt-10">
                                Personal Information:
                            </h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group  mb-20">
                                        <label htmlFor="reg-name">Name:</label>
                                        <Input
                                            type="text"
                                            name="name"
                                            id="name"
                                            value={data?.name}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group  mb-20">
                                        <label htmlFor="reg-Number">Phone Number:</label>
                                        <Input
                                            type="number"
                                            name="pt_phone_number"
                                            id="pt_phone_number"
                                            value={data?.phoneNumber}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="reg-Vehicle">
                                            Vehicle Registration Number:
                                        </label>
                                        <Input
                                            type="text"
                                            name="pt_vehicle_registration_number"
                                            id="pt_vehicle_registration_number"
                                            value={data?.vehicleRegistrationNumber}
                                            disabled
                                        />


                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="reg-Postcode  mb-20">
                                            Postcode:
                                        </label>
                                        <Input
                                            type="number"
                                            name="pt_postal_code"
                                            id="pt_postal_code"
                                            value={data?.postCode}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Address">Address:</label>
                                        <Input
                                            type="textarea"
                                            rows="4"
                                            name="pt_address"
                                            id="pt_address"
                                            value={data?.address}
                                            disabled
                                        />

                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30">Offense Information:</h3>

                            <div className="row mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group  mb-20">
                                        <label htmlFor="log-Date">
                                            Date and Time of the offense:
                                        </label>
                                        <Input
                                            className="form-control"
                                            type="datetime-local"
                                            defaultValue="2019-08-19"
                                            id="pt_offense_datetime"
                                            name="pt_offense_datetime"
                                            placeholder="Select Date"
                                            value={moment(data?.offenseDate).format("YYYY-MM-DD")}
                                            disabled
                                        />

                                    </div>
                                    <div className="form-group  mb-20">
                                        <label htmlFor="reg-Postcode">
                                            Location of the offense:
                                        </label>
                                        <Input
                                            type="text"
                                            name="pt_offense_location"
                                            id="pt_offense_location"
                                            value={data?.offenseLocation}
                                            disabled
                                        />


                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-Description">
                                            Description of the offense:
                                        </label>
                                        <Input
                                            type="textarea"
                                            rows="4"
                                            name="pt_offense_description"
                                            id="pt_offense_description"
                                            value={data?.offenseDescription}
                                            disabled
                                        />

                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30">Fine Information:</h3>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-4">
                                    <div className="form-group  mb-20">
                                        <label htmlFor="reg-Amount">Fine Amount:</label>
                                        <Input
                                            type="number"
                                            name="pt_fine_amount"
                                            id="pt_fine_amount"
                                            value={data?.fineAmount}
                                            disabled
                                        />


                                    </div>


                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Amount">Signature:</label>
                                        <Input
                                            type="text"
                                            name="pt_signature"
                                            id="pt_signature"
                                            value={data?.signature}
                                            disabled
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group  mb-20">
                                        <label htmlFor="reg-revenue">Due Date:</label>
                                        <Input
                                            className="form-control"
                                            type="date"
                                            defaultValue="2019-08-19"
                                            id="pt_duedate"
                                            name="pt_duedate"
                                            placeholder="Select Date"
                                            value={moment(data?.dueDate).format("YYYY-MM-DD")}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Insurance-contract-copy-upload">
                                            Picture of the parking ticket
                                        </label>

                                        <img
                                            src={data?.ticketUploadId?.url == null || data?.ticketUploadId?.url == "" || data?.ticketUploadId?.url == undefined ? img1 : data?.ticketUploadId?.url}
                                            alt="Profile Image" />
                                    </div>

                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Information">
                                            Information on how to contest the ticket:
                                        </label>
                                        <Input
                                            type="text"
                                            name="pt_how_to_contest_ticket"
                                            id="pt_how_to_contest_ticket"
                                            value={data?.howToContestTicketInfo}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Amount">Ticket Id:</label>
                                        <Input
                                            type="text"
                                            name="pt_ticketId"
                                            id="pt_ticketId"
                                            value={data?.ticketId}
                                            disabled
                                        />
                                    </div>

                                </div>
                            </div>

                        </Row>
                    }

                    {data?.penaltyType == "SPEEDING_TICKET" &&
                        <Row>
                            <h3 className="title mb-20 mt-10">
                                Personal Information:
                            </h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group  mb-20">
                                        <label htmlFor="reg-name">Name:</label>
                                        <Input
                                            type="text"
                                            name="st_name"
                                            id="st_name"
                                            value={data?.name}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group  mb-20">
                                        <label htmlFor="reg-Number">Phone Number:</label>
                                        <Input
                                            type="number"
                                            name="st_phone_number"
                                            id="st_phone_number"
                                            value={data?.phoneNumber}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group  mb-20">
                                        <label htmlFor="reg-Vehicle">
                                            Date of Birth:
                                        </label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="dateOfBirth"
                                            placeholder="Date of birth"
                                            name="dateOfBirth"
                                            max={moment().format("YYYY-MM-DD")}
                                            value={moment(data?.dateOfBirth).format("YYYY-MM-DD")}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="reg-License">
                                            Driver's License Number:
                                        </label>
                                        <Input
                                            type="text"
                                            name="st_driver_license_number"
                                            id="st_driver_license_number"
                                            value={data?.drivingLicenseNumber}
                                            disabled
                                        />


                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group  mb-20">
                                        <label htmlFor="reg-Postcode">Postcode:</label>
                                        <Input
                                            type="text"
                                            name="st_postal_code"
                                            id="st_postal_code"
                                            value={data?.postCode}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Address">Address:</label>
                                        <Input
                                            type="textarea"
                                            rows="4"
                                            name="st_address"
                                            id="st_address"
                                            value={data?.address}
                                            disabled
                                        />

                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30">Vehicle Information:</h3>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group  mb-20">
                                        <div className="form-group">
                                            <label htmlFor="reg-Postcode">
                                                Make of the vehicle:
                                            </label>
                                            <Input
                                                type="text"
                                                name="st_make_of_vehicle"
                                                id="st_make_of_vehicle"
                                                value={data?.makeOfVehicle}
                                                disabled
                                            />


                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="reg-Postcode">
                                            Model of the vehicle:
                                        </label>
                                        <Input
                                            type="text"
                                            name="st_model_of_vehicle"
                                            id="st_model_of_vehicle"
                                            value={data?.modelOfVehicle}
                                            disabled
                                        />


                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-Registration">
                                            Registration Number:
                                        </label>
                                        <Input
                                            type="text"
                                            name="st_registration_number"
                                            id="st_registration_number"
                                            value={data?.vehicleRegistrationNumber}
                                            disabled
                                        />


                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />

                            <h3 className="title mb-30">Offense Information:</h3>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group  mb-20">
                                        <label htmlFor="log-Date">
                                            Date and Time of the offense:
                                        </label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="pt_offense_datetime"
                                            placeholder="Date of birth"
                                            name="pt_offense_datetime"
                                            max={moment().format("YYYY-MM-DD")}
                                            value={moment(data?.offenseDate).format("YYYY-MM-DD")}
                                            disabled
                                        />

                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="reg-Speed">
                                            Speed at which the vehicle was traveling:
                                        </label>
                                        <Input
                                            type="text"
                                            name="st_travelling_speed"
                                            id="st_travelling_speed"
                                            value={data?.travellingSpeedOfTheTimeOftheOffense}
                                            disabled
                                        />


                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group  mb-20">
                                        <label htmlFor="log-offense">
                                            Location of the offense:
                                        </label>
                                        <Input
                                            type="textarea"
                                            rows="4"
                                            name="st_offense_location"
                                            id="st_offense_location"
                                            value={data?.offenseLocation}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Limit">
                                            Legal Speed Limit for the location:
                                        </label>
                                        <Input
                                            type="text"
                                            name="st_speed_limit"
                                            id="st_speed_limit"
                                            value={data?.legitSpeedLimit}
                                            disabled
                                        />


                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30">Fine Information:</h3>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group  mb-20">
                                        <label htmlFor="reg-Amount">Fine Amount:</label>
                                        <Input
                                            type="number"
                                            name="st_fine_amount"
                                            id="st_fine_amount"
                                            value={data?.fineAmount}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-revenue">Due Date:</label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="st_duedate"
                                            placeholder="Date of birth"
                                            name="st_duedate"
                                            max={moment().format("YYYY-MM-DD")}
                                            value={moment(data?.dueDate).format("YYYY-MM-DD")}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Information">
                                            Information on how to contest the ticket:
                                        </label>
                                        <Input
                                            type="text"
                                            name="st_how_to_contest"
                                            id="st_how_to_contest"
                                            value={data?.howToContestTicketInfo}
                                            disabled
                                        />


                                    </div>


                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="log-Insurance-contract-copy-upload">
                                            Picture of the Speeding ticket
                                        </label>
                                        <img
                                            src={data?.ticketUploadId?.url == null || data?.ticketUploadId?.url == "" || data?.ticketUploadId?.url == undefined ? img1 : data?.ticketUploadId?.url}
                                            alt="Profile Image" />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Amount">Ticket Id:</label>
                                        <Input
                                            type="text"
                                            name="st_ticketId"
                                            id="st_ticketId"
                                            value={data?.ticketId}
                                            disabled
                                        />


                                    </div>
                                    <div className="form-group mb-20">
                                        <label htmlFor="log-Registration">
                                            Signature:
                                        </label>
                                        <Input
                                            type="text"
                                            name="st_signature"
                                            id="st_signature"
                                            value={data?.signature}
                                            disabled
                                        />


                                    </div>
                                </div>
                            </div>


                        </Row>
                    }

                    {data?.penaltyType == "CONGESTION_CHARGES" &&
                        <Row>
                            <h3 className="title mb-30 mt-10">
                                Patient Information:
                            </h3>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-name">Name:</label>
                                        <Input
                                            type="text"
                                            name="cc_name"
                                            id="cc_name"
                                            value={data?.name}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-email">Email:</label>
                                        <Input
                                            type="text"
                                            name="cc_email"
                                            id="cc_email"
                                            value={data?.email}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Number">
                                            Phone Number:
                                        </label>
                                        <Input
                                            type="number"
                                            name="cc_phone_number"
                                            id="cc_phone_number"
                                            value={data?.phoneNumber}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="reg-Vehicle">
                                            Vehicle Registration Number:
                                        </label>
                                        <Input
                                            type="text"
                                            name="cc_vehicle_registration_number"
                                            id="cc_vehicle_registration_number"
                                            value={data?.vehicleRegistrationNumber}
                                            disabled
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Postcode">Postcode:</label>
                                        <Input
                                            type="text"
                                            name="cc_postal_code"
                                            id="cc_postal_code"
                                            value={data?.postCode}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Address">Address:</label>
                                        <Input
                                            type="textarea"
                                            rows="4"
                                            name="cc_address"
                                            id="cc_address"
                                            value={data?.address}
                                            disabled
                                        />

                                    </div>
                                </div>
                            </div>

                            <h3 className="title mb-30 mt-10">
                                Charge Information:
                            </h3>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-name">
                                            Date and Time of the charge:
                                        </label>
                                        <Input
                                            className="form-control"
                                            type="date"
                                            id="cc_charge_date"
                                            name="cc_charge_date"
                                            placeholder="Select Date"
                                            value={ moment(data?.chargeDate).format("YYYY-MM-DD")}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-email">
                                            Location of the charge:
                                        </label>
                                        <Input
                                            type="text"
                                            name="cc_charge_location"
                                            id="cc_charge_location"
                                            value={data?.chargeLocation}
                                            disabled
                                        />


                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-Description">
                                            Description of the charge:
                                        </label>
                                        <Input
                                            type="textarea"
                                            rows="4"
                                            name="cc_charge_description"
                                            id="cc_charge_description"
                                            value={data?.chargeDescription}
                                            disabled
                                        />

                                    </div>
                                </div>
                            </div>

                            <h3 className="title mb-30 mt-10">
                                Payment Information:
                            </h3>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="reg-Amount">
                                            Charge Amount:
                                        </label>
                                        <Input
                                            type="number"
                                            name="cc_chargeAmount"
                                            id="cc_chargeAmount"
                                            value={data?.chargeAmount}
                                            disabled
                                        />


                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-Description">
                                            Due Date:
                                        </label>
                                        <Input
                                            className="form-control"
                                            type="date"
                                            id="cc_dueDate"
                                            name="cc_dueDate"
                                            placeholder="Select Date"
                                            value={ moment(data?.dueDate).format("YYYY-MM-DD")}
                                            disabled
                                        />

                                    </div>
                                </div>
                            </div>

                            <h3 className="title mb-30 mt-10">
                                Additional information:
                            </h3>

                            <div className="row mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Information">
                                            Information about the congestion zone and the
                                            regulations:
                                        </label>
                                        <Input
                                            type="textarea"
                                            rows="4"
                                            name="cc_congestionZoneAndRegulationsInfo"
                                            id="cc_congestionZoneAndRegulationsInfo"
                                            value={data?.congestionZoneAndRegulationsInfo}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-about">
                                            Information about the appeals process:
                                        </label>
                                        <Input
                                            type="textarea"
                                            rows="4"
                                            name="cc_appealProcessInfo"
                                            id="cc_appealProcessInfo"
                                            value={data?.appealProcessInfo}
                                            disabled
                                        />

                                    </div>
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-name">Signature:</label>
                                        <Input
                                            type="text"
                                            name="cc_signature"
                                            id="cc_signature"
                                            value={data?.signature}
                                            disabled
                                        />


                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-discounts">
                                            Information about discounts, exemptions or
                                            special permits:
                                        </label>
                                        <Input
                                            type="textarea"
                                            rows="4"
                                            name="cc_discountsExemptionsAndSpecialPermitInfo"
                                            id="cc_discountsExemptionsAndSpecialPermitInfo"
                                            value={data?.discountsExemptionsAndSpecialPermitInfo}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-vehicle">
                                            Information about the vehicle (e.g. make,
                                            model, color):
                                        </label>
                                        <Input
                                            type="textarea"
                                            rows="4"
                                            name="cc_vehicleInfo"
                                            id="cc_vehicleInfo"
                                            value={data?.vehicleInfo}
                                            disabled
                                        />

                                    </div>
                                </div>
                            </div>

                        </Row>
                    }
                </div>
            </Modal>
        </>
    ) : null;
}

export default PanaltyChargesModal;