import React from "react";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";

const Footer: React.FC = () => {

    return (
        <footer className="edu-footer footer-lighten bg-image footer-style-1">
        <div className="footer-top">
            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-4 col-md-6">
                        <div className="edu-footer-widget">
                            <div className="logo">
                            <a href="/home">
                                    <img className="logo-light img-logo" src="../assets/images/logo/logo-v1.png"
                                        alt="Corporate Logo" />
                                    <img className="logo-dark img-logo" src="../assets/images/logo/logo-v1.png"
                                        alt="Corporate Logo" />
                                </a>
                            </div>
                            <p className="description">Our goal is simple: to help you save time and stress by taking
                                care of your daily tasks and responsibilities so you can focus on your priorities
                                and goals.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="edu-footer-widget explore-widget">
                            <h4 className="widget-title">Services Menu</h4>
                            <div className="inner">
                                <ul className="footer-link link-hover">
                                    <li><a href="/customer/parking-ticket">Car Penalty charges</a></li>
                                    <li><a href="/customer/gp">Doctor Appointments</a></li>
                                    <li><a href="/customer/car-insurance">Insurance Services</a></li>
                                    <li><a href="/customer/water">Monthly Bills and Utilities Payments</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="edu-footer-widget quick-link-widget">
                            <h4 className="widget-title">Quick Links</h4>
                            <div className="inner">
                                <ul className="footer-link link-hover">
                                    <li><a href="/account">Membership Cancel</a></li>
                                    <li><a href="/account">Membership Billing</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <p>2022 @ all rights reserved by Groupdocu</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    )
}

export default Footer;