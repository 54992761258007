import moment from "moment";
import React from "react";
import { Col, Row, Modal, Input } from "reactstrap";

const PersonalRequestModel: React.FC<{ trigger?: boolean; setTrigger?: any; data: any }> = (props) => {
    const { data } = props;
    return (props.trigger) ? (
        <>
            <Modal isOpen={props.trigger} centered >
                <div className="modal-header">
                    <h5 className="text-center mt-2 mb-0 font-size-20">Customer Name : {data.fulName}</h5>
                    <button
                        type="button"
                        onClick={() => props.setTrigger(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span>&times;</span>
                    </button>
                </div>
                <div className="modal-body mt-4">

                    <Row>
                        <div className="row  justify-content-center">
                        <h3 className="title mb-30">Personal Information:</h3>
                            <div className="col-lg-6 mb-20">
                                <div className="form-group mb-20">
                                    <label htmlFor="reg-name">Full name:</label>
                                    <Input type="text" name="fulName" id="business-name"
                                        value={data?.fulName}
                                        disabled
                                    />

                                </div>

                                <div className="form-group mb-20">
                                    <label htmlFor="reg-Email">Email:</label>
                                    <Input type="text" name="email" id="reg-Email"
                                        value={data?.email}
                                        disabled
                                    />
                                </div>

                                <div className="form-group mb-20">
                                    <label htmlFor="reg-Number">Phone Number:</label>
                                    <Input type="number" name="phoneNo" id="phoneNo"
                                        value={data?.phoneNo}
                                        disabled
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="reg-Vehicle">Date of Birth:</label>
                                    <Input
                                        type="date"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Date of birth"
                                        value={ moment(data?.dob).format("YYYY-MM-DD")}
                                        disabled
                                        name="dob"
                                    />
                                </div>

                            </div>
                            <div className="col-lg-6">

                                <div className="form-group mb-20">
                                    <label htmlFor="reg-Postcode">Postcode:</label>
                                    <Input type="text" name="postCode" id="log-Postcode"
                                        value={data?.postCode}
                                        disabled
                                    />
                                </div>

                                <div className="form-group mb-20">
                                    <label htmlFor="log-Address">Address:</label>
                                    <Input type="textarea" name="address" id="log-Address"
                                        value={data?.address}
                                        disabled
                                    />
                                </div>


                            </div>
                        </div>



                        <hr className="mb-30 mt-10" />
                        <h3 className="title mb-30">Service Information:</h3>

                        <div className="row  mb-20 justify-content-center">
                            <div className="col-lg-6">
                                <div className="form-group mb-20">
                                    <label htmlFor="log-Property">Property Type:</label>
                                    <Input type="text" name="propertyType" id="log-Property"
                                        value={data?.propertyType}
                                        disabled
                                    />

                                </div>

                                <div className="form-group">
                                    <label htmlFor="reg-Move">Move-in date:</label>
                                    <Input
                                        className="form-control"
                                        type="date"
                                        id="reg-Move"
                                        name="moveinDate"
                                        placeholder="Select Date"
                                        value={ moment(data?.moveinDate).format("YYYY-MM-DD")}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-20">
                                    <label htmlFor="reg-Bedrooms">Number of Bedrooms:</label>
                                    <Input type="number" name="numberOfBedrooms" id="log-Bedrooms"
                                        value={data?.numberOfBedrooms}
                                        disabled

                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="reg-MPRN">Water meter Point Reference Number (MPRN):</label>
                                    <Input type="text" name="mPRN" id="log-MPRN"
                                        value={data?.mPRN}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>



                        <hr className="mt-20" />
                        <div className="row  mb-20 justify-content-center">
                            <h3 className="title mb-30">Additional Information:</h3>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="log-drive">Are you eligible for any discounts or benefits?</label>
                                </div>
                                <div className="card-heading mb-2">
                                    <div className="row">
                                        <div
                                            className="col"
                                            style={{ display: "flex", flexWrap: "wrap" }}
                                        >
                                            <div
                                                className="form-check check mb-3 "
                                                style={{ marginRight: "1.2rem" }}
                                            >
                                                <Input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="eligible_for_discounts_one"
                                                    name="eligible_for_discounts"
                                                    disabled
                                                    checked={data?.eligible.includes('PENSION_CREDIT')}
                                                    value="PENSION_CREDIT"
                                                />

                                                <label
                                                    className="form-check-label checkLable"
                                                    htmlFor="credit"
                                                >
                                                    Pension credit
                                                </label>
                                            </div>

                                            <div
                                                className="form-check check mb-3 "
                                                style={{ marginRight: "1.2rem" }}
                                            >
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="eligible_for_discounts_two"
                                                    disabled
                                                    name="eligible_for_discounts"
                                                    checked={data?.eligible.includes('INCOME_SUPPORT')}
                                                    value="INCOME_SUPPORT"
                                                />

                                                <label
                                                    className="form-check-label checkLable"
                                                    htmlFor="support"
                                                >
                                                    Income support
                                                </label>
                                            </div>

                                            <div className="form-check check mb-3 ">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="eligible_for_discounts_three"
                                                    disabled
                                                    name="eligible_for_discounts"
                                                    checked={data?.eligible.includes('JOBSEEKER_ALLOWANCE')}
                                                    value="JOBSEEKER_ALLOWANCE"
                                                />

                                                <label
                                                    className="form-check-label checkLable"
                                                    htmlFor="Allowance"
                                                >
                                                    Jobseeker's Allowance
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="log-drive">Are you the account holder?</label>
                                </div>
                                <div className="card-heading mb-2">
                                    <div className="row">
                                        <Input type="text" name="mPRN" id="log-MPRN"
                                            value={data?.accountHolder}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row g-5 mb-20 justify-content-center">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="reg-please">If yes, please provide proof of eligibility:</label>
                                    <Input type="text" name="proofOfEligibility" id="reg-please"
                                        value={data?.proofOfEligibility}
                                        disabled
                                    />

                                </div>

                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="reg-name">If no, please provide the name of the account holder:</label>
                                    <Input type="text" name="nameOfAccount" id="reg-name"
                                        value={data?.nameOfAccount}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>

                    </Row>
                </div>
            </Modal>
        </>
    ) : null;
}

export default PersonalRequestModel;