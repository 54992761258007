import React from "react";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";


const BusinessInsurance: React.FC = () => {

    return (
        <div className="login-page edu-footer footer-lighten bg-image footer-style-1">
            <section className="account-page-area section-gap-equal1">
                <div className="container position-relative">
                    <div className="row g-5 justify-content-center text-center ">
                        <div className="col-lg-6">
                            <a href="/customer/business-insurance-personal-request">
                                <div className="login-form-box registration-form">

                                    <div className="App">
                                        <div className="box">
                                            <div className="icon mt-10">
                                                <i className="icon-35 icon-size mb-10"></i>
                                            </div>
                                            <h3 className="title">Business Insurance Personal request submissions</h3>
                                            <p className="service-p mt-15">This is a process of submitting a request to an insurance company for coverage or a change in coverage for a business. This may include requesting a new policy, making changes to an existing policy, or submitting a claim for damages.</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-6">
                            <a href="/customer/business-insurance-quote">
                                <div className="login-form-box registration-form">
                                    <div className="App">
                                        <div className="box">
                                            <div className="icon mt-10">
                                                <i className="icon-36 icon-size mb-10"></i>
                                            </div>
                                            <h3 className="title">Business Insurance Quote</h3>
                                            <p className="service-p mt-15 mb-35">This is a process to receive an estimate of the cost of an insurance policy for a specific business. It is provided by an insurance company and takes into account various factors such as the type of business, its location, its annual revenue, and the coverage options selected.</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>

                    </div>
                </div>
            </section>
        </div >
    );
};

export default BusinessInsurance;