import moment from "moment";
import React from "react";
import { Col, Row, Modal, Input } from "reactstrap";

const SubscibeUserseModel: React.FC<{ trigger?: boolean; setTrigger?: any; data: any }> = (props) => {
    const { data } = props;
    return (props.trigger) ? (
        <>
            <Modal isOpen={props.trigger} centered >
                <div className="modal-header">
                    <h5 className="text-center mt-2 mb-0 font-size-20">Customer Name : {data.userName}</h5>
                    <button
                        type="button"
                        onClick={() => props.setTrigger(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span>&times;</span>
                    </button>
                </div>
                <div className="modal-body mt-4">

                    <Row>
                        <h3 className="title mb-30">Subscribe Users Information:</h3>

                        <div className="row  mb-20 justify-content-center">
                            <div className="col-lg-6">
                                <div className="form-group mb-20">
                                    <label htmlFor="reg-name">Name</label>
                                    <Input type="text" name="name" id="business-name"
                                        value={data?.userName}
                                        disabled
                                    />

                                </div>

                                <div className="form-group mb-20">
                                    <label htmlFor="reg-number">Email</label>
                                    <Input type="text" name="email" id="email"
                                        value={data?.email}
                                        disabled
                                    />


                                </div>

                                <div className="form-group mb-20">
                                    <label htmlFor="log-email">Post Code</label>
                                    <Input type="text" name="email" id="email"
                                        value={data?.postCode}
                                        disabled
                                    />


                                </div>

                                <div className="form-group">
                                    <label htmlFor="log-Industry">Address</label>
                                    <Input type="text" name="industry" id="log-Industry"
                                        value={data?.address}
                                        disabled
                                    />


                                </div>
                            </div>
                            <div className="col-lg-6">

                                <div className="form-group mb-20">
                                    <label htmlFor="reg-number">Phone Number</label>
                                    <Input type="textarea" name="address" id="log-Driving"
                                        value={data?.phoneNumber}
                                        disabled
                                    />


                                </div>

                                <div className="form-group mb-20">
                                    <label htmlFor="log-registration">Date of Birth</label>
                                    <Input type="text" name="regNum" id="log-registration"
                                        value={ moment(data.dateOfBirth).format('MM-DD-YYYY')}
                                        disabled
                                    />


                                </div>

                                <div className="form-group">
                                    <label htmlFor="log-type">Subscription Id</label>
                                    <Input type="text" name="type" id="log-type"
                                        value={data?.subscriptionId}
                                        disabled
                                    />

                                </div>
                            </div>
                        </div>

                    </Row>
                </div>
            </Modal>
        </>
    ) : null;
}

export default SubscibeUserseModel;