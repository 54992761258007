import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import $ from 'jquery';

const AdminNavBar: React.FC = () => {
  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };
  useEffect(() => {
    $('.hamberger-button').on('click', function (e) {
      $('.popup-mobile-menu').addClass('active');
    });

    $('.close-menu').on('click', function (e) {
      $('.popup-mobile-menu').removeClass('active');
      $('.popup-mobile-menu .mainmenu .has-droupdown > a').siblings('.submenu, .mega-menu').removeClass('active').slideUp('400');
      $('.popup-mobile-menu .mainmenu .has-droupdown > a').removeClass('open');
    });

    $('.popup-mobile-menu .mainmenu .has-droupdown > a').on('click', function (e) {
      e.preventDefault();
      $(this).siblings('.submenu, .mega-menu').toggleClass('active').slideToggle('400');
      $(this).toggleClass('open');
    });

    $('.popup-mobile-menu, .splash-mobile-menu .mainmenu li a').on('click', function (e) {
      e.target === this && $('.popup-mobile-menu').removeClass('active') && $('.popup-mobile-menu .mainmenu .has-droupdown > a').siblings('.submenu, .mega-menu').removeClass('active').slideUp('400') && $('.popup-mobile-menu .mainmenu .has-droupdown > a').removeClass('open');
    });
  }, []);

  return (
    <header className="edu-header header-style-1 header-fullwidth">
      <div id="edu-sticky-placeholder"></div>
      <div className="header-mainmenu">
        <div className="container-fluid">
          <div className="header-navbar">
            <div className="header-brand">
              <div className="logo">
                <a href="/dashboard">
                  <img
                    className="logo-light img-logo"
                    src="../assets/images/logo/logo-v1.png"
                    alt="Corporate Logo"
                  />
                  <img
                    className="logo-dark img-logo"
                    src="../assets/images/logo/logo-v1.png"
                    alt="Corporate Logo"
                  />
                </a>
              </div>
            </div>
            <div className="header-mainnav">
              <nav className="mainmenu-nav">
                <ul className="mainmenu">
                  <li>
                    <NavLink to="/dashboard">Dashboard</NavLink>
                  </li>

                  <li>
                    <NavLink to="/user-management">User management</NavLink>
                  </li>
                  <li className="has-droupdown">
                    <NavLink to="/personal-request-submissions">New Submissions</NavLink>

                    <ul className="mega-menu mega-menu1">
                      <li>
                        <ul className="submenu mega-sub-menu-01">
                          <li>
                            <NavLink to="/personal-request-submissions">
                              Personal Request Submissions
                            </NavLink>
                          </li>

                          <li>
                            <NavLink to="/vehicle-insurance">
                              Vehicle Insurance
                            </NavLink>
                          </li>

                          <li>
                            <NavLink to="/personal-request-insurance">
                              Personal Request Insurance
                            </NavLink>
                          </li>

                          <li>
                            <NavLink to="/business-insurance-quotes">
                              Business Insurance
                            </NavLink>
                          </li>

                          <li>
                            <NavLink to="/business-home-quotes">
                              Home Insurance
                            </NavLink>
                          </li>

                          <li>
                            <NavLink to="/doctor-appointments">
                              Doctor Appointment
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/penalty-charges">
                              Penalty Charges
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/utilities">
                              Utilities
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>


                  <li className="has-droupdown">
                    <NavLink to="/completed-personal-request-submissions">Completed Submissions</NavLink>

                    <ul className="mega-menu mega-menu1">
                      <li>
                        <ul className="submenu mega-sub-menu-01">
                          <li>
                            <NavLink to="/completed-personal-request-submissions">
                              Personal Request Submissions
                            </NavLink>
                          </li>


                          <li>
                            <NavLink to="/completed-vehicle-insurance">
                              Vehicle Insurance
                            </NavLink>
                          </li>

                          <li>
                            <NavLink to="/completed-personal-request-insurance">
                              Personal Request Insurance
                            </NavLink>
                          </li>

                          <li>
                            <NavLink to="/completed-business-insurance-quote">
                              Business Insurance
                            </NavLink>
                          </li>

                          <li>
                            <NavLink to="/completed-home-insurance-quote">
                              Home Insurance
                            </NavLink>
                          </li>

                          <li>
                            <NavLink to="/completed-doctor-appointments">
                              Doctor Appointment
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/completed-penalty-charges">
                              Penalty Charges
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/completed-utilities">
                              Utilities
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  <li className="has-droupdown">
                    <NavLink to="/subscription-users">Payments</NavLink>

                    <ul className="mega-menu mega-menu1">
                      <li>
                        <ul className="submenu mega-sub-menu-01">
                          <li>
                            <NavLink to="/subscription-users">
                              Subscription
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/single-payments">
                              Single Payments
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>

                  </li>

                </ul>
              </nav>
            </div>
            <div className="header-right">
              <ul className="header-action">
                <li className="header-btn">
                  <button className="edu-btn btn-medium" onClick={logout}>Log Out <i className="ri-logout-box-r-line" style={{ fontSize: "13px" }}></i></button>
                </li>
                <li className="mobile-menu-bar d-block d-xl-none">
                  <button className="hamberger-button">
                    <i className="icon-54"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="popup-mobile-menu">
        <div className="inner">
          <div className="header-top">
            <div className="logo">
              <a href="index.html">
                <img
                  className="logo-light img-logo"
                  src="../assets/images/logo/logo-v1.png"
                  alt="Corporate Logo"
                />
                <img
                  className="logo-dark img-logo"
                  src="../assets/images/logo/logo-v1.png"
                  alt="Corporate Logo"
                />
              </a>
            </div>
            <div className="close-menu">
              <button className="close-button">
                <i className="icon-73"></i>
              </button>
            </div>
          </div>
          <ul className="mainmenu">
            <li>
              <NavLink to="/dashboard">Dashboard</NavLink>
            </li>

            <li>
              <NavLink to="/user-management">User management</NavLink>
            </li>
            <li className="has-droupdown">
              <NavLink to="/personal-request-submissions">New Payments</NavLink>

              <ul className="mega-menu mega-menu1">
                <li>
                  <ul className="submenu mega-sub-menu-01">
                    <li>
                      <NavLink to="/personal-request-submissions">
                        Personal Request Submissions
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/vehicle-insurance">
                        Vehicle Insurance
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/personal-request-insurance">
                        Personal Request Insurance
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/business-insurance-quotes">
                        Business Insurance
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/business-home-quotes">
                        Home Insurance
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/doctor-appointments">
                        Doctor Appointment
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/penalty-charges">
                        Penalty Charges
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/utilities">
                        Utilities
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>


            <li className="has-droupdown">
              <NavLink to="/completed-personal-request-submissions">Completed</NavLink>

              <ul className="mega-menu mega-menu1">
                <li>
                  <ul className="submenu mega-sub-menu-01">
                    <li>
                      <NavLink to="/completed-personal-request-submissions">
                        Personal Request Submissions
                      </NavLink>
                    </li>


                    <li>
                      <NavLink to="/completed-vehicle-insurance">
                        Vehicle Insurance
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/completed-personal-request-insurance">
                        Personal Request Insurance
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/completed-business-insurance-quote">
                        Business Insurance
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/completed-home-insurance-quote">
                        Home Insurance
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/completed-doctor-appointments">
                        Doctor Appointment
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/completed-penalty-charges">
                        Penalty Charges
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/completed-utilities">
                        Utilities
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li className="has-droupdown">
              <NavLink to="/subscription-users">Payments</NavLink>

              <ul className="mega-menu mega-menu1">
                <li>
                  <ul className="submenu mega-sub-menu-01">
                    <li>
                      <NavLink to="/subscription-users">
                        Subscription
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/single-payments">
                        Single Payments
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>

            </li>

            <li>
              {" "}
              <a href="#" className="edu-btn">Log Out <i className="ri-logout-box-r-line" style={{ fontSize: "13px" }}></i></a>
            </li>
          </ul>
        </div>
      </div >

      <div className="edu-search-popup">
        <div className="content-wrap">
          <div className="site-logo">
            <img
              className="logo-light"
              src="../assets/images/logo/logo-dark.png"
              alt="Corporate Logo"
            />
            <img
              className="logo-dark"
              src="../assets/images/logo/logo-white.png"
              alt="Corporate Logo"
            />
          </div>
          <div className="close-button">
            <button className="close-trigger">
              <i className="icon-73"></i>
            </button>
          </div>
          <div className="inner">
            <form className="search-form" action="#">
              <input
                type="text"
                className="edublink-search-popup-field"
                placeholder="Search Here..."
              />
              <button className="submit-button">
                <i className="icon-2"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </header >
  );
};

export default AdminNavBar;
