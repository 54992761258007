import React, { useState, useContext} from "react";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";
import { useFormik } from "formik";
import * as Yup from "yup"
import { useTranslation } from "react-i18next";
import { Form, FormFeedback, Input } from "reactstrap";
import { HomeQuote } from "../../models/homeInsuranceQuote";
import Swal from "sweetalert2";
import InsuranceService from "../../servises/InsuranceService";
import UserContext from "../context/UserContext";

const HomeInsuranceQuote: React.FC = () => {
    const [stepNumber, setStepNumber] = useState(1);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({})
    const [user, setUser] = useContext(UserContext);

    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (event.target.checked) {
            setSelectedOptions([...selectedOptions, value]);
        } else {
            setSelectedOptions(selectedOptions.filter((option) => option !== value));
        }
    };

    const submitHomeInsuranceQuotetInfo = (values: HomeQuote) => {
        if (user?.subscriptionStatus==="CANCELLED" || !user?.subscriptionId) {
            Swal.fire({
                icon: "warning",
                title: "in order to be able receive our services you will have to be subscribe",
                confirmButtonText: "Subscribe",
                confirmButtonColor: "#FD7F00",
                showCancelButton: true,
                cancelButtonText: "Cancel",
              }).then((result) => {
                if (result.isConfirmed) {
                  // Navigate to subscription page
                  window.location.href = "/account";
                }
              });
              return; // Stop further execution
          }else{
        setIsLoading(true);
        InsuranceService.submitHomeInsuranceQuotetInfo(values).then((res: any) => {
            if (res.success) {
                setIsLoading(false);
                Swal.fire({ title: "Informations Added" });
            } else {
                setIsLoading(false);
                Swal.fire({ icon: "error", title: res.error, confirmButtonColor: "#FD7F00" });
            }
        });
    }
    };

    const validationStep2 = useFormik({
        enableReinitialize: true,

        initialValues: {
            address: "",
            postalCode: "",
            propertyType: "",
            bedRoomCount: "",
            livingRoomCount: "",
            kitchenCount: "",
            otherRoomsCount: ""

        },
        validationSchema: Yup.object({
            address: Yup.string().required(t<string>("Please Enter address")),
            postalCode: Yup.string().required(t<string>("Please Enter postalCode")).matches(/^\d+$/, 'No of Employees Should be a Number'),
            propertyType: Yup.string().required(t<string>("Please Enter propertyType")),
            bedRoomCount: Yup.string().required(t<string>("Please Enter bedRoomCount")),
            livingRoomCount: Yup.string().required(t<string>("Please Enter livingRoomCount")),
            kitchenCount: Yup.string().required(t<string>("Please Enter kitchenCount")),
            otherRoomsCount: Yup.string().required(t<string>("Please Enter otherRoomsCount")),
        }),
        onSubmit: (values, onSubmitProps) => {
            setStepNumber(stepNumber + 1);
            setFormData(pre => ({
                ...pre,
                ...values
            }))
            onSubmitProps.resetForm();
        },
    });


    const validationStep3 = useFormik({
        enableReinitialize: true,

        initialValues: {

            heatingType: "",
            propertyUseFor: [],
            year: "",
            roofFlat: "",

        },

        onSubmit: (values, onSubmitProps) => {
            const payload = {
                ...formData,
                ...values,
                propertyUseFor: selectedOptions
            }
            submitHomeInsuranceQuotetInfo(payload)
            onSubmitProps.resetForm();
            setSelectedOptions([]);
        },
    });


    const registerSteps = () => {
        return (
            <div
                className="d-flex nav-dots-container justify-content-between align-items-center"
                style={{ width: "70px" }}
            >
                <span className={`${stepNumber == 1 && "bg-secondary"}`}></span>
                <span className={`${stepNumber == 2 && "bg-secondary"}`}></span>
            </div>
        );
    };



    return (
        <div className="login-page edu-footer footer-lighten bg-image footer-style-1">
            <section className="account-page-area section-gap-equal1">
                <div className="container-custom position-relative">
                    <div className="row g-5 justify-content-center">
                        <div className="col-lg-8">
                            <div className="login-form-box registration-form">
                                <div className="App">
                                    <div className="box">
                                        {stepNumber == 1 && (
                                            <div>
                                                <Form
                                                    className="form-horizontal"
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validationStep2.handleSubmit();
                                                        return false;
                                                    }}
                                                >

                                                    <h3 className="title mb-20 mt-10">You should answer all questions honestly and to the best of your knowledge. Otherwise, your level of coverage and future claims could be affected.</h3>
                                                    <p className="themeColor mb-20 font-16 mt-3">Insurance quotes life admin services provide assistance in obtaining and comparing insurance quotes. They handle tasks such as gathering necessary information, requesting quotes from multiple insurance providers, reviewing and presenting the options, and providing guidance to help individuals make informed decisions about their insurance coverage.
                                                    </p>
                                                    <h3 className="title mb-30 mt-10">Your address</h3>

                                                    <div className="row g-5 mb-20 justify-content-center">
                                                        <div className="col-lg-12">
                                                            <div className="form-group mb-20">
                                                                <label htmlFor="reg-name">Address *</label>
                                                                <Input type="text" name="address" id="address"
                                                                    value={validationStep2.values.address}
                                                                    onChange={validationStep2.handleChange}
                                                                    invalid={validationStep2.touched.address && validationStep2.errors.address ? true : false}
                                                                />
                                                                <FormFeedback>
                                                                    {validationStep2.errors.address}
                                                                </FormFeedback>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="form-group mb-20">
                                                                <label htmlFor="reg-Postcode">Postcode</label>
                                                                <Input type="text" name="postalCode" id="postalCode"
                                                                    value={validationStep2.values.postalCode}
                                                                    onChange={validationStep2.handleChange}
                                                                    invalid={validationStep2.touched.postalCode && validationStep2.errors.postalCode ? true : false}
                                                                />
                                                                <FormFeedback>
                                                                    {validationStep2.errors.postalCode}
                                                                </FormFeedback>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <h3 className="title mb-30 mt-10">About your property</h3>

                                                    <div className="row g-5 mb-20 justify-content-center">
                                                        <div className="col-lg-4">
                                                            <div className="form-group">
                                                                <label htmlFor="log-Transmission">What type of property is it?</label>
                                                            </div>
                                                            <div className="card-heading mb-2">
                                                                <div className="row">
                                                                    <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="propertyType"
                                                                                id="HOUSE"
                                                                                value="HOUSE"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.propertyType}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="HOUSE">
                                                                                HOUSE
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="propertyType"
                                                                                id="FLAT"
                                                                                value="FLAT"
                                                                                onChange={validationStep2.handleChange}

                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.propertyType}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="FLAT">
                                                                                FLAT
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="propertyType"
                                                                                id="BUNGALOW"
                                                                                value="BUNGALOW"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.propertyType}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="BUNGALOW">
                                                                                BUNGALOW
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="propertyType"
                                                                                id="TOWN HOUSE"
                                                                                value="TOWN HOUSE"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.propertyType}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="TOWN HOUSE">
                                                                                TOWN HOUSE
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="propertyType"
                                                                                id="BEDSIT"
                                                                                value="BEDSIT"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.propertyType}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="BEDSIT">
                                                                                BEDSIT
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="propertyType"
                                                                                id="MAISONETTE"
                                                                                value="MAISONETTE"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.propertyType}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="MAISONETTE">
                                                                                MAISONETTE
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="propertyType"
                                                                                id="FARM HOUSE"
                                                                                value="FARM HOUSE"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.propertyType}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="FARM HOUSE">
                                                                                FARM HOUSE
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-lg-4">
                                                            <div className="form-group">
                                                                <label htmlFor="log-Transmission">How many bedrooms does the property have?</label>
                                                            </div>
                                                            <div className="card-heading mb-2">
                                                                <div className="row">
                                                                    <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="bedRoomCount"
                                                                                id="1"
                                                                                value="1"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.bedRoomCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="1">
                                                                                1
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="bedRoomCount"
                                                                                id="2"
                                                                                value="2"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.bedRoomCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="2">
                                                                                2
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="bedRoomCount"
                                                                                id="3"
                                                                                value="3"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.bedRoomCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="3">
                                                                                3
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="bedRoomCount"
                                                                                id="4"
                                                                                value="4"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.bedRoomCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="4">
                                                                                4
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="bedRoomCount"
                                                                                id="5"
                                                                                value="5"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.bedRoomCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="5">
                                                                                5
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="bedRoomCount"
                                                                                id="6"
                                                                                value="6"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.bedRoomCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="6">
                                                                                6
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="bedRoomCount"
                                                                                id="7"
                                                                                value="7"
                                                                                onChange={validationStep2.handleChange}

                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.bedRoomCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="7">
                                                                                7
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="bedRoomCount"
                                                                                id="8"
                                                                                value="8"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.bedRoomCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="8">
                                                                                8
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="bedRoomCount"
                                                                                id="9"
                                                                                value="9"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.bedRoomCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="9">
                                                                                9
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="bedRoomCount"
                                                                                id="10"
                                                                                value="10"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.bedRoomCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="10">
                                                                                10
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-20">This includes rooms that were bedrooms but are now used for something else (e.g. as a study/office)</p>
                                                                </div>
                                                            </div>


                                                            <div className="form-group">
                                                                <label htmlFor="log-Transmission">How many living rooms?</label>
                                                            </div>
                                                            <div className="card-heading mb-2">
                                                                <div className="row">
                                                                    <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="livingRoomCount"
                                                                                id="livingRoomCount0"
                                                                                value="0"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.livingRoomCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="livingRoomCount0">
                                                                                0
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="livingRoomCount"
                                                                                id="livingRoomCount1"
                                                                                value="1"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.livingRoomCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="livingRoomCount1">
                                                                                1
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="livingRoomCount"
                                                                                id="livingRoomCount2"
                                                                                value="2"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.livingRoomCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="livingRoomCount2">
                                                                                2
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="livingRoomCount"
                                                                                id="livingRoomCount3"
                                                                                value="3"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.livingRoomCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="livingRoomCount3">
                                                                                3+
                                                                            </label>
                                                                        </div>


                                                                    </div>
                                                                    <p className="mb-20">This includes rooms that were bedrooms but are now used for something else (e.g. as a study/office)</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4">
                                                            <div className="form-group">
                                                                <label htmlFor="log-Transmission">How many kitchens?</label>
                                                            </div>
                                                            <div className="card-heading mb-2">
                                                                <div className="row">
                                                                    <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="kitchenCount"
                                                                                id="kitchenCount0"
                                                                                value="0"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.kitchenCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="kitchenCount0">
                                                                                0
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="kitchenCount"
                                                                                id="kitchenCount1"
                                                                                value="1"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.kitchenCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="kitchenCount1">
                                                                                1
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="kitchenCount"
                                                                                id="kitchenCount2"
                                                                                value="2"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.kitchenCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="kitchenCount2">
                                                                                2
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="kitchenCount"
                                                                                id="kitchenCount3"
                                                                                value="3"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.kitchenCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="kitchenCount3">
                                                                                3+
                                                                            </label>
                                                                        </div>


                                                                    </div>
                                                                    <p className="mb-20">This includes kitchens that are part of another room (e.g. open-plan lounge and kitchen).</p>
                                                                </div>
                                                            </div>


                                                            <div className="form-group">
                                                                <label htmlFor="log-Transmission">How many other rooms?</label>
                                                            </div>
                                                            <div className="card-heading mb-2">
                                                                <div className="row">
                                                                    <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="otherRoomsCount"
                                                                                id="other1"
                                                                                value="1"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.otherRoomsCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="other1">
                                                                                1
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="otherRoomsCount"
                                                                                id="other2"
                                                                                value="2"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.otherRoomsCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="other2">
                                                                                2
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="otherRoomsCount"
                                                                                id="other3"
                                                                                value="3"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.otherRoomsCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="other3">
                                                                                3
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="otherRoomsCount"
                                                                                id="other4"
                                                                                value="4"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.otherRoomsCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="other4">
                                                                                4
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="otherRoomsCount"
                                                                                id="other5"
                                                                                value="5"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.otherRoomsCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="other5">
                                                                                5
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="otherRoomsCount"
                                                                                id="other6"
                                                                                value="6"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.otherRoomsCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="other6">
                                                                                6
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="otherRoomsCount"
                                                                                id="other7"
                                                                                value="7"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.otherRoomsCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="other7">
                                                                                7
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="otherRoomsCount"
                                                                                id="other8"
                                                                                value="8"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.otherRoomsCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="other8">
                                                                                8
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="otherRoomsCount"
                                                                                id="other9"
                                                                                value="9"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.otherRoomsCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="other9">
                                                                                9
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                            <Input
                                                                                className="form-check-Input"
                                                                                type="radio"
                                                                                name="otherRoomsCount"
                                                                                id="other10"
                                                                                value="10"
                                                                                onChange={validationStep2.handleChange}
                                                                            />
                                                                            <FormFeedback>
                                                                                {validationStep2.errors.otherRoomsCount}
                                                                            </FormFeedback>

                                                                            <label className="form-check-label" htmlFor="other10">
                                                                                10
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-20">e.g. utility rooms, cloak rooms (not including hallways/corridors).</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="d-flex justify-content-between mt-4">
                                                        {registerSteps()}
                                                        <button
                                                            className="btn colorchangeLog leadMargin edu-btn btn-medium"
                                                            type="submit"
                                                        >
                                                            {t<string>("Next")}
                                                        </button>
                                                    </div>
                                                </Form>
                                            </div>
                                        )
                                        }


                                        {
                                            stepNumber == 2 && (
                                                <div>
                                                    <Form
                                                        className="form-horizontal"
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            validationStep3.handleSubmit();
                                                            return false;
                                                        }}
                                                    >


                                                        <h3 className="title mb-30 mt-10">Other details</h3>

                                                        <div className="row g-5 mb-20 justify-content-center">
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="log-Transmission">What type of heating does the property have?</label>
                                                                </div>
                                                                <div className="card-heading mb-2">
                                                                    <div className="row">
                                                                        <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="heatingType"
                                                                                    id="GAS/NO HOT WATER TANK"
                                                                                    value="GAS/NO HOT WATER TANK"
                                                                                    onChange={validationStep3.handleChange}
                                                                                />
                                                                                <FormFeedback>
                                                                                    {validationStep3.errors.heatingType}
                                                                                </FormFeedback>

                                                                                <label className="form-check-label" htmlFor="GAS/NO HOT WATER TANK">
                                                                                    GAS/NO HOT WATER TANK
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="heatingType"
                                                                                    id="GAS/HOT WATER TANK"
                                                                                    value="GAS/HOT WATER TANK"
                                                                                    onChange={validationStep3.handleChange}
                                                                                />
                                                                                <FormFeedback>
                                                                                    {validationStep3.errors.heatingType}
                                                                                </FormFeedback>

                                                                                <label className="form-check-label" htmlFor="GAS/HOT WATER TANK">
                                                                                    GAS/HOT WATER TANK
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="heatingType"
                                                                                    id="OIL"
                                                                                    value="OIL"
                                                                                    onChange={validationStep3.handleChange}
                                                                                />
                                                                                <FormFeedback>
                                                                                    {validationStep3.errors.heatingType}
                                                                                </FormFeedback>

                                                                                <label className="form-check-label" htmlFor="OIL">
                                                                                    OIL
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="heatingType"
                                                                                    id="ELECTRIC"
                                                                                    value="ELECTRIC"
                                                                                    onChange={validationStep3.handleChange}
                                                                                />
                                                                                <FormFeedback>
                                                                                    {validationStep3.errors.heatingType}
                                                                                </FormFeedback>

                                                                                <label className="form-check-label" htmlFor="ELECTRIC">
                                                                                    ELECTRIC
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="heatingType"
                                                                                    id="MULTI_FUEL"
                                                                                    value="MULTI-FUEL"
                                                                                    onChange={validationStep3.handleChange}
                                                                                />
                                                                                <FormFeedback>
                                                                                    {validationStep3.errors.heatingType}
                                                                                </FormFeedback>

                                                                                <label className="form-check-label" htmlFor="MULTI_FUEL">
                                                                                    MULTI-FUEL
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="heatingType"
                                                                                    id="OTHER"
                                                                                    value="OTHER"
                                                                                    onChange={validationStep3.handleChange}
                                                                                />
                                                                                <FormFeedback>
                                                                                    {validationStep3.errors.heatingType}
                                                                                </FormFeedback>

                                                                                <label className="form-check-label" htmlFor="OTHER">
                                                                                    OTHER
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="heatingType"
                                                                                    id="SOLID_FUEL"
                                                                                    value="SOLID_FUEL"
                                                                                    onChange={validationStep3.handleChange}
                                                                                />
                                                                                <FormFeedback>
                                                                                    {validationStep3.errors.heatingType}
                                                                                </FormFeedback>

                                                                                <label className="form-check-label" htmlFor="SOLID_FUEL">
                                                                                    SOLID FUEL
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="heatingType"
                                                                                    id="NO HEATING"
                                                                                    value="NO HEATING"
                                                                                    onChange={validationStep3.handleChange}
                                                                                />
                                                                                <FormFeedback>
                                                                                    {validationStep3.errors.heatingType}
                                                                                </FormFeedback>

                                                                                <label className="form-check-label" htmlFor="NO HEATING">
                                                                                    NO HEATING
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="log-Transmission">Is the property used for business?</label>
                                                                </div>
                                                                <div className="card-heading mb-2">
                                                                    <div className="row">
                                                                        <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                            <div className="form-check check mb-3" style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="checkbox"
                                                                                    name="NO_BUSINESS_USE"
                                                                                    id="NO BUSINESS USE"
                                                                                    value="NO_BUSINESS_USE"
                                                                                    onChange={handleCheckboxChange}
                                                                                    checked={selectedOptions.includes('NO_BUSINESS_USE')}
                                                                                />

                                                                                <label className="form-check-label checkLable" htmlFor="NO BUSINESS USE">
                                                                                    NO BUSINESS USE
                                                                                </label>
                                                                            </div>


                                                                            <div className="form-check check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="checkbox"
                                                                                    name="CLERICAL_ONLY"
                                                                                    id="CLERICAL ONLY"
                                                                                    value="CLERICAL_ONLY"
                                                                                    onChange={handleCheckboxChange}
                                                                                    checked={selectedOptions.includes('CLERICAL_ONLY')}
                                                                                />

                                                                                <label className="form-check-label checkLable" htmlFor="CLERICAL ONLY">
                                                                                    CLERICAL ONLY
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="checkbox"
                                                                                    name="CLERICAL_WITH_VISITORS"
                                                                                    id="CLERICAL WITH VISITORS"
                                                                                    value="CLERICAL_WITH_VISITORS"
                                                                                    onChange={handleCheckboxChange}
                                                                                    checked={selectedOptions.includes('CLERICAL_WITH_VISITORS')}
                                                                                />

                                                                                <label className="form-check-label checkLable" htmlFor="CLERICAL WITH VISITORS">
                                                                                    CLERICAL WITH VISITORS
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="checkbox"
                                                                                    name="OTHER_BUSINESS_USE"
                                                                                    id="OTHER BUSINESS USE"
                                                                                    value="OTHER_BUSINESS_USE"
                                                                                    onChange={handleCheckboxChange}
                                                                                    checked={selectedOptions.includes('OTHER_BUSINESS_USE')}
                                                                                />

                                                                                <label className="form-check-label checkLable" htmlFor="OTHER BUSINESS USE">
                                                                                    OTHER BUSINESS USE
                                                                                </label>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4">
                                                                <div className="form-group mb-20">
                                                                    <label htmlFor="reg-Last">Approximately, what year was the property built?</label>
                                                                    <Input type="number" name="year" id="reg-Last"
                                                                        value={validationStep3.values.year}
                                                                        onChange={validationStep3.handleChange}
                                                                        invalid={validationStep3.touched.year && validationStep3.errors.year ? true : false}
                                                                    />
                                                                    <FormFeedback>
                                                                        {validationStep3.errors.year}
                                                                    </FormFeedback>
                                                                </div>

                                                                <div className="form-group">
                                                                    <label htmlFor="log-Doors">Is any part of the property's roof flat?</label>
                                                                </div>
                                                                <div className="card-heading mb-2">
                                                                    <div className="row">
                                                                        <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="roofFlat"
                                                                                    id="birth"
                                                                                    value="YES"
                                                                                    onChange={validationStep3.handleChange}
                                                                                />
                                                                                <FormFeedback>
                                                                                    {validationStep3.errors.roofFlat}
                                                                                </FormFeedback>

                                                                                <label className="form-check-label" htmlFor="birth">
                                                                                    YES
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                                <Input
                                                                                    className="form-check-Input"
                                                                                    type="radio"
                                                                                    name="roofFlat"
                                                                                    id="birthNO"
                                                                                    value="NO"
                                                                                    onChange={validationStep3.handleChange}
                                                                                />
                                                                                <FormFeedback>
                                                                                    {validationStep3.errors.roofFlat}
                                                                                </FormFeedback>

                                                                                <label className="form-check-label" htmlFor="birthNO">
                                                                                    NO
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p className="mb-20">This includes any outbuildings or garages the property might have.</p>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex justify-content-between mt-4">
                                                            <button
                                                                className="btn colorchangeback back-btn btn-medium"
                                                                type="submit"
                                                                onClick={() => setStepNumber(stepNumber - 1)}
                                                            >
                                                                {t<string>("Previous")}
                                                            </button>
                                                            {registerSteps()}
                                                            <button
                                                                className="btn colorchangeLog leadMargin edu-btn btn-medium"
                                                                type="submit"
                                                            >
                                                                {t<string>("Finish")}
                                                            </button>
                                                        </div>
                                                    </Form>
                                                </div>
                                            )
                                        }



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    );
};

export default HomeInsuranceQuote;
