import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { RouteName } from "./RouteName";
import Auth from "./components/common/Auth";
import NavBar from "./components/common/Navbar";
import Signup from "./components/common/Signup";
import InsuranceQuote from "./components/common/InsuranceQuote";
import HomeInsurance from "./components/common/HomeInsurance";
import BusinessInsurance from "./components/common/BusinessInsurance";
import BusinessInsuranceQuote from "./components/common/BusinessInsuranceQuote";
import GP from "./components/common/GP";
import ParkingTicket from "./components/common/ParkingTicket";
import SpeedingTicket from "./components/common/SpeedingTicket";
import CongestionCharges from "./components/common/CongestionCharges";
import Water from "./components/common/Water";
import TVLicence from "./components/common/TVlicence";
import Footer from "./components/common/Footer";
import GasAndElectricity from "./components/common/ElectricityBill";
import CounsilTaxBills from "./components/common/CouncilTaxBills";
import MYACCOUNT from "./components/common/MyAccount";
import Account from "./components/common/MyAccount";
import Profile from "./components/common/Profile";
import ChangePassword from "./components/common/ChangePassword";
import PersonalRequest from "./components/common/PersonalRequest";
import "./components/assets/css/home.css";
import "./components/assets/css/bootstrap.min.css";
import "./components/assets/css/app.css";
import "./components/assets/css/media.css";
import "./components/assets/css/style12.css";
import "./components/assets/css/icomoon.css";
import CarInsurancePersonalRequest from "./components/common/CarInsurancePersonalRequest";
import Account2 from "./components/common/MyAccount";
import VerifyRole from "./components/common/VerifyRole";
import { Role } from "./models/Role";
import PrivateHospital from "./components/common/PrivateHospital";
import ForgetPassword from "./components/common/Forget_password";
import GasBill from "./components/common/GasBill";
import ServiceLogin from "./components/common/ServicesLogin";
import HomeInsuranceQuote from "./components/common/HomeInsuranceQuote";
import ElectricityBill from "./components/common/ElectricityBill";
import CarInsurance from "./components/common/CarInsurance";
import BusinessInsurancePersonalRequest from "./components/common/BusinessPersonalRequest";
import AdminNavBar from "./components/admin/AdminNavbar";
import AdminDashboard from "./components/admin/Dashboard";
import ContentLayout from "./components/common/ContentLayout";
import AdminUserManagement from "./components/admin/UserManagement";
import AdminInsurancePayments from "./components/admin/AdminNewInsurance";
import AdminDoctorAppointments from "./components/admin/AdminDoctorAppointments";
import AdminPaneltyCharges from "./components/admin/AdminPenaltyCharges";
import AdminUtilities from "./components/admin/AdminUtilities";
import AdminCompletedUtilities from "./components/admin/CompletedUtilities";
import AdminCompletedPaneltyCharges from "./components/admin/CompletedPenaltyCharges";
import AdminCompletedDoctorAppointments from "./components/admin/CompletedDoctorAppointments";
import HomeInsurancePersonalRequest from "./components/common/HomeInsurancePersonalRequest";
import AdminCompletedVehicleInsurance from "./components/admin/CompletedVehicleInsurance";
import AdminPersonalRequestPayments from "./components/admin/AdminPersonalRequest";
import AdminCompletedPersonalRequestPayments from "./components/admin/CompletedPersonalRequest";
import AdminBusinessInsurancePayments from "./components/admin/AdminBusinessInsurance";
import AdminCompletedBusinessInsurancePayments from "./components/admin/CompletedBusinessInsurance";
import AdminCompletedHomeInsurancePayments from "./components/admin/CompletedHomeInsurance";
import AdminHomeInsurancePayments from "./components/admin/AdminHomeInsurance";
import AdminEditUser from "./components/admin/EditUser";
import PersonalRequestSubmissions from "./components/common/PersonalRequest";
import AdminCompletedPersonalRequestSubmissionsPayments from "./components/admin/CompletedPersonalSubmissions";
import AdminPersonalRequestSubmissionsPayments from "./components/admin/AdminPersonalSubmissions";
import AdminSubscriptionUsers from "./components/admin/AdminSubscriptionDetails";
import AdminSinglePaymentUsers from "./components/admin/AdminSinglePaymentUsers";
import AdminViewUsersModal from "./components/popup/ViewUserModal";
const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path={RouteName.SIGNUP}>
          <NavBar />
          <Signup />
          <Footer />
        </Route>

        <Route path={RouteName.FORGETPASSWORD}>
          <NavBar />
          <ForgetPassword />
          <Footer />
        </Route>

        <Route path={RouteName.SERVICES}>
          <NavBar />
          <ServiceLogin />
          <Footer />
        </Route>

        <Route path={RouteName.CAR_INSURANCE_COMMON}>
          <NavBar />
          <ServiceLogin />
          <Footer />
        </Route>

        <Route path={RouteName.INSURANCE_QUOTE_COMMON}>
          <NavBar />
          <ServiceLogin />
          <Footer />
        </Route>

        <Route path={RouteName.HOME_INSURANCE_COMMON}>
          <NavBar />
          <ServiceLogin />
          <Footer />
        </Route>

        <Route path={RouteName.BUSINESS_INSURANCE_COMMON}>
          <NavBar />
          <ServiceLogin />
          <Footer />
        </Route>

        <Route path={RouteName.BUSINESS_INSURANCE_QUOTE_COMMON}>
          <NavBar />
          <ServiceLogin />
          <Footer />
        </Route>

        <Route path={RouteName.GP_COMMON}>
          <NavBar />
          <ServiceLogin />
          <Footer />
        </Route>

        <Route path={RouteName.PRIVATE_HOSPITAL_COMMON}>
          <NavBar />
          <ServiceLogin />
          <Footer />
        </Route>

        <Route path={RouteName.PARKING_TICKET_COMMON}>
          <NavBar />
          <ServiceLogin />
          <Footer />
        </Route>

        <Route path={RouteName.SPEEDING_TICKET_COMMON}>
          <NavBar />
          <ServiceLogin />
          <Footer />
        </Route>

        <Route path={RouteName.CONGESTION_CHARGES_COMMON}>
          <NavBar />
          <ServiceLogin />
          <Footer />
        </Route>

        <Route path={RouteName.WATER_COMMON}>
          <NavBar />
          <ServiceLogin />
          <Footer />
        </Route>

        <Route path={RouteName.TV_LICENCE_COMMON}>
          <NavBar />
          <ServiceLogin />
          <Footer />
        </Route>

        <Route path={RouteName.ELECTRICITY_COMMON}>
          <NavBar />
          <ServiceLogin />
          <Footer />
        </Route>

        <Route path={RouteName.GAS_COMMON}>
          <NavBar />
          <ServiceLogin />
          <Footer />
        </Route>

        <Route path={RouteName.COUNCIL_TAX_BILLS_COMMON}>
          <NavBar />
          <ServiceLogin />
          <Footer />
        </Route>

        <Route path={RouteName.PERSONAL_REQUEST_COMMON}>
          <NavBar />
          <ServiceLogin />
          <Footer />
        </Route>


        <Route path={RouteName.CAR_INSURANCE_PERSONAL_REQUEST_COMMON}>
          <NavBar />
          <ServiceLogin />
          <Footer />
        </Route>

        <Route path="/">
          <Auth>
            <Router>
              <Switch>
                <Route path="/customer" >
                  <VerifyRole allow={Role.CUSTOMER} >
                    <CustomerDashbordRouter />
                  </VerifyRole>
                </Route>
                <Route path="/" >
                  <VerifyRole allow={Role.SUPER_ADMIN}>
                    <AdminDashbordRouter />
                  </VerifyRole>
                </Route>
              </Switch>
            </Router>
          </Auth>
        </Route>
      </Switch>
    </Router>
  );
};

const CustomerDashbordRouter: React.FC = () => {
  return (
    <ContentLayout>
      <Router>
        <NavBar />
        <Switch>
          <Route path={RouteName.ROOT} exact={true}  >
            <Account2 />
          </Route>
          <Route path={RouteName.ACCOUNT} >
            <Account2 />
          </Route>

          <Route path={RouteName.INSURANCE_QUOTE}>
            <InsuranceQuote />
          </Route>

          <Route path={RouteName.CAR_INSURANCE_PERSONAL_REQUEST}>
            <CarInsurancePersonalRequest />
          </Route>

          <Route path={RouteName.CAR_INSURANCE}>
            <CarInsurance />
          </Route>

          <Route path={RouteName.HOME_INSURANCE}>
            <HomeInsurance />
          </Route>

          <Route path={RouteName.HOME_INSURANCE_QUOTE}>
            <HomeInsuranceQuote />
          </Route>

          <Route path={RouteName.BUSINESS_INSURANCE}>
            <BusinessInsurance />
          </Route>
          <Route path={RouteName.HOME_INSURANCE_PERSONAL_REQUEST}>
            <HomeInsurancePersonalRequest />
          </Route>

          <Route path={RouteName.BUSINESS_INSURANCE}>
            <BusinessInsurance />
          </Route>

          <Route path={RouteName.BUSINESS_INSURANCE_QUOTE}>
            <BusinessInsuranceQuote />
          </Route>

          <Route path={RouteName.BUSINESS_INSURANCE_PERSONAL_REQUEST}>
            <BusinessInsurancePersonalRequest />
          </Route>

          <Route path={RouteName.GP}>
            <GP />
          </Route>

          <Route path={RouteName.PRIVATE_HOSPITAL}>
            <PrivateHospital />
          </Route>

          <Route path={RouteName.PARKING_TICKET}>
            <ParkingTicket />
          </Route>

          <Route path={RouteName.SPEEDING_TICKET}>
            <SpeedingTicket />
          </Route>

          <Route path={RouteName.CONGESTION_CHARGES}>
            <CongestionCharges />
          </Route>

          <Route path={RouteName.WATER}>
            <Water />
          </Route>

          <Route path={RouteName.PERSONAL_REQUEST}>
            <PersonalRequestSubmissions />
          </Route>

          <Route path={RouteName.TV_LICENCE}>
            <TVLicence />
          </Route>

          <Route path={RouteName.ELECTRICITY}>
            <ElectricityBill />
          </Route>

          <Route path={RouteName.GAS}>
            <GasBill />
          </Route>

          <Route path={RouteName.COUNCIL_TAX_BILLS}>
            <CounsilTaxBills />
          </Route>

          <Route path={RouteName.PROFILE}>
            <Profile />
          </Route>

          <Route path={RouteName.CHANGE_PASSWORD}>
            <ChangePassword />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </ContentLayout>
  );
};

const AdminDashbordRouter: React.FC = () => {
  return (
    <ContentLayout>
      <Router>
        <AdminNavBar />
        <Switch>
          <Route path={RouteName.ROOT} exact={true}  >
            <AdminDashboard />
          </Route>
          <Route path={RouteName.ADMIN_DASHBOARD} >
            <AdminDashboard />
          </Route>
          <Route path={RouteName.ADMIN_USER_MANAGEMENT} >
            <AdminUserManagement />
          </Route>
          <Route path={RouteName.ADMIN_VEHICLE_INSURANCE} >
            <AdminInsurancePayments />
          </Route>
          <Route path={RouteName.ADMIN_BUSINESS_INSURANCE} >
            <AdminBusinessInsurancePayments />
          </Route>
          <Route path={RouteName.ADMIN_HOME_INSURANCE} >
            <AdminHomeInsurancePayments />
          </Route>
          <Route path={RouteName.ADMIN_DOCTOR_APPOINTMENTS} >
            <AdminDoctorAppointments />
          </Route>
          <Route path={RouteName.ADMIN_PERSONAL_REQUEST} >
            <AdminPersonalRequestPayments />
          </Route>
          <Route path={RouteName.ADMIN_PERSONAL_REQUEST_SUBMISSIONS} >
            <AdminPersonalRequestSubmissionsPayments />
          </Route>
          <Route path={RouteName.ADMIN_PENALTY_CHARGES} >
            <AdminPaneltyCharges />
          </Route>
          <Route path={RouteName.ADMIN_UTILIES} >
            <AdminUtilities />
          </Route>
          <Route path={RouteName.ADMIN_COMPLETED_DOCTOR_APPOINTMENTS} >
            <AdminCompletedDoctorAppointments />
          </Route>
          <Route path={RouteName.ADMIN_COMPLETED_PENALTY_CHARGES} >
            <AdminCompletedPaneltyCharges />
          </Route>
          <Route path={RouteName.ADMIN_COMPLETED_UTILIES} >
            <AdminCompletedUtilities />
          </Route>
          <Route path={RouteName.ADMIN_COMPLETED_PERSONAL_REQUEST} >
            <AdminCompletedPersonalRequestPayments />
          </Route>
          <Route path={RouteName.ADMIN_COMPLETED_PERSONAL_REQUEST_SUBMISSIONS} >
            <AdminCompletedPersonalRequestSubmissionsPayments />
          </Route>
          <Route path={RouteName.ADMIN_COMPLETED_VEHICLE_INSURANCE} >
            <AdminCompletedVehicleInsurance />
          </Route>
          <Route path={RouteName.ADMIN_COMPLETED_BUSINESS_INSURANCE} >
            <AdminCompletedBusinessInsurancePayments />
          </Route>
          <Route path={RouteName.ADMIN_COMPLETED_HOME_INSURANCE} >
            <AdminCompletedHomeInsurancePayments />
          </Route>
          <Route path={RouteName.ADMIN_EDIT_USER}>
            <AdminEditUser />
          </Route>
          <Route path={RouteName.ADMIN_SUBSCRIPTION_USERS}>
            <AdminSubscriptionUsers />
          </Route>
          <Route path={RouteName. ADMIN_SINGLE_PAYMENT}>
            <AdminSinglePaymentUsers />
          </Route>

        </Switch>
      </Router>
    </ContentLayout>
  );
};
export default App;
