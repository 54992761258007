import React, { useState, useContext } from "react";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";
import UserContext from "../context/UserContext";
import { Form, FormFeedback, Input } from "reactstrap";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { PersonalRequest } from "../../models/PersonalRequestSubmissions";
import InsuranceService from "../../servises/InsuranceService";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import PayPalButton from "./PayPalButton";
import { PaymentObject } from "../../models/Payment";
import swal from "sweetalert";
import { UserDetails } from "../../models/User";
const PersonalRequestSubmissions: React.FC = () => {
    const { t } = useTranslation();
    const [user, setUser] = useContext(UserContext);
    const [isLoading, setIsloading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (event.target.checked) {
            setSelectedOptions([...selectedOptions, value]);
        } else {
            setSelectedOptions(selectedOptions.filter((option) => option !== value));
        }
    };

    // call function of paypal button
    const handlePaymentComplete = (payment: PaymentObject) => {
        try {
            // Extract values from the payment object
            const { paymentId, status } = payment;
            //extract values
            Swal.fire({
                icon: "success",
                title: "Success!",
                text: "Your Payment is Completed!.",
            })
            personalRequestForm.setFieldValue("status", status)
            personalRequestForm.setFieldValue("paymentId", paymentId)
        } catch {
            Swal.fire({
                icon: "error",
                title: "Oops!",
                text: "Payment Unsuccessful. Please Try Again!",
            });
        }
    };
    const [userdata, setUserdata] = useState<UserDetails | undefined>({} as UserDetails);

    const autoFill = () => {
        setUserdata(user);
    }




    const personalRequestForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            fulName: "",
            email: "",
            phoneNo: "",
            dob: "",
            postCode: "",
            address: "",
            propertyType: "",
            numberOfBedrooms: "",
            moveinDate: "",
            mPRN: "",
            eligible: [],
            accountHolder: "",
            proofOfEligibility: "",
            nameOfAccount: "",
            paymentId: "",
            status: ""

        },
        validationSchema: Yup.object({
            // fulName: Yup.string().required(t<string>("Please Enter Full Name")),
            // phoneNo: Yup.string().required(t<string>("Please Enter phone Number")).matches(/^\+?\d{1,3}[- ]?\d{3}[- ]?\d{4}$/, { message: "Please Enter Valid Phone Number.", excludeEmptyString: false }),
            // email: Yup.string().required(t<string>("Please Enter Business Email")).email(t<string>('Invalid Email')),
            // dob: Yup.string().required(t<string>("Please Enter date of birth")),
            // postCode: Yup.string().required(
            //     t<string>("Please Enter postal Code")
            // ),
            // address: Yup.string().required(t<string>("Please Enter Address")),
            propertyType: Yup.string().required(t<string>("This field is required")),
            numberOfBedrooms: Yup.string().required(t<string>("This field is required")),
            moveinDate: Yup.string().required(t<string>("Please Enter move in date")),
            mPRN: Yup.string().required(t<string>("This field is required")),
            accountHolder: Yup.string().required(t<string>("This field is required")),
            proofOfEligibility: Yup.string().required(t<string>("This field is required")),
            nameOfAccount: Yup.string().required(t<string>("This field is required")),
        }),

        onSubmit: (values, onSubmitProps) => {
            const dataObject: PersonalRequest = {
                fulName: values.fulName ||  (userdata?.firstName + " " + userdata?.lastName),
                email: values.email || userdata?.email,
                phoneNo: parseInt(values.phoneNo) || userdata?.phoneNumber,
                dob: values.dob || userdata?.dateOfBirth as any,
                postCode: values.postCode || userdata?.postCode,
                address: values.address || userdata?.firstName,
                propertyType: values.propertyType,
                numberOfBedrooms: values.numberOfBedrooms,
                moveinDate: values.moveinDate,
                mPRN: values.mPRN,
                eligible: selectedOptions,
                accountHolder: values.accountHolder,
                proofOfEligibility: values.proofOfEligibility,
                nameOfAccount: values.nameOfAccount,
                paymentId: values.paymentId,
                status: values.status

            };
            if (!values.paymentId || values.status !== 'COMPLETED') {
                // Payment not completed, show error message
                Swal.fire({
                    icon: "error",
                    title: "Please complete the payment before submitting the form.",
                    confirmButtonColor: "#FD7F00"
                });
                return; // Stop further execution
            }
            setIsloading(true);
            InsuranceService.submitPersonalRequestInfo(dataObject).then((res: any) => {
                if (res.success) {
                    setIsloading(false);
                    Swal.fire({ title: "Informations Added" });
                    setUserdata({ firstName: "", lastName: "", phoneNumber: "" as any, postCode: "", address: "", email: "", dateOfBirth: undefined, });  
                    setSelectedOptions([]);
                    onSubmitProps.resetForm();
                } else {
                    setIsloading(false);
                    Swal.fire({ icon: "error", title: res.error, confirmButtonColor: "#FD7F00" });
                }
            });
     
        }
    });

    return (
        <div className="login-page edu-footer footer-lighten bg-image footer-style-1">
            <section className="account-page-area section-gap-equal1">
                <div className="container-custom position-relative">
                    <div className="row g-5 justify-content-center">
                        <div className="col-lg-8">

                            <div className="login-form-box registration-form">
                                <div className="App">
                                    <div className="box">
                                        <div>
                                            <h3 className="title mb-20 mt-10">PERSONAL REQUEST SUBMISSIONS</h3>
                                            <p className="themeColor  mb-20  font-16 mt-3">"Personal Requests" refer to unique and specific needs that a person may have for life admin services beyond the standard services offered . These requests can range from personal errands and tasks to more complex administrative needs, such as assistance with travel arrangements, event planning, or even special project management.</p>
                                            <button
                                                className="btn colorchangeLog edu-btn btn-medium mb-20"
                                                type="submit"
                                                onClick={autoFill}
                                            >AutoFill</button>
                                            <h3 className="title mb-30 mt-10">Personal Information:</h3>

                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    personalRequestForm.handleSubmit();
                                                    return false;
                                                }}
                                            >
                                                <div className="row g-5 mb-20 justify-content-center">
                                                    <div className="col-lg-6">
                                                        <div className="form-group mb-20">
                                                            <label htmlFor="reg-name">Full name:</label>
                                                            <Input type="text" name="fulName" id="business-name"
                                                                value={personalRequestForm.values.fulName ||  userdata?.firstName == undefined ? "" : `${userdata?.firstName} ${userdata?.lastName}`}
                                                                onChange={personalRequestForm.handleChange}
                                                                invalid={personalRequestForm.touched.fulName && personalRequestForm.errors.fulName ? true : false}
                                                            />
                                                            <FormFeedback>
                                                                {personalRequestForm.errors.fulName}
                                                            </FormFeedback>
                                                        </div>

                                                        <div className="form-group mb-20">
                                                            <label htmlFor="reg-Email">Email:</label>
                                                            <Input type="text" name="email" id="reg-Email"
                                                                value={personalRequestForm.values.email || userdata?.email}
                                                                onChange={personalRequestForm.handleChange}
                                                                invalid={personalRequestForm.touched.email && personalRequestForm.errors.email ? true : false}
                                                            />
                                                            <FormFeedback>
                                                                {personalRequestForm.errors.email}
                                                            </FormFeedback>
                                                        </div>

                                                        <div className="form-group mb-20">
                                                            <label htmlFor="reg-Number">Phone Number:</label>
                                                            <Input type="number" name="phoneNo" id="phoneNo"
                                                                value={personalRequestForm.values.phoneNo || userdata?.phoneNumber}
                                                                onChange={personalRequestForm.handleChange}
                                                                invalid={personalRequestForm.touched.phoneNo && personalRequestForm.errors.phoneNo ? true : false}
                                                            />
                                                            <FormFeedback>
                                                                {personalRequestForm.errors.phoneNo}
                                                            </FormFeedback>
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="reg-Vehicle">Date of Birth:</label>
                                                            <Input
                                                                type="date"
                                                                className="form-control"
                                                                id="formrow-firstname-Input"
                                                                placeholder="Date of birth"
                                                                // value={db || ""}
                                                                // onChange={e => handleDateUpdate(e)}
                                                                name="dob"
                                                                max={moment().format("YYYY-MM-DD")}
                                                                required
                                                                value={personalRequestForm.values.dob || moment(userdata?.dateOfBirth).format("YYYY-MM-DD")}
                                                                onChange={personalRequestForm.handleChange}
                                                                invalid={personalRequestForm.touched.dob && personalRequestForm.errors.dob ? true : false}
                                                            />
                                                            <FormFeedback>
                                                                {personalRequestForm.errors.dob}
                                                            </FormFeedback>
                                                        </div>

                                                    </div>
                                                    <div className="col-lg-6">

                                                        <div className="form-group mb-20">
                                                            <label htmlFor="reg-Postcode">Postcode:</label>
                                                            <Input type="text" name="postCode" id="log-Postcode"
                                                                value={personalRequestForm.values.postCode || userdata?.postCode}
                                                                onChange={personalRequestForm.handleChange}
                                                                invalid={personalRequestForm.touched.postCode && personalRequestForm.errors.postCode ? true : false}
                                                            />
                                                            <FormFeedback>
                                                                {personalRequestForm.errors.postCode}
                                                            </FormFeedback>
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="log-Address">Address:</label>
                                                            <Input type="textarea" name="address" id="log-Address"
                                                                value={personalRequestForm.values.address || userdata?.address}
                                                                onChange={personalRequestForm.handleChange}
                                                                invalid={personalRequestForm.touched.address && personalRequestForm.errors.address ? true : false}
                                                            />
                                                            <FormFeedback>
                                                                {personalRequestForm.errors.address}
                                                            </FormFeedback>
                                                        </div>


                                                    </div>
                                                </div>



                                                <hr className="mb-30 mt-40" />
                                                <h3 className="title mb-30">Service Information:</h3>

                                                <div className="row g-5 mb-20 justify-content-center">
                                                    <div className="col-lg-6">
                                                        <div className="form-group mb-20">
                                                            <label htmlFor="log-Property">Property Type:</label>
                                                            <Input type="text" name="propertyType" id="log-Property"
                                                                value={personalRequestForm.values.propertyType}
                                                                onChange={personalRequestForm.handleChange}
                                                                invalid={personalRequestForm.touched.propertyType && personalRequestForm.errors.propertyType ? true : false}
                                                            />
                                                            <FormFeedback>
                                                                {personalRequestForm.errors.propertyType}
                                                            </FormFeedback>
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="reg-Move">Move-in date:</label>
                                                            <Input
                                                                className="form-control"
                                                                type="date"
                                                                id="reg-Move"
                                                                name="moveinDate"
                                                                placeholder="Select Date"
                                                                onChange={
                                                                    personalRequestForm.handleChange
                                                                }
                                                                onBlur={personalRequestForm.handleBlur}
                                                                value={
                                                                    personalRequestForm.values
                                                                        .moveinDate
                                                                }
                                                                invalid={
                                                                    personalRequestForm.touched
                                                                        .moveinDate &&
                                                                        personalRequestForm.errors
                                                                            .moveinDate
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {personalRequestForm.touched
                                                                .moveinDate &&
                                                                personalRequestForm.errors
                                                                    .moveinDate ? (
                                                                <FormFeedback type="invalid">
                                                                    {
                                                                        personalRequestForm.errors
                                                                            .moveinDate
                                                                    }
                                                                </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group mb-20">
                                                            <label htmlFor="reg-Bedrooms">Number of Bedrooms:</label>
                                                            <Input type="number" name="numberOfBedrooms" id="log-Bedrooms"
                                                                value={personalRequestForm.values.numberOfBedrooms}
                                                                onChange={personalRequestForm.handleChange}
                                                                invalid={personalRequestForm.touched.numberOfBedrooms && personalRequestForm.errors.numberOfBedrooms ? true : false}
                                                            />
                                                            <FormFeedback>
                                                                {personalRequestForm.errors.numberOfBedrooms}
                                                            </FormFeedback>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="reg-MPRN">Water meter Point Reference Number (MPRN):</label>
                                                            <Input type="text" name="mPRN" id="log-MPRN"
                                                                value={personalRequestForm.values.mPRN}
                                                                onChange={personalRequestForm.handleChange}
                                                                invalid={personalRequestForm.touched.mPRN && personalRequestForm.errors.mPRN ? true : false}
                                                            />
                                                            <FormFeedback>
                                                                {personalRequestForm.errors.mPRN}
                                                            </FormFeedback>
                                                        </div>
                                                    </div>
                                                </div>


                                                <hr className="mb-30 mt-40" />
                                                <h3 className="title mb-30">Payment Information:</h3>
                                                <div className="changePaypalStyle"><PayPalButton onPaymentComplete={handlePaymentComplete} /></div>


                                                <hr className="mb-30 mt-40" />
                                                <div className="row g-5 mb-20 justify-content-center">
                                                    <h3 className="title mb-30">Additional Information:</h3>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="log-drive">Are you eligible for any discounts or benefits?</label>
                                                        </div>
                                                        <div className="card-heading mb-2">
                                                            <div className="row">
                                                                <div
                                                                    className="col"
                                                                    style={{ display: "flex", flexWrap: "wrap" }}
                                                                >
                                                                    <div
                                                                        className="form-check check mb-3 "
                                                                        style={{ marginRight: "1.2rem" }}
                                                                    >
                                                                        <Input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="eligible_for_discounts_one"
                                                                            name="eligible_for_discounts"
                                                                            onChange={handleCheckboxChange}
                                                                            checked={selectedOptions.includes('PENSION_CREDIT')}
                                                                            value="PENSION_CREDIT"
                                                                        />

                                                                        <label
                                                                            className="form-check-label checkLable"
                                                                            htmlFor="credit"
                                                                        >
                                                                            Pension credit
                                                                        </label>
                                                                    </div>

                                                                    <div
                                                                        className="form-check check mb-3 "
                                                                        style={{ marginRight: "1.2rem" }}
                                                                    >
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="eligible_for_discounts_two"
                                                                            name="eligible_for_discounts"
                                                                            onChange={handleCheckboxChange}
                                                                            checked={selectedOptions.includes('INCOME_SUPPORT')}
                                                                            value="INCOME_SUPPORT"
                                                                        />

                                                                        <label
                                                                            className="form-check-label checkLable"
                                                                            htmlFor="support"
                                                                        >
                                                                            Income support
                                                                        </label>
                                                                    </div>

                                                                    <div className="form-check check mb-3 ">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="eligible_for_discounts_three"
                                                                            name="eligible_for_discounts"
                                                                            onChange={handleCheckboxChange}
                                                                            checked={selectedOptions.includes('JOBSEEKER_ALLOWANCE')}
                                                                            value="JOBSEEKER_ALLOWANCE"
                                                                        />

                                                                        <label
                                                                            className="form-check-label checkLable"
                                                                            htmlFor="Allowance"
                                                                        >
                                                                            Jobseeker's Allowance
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="log-drive">Are you the account holder?</label>
                                                        </div>
                                                        <div className="card-heading mb-2">
                                                            <div className="row">
                                                                <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                        <Input
                                                                            className="form-check-Input"
                                                                            type="radio"
                                                                            name="accountHolder"
                                                                            id="birth"
                                                                            value="YES"
                                                                            onChange={personalRequestForm.handleChange}
                                                                        />
                                                                        <FormFeedback>
                                                                            {personalRequestForm.errors.accountHolder}
                                                                        </FormFeedback>

                                                                        <label className="form-check-label" htmlFor="birth">
                                                                            YES
                                                                        </label>
                                                                    </div>

                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                        <Input
                                                                            className="form-check-Input"
                                                                            type="radio"
                                                                            name="accountHolder"
                                                                            id="birthNO"
                                                                            value="NO"
                                                                            onChange={personalRequestForm.handleChange}
                                                                        />
                                                                        <FormFeedback>
                                                                            {personalRequestForm.errors.accountHolder}
                                                                        </FormFeedback>

                                                                        <label className="form-check-label" htmlFor="birthNO">
                                                                            NO
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row g-5 mb-20 justify-content-center">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="reg-please">If yes, please provide proof of eligibility:</label>
                                                            <Input type="text" name="proofOfEligibility" id="reg-please"
                                                                value={personalRequestForm.values.proofOfEligibility}
                                                                onChange={personalRequestForm.handleChange}
                                                                invalid={personalRequestForm.touched.proofOfEligibility && personalRequestForm.errors.proofOfEligibility ? true : false}
                                                            />
                                                            <FormFeedback>
                                                                {personalRequestForm.errors.proofOfEligibility}
                                                            </FormFeedback>
                                                        </div>

                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="reg-name">If no, please provide the name of the account holder:</label>
                                                            <Input type="text" name="nameOfAccount" id="reg-name"
                                                                value={personalRequestForm.values.nameOfAccount}
                                                                onChange={personalRequestForm.handleChange}
                                                                invalid={personalRequestForm.touched.nameOfAccount && personalRequestForm.errors.nameOfAccount ? true : false}
                                                            />
                                                            <FormFeedback>
                                                                {personalRequestForm.errors.nameOfAccount}
                                                            </FormFeedback>
                                                        </div>
                                                    </div>
                                                </div>

                                                <button
                                                    className="btn colorchangeLog leadMargin edu-btn btn-medium mb-20"
                                                    type="submit"
                                                >
                                                    <div>Submit</div>
                                                </button>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div >
            </section >
        </div >
    );
};

export default PersonalRequestSubmissions;
