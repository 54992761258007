import React, { useState, useContext, useRef } from "react";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";
import { Form, FormFeedback, Input } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import UserContext from "../context/UserContext";
import { PenaltyChargesService } from "../../servises/Penalty-Charges-Service";
import Swal from "sweetalert2";
import moment from "moment";
import { UserDetails } from "../../models/User";

const ParkingTicket: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [categoryImage, setCategoryImage] = useState({ raw: "" });
  const inputRef = useRef(null);
  const imageUpload = (e: any): void => {
    setCategoryImage({
      raw: e.target.files[0],
    });;
  };


  const [userdata, setUserdata] = useState<UserDetails | undefined>({} as UserDetails);

  const autoFill = () => {
    setUserdata(user);
  }

  const parkingTicketValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      pt_phone_number: "",
      pt_vehicle_registration_number: "",
      pt_postal_code: "",
      pt_address: "",
      pt_offense_datetime: "",
      pt_offense_location: "",
      pt_offense_description: "",
      pt_fine_amount: "",
      pt_how_to_contest_ticket: "",
      pt_duedate: "",
      pt_signature: "",
      pt_ticketId: "",
    },
    validationSchema: Yup.object({
      // name: Yup.string().required(t<string>("Please Enter Name")),
      // pt_phone_number: Yup.number().required(
      //   t<string>("Please Enter Phone Number")
      // ),
      // pt_vehicle_registration_number: Yup.string().required(
      //   t<string>("Please Enter vehicle registration number")
      // ),
      // pt_postal_code: Yup.string().required(
      //   t<string>("Please Enter the Postal Code")
      // ),
      // pt_address: Yup.string().required(t<string>("Please Enter the Address")),
      pt_offense_datetime: Yup.date().required(
        t<string>("Please Select the Date & Time of the offense")
      ),
      pt_offense_location: Yup.string().required(
        t<string>("Please Enter the location of the offense")
      ),
      pt_offense_description: Yup.string().required(
        t<string>("Please Enter a description of the offense")
      ),
      pt_fine_amount: Yup.number().required(
        t<string>("Please Enter the fine amount")
      ),
      pt_how_to_contest_ticket: Yup.string().required(
        t<string>("Please Enter how to contest the ticket")
      ),
      pt_duedate: Yup.date().required(
        t<string>("Please Enter the due date for the ticket")
      ),
      pt_signature: Yup.string().required(
        t<string>("Please Enter the Signature")
      ),
      pt_ticketId: Yup.string().required(
        t<string>("Please Enter the Unique Id of the Ticket")
      ),
    }),
    onSubmit: (values, onSubmitProps) => {
      var bodyFormData = new FormData();

      const finalData = {
        name: values.name || (userdata?.firstName + " " + userdata?.lastName),
        phoneNumber: values.pt_phone_number || userdata?.phoneNumber,
        postCode: values.pt_postal_code || userdata?.postCode,
        address: values.pt_address || userdata?.address,
        vehicleRegistrationNumber: values.pt_vehicle_registration_number,
        offenseDate: values.pt_offense_datetime,
        offenseDescription: values.pt_offense_description,
        offenseLocation: values.pt_offense_location,
        fineAmount: values.pt_fine_amount,
        dueDate: values.pt_duedate,
        howToContestTicketInfo: values.pt_how_to_contest_ticket,
        paymentMethod: "CREDIT_CARD",
        signature: values.pt_signature,
        ticketId: values.pt_ticketId,
      };
      const dataString = JSON.stringify(finalData);

      bodyFormData.append("parkingTicketDetails", dataString);

      bodyFormData.append("parkingticket", categoryImage.raw);
      if (!user?.subscriptionId || user?.subscriptionStatus === "CANCELLED") {
        Swal.fire({
          icon: "warning",
          title: "in order to be able receive our services you will have to be subscribe",
          confirmButtonText: "Subscribe",
          confirmButtonColor: "#FD7F00",
          showCancelButton: true,
          cancelButtonText: "Cancel",
        }).then((result) => {
          if (result.isConfirmed) {
            // Navigate to subscription page
            window.location.href = "/account";
          }
        });
        return; // Stop further execution
      } else {
        PenaltyChargesService.payParkingTicket(bodyFormData).then((res) => {
          if (res.success) {
            setIsLoading(false);
            Swal.fire({ title: "Parking Ticket Payement is Valid" });
            setCategoryImage({ raw: "" });
            setUserdata({ firstName: "", lastName: "", phoneNumber: "" as any, postCode: "", address: "", email: "", dateOfBirth: undefined, });
            onSubmitProps.resetForm();
            if (inputRef.current !== null) {
              (inputRef.current as HTMLInputElement).value = "";
            }
          } else {
            setIsLoading(false);
            Swal.fire({
              icon: "error",
              title: res.error,
              confirmButtonColor: "#FD7F00",
            });
          }
        });
      }
    },
  });

  return (
    <div className="login-page edu-footer footer-lighten bg-image footer-style-1">
      <section className="account-page-area section-gap-equal1">
        <div className="container-custom position-relative">
          <div className="row g-5 justify-content-center">
            <div className="col-lg-8">

              <div className="login-form-box registration-form">
                <div className="App">
                  <div className="box">
                    <div>

                      <h3 className="title mb-20 mt-10">
                        Personal Information:
                      </h3>

                      <p className="themeColor mb-30 font-16 mt-3">Parking ticket life admin services offer support in managing and addressing parking violations.
                        They handle tasks such as payment of parking tickets by just uploading a picture.</p>

                      <button
                        className="btn colorchangeLog edu-btn btn-medium mb-20"
                        type="submit"
                        onClick={autoFill}
                      >AutoFill</button>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          parkingTicketValidation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="row g-5 mb-20 justify-content-center">
                          <div className="col-lg-6">
                            <div className="form-group  mb-20">
                              <label htmlFor="reg-name">Name:</label>
                              <Input
                                type="text"
                                name="name"
                                id="name"
                                value={parkingTicketValidation.values.name || userdata?.firstName == undefined ? "" : `${userdata?.firstName} ${userdata?.lastName}`}
                                onChange={parkingTicketValidation.handleChange}
                                invalid={
                                  parkingTicketValidation.touched.name &&
                                    parkingTicketValidation.errors.name
                                    ? true
                                    : false
                                }
                              />

                              {parkingTicketValidation.touched.name &&
                                parkingTicketValidation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {parkingTicketValidation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group  mb-20">
                              <label htmlFor="reg-Number">Phone Number:</label>
                              <Input
                                type="number"
                                name="pt_phone_number"
                                id="pt_phone_number"
                                value={
                                  parkingTicketValidation.values.pt_phone_number || userdata?.phoneNumber
                                }
                                onChange={parkingTicketValidation.handleChange}
                                invalid={
                                  parkingTicketValidation.touched
                                    .pt_phone_number &&
                                    parkingTicketValidation.errors.pt_phone_number
                                    ? true
                                    : false
                                }
                              />

                              {parkingTicketValidation.touched
                                .pt_phone_number &&
                                parkingTicketValidation.errors.pt_phone_number ? (
                                <FormFeedback type="invalid">
                                  {
                                    parkingTicketValidation.errors
                                      .pt_phone_number
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <label htmlFor="reg-Vehicle">
                                Vehicle Registration Number:
                              </label>
                              <Input
                                type="text"
                                name="pt_vehicle_registration_number"
                                id="pt_vehicle_registration_number"
                                value={
                                  parkingTicketValidation.values
                                    .pt_vehicle_registration_number
                                }
                                onChange={parkingTicketValidation.handleChange}
                                invalid={
                                  parkingTicketValidation.touched
                                    .pt_vehicle_registration_number &&
                                    parkingTicketValidation.errors
                                      .pt_vehicle_registration_number
                                    ? true
                                    : false
                                }
                              />

                              {parkingTicketValidation.touched
                                .pt_vehicle_registration_number &&
                                parkingTicketValidation.errors
                                  .pt_vehicle_registration_number ? (
                                <FormFeedback type="invalid">
                                  {
                                    parkingTicketValidation.errors
                                      .pt_vehicle_registration_number
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="reg-Postcode  mb-20">
                                Postcode:
                              </label>
                              <Input
                                type="text"
                                name="pt_postal_code"
                                id="pt_postal_code"
                                value={
                                  parkingTicketValidation.values.pt_postal_code || userdata?.postCode
                                }
                                onChange={parkingTicketValidation.handleChange}
                                invalid={
                                  parkingTicketValidation.touched
                                    .pt_postal_code &&
                                    parkingTicketValidation.errors.pt_postal_code
                                    ? true
                                    : false
                                }
                              />

                              {parkingTicketValidation.touched.pt_postal_code &&
                                parkingTicketValidation.errors.pt_postal_code ? (
                                <FormFeedback type="invalid">
                                  {
                                    parkingTicketValidation.errors
                                      .pt_postal_code
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <label htmlFor="log-Address">Address:</label>
                              <Input
                                type="textarea"
                                rows="4"
                                name="pt_address"
                                id="pt_address"
                                value={
                                  parkingTicketValidation.values.pt_address || userdata?.address
                                }
                                onChange={parkingTicketValidation.handleChange}
                                invalid={
                                  parkingTicketValidation.touched.pt_address &&
                                    parkingTicketValidation.errors.pt_address
                                    ? true
                                    : false
                                }
                              />
                              {parkingTicketValidation.touched.pt_address &&
                                parkingTicketValidation.errors.pt_address ? (
                                <FormFeedback type="invalid">
                                  {parkingTicketValidation.errors.pt_address}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <hr className="mb-30 mt-40" />
                        <h3 className="title mb-30">Offense Information:</h3>

                        <div className="row g-5 mb-20 justify-content-center">
                          <div className="col-lg-6">
                            <div className="form-group  mb-20">
                              <label htmlFor="log-Date">
                                Date and Time of the offense:
                              </label>
                              <Input
                                className="form-control"
                                type="datetime-local"
                                defaultValue="2019-08-19"
                                id="pt_offense_datetime"
                                name="pt_offense_datetime"
                                placeholder="Select Date"
                                onChange={parkingTicketValidation.handleChange}
                                onBlur={parkingTicketValidation.handleBlur}
                                value={
                                  parkingTicketValidation.values
                                    .pt_offense_datetime
                                }
                                invalid={
                                  parkingTicketValidation.touched
                                    .pt_offense_datetime &&
                                    parkingTicketValidation.errors
                                      .pt_offense_datetime
                                    ? true
                                    : false
                                }
                              />
                              {parkingTicketValidation.touched
                                .pt_offense_datetime &&
                                parkingTicketValidation.errors
                                  .pt_offense_datetime ? (
                                <FormFeedback type="invalid">
                                  {
                                    parkingTicketValidation.errors
                                      .pt_offense_datetime
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="form-group  mb-20">
                              <label htmlFor="reg-Postcode">
                                Location of the offense:
                              </label>
                              <Input
                                type="text"
                                name="pt_offense_location"
                                id="pt_offense_location"
                                value={
                                  parkingTicketValidation.values
                                    .pt_offense_location
                                }
                                onChange={parkingTicketValidation.handleChange}
                                invalid={
                                  parkingTicketValidation.touched
                                    .pt_offense_location &&
                                    parkingTicketValidation.errors
                                      .pt_offense_location
                                    ? true
                                    : false
                                }
                              />

                              {parkingTicketValidation.touched
                                .pt_offense_location &&
                                parkingTicketValidation.errors
                                  .pt_offense_location ? (
                                <FormFeedback type="invalid">
                                  {
                                    parkingTicketValidation.errors
                                      .pt_offense_location
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="log-Description">
                                Description of the offense:
                              </label>
                              <Input
                                type="textarea"
                                rows="4"
                                name="pt_offense_description"
                                id="pt_offense_description"
                                value={
                                  parkingTicketValidation.values
                                    .pt_offense_description
                                }
                                onChange={parkingTicketValidation.handleChange}
                                invalid={
                                  parkingTicketValidation.touched
                                    .pt_offense_description &&
                                    parkingTicketValidation.errors
                                      .pt_offense_description
                                    ? true
                                    : false
                                }
                              />
                              {parkingTicketValidation.touched
                                .pt_offense_description &&
                                parkingTicketValidation.errors
                                  .pt_offense_description ? (
                                <FormFeedback type="invalid">
                                  {
                                    parkingTicketValidation.errors
                                      .pt_offense_description
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <hr className="mb-30 mt-40" />
                        <h3 className="title mb-30">Fine Information:</h3>

                        <div className="row g-5 mb-20 justify-content-center">
                          <div className="col-lg-4">
                            <div className="form-group  mb-20">
                              <label htmlFor="reg-Amount">Fine Amount:</label>
                              <Input
                                type="number"
                                name="pt_fine_amount"
                                id="pt_fine_amount"
                                value={
                                  parkingTicketValidation.values.pt_fine_amount
                                }
                                onChange={parkingTicketValidation.handleChange}
                                invalid={
                                  parkingTicketValidation.touched
                                    .pt_fine_amount &&
                                    parkingTicketValidation.errors.pt_fine_amount
                                    ? true
                                    : false
                                }
                              />

                              {parkingTicketValidation.touched.pt_fine_amount &&
                                parkingTicketValidation.errors.pt_fine_amount ? (
                                <FormFeedback type="invalid">
                                  {
                                    parkingTicketValidation.errors
                                      .pt_fine_amount
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="form-group mb-20">
                              <label htmlFor="reg-Amount">Signature:</label>
                              <Input
                                type="text"
                                name="pt_signature"
                                id="pt_signature"
                                value={
                                  parkingTicketValidation.values.pt_signature
                                }
                                onChange={parkingTicketValidation.handleChange}
                                invalid={
                                  parkingTicketValidation.touched
                                    .pt_signature &&
                                    parkingTicketValidation.errors.pt_signature
                                    ? true
                                    : false
                                }
                              />

                              {parkingTicketValidation.touched.pt_signature &&
                                parkingTicketValidation.errors.pt_signature ? (
                                <FormFeedback type="invalid">
                                  {parkingTicketValidation.errors.pt_signature}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group  mb-20">
                              <label htmlFor="reg-revenue">Due Date:</label>
                              <Input
                                className="form-control"
                                type="date"
                                defaultValue="2019-08-19"
                                id="pt_duedate"
                                name="pt_duedate"
                                placeholder="Select Date"
                                onChange={parkingTicketValidation.handleChange}
                                onBlur={parkingTicketValidation.handleBlur}
                                value={
                                  parkingTicketValidation.values.pt_duedate
                                }
                                invalid={
                                  parkingTicketValidation.touched.pt_duedate &&
                                    parkingTicketValidation.errors.pt_duedate
                                    ? true
                                    : false
                                }
                              />
                              {parkingTicketValidation.touched.pt_duedate &&
                                parkingTicketValidation.errors.pt_duedate ? (
                                <FormFeedback type="invalid">
                                  {parkingTicketValidation.errors.pt_duedate}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <label htmlFor="log-Insurance-contract-copy-upload">
                                Picture of the parking ticket
                              </label>

                              <input
                                type="file"
                                className="inputFile"
                                ref={inputRef}
                                onChange={(e) => imageUpload(e)}
                              />
                            </div>

                          </div>
                          <div className="col-lg-4">
                            <div className="form-group mb-20">
                              <label htmlFor="reg-Information">
                                Information on how to contest the ticket:
                              </label>
                              <Input
                                type="text"
                                name="pt_how_to_contest_ticket"
                                id="pt_how_to_contest_ticket"
                                value={
                                  parkingTicketValidation.values
                                    .pt_how_to_contest_ticket
                                }
                                onChange={parkingTicketValidation.handleChange}
                                invalid={
                                  parkingTicketValidation.touched
                                    .pt_how_to_contest_ticket &&
                                    parkingTicketValidation.errors
                                      .pt_how_to_contest_ticket
                                    ? true
                                    : false
                                }
                              />
                              {parkingTicketValidation.touched
                                .pt_how_to_contest_ticket &&
                                parkingTicketValidation.errors
                                  .pt_how_to_contest_ticket ? (
                                <FormFeedback type="invalid">
                                  {
                                    parkingTicketValidation.errors
                                      .pt_how_to_contest_ticket
                                  }
                                </FormFeedback>
                              ) : null}{" "}
                            </div>

                            <div className="form-group mb-20">
                              <label htmlFor="reg-Amount">Ticket Id:</label>
                              <Input
                                type="text"
                                name="pt_ticketId"
                                id="pt_ticketId"
                                value={
                                  parkingTicketValidation.values.pt_ticketId
                                }
                                onChange={parkingTicketValidation.handleChange}
                                invalid={
                                  parkingTicketValidation.touched.pt_ticketId &&
                                    parkingTicketValidation.errors.pt_ticketId
                                    ? true
                                    : false
                                }
                              />

                              {parkingTicketValidation.touched.pt_ticketId &&
                                parkingTicketValidation.errors.pt_ticketId ? (
                                <FormFeedback type="invalid">
                                  {parkingTicketValidation.errors.pt_ticketId}
                                </FormFeedback>
                              ) : null}
                            </div>

                          </div>
                        </div>

                        <button
                          className="btn colorchangeLog leadMargin edu-btn btn-medium mb-20"
                          type="submit"
                        >
                          <div>Submit</div>
                        </button>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ParkingTicket;
