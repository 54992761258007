import React, { useState, useContext } from "react";
import { FormFeedback, Input, } from "reactstrap";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import InsuranceService from "../../servises/InsuranceService";
import { BusinessPersonalRequest } from "../../models/businessPersonalRequest";
import { UserDetails } from "../../models/User";
import UserContext from "../context/UserContext";

const BusinessInsurancePersonalRequest: React.FC = () => {
    const { t } = useTranslation();
    const [user, setUser] = useContext(UserContext);
    const [isLoading, setIsloading] = useState(false);

    const [userdata, setUserdata] = useState<UserDetails | undefined>({} as UserDetails);

    const autoFill = () => {
        setUserdata(user);
    }



    const BusinessPersonalRequestForm = useFormik({

        initialValues: {
            fulName: "",
            email: "",
            phoneNo: "",
            dob: "",
            postCode: "",
            address: "",
            specificLifeAdminService: "",
            frequencyAndDurationofService: "",
            instructionOrPreference: ""
        },

        validationSchema: Yup.object({
            // fulName: Yup.string().required(t<string>("Please Enter Full Name")),
            // phoneNo: Yup.string().required(t<string>("Please Enter phone Number")).matches(/^\+?\d{1,3}[- ]?\d{3}[- ]?\d{4}$/, { message: "Please Enter Valid Phone Number.", excludeEmptyString: false }),
            // email: Yup.string().required(t<string>("Please Enter Business Email")).email(t<string>('Invalid Email')),
            // dob: Yup.string().required(t<string>("Please Enter date of birth")),
            // postCode: Yup.string().required(t<string>("Please Enter post code")).matches(/^\d+$/, 'PostCode Should be a Number'),
            // address: Yup.string().required(t<string>("Please Enter Address")),
            frequencyAndDurationofService: Yup.string().required(t<string>("This field is required")),
            specificLifeAdminService: Yup.string().required(t<string>("This field is required")),
            instructionOrPreference: Yup.string().required(t<string>("This field is required")),


        }),

        onSubmit: (values, onSubmitProps) => {
            const dataObject: BusinessPersonalRequest = {
                fulName: values.fulName ||  (userdata?.firstName + " " + userdata?.lastName),
                phoneNo: parseInt(values.phoneNo) || userdata?.phoneNumber,
                email: values.email || userdata?.email,
                dob: values.dob || userdata?.dateOfBirth as any,
                address: values.address || userdata?.address,
                postCode: values.postCode || userdata?.postCode,
                frequencyAndDurationofService: values.frequencyAndDurationofService,
                specificLifeAdminService: values.specificLifeAdminService,
                instructionOrPreference: values.instructionOrPreference,
            };
            InsuranceService.submitBusinessPersonalRequestInfo(dataObject).then((res: any) => {
                if (res.success) {
                    setIsloading(false);
                    Swal.fire({ title: "Informations Added" });
                    onSubmitProps.resetForm();
                    setUserdata({ firstName: "", lastName: "", phoneNumber: "" as any, postCode: "", address: "", email: "", dateOfBirth: undefined, });
                } else {
                    setIsloading(false);
                    Swal.fire({ icon: "error", title: res.error, confirmButtonColor: "#FD7F00" });
                }
            });
        }
    })

    return (
        <div className="login-page edu-footer footer-lighten bg-image footer-style-1">
            <section className="account-page-area section-gap-equal1">
                <div className="container-custom position-relative">
                    <div className="row g-5 justify-content-center">
                        <div className="col-lg-8">
                            <div className="login-form-box registration-form">
                                <div className="App">
                                    <div className="box">
                                        <div>

                                            <h3 className="title mb-20 mt-10">PERSONAL REQUEST SUBMISSIONS</h3>

                                            <p className="themeColor font-16  mb-20 mt-3">Insurance services life admin assists individuals in managing their insurance needs effectively. They handle tasks such as policy research and selection, policy management, claims assistance, premium payments, updating coverage details, and providing guidance on insurance-related matters to ensure comprehensive coverage and peace of mind.

                                            </p>


                                            <button
                                                className="btn colorchangeLog edu-btn btn-medium mb-20"
                                                type="submit"
                                                onClick={autoFill}
                                            >AutoFill</button>
                                            <h3 className="title mb-30 mt-10">Personal Information:</h3>

                                            <div className="row g-5 mb-20 justify-content-center">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-20">
                                                        <label htmlFor="reg-name">Full name:</label>
                                                        <Input type="text" name="fulName" id="business-name"
                                                            value={BusinessPersonalRequestForm.values.fulName ||  userdata?.firstName == undefined ? "" : `${userdata?.firstName} ${userdata?.lastName}`}
                                                            onChange={BusinessPersonalRequestForm.handleChange}
                                                            invalid={BusinessPersonalRequestForm.touched.fulName && BusinessPersonalRequestForm.errors.fulName ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessPersonalRequestForm.errors.fulName}
                                                        </FormFeedback>
                                                    </div>

                                                    <div className="form-group mb-20">
                                                        <label htmlFor="reg-Email">Email:</label>
                                                        <Input type="text" name="email" id="reg-Email"
                                                            value={BusinessPersonalRequestForm.values.email || userdata?.email}
                                                            onChange={BusinessPersonalRequestForm.handleChange}
                                                            invalid={BusinessPersonalRequestForm.touched.email && BusinessPersonalRequestForm.errors.email ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessPersonalRequestForm.errors.email}
                                                        </FormFeedback>
                                                    </div>

                                                    <div className="form-group mb-20">
                                                        <label htmlFor="reg-Number">Phone Number:</label>
                                                        <Input type="number" name="phoneNo" id="phoneNo"
                                                            value={BusinessPersonalRequestForm.values.phoneNo || userdata?.phoneNumber}
                                                            onChange={BusinessPersonalRequestForm.handleChange}
                                                            invalid={BusinessPersonalRequestForm.touched.phoneNo && BusinessPersonalRequestForm.errors.phoneNo ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessPersonalRequestForm.errors.phoneNo}
                                                        </FormFeedback>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="reg-Vehicle">Date of Birth:</label>
                                                        <Input
                                                            type="date"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="Date of birth"
                                                            name="dob"
                                                            max={moment().format("YYYY-MM-DD")}
                                                            required
                                                            value={BusinessPersonalRequestForm.values.dob || moment(userdata?.dateOfBirth).format("YYYY-MM-DD")}
                                                            onChange={BusinessPersonalRequestForm.handleChange}
                                                            invalid={BusinessPersonalRequestForm.touched.dob && BusinessPersonalRequestForm.errors.dob ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessPersonalRequestForm.errors.dob}
                                                        </FormFeedback>
                                                    </div>

                                                </div>
                                                <div className="col-lg-6">

                                                    <div className="form-group mb-20">
                                                        <label htmlFor="reg-Postcode">Postcode:</label>
                                                        <Input type="text" name="postCode" id="log-Postcode"
                                                            value={BusinessPersonalRequestForm.values.postCode || userdata?.postCode}
                                                            onChange={BusinessPersonalRequestForm.handleChange}
                                                            invalid={BusinessPersonalRequestForm.touched.postCode && BusinessPersonalRequestForm.errors.postCode ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessPersonalRequestForm.errors.postCode}
                                                        </FormFeedback>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="log-Address">Address:</label>
                                                        <Input type="textarea" name="address" id="log-Address"
                                                            value={BusinessPersonalRequestForm.values.address || userdata?.address}
                                                            onChange={BusinessPersonalRequestForm.handleChange}
                                                            invalid={BusinessPersonalRequestForm.touched.address && BusinessPersonalRequestForm.errors.address ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessPersonalRequestForm.errors.address}
                                                        </FormFeedback>
                                                    </div>


                                                </div>
                                            </div>


                                            <hr className="mb-30 mt-40" />
                                            <h3 className="title mb-30">Service Information:</h3>

                                            <div className="row g-5 mb-20 justify-content-center">
                                                <div className="col-lg-12">
                                                    <div className="form-group mb-20">
                                                        <label htmlFor="reg-Property">The specific life admin services being requested</label>
                                                        <Input type="text" name="specificLifeAdminService" id="log-Property"
                                                            value={BusinessPersonalRequestForm.values.specificLifeAdminService}
                                                            onChange={BusinessPersonalRequestForm.handleChange}
                                                            invalid={BusinessPersonalRequestForm.touched.specificLifeAdminService && BusinessPersonalRequestForm.errors.specificLifeAdminService ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessPersonalRequestForm.errors.specificLifeAdminService}
                                                        </FormFeedback>
                                                    </div>

                                                    <div className="form-group mb-20">
                                                        <label htmlFor="reg-Property">The frequency and duration of the services</label>
                                                        <Input type="text" name="frequencyAndDurationofService" id="log-Property"
                                                            value={BusinessPersonalRequestForm.values.frequencyAndDurationofService}
                                                            onChange={BusinessPersonalRequestForm.handleChange}
                                                            invalid={BusinessPersonalRequestForm.touched.frequencyAndDurationofService && BusinessPersonalRequestForm.errors.frequencyAndDurationofService ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessPersonalRequestForm.errors.frequencyAndDurationofService}
                                                        </FormFeedback>
                                                    </div>

                                                    <div className="form-group mb-20">
                                                        <label htmlFor="reg-Property">Any specific instructions or preferences for the services</label>
                                                        <Input type="text" name="instructionOrPreference" id="log-Property"
                                                            value={BusinessPersonalRequestForm.values.instructionOrPreference}
                                                            onChange={BusinessPersonalRequestForm.handleChange}
                                                            invalid={BusinessPersonalRequestForm.touched.instructionOrPreference && BusinessPersonalRequestForm.errors.instructionOrPreference ? true : false}
                                                        />
                                                        <FormFeedback>
                                                            {BusinessPersonalRequestForm.errors.instructionOrPreference}
                                                        </FormFeedback>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr className="mb-30 mt-40" />

                                            <button className="btn colorchangeLog leadMargin edu-btn btn-medium mb-20" onClick={(e) => BusinessPersonalRequestForm.handleSubmit()}>
                                                <div>Submit</div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </div >
    );
};

export default BusinessInsurancePersonalRequest;
