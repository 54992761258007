import moment from "moment";
import React from "react";
import { Col, Row, Modal, Input } from "reactstrap";

const PersonalRequestInsuranceModel: React.FC<{ trigger?: boolean; setTrigger?: any; data: any }> = (props) => {
    const { data } = props;
    return (props.trigger) ? (
        <>
            <Modal isOpen={props.trigger} centered >
                <div className="modal-header">
                    <h5 className="text-center mt-2 mb-0 font-size-20">Customer Name : {data.fulName}</h5>
                    <button
                        type="button"
                        onClick={() => props.setTrigger(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span>&times;</span>
                    </button>
                </div>
                <div className="modal-body mt-4">

                    <Row>
                        <h3 className="title mb-20 mt-10">PERSONAL REQUEST SUBMISSIONS</h3>

                        <h3 className="title mb-30 mt-10">Personal Information:</h3>

                        <div className="row  mb-20 justify-content-center">
                            <div className="col-lg-6">
                                <div className="form-group mb-20">
                                    <label htmlFor="reg-name">Full name:</label>
                                    <Input type="text" name="fulName" id="business-name"
                                        value={data?.fulName}
                                        disabled
                                    />

                                </div>

                                <div className="form-group mb-20">
                                    <label htmlFor="reg-Email">Email:</label>
                                    <Input type="text" name="email" id="reg-Email"
                                        value={data?.email}
                                        disabled
                                    />

                                </div>

                                <div className="form-group mb-20">
                                    <label htmlFor="reg-Number">Phone Number:</label>
                                    <Input type="number" name="phoneNo" id="phoneNo"
                                        value={data?.phoneNo}
                                        disabled
                                    />

                                </div>

                                <div className="form-group">
                                    <label htmlFor="reg-Vehicle">Date of Birth:</label>
                                    <Input
                                        type="date"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Date of birth"
                                        name="dob"
                                        max={moment().format("YYYY-MM-DD")}
                                        required
                                        value={data?.dob}
                                        disabled
                                    />

                                </div>

                            </div>
                            <div className="col-lg-6">

                                <div className="form-group mb-20">
                                    <label htmlFor="reg-Postcode">Postcode:</label>
                                    <Input type="text" name="postCode" id="log-Postcode"
                                        value={data?.postCode}
                                        disabled
                                    />

                                </div>

                                <div className="form-group">
                                    <label htmlFor="log-Address">Address:</label>
                                    <Input type="textarea" name="address" id="log-Address"
                                        value={data?.address}
                                        disabled
                                    />

                                </div>


                            </div>
                        </div>


                        <hr className="mb-30 mt-40" />
                        <h3 className="title mb-30">Service Information:</h3>

                        <div className="row  mb-20 justify-content-center">
                            <div className="col-lg-12">
                                <div className="form-group mb-20">
                                    <label htmlFor="reg-Property">The specific life admin services being requested</label>
                                    <Input type="text" name="specificLifeAdminService" id="log-Property"
                                        value={data?.specificLifeAdminService}
                                        disabled
                                    />

                                </div>

                                <div className="form-group mb-20">
                                    <label htmlFor="reg-Property">The frequency and duration of the services</label>
                                    <Input type="text" name="frequencyAndDurationofService" id="log-Property"
                                        value={data?.frequencyAndDurationofService}
                                        disabled
                                    />

                                </div>

                                <div className="form-group mb-20">
                                    <label htmlFor="reg-Property">Any specific instructions or preferences for the services</label>
                                    <Input type="text" name="instructionOrPreference" id="log-Property"
                                        value={data?.instructionOrPreference}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>

                    </Row>
                </div>
            </Modal>
        </>
    ) : null;
}

export default PersonalRequestInsuranceModel;