import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { PaymentObject } from '../../models/Payment';
import { environment } from '../../environment/environment';

interface PayPalButtonProps {
  onPaymentComplete?: (payment: PaymentObject) => void;
}

const PayPalButton: React.FC<PayPalButtonProps> = ({ onPaymentComplete }) => {
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const [showPayPalButton, setShowPayPalButton] = useState(true);

  const createOrder = (data: any, actions: any) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: 1.99,
            // currency_code: 'EUR'
          },
        },
      ],
    });
  };

  const onApprove = (data: any, actions: any) => {
    return actions.order?.capture().then(async (details: any) => {
      const paymentObject: PaymentObject = {
        paymentId: details.id,
        status: details.status,
      };
      setIsPaymentCompleted(true); // Set the payment completion status to true
      onPaymentComplete && onPaymentComplete(paymentObject);
      setShowPayPalButton(false); // Hide the PayPal button
    });
  };

  const clientid = environment.PAYPAL_CLIENT_ID;

  return (
    <PayPalScriptProvider
      options={{
        'client-id': clientid,
        currency: 'GBP',
      }}
    >
      {showPayPalButton && (
        <PayPalButtons
          createOrder={createOrder}
          onApprove={onApprove}
        />
      )}
      {isPaymentCompleted && !showPayPalButton && <p>Payment completed.</p>}
    </PayPalScriptProvider>
  );
};

export default PayPalButton;
