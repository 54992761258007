import React, { useState, useContext } from "react";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Form, FormFeedback, Input } from "reactstrap";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { CongestionChargesObject } from "../../models/CongestionChargesObject";
import { PenaltyChargesService } from "../../servises/Penalty-Charges-Service";
import UserContext from "../context/UserContext";
import moment from "moment";
import { UserDetails } from "../../models/User";

const CongestionCharges: React.FC = () => {
  const [stepNumber, setStepNumber] = useState(1);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [congestionChargesData, setCongestionChargesData] =
    useState<CongestionChargesObject>({} as CongestionChargesObject);

  const [userdata, setUserdata] = useState<UserDetails | undefined>({} as UserDetails);

  const autoFill = () => {
    setUserdata(user);
  }



  const validationStep2 = useFormik({
    enableReinitialize: true,

    initialValues: {
      cc_name: "",
      cc_email: "",
      cc_phone_number: "",
      cc_vehicle_registration_number: "",
      cc_postal_code: "",
      cc_address: "",
    },
    validationSchema: Yup.object({
      // cc_name: Yup.string().required(t<string>("Please Enter Name")),
      // cc_email: Yup.string().required(t<string>("Please Enter Email")),
      // cc_phone_number: Yup.number().required(
      //   t<string>("Please Enter Phone Number")
      // ),

      // cc_postal_code: Yup.string().required(
      //   t<string>("Please Enter the Postal Code")
      // ),
      // cc_address: Yup.string().required(t<string>("Please Enter the Address")),
      cc_vehicle_registration_number: Yup.string().required(
        t<string>("Please Enter vehicle registration number")
      ),
    }),
    onSubmit: (values) => {
      setStepNumber(stepNumber + 1);
      setCongestionChargesData({
        ...congestionChargesData,
        name: values.cc_name ||  (userdata?.firstName + " " + userdata?.lastName),
        phoneNumber: parseInt(values.cc_phone_number) || userdata?.phoneNumber,
        postCode: values.cc_postal_code || userdata?.postCode,
        address: values.cc_address || userdata?.address,
        vehicleRegistrationNumber: values.cc_vehicle_registration_number,
        email: values.cc_email || userdata?.email,
      });
    },
  });

  const validationStep3 = useFormik({
    enableReinitialize: true,

    initialValues: {
      cc_charge_date: "",
      cc_charge_location: "",
      cc_charge_description: "",
    },
    validationSchema: Yup.object({
      cc_charge_date: Yup.date().required(
        t<string>("Please Enter the Charge Date")
      ),
      cc_charge_location: Yup.string().required(
        t<string>("Please Enter location of the charge")
      ),
      cc_charge_description: Yup.string().required(
        t<string>("Please Enter the Charge description")
      ),
    }),
    onSubmit: (values) => {
      setStepNumber(stepNumber + 1);
      setCongestionChargesData({
        ...congestionChargesData,
        chargeDate: values.cc_charge_date,
        chargeDescription: values.cc_charge_description,
        chargeLocation: values.cc_charge_location,
      });
    },
  });

  const validationStep4 = useFormik({
    enableReinitialize: true,

    initialValues: {
      cc_chargeAmount: "",
      cc_dueDate: "",
    },
    validationSchema: Yup.object({
      cc_chargeAmount: Yup.number().required(
        t<string>("Please Enter amount of the charge")
      ),
      cc_dueDate: Yup.string().required(
        t<string>("Please Enter the due date of the charge")
      ),
    }),
    onSubmit: (values) => {
      setStepNumber(stepNumber + 1);
      setCongestionChargesData({
        ...congestionChargesData,
        chargeAmount: parseFloat(values.cc_chargeAmount),
        dueDate: values.cc_dueDate,
      });
    },
  });

  const validationStep5 = useFormik({
    enableReinitialize: true,

    initialValues: {
      cc_congestionZoneAndRegulationsInfo: "",
      cc_discountsExemptionsAndSpecialPermitInfo: "",
      cc_appealProcessInfo: "",
      cc_vehicleInfo: "",
      cc_signature: "",
    },
    validationSchema: Yup.object({
      cc_congestionZoneAndRegulationsInfo: Yup.string().required(
        t<string>("Please Enter the congestion zone and regulations info")
      ),
      cc_discountsExemptionsAndSpecialPermitInfo: Yup.string().required(
        t<string>(
          "Please Enter the discount exemptions and special permit info"
        )
      ),
      cc_appealProcessInfo: Yup.string().required(
        t<string>("Please Enter the appeal process info")
      ),
      cc_vehicleInfo: Yup.string().required(
        t<string>("Please Enter the vehicle info")
      ),
      cc_signature: Yup.string().required(
        t<string>("Please Enter the signature")
      ),
    }),
    onSubmit: (values, onSubmitProps) => {
      const dataObject: CongestionChargesObject = {
        name: congestionChargesData.name,
        phoneNumber: congestionChargesData.phoneNumber,
        postCode: congestionChargesData.postCode,
        address: congestionChargesData.address,
        vehicleRegistrationNumber:
          congestionChargesData.vehicleRegistrationNumber,
        email: congestionChargesData.email,
        chargeDate: congestionChargesData.chargeDate,
        chargeDescription: congestionChargesData.chargeDescription,
        chargeLocation: congestionChargesData.chargeLocation,
        chargeAmount: congestionChargesData.chargeAmount,
        dueDate: congestionChargesData.dueDate,
        congestionZoneAndRegulationsInfo:
          values.cc_congestionZoneAndRegulationsInfo,
        discountsExemptionsAndSpecialPermitInfo:
          values.cc_discountsExemptionsAndSpecialPermitInfo,
        appealProcessInfo: values.cc_appealProcessInfo,
        vehicleInfo: values.cc_vehicleInfo,
        paymentMethod: "CREDIT_CARD",
        signature: values.cc_signature,
      };

      // Check if the user has a subscription plan
      if (user?.subscriptionStatus === "CANCELLED" || !user?.subscriptionId) {
        Swal.fire({
          icon: "warning",
          title: "in order to be able receive our services you will have to be subscribe",
          confirmButtonText: "Subscribe",
          confirmButtonColor: "#FD7F00",
          showCancelButton: true,
          cancelButtonText: "Cancel",
        }).then((result) => {
          if (result.isConfirmed) {
            // Navigate to subscription page
            window.location.href = "/account";
          }
        });
        return; // Stop further execution
      } else {
        setIsLoading(true);
        PenaltyChargesService.payCongestionCharges(dataObject).then((res) => {
          if (res.success) {
            setIsLoading(false);
            Swal.fire({
              icon: "success",
              title: "Congestion Payment Successfull",
              confirmButtonColor: "#FD7F00",
            });
            onSubmitProps.resetForm();
            setStepNumber(1);
            setUserdata({ firstName: "", lastName: "", phoneNumber: "" as any, postCode: "", address: "", email: "", dateOfBirth: undefined, });
          } else {
            setIsLoading(false);
            Swal.fire({
              icon: "error",
              title: res.error,
              confirmButtonColor: "#FD7F00",
            });
          }
        });
      }
    },
  });

  const registerSteps = () => {
    return (
      <div
        className="d-flex nav-dots-container justify-content-between align-items-center"
        style={{ width: "70px" }}
      >
        <span className={`${stepNumber == 1 && "bg-secondary"}`}></span>
        <span className={`${stepNumber == 2 && "bg-secondary"}`}></span>
        <span className={`${stepNumber == 3 && "bg-secondary"}`}></span>
        <span className={`${stepNumber == 4 && "bg-secondary"}`}></span>
      </div>
    );
  };

  return (
    <div className="login-page edu-footer footer-lighten bg-image footer-style-1">
      <section className="account-page-area section-gap-equal1">
        <div className="container-custom position-relative">
          <div className="row g-5 justify-content-center">
            <div className="col-lg-8">
              <div className="login-form-box registration-form">
                <div className="App">
                  <div className="box">
                    {stepNumber == 1 && (
                      <div>


                        <h3 className="title mb-20 mt-10">
                          To fill out a congestion charge form in the UK, you
                          typically need to provide the following information:
                        </h3>
                        <p className="themeColor font-16  mb-20  mt-3">Congestion charge life admin services provide assistance in dealing with congestion charges. They handle tasks such as managing payments, on your behalf.
                        </p>

                        <button
                          className="btn colorchangeLog edu-btn btn-medium mb-20"
                          type="submit"
                          onClick={autoFill}
                        >AutoFill</button>
                        <h3 className="title mb-30 mt-10">
                          Patient Information:
                        </h3>

                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validationStep2.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="row g-5 mb-20 justify-content-center">
                            <div className="col-lg-6">
                              <div className="form-group mb-20">
                                <label htmlFor="reg-name">Name:</label>
                                <Input
                                  type="text"
                                  name="cc_name"
                                  id="cc_name"
                                  value={validationStep2.values.cc_name ||  userdata?.firstName == undefined ? "" : `${userdata?.firstName} ${userdata?.lastName}`}
                                  onChange={validationStep2.handleChange}
                                  invalid={
                                    validationStep2.touched.cc_name &&
                                      validationStep2.errors.cc_name
                                      ? true
                                      : false
                                  }
                                />

                                {validationStep2.touched.cc_name &&
                                  validationStep2.errors.cc_name ? (
                                  <FormFeedback type="invalid">
                                    {validationStep2.errors.cc_name}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="form-group mb-20">
                                <label htmlFor="reg-email">Email:</label>
                                <Input
                                  type="text"
                                  name="cc_email"
                                  id="cc_email"
                                  value={validationStep2.values.cc_email || userdata?.email}
                                  onChange={validationStep2.handleChange}
                                  invalid={
                                    validationStep2.touched.cc_email &&
                                      validationStep2.errors.cc_email
                                      ? true
                                      : false
                                  }
                                />

                                {validationStep2.touched.cc_email &&
                                  validationStep2.errors.cc_email ? (
                                  <FormFeedback type="invalid">
                                    {validationStep2.errors.cc_email}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="form-group mb-20">
                                <label htmlFor="reg-Number">
                                  Phone Number:
                                </label>
                                <Input
                                  type="number"
                                  name="cc_phone_number"
                                  id="cc_phone_number"
                                  value={validationStep2.values.cc_phone_number || userdata?.phoneNumber}
                                  onChange={validationStep2.handleChange}
                                  invalid={
                                    validationStep2.touched.cc_phone_number &&
                                      validationStep2.errors.cc_phone_number
                                      ? true
                                      : false
                                  }
                                />

                                {validationStep2.touched.cc_phone_number &&
                                  validationStep2.errors.cc_phone_number ? (
                                  <FormFeedback type="invalid">
                                    {validationStep2.errors.cc_phone_number}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="form-group">
                                <label htmlFor="reg-Vehicle">
                                  Vehicle Registration Number:
                                </label>
                                <Input
                                  type="text"
                                  name="cc_vehicle_registration_number"
                                  id="cc_vehicle_registration_number"
                                  value={
                                    validationStep2.values
                                      .cc_vehicle_registration_number 
                                  }
                                  onChange={validationStep2.handleChange}
                                  invalid={
                                    validationStep2.touched
                                      .cc_vehicle_registration_number &&
                                      validationStep2.errors
                                        .cc_vehicle_registration_number
                                      ? true
                                      : false
                                  }
                                />

                                {validationStep2.touched
                                  .cc_vehicle_registration_number &&
                                  validationStep2.errors
                                    .cc_vehicle_registration_number ? (
                                  <FormFeedback type="invalid">
                                    {
                                      validationStep2.errors
                                        .cc_vehicle_registration_number
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group mb-20">
                                <label htmlFor="reg-Postcode">Postcode:</label>
                                <Input
                                  type="text"
                                  name="cc_postal_code"
                                  id="cc_postal_code"
                                  value={validationStep2.values.cc_postal_code || userdata?.postCode}
                                  onChange={validationStep2.handleChange}
                                  invalid={
                                    validationStep2.touched.cc_postal_code &&
                                      validationStep2.errors.cc_postal_code
                                      ? true
                                      : false
                                  }
                                />

                                {validationStep2.touched.cc_postal_code &&
                                  validationStep2.errors.cc_postal_code ? (
                                  <FormFeedback type="invalid">
                                    {validationStep2.errors.cc_postal_code}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="form-group">
                                <label htmlFor="log-Address">Address:</label>
                                <Input
                                  type="textarea"
                                  rows="4"
                                  name="cc_address"
                                  id="cc_address"
                                  value={validationStep2.values.cc_address || userdata?.address}
                                  onChange={validationStep2.handleChange}
                                  invalid={
                                    validationStep2.touched.cc_address &&
                                      validationStep2.errors.cc_address
                                      ? true
                                      : false
                                  }
                                />
                                {validationStep2.touched.cc_address &&
                                  validationStep2.errors.cc_address ? (
                                  <FormFeedback type="invalid">
                                    {validationStep2.errors.cc_address}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between mt-4">
                            {registerSteps()}
                            <button
                              className="btn colorchangeLog leadMargin edu-btn btn-medium"
                              type="submit"
                            >
                              {t<string>("Next")}
                            </button>
                          </div>
                        </Form>
                      </div>
                    )}

                    {stepNumber == 2 && (
                      <div>
                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validationStep3.handleSubmit();
                            return false;
                          }}
                        >
                          <h3 className="title mb-20 mt-10">
                            To fill out a congestion charge form in the UK, you
                            typically need to provide the following information:
                          </h3>

                          <h3 className="title mb-30 mt-10">
                            Charge Information:
                          </h3>

                          <div className="row g-5 mb-20 justify-content-center">
                            <div className="col-lg-6">
                              <div className="form-group mb-20">
                                <label htmlFor="reg-name">
                                  Date and Time of the charge:
                                </label>
                                <Input
                                  className="form-control"
                                  type="date"
                                  id="cc_charge_date"
                                  name="cc_charge_date"
                                  placeholder="Select Date"
                                  onChange={validationStep3.handleChange}
                                  onBlur={validationStep3.handleBlur}
                                  value={validationStep3.values.cc_charge_date}
                                  invalid={
                                    validationStep3.touched.cc_charge_date &&
                                      validationStep3.errors.cc_charge_date
                                      ? true
                                      : false
                                  }
                                />
                                {validationStep3.touched.cc_charge_date &&
                                  validationStep3.errors.cc_charge_date ? (
                                  <FormFeedback type="invalid">
                                    {validationStep3.errors.cc_charge_date}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="form-group mb-20">
                                <label htmlFor="reg-email">
                                  Location of the charge:
                                </label>
                                <Input
                                  type="text"
                                  name="cc_charge_location"
                                  id="cc_charge_location"
                                  value={
                                    validationStep3.values.cc_charge_location
                                  }
                                  onChange={validationStep3.handleChange}
                                  invalid={
                                    validationStep3.touched
                                      .cc_charge_location &&
                                      validationStep3.errors.cc_charge_location
                                      ? true
                                      : false
                                  }
                                />

                                {validationStep3.touched.cc_charge_location &&
                                  validationStep3.errors.cc_charge_location ? (
                                  <FormFeedback type="invalid">
                                    {validationStep3.errors.cc_charge_location}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label htmlFor="log-Description">
                                  Description of the charge:
                                </label>
                                <Input
                                  type="textarea"
                                  rows="4"
                                  name="cc_charge_description"
                                  id="cc_charge_description"
                                  value={
                                    validationStep3.values.cc_charge_description
                                  }
                                  onChange={validationStep3.handleChange}
                                  invalid={
                                    validationStep3.touched
                                      .cc_charge_description &&
                                      validationStep3.errors.cc_charge_description
                                      ? true
                                      : false
                                  }
                                />
                                {validationStep3.touched
                                  .cc_charge_description &&
                                  validationStep3.errors.cc_charge_description ? (
                                  <FormFeedback type="invalid">
                                    {
                                      validationStep3.errors
                                        .cc_charge_description
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between mt-4">
                            <button
                              className="btn colorchangeback back-btn btn-medium"
                              type="submit"
                              onClick={() => setStepNumber(stepNumber - 1)}
                            >
                              {t<string>("Previous")}
                            </button>
                            {registerSteps()}

                            <button
                              className="btn colorchangeLog leadMargin edu-btn btn-medium"
                              type="submit"
                            >
                              {t<string>("Next")}
                            </button>
                          </div>
                        </Form>
                      </div>
                    )}

                    {stepNumber == 3 && (
                      <div>
                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validationStep4.handleSubmit();
                            return false;
                          }}
                        >
                          <h3 className="title mb-20 mt-10">
                            To fill out a congestion charge form in the UK, you
                            typically need to provide the following information:
                          </h3>

                          <h3 className="title mb-30 mt-10">
                            Payment Information:
                          </h3>

                          <div className="row g-5 mb-20 justify-content-center">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label htmlFor="reg-Amount">
                                  Charge Amount:
                                </label>
                                <Input
                                  type="number"
                                  name="cc_chargeAmount"
                                  id="cc_chargeAmount"
                                  value={validationStep4.values.cc_chargeAmount}
                                  onChange={validationStep4.handleChange}
                                  invalid={
                                    validationStep4.touched.cc_chargeAmount &&
                                      validationStep4.errors.cc_chargeAmount
                                      ? true
                                      : false
                                  }
                                />

                                {validationStep4.touched.cc_chargeAmount &&
                                  validationStep4.errors.cc_chargeAmount ? (
                                  <FormFeedback type="invalid">
                                    {validationStep4.errors.cc_chargeAmount}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label htmlFor="log-Description">
                                  Due Date:
                                </label>
                                <Input
                                  className="form-control"
                                  type="date"
                                  id="cc_dueDate"
                                  name="cc_dueDate"
                                  placeholder="Select Date"
                                  onChange={validationStep4.handleChange}
                                  onBlur={validationStep4.handleBlur}
                                  value={validationStep4.values.cc_dueDate}
                                  invalid={
                                    validationStep4.touched.cc_dueDate &&
                                      validationStep4.errors.cc_dueDate
                                      ? true
                                      : false
                                  }
                                />
                                {validationStep4.touched.cc_dueDate &&
                                  validationStep4.errors.cc_dueDate ? (
                                  <FormFeedback type="invalid">
                                    {validationStep4.errors.cc_dueDate}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between mt-4">
                            <button
                              className="btn colorchangeback back-btn btn-medium"
                              type="submit"
                              onClick={() => setStepNumber(stepNumber - 1)}
                            >
                              {t<string>("Previous")}
                            </button>
                            {registerSteps()}

                            <button
                              className="btn colorchangeLog leadMargin edu-btn btn-medium"
                              type="submit"
                            >
                              {t<string>("Next")}{" "}
                              <i className="dw dw-right-arrow right_arrow_icon"></i>
                            </button>
                          </div>
                        </Form>
                      </div>
                    )}

                    {stepNumber == 4 && (
                      <div>
                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validationStep5.handleSubmit();
                            return false;
                          }}
                        >
                          <h3 className="title mb-20 mt-10">
                            To fill out a congestion charge form in the UK, you
                            typically need to provide the following information:
                          </h3>

                          <h3 className="title mb-30 mt-10">
                            Additional information:
                          </h3>

                          <div className="row g-5 mb-20 justify-content-center">
                            <div className="col-lg-6">
                              <div className="form-group mb-20">
                                <label htmlFor="reg-Information">
                                  Information about the congestion zone and the
                                  regulations:
                                </label>
                                <Input
                                  type="textarea"
                                  rows="4"
                                  name="cc_congestionZoneAndRegulationsInfo"
                                  id="cc_congestionZoneAndRegulationsInfo"
                                  value={
                                    validationStep5.values
                                      .cc_congestionZoneAndRegulationsInfo
                                  }
                                  onChange={validationStep5.handleChange}
                                  invalid={
                                    validationStep5.touched
                                      .cc_congestionZoneAndRegulationsInfo &&
                                      validationStep5.errors
                                        .cc_congestionZoneAndRegulationsInfo
                                      ? true
                                      : false
                                  }
                                />
                                {validationStep5.touched
                                  .cc_congestionZoneAndRegulationsInfo &&
                                  validationStep5.errors
                                    .cc_congestionZoneAndRegulationsInfo ? (
                                  <FormFeedback type="invalid">
                                    {
                                      validationStep5.errors
                                        .cc_congestionZoneAndRegulationsInfo
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="form-group mb-20">
                                <label htmlFor="reg-about">
                                  Information about the appeals process:
                                </label>
                                <Input
                                  type="textarea"
                                  rows="4"
                                  name="cc_appealProcessInfo"
                                  id="cc_appealProcessInfo"
                                  value={
                                    validationStep5.values.cc_appealProcessInfo
                                  }
                                  onChange={validationStep5.handleChange}
                                  invalid={
                                    validationStep5.touched
                                      .cc_appealProcessInfo &&
                                      validationStep5.errors.cc_appealProcessInfo
                                      ? true
                                      : false
                                  }
                                />
                                {validationStep5.touched.cc_appealProcessInfo &&
                                  validationStep5.errors.cc_appealProcessInfo ? (
                                  <FormFeedback type="invalid">
                                    {
                                      validationStep5.errors
                                        .cc_appealProcessInfo
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="form-group mb-20">
                                <label htmlFor="reg-name">Signature:</label>
                                <Input
                                  type="text"
                                  name="cc_signature"
                                  id="cc_signature"
                                  value={validationStep5.values.cc_signature}
                                  onChange={validationStep5.handleChange}
                                  invalid={
                                    validationStep5.touched.cc_signature &&
                                      validationStep5.errors.cc_signature
                                      ? true
                                      : false
                                  }
                                />

                                {validationStep5.touched.cc_signature &&
                                  validationStep5.errors.cc_signature ? (
                                  <FormFeedback type="invalid">
                                    {validationStep5.errors.cc_signature}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group mb-20">
                                <label htmlFor="reg-discounts">
                                  Information about discounts, exemptions or
                                  special permits:
                                </label>
                                <Input
                                  type="textarea"
                                  rows="4"
                                  name="cc_discountsExemptionsAndSpecialPermitInfo"
                                  id="cc_discountsExemptionsAndSpecialPermitInfo"
                                  value={
                                    validationStep5.values
                                      .cc_discountsExemptionsAndSpecialPermitInfo
                                  }
                                  onChange={validationStep5.handleChange}
                                  invalid={
                                    validationStep5.touched
                                      .cc_discountsExemptionsAndSpecialPermitInfo &&
                                      validationStep5.errors
                                        .cc_discountsExemptionsAndSpecialPermitInfo
                                      ? true
                                      : false
                                  }
                                />
                                {validationStep5.touched
                                  .cc_discountsExemptionsAndSpecialPermitInfo &&
                                  validationStep5.errors
                                    .cc_discountsExemptionsAndSpecialPermitInfo ? (
                                  <FormFeedback type="invalid">
                                    {
                                      validationStep5.errors
                                        .cc_discountsExemptionsAndSpecialPermitInfo
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="form-group mb-20">
                                <label htmlFor="reg-vehicle">
                                  Information about the vehicle (e.g. make,
                                  model, color):
                                </label>
                                <Input
                                  type="textarea"
                                  rows="4"
                                  name="cc_vehicleInfo"
                                  id="cc_vehicleInfo"
                                  value={validationStep5.values.cc_vehicleInfo}
                                  onChange={validationStep5.handleChange}
                                  invalid={
                                    validationStep5.touched.cc_vehicleInfo &&
                                      validationStep5.errors.cc_vehicleInfo
                                      ? true
                                      : false
                                  }
                                />
                                {validationStep5.touched.cc_vehicleInfo &&
                                  validationStep5.errors.cc_vehicleInfo ? (
                                  <FormFeedback type="invalid">
                                    {validationStep5.errors.cc_vehicleInfo}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between mt-4">
                            <button
                              className="btn colorchangeback back-btn btn-medium"
                              type="submit"
                              onClick={() => setStepNumber(stepNumber - 1)}
                            >
                              {t<string>("Previous")}
                            </button>
                            {registerSteps()}
                            <button
                              className="btn colorchangeLog leadMargin edu-btn btn-medium"
                              type="submit"
                            >
                              {t<string>("Finish")}
                            </button>
                          </div>
                        </Form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CongestionCharges;
