import React, { useState, useContext } from "react";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";
import { Form, FormFeedback, Input } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { DcotorAppointmentRequestDataObject } from "../../models/DoctorAppointment";
import { DoctorAppointmentService } from "../../servises/Doctor-Appointment-Service";
import Swal from "sweetalert2";
import UserContext from "../context/UserContext";
import ServiceLogin from "./ServicesLogin";
import moment from "moment";
import { UserDetails } from "../../models/User";

const PrivateHospital: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useContext(UserContext);

  const [userdata, setUserdata] = useState<UserDetails | undefined>({} as UserDetails);

  const autoFill = () => {
    setUserdata(user);
  }


  const appointmentFormValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      regname: "",
      reg_email_address: "",
      patient_phone_number: "",
      method_of_communication: "",
      appointment_location: "",
      appointmentDate: "",
      rescheduledDate: "",
      reasonToReschedule: "",
      addtionalCharges: "",
    },
    validationSchema: Yup.object({
      // regname: Yup.string().required(t<string>("Please Enter Name")),
      // reg_email_address: Yup.string().required(t<string>("Please Enter Email")),
      // patient_phone_number: Yup.number().required(
      //   t<string>("Please Enter Phone Number")
      // ),
      method_of_communication: Yup.string().required(
        t<string>("Please Select an Option")
      ),
      appointment_location: Yup.string().required(
        t<string>("Please Enter the Location")
      ),
      appointmentDate: Yup.date().required(
        t<string>("Select a Date and Time For the Appointment")
      ),
      rescheduledDate: Yup.date().required(
        t<string>("Select a Date and Time For the rescheduled Date")
      ),
      reasonToReschedule: Yup.string().required(
        t<string>("Please Enter the reason For rescheduling")
      ),
      addtionalCharges: Yup.string().required(
        t<string>("Please Select an Option")
      ),

      //   dateOfBirth: Yup.date()
      //     .required(t<string>("Please Enter Your Birth Date"))
      //     .test(
      //       "dateOfBirth",
      //       t<string>("Must be over 18 years old"),
      //       (value) => {
      //         return moment().diff(moment(value), "years") >= 18;
      //       }
      //     ),
    }),
    onSubmit: (values, onSubmitProps) => {
      const appointmentObject: DcotorAppointmentRequestDataObject = {
        patientName: values.regname ||  (userdata?.firstName + " " + userdata?.lastName),
        patientEmail: values.reg_email_address || userdata?.email,
        patientPhoneNumber: parseInt(values.patient_phone_number) || userdata?.phoneNumber,
        preferredMethodOfCommunication: values.method_of_communication,
        appointmentType: "PRIVATE_HOSPITAL",
        appointmentLocation: values.appointment_location,
        appointmentDate: values.appointmentDate,
        rescheduleDate: values.rescheduledDate,
        reasonForReschedule: values.reasonToReschedule,
        addtionalCharges: values.addtionalCharges,
      };

      if (user?.subscriptionStatus === "CANCELLED" || !user?.subscriptionId) {
        Swal.fire({
          icon: "warning",
          title: "in order to be able receive our services you will have to be subscribe",
          confirmButtonText: "Subscribe",
          confirmButtonColor: "#FD7F00",
          showCancelButton: true,
          cancelButtonText: "Cancel",
        }).then((result) => {
          if (result.isConfirmed) {
            // Navigate to subscription page
            window.location.href = "/account";
          }
        });
        return; // Stop further execution
      } else {
        setIsLoading(true);
        DoctorAppointmentService.createADoctorAppointment(appointmentObject).then((res) => {
          if (res.success) {
            setIsLoading(false);
            Swal.fire({ title: "Appointment Added" });
            onSubmitProps.resetForm();
            setUserdata({ firstName: "", lastName: "", phoneNumber: "" as any, postCode: "", address: "", email: "", dateOfBirth: undefined, });
          } else {
            setIsLoading(false);
            Swal.fire({
              icon: "error",
              title: res.error,
              confirmButtonColor: "#FD7F00",
            });
          }
        });
      }
    },
  });

  return (
    <div className="login-page edu-footer footer-lighten bg-image footer-style-1">
      {user?._id ? (
        <section className="account-page-area section-gap-equal1">
          <div className="container-custom position-relative">
            <div className="row g-5 justify-content-center">
              <div className="col-lg-8">

                <div className="login-form-box registration-form">
                  <div className="App">
                    <div className="box">
                      <div>
                        <h3 className="title mb-20 mt-10">
                          Patient Information:
                        </h3>
                        <p className="themeColor mb-30 font-16 mt-3">Private hospital doctor appointment life admin services aid individuals in managing their medical appointments. They handle tasks such as scheduling doctor visits, rescheduling or canceling appointments, providing reminders, assisting with paperwork, and ensuring a smooth and organized healthcare experience.</p>

                        <button
                          className="btn colorchangeLog edu-btn btn-medium mb-20"
                          type="submit"
                          onClick={autoFill}
                        >AutoFill</button>

                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            appointmentFormValidation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="row g-5 mb-0 justify-content-center">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label htmlFor="reg-name">
                                  Patient's full name:
                                </label>
                                <Input
                                  type="text"
                                  name="regname"
                                  id="regname"
                                  value={
                                    appointmentFormValidation.values.regname ||  userdata?.firstName == undefined ? "" : `${userdata?.firstName} ${userdata?.lastName}`}
                                  onChange={
                                    appointmentFormValidation.handleChange
                                  }
                                  invalid={
                                    appointmentFormValidation.touched.regname &&
                                      appointmentFormValidation.errors.regname
                                      ? true
                                      : false
                                  }
                                />

                                {appointmentFormValidation.touched.regname &&
                                  appointmentFormValidation.errors.regname ? (
                                  <FormFeedback type="invalid">
                                    {appointmentFormValidation.errors.regname}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <p className="mb-20">
                                As it appears on the original appointment.
                              </p>

                              <div className="form-group mb-20">
                                <label htmlFor="reg-address">
                                  Patient's email address:
                                </label>
                                <Input
                                  type="text"
                                  name="reg_email_address"
                                  id="reg_email_address"
                                  value={
                                    appointmentFormValidation.values
                                      .reg_email_address || userdata?.email
                                  }
                                  onChange={
                                    appointmentFormValidation.handleChange
                                  }
                                  invalid={
                                    appointmentFormValidation.touched
                                      .reg_email_address &&
                                      appointmentFormValidation.errors
                                        .reg_email_address
                                      ? true
                                      : false
                                  }
                                />
                                {appointmentFormValidation.touched
                                  .reg_email_address &&
                                  appointmentFormValidation.errors
                                    .reg_email_address ? (
                                  <FormFeedback type="invalid">
                                    {
                                      appointmentFormValidation.errors
                                        .reg_email_address
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="form-group">
                                <label htmlFor="reg-number">
                                  Patient's phone number:
                                </label>

                                <Input
                                  type="number"
                                  placeholder=""
                                  name="patient_phone_number"
                                  id="patient_phone_number"
                                  value={
                                    appointmentFormValidation.values
                                      .patient_phone_number || userdata?.phoneNumber
                                  }
                                  onChange={
                                    appointmentFormValidation.handleChange
                                  }
                                  invalid={
                                    appointmentFormValidation.touched
                                      .patient_phone_number &&
                                      appointmentFormValidation.errors
                                        .patient_phone_number
                                      ? true
                                      : false
                                  }
                                />
                                {appointmentFormValidation.touched
                                  .patient_phone_number &&
                                  appointmentFormValidation.errors
                                    .patient_phone_number ? (
                                  <FormFeedback type="invalid">
                                    {
                                      appointmentFormValidation.errors
                                        .patient_phone_number
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label htmlFor="log-drive">
                                  Preferred Method of communication:
                                </label>
                              </div>
                              <div className="card-heading mb-2">
                                <div className="row">
                                  <div className="col">
                                    <div
                                      className="form-check mb-3 "
                                      style={{ marginRight: "1.2rem" }}
                                    >
                                      <Input
                                        className="form-check-input"
                                        type="radio"
                                        name="method_of_communication"
                                        id="Number"
                                        value="PHONE"
                                        onChange={
                                          appointmentFormValidation.handleChange
                                        }
                                        invalid={
                                          appointmentFormValidation.touched
                                            .method_of_communication &&
                                            appointmentFormValidation.errors
                                              .method_of_communication
                                            ? true
                                            : false
                                        }
                                      />

                                      <label
                                        className="form-check-label"
                                        htmlFor="Number"
                                      >
                                        Phone Number
                                      </label>
                                    </div>

                                    <div className="form-check mb-3 ">
                                      <Input
                                        className="form-check-input"
                                        type="radio"
                                        name="method_of_communication"
                                        id="Email"
                                        value="EMAIL"
                                        onChange={
                                          appointmentFormValidation.handleChange
                                        }
                                        invalid={
                                          appointmentFormValidation.touched
                                            .method_of_communication &&
                                            appointmentFormValidation.errors
                                              .method_of_communication
                                            ? true
                                            : false
                                        }
                                      />

                                      <label
                                        className="form-check-label"
                                        htmlFor="Email"
                                      >
                                        Email Address
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <hr className="mb-30 mt-40" />

                          <h3 className="title mb-30">Appointment Details:</h3>

                          <div className="row g-5 mb-20 justify-content-center">
                            <div className="col-lg-6">


                              <div className="form-group mb-20">
                                <label htmlFor="log-Location">
                                  Location of the original Appointment (Name of
                                  the clinic)
                                </label>

                                <Input
                                  type="text"
                                  name="appointment_location"
                                  id="appointment_location"
                                  value={
                                    appointmentFormValidation.values
                                      .appointment_location
                                  }
                                  onChange={
                                    appointmentFormValidation.handleChange
                                  }
                                  invalid={
                                    appointmentFormValidation.touched
                                      .appointment_location &&
                                      appointmentFormValidation.errors
                                        .appointment_location
                                      ? true
                                      : false
                                  }
                                />
                                {appointmentFormValidation.touched
                                  .appointment_location &&
                                  appointmentFormValidation.errors
                                    .appointment_location ? (
                                  <FormFeedback type="invalid">
                                    {
                                      appointmentFormValidation.errors
                                        .appointment_location
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="form-group mb-20">
                                <label htmlFor="log-Location">
                                  Preferred date and time for the appointment:
                                </label>

                                <Input
                                  className="form-control"
                                  type="datetime-local"
                                  defaultValue="2019-08-19"
                                  id="example-date-input"
                                  name="appointmentDate"
                                  placeholder="Select Date"
                                  onChange={
                                    appointmentFormValidation.handleChange
                                  }
                                  onBlur={appointmentFormValidation.handleBlur}
                                  value={
                                    appointmentFormValidation.values
                                      .appointmentDate
                                  }
                                  invalid={
                                    appointmentFormValidation.touched
                                      .appointmentDate &&
                                      appointmentFormValidation.errors
                                        .appointmentDate
                                      ? true
                                      : false
                                  }
                                />
                                {appointmentFormValidation.touched
                                  .appointmentDate &&
                                  appointmentFormValidation.errors
                                    .appointmentDate ? (
                                  <FormFeedback type="invalid">
                                    {
                                      appointmentFormValidation.errors
                                        .appointmentDate
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label htmlFor="log-rearrangement">
                                  Reason for rearrangement
                                </label>
                                <Input
                                  type="textarea"
                                  rows="4"
                                  name="reasonToReschedule"
                                  id="reasonToReschedule"
                                  value={
                                    appointmentFormValidation.values
                                      .reasonToReschedule
                                  }
                                  onChange={
                                    appointmentFormValidation.handleChange
                                  }
                                  invalid={
                                    appointmentFormValidation.touched
                                      .reasonToReschedule &&
                                      appointmentFormValidation.errors
                                        .reasonToReschedule
                                      ? true
                                      : false
                                  }
                                />
                                {appointmentFormValidation.touched
                                  .reasonToReschedule &&
                                  appointmentFormValidation.errors
                                    .reasonToReschedule ? (
                                  <FormFeedback type="invalid">
                                    {
                                      appointmentFormValidation.errors
                                        .reasonToReschedule
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <p className="mb-20">
                                A brief explanation of why you need to
                                reschedule the appointment.
                              </p>
                            </div>
                          </div>

                          <hr className="mb-30 mt-40" />

                          <h3 className="title mb-30">
                            Additional Information:
                          </h3>

                          <div className="row g-5 mb-20 justify-content-center">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label htmlFor="reg-revenue">
                                  Preferred date and time: you would like to
                                  reschedule the appointment for
                                </label>
                                <Input
                                  className="form-control"
                                  type="datetime-local"
                                  defaultValue="2019-08-19"
                                  id="rescheduledDate"
                                  name="rescheduledDate"
                                  placeholder="Select Date"
                                  onChange={
                                    appointmentFormValidation.handleChange
                                  }
                                  onBlur={appointmentFormValidation.handleBlur}
                                  value={
                                    appointmentFormValidation.values
                                      .rescheduledDate
                                  }
                                  invalid={
                                    appointmentFormValidation.touched
                                      .rescheduledDate &&
                                      appointmentFormValidation.errors
                                        .rescheduledDate
                                      ? true
                                      : false
                                  }
                                />
                                {appointmentFormValidation.touched
                                  .rescheduledDate &&
                                  appointmentFormValidation.errors
                                    .rescheduledDate ? (
                                  <FormFeedback type="invalid">
                                    {
                                      appointmentFormValidation.errors
                                        .rescheduledDate
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label htmlFor="log-drive">
                                  Additional Charges
                                </label>
                              </div>
                              <div className="card-heading mb-2">
                                <div className="row">
                                  <div
                                    className="col"
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <div
                                      className="form-check mb-3 "
                                      style={{ marginRight: "1.2rem" }}
                                    >
                                      <Input
                                        className="form-check-input"
                                        type="radio"
                                        name="addtionalCharges"
                                        id="YES"
                                        value="YES"
                                        onChange={
                                          appointmentFormValidation.handleChange
                                        }
                                        invalid={
                                          appointmentFormValidation.touched
                                            .addtionalCharges &&
                                            appointmentFormValidation.errors
                                              .addtionalCharges
                                            ? true
                                            : false
                                        }
                                      />

                                      <label
                                        className="form-check-label"
                                        htmlFor="YES"
                                      >
                                        YES
                                      </label>
                                    </div>

                                    <div className="form-check mb-3 ">
                                      <Input
                                        className="form-check-input"
                                        type="radio"
                                        name="addtionalCharges"
                                        id="NO"
                                        value="NO"
                                        onChange={
                                          appointmentFormValidation.handleChange
                                        }
                                        invalid={
                                          appointmentFormValidation.touched
                                            .addtionalCharges &&
                                            appointmentFormValidation.errors
                                              .addtionalCharges
                                            ? true
                                            : false
                                        }
                                      />

                                      <label
                                        className="form-check-label"
                                        htmlFor="NO"
                                      >
                                        NO
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {isLoading ? (
                            <button
                              disabled={true}
                              className="btn colorchangeLog leadMargin edu-btn btn-medium mb-20"
                            >
                              <div>Submit</div>
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn colorchangeLog leadMargin edu-btn btn-medium mb-20"
                            >
                              <div>Submit</div>
                            </button>
                          )}
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      ) : (
        <ServiceLogin />
      )
      }
    </div >
  );
};

export default PrivateHospital;
