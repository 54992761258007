import React, { useState, useContext, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Input, } from "reactstrap";
import moment from "moment";
import { UserDetails } from "../../models/User";
import { CommonService } from "../../servises/CommonService";
import swal from "sweetalert";
import { RouteName } from "../../RouteName";

const ForgetPassword: React.FC = () => {
    const initialState = { email: "" }
    const [userData, setUserData] = useState(initialState);
    const history = useHistory();
    const initialState2 = { otp: "" }
    const [userData2, setUserData2] = useState(initialState2);
    const [forgetPassword1, setForgetPassword1] = useState(true);
    const [forgetPassword2, setForgetPassword2] = useState(false);

    const initialState3 = { password: "" }
    const [userData3, setUserData3] = useState(initialState3);
    const [forgetPassword3, setForgetPassword3] = useState(false);

    const sendEmail = () => {
        if (!userData.email) {
            swal({ icon: "error", title: "Please enter user email!" });
            return;
        } else {
            const data = { email: userData.email }
            CommonService.forgotPassword1(data).then((res) => {
                if (res.success) {
                    setForgetPassword1(false);
                    setForgetPassword2(true);
                } else {
                    swal({ icon: "error", title: res.error });
                }
            });
            return;
        }
    }

    const confiirmOtp = () => {
        if (!userData2.otp) {
            swal({ icon: "error", title: "Please enter !" });
            return;
        } else {
            const data = { email: userData.email, otp: userData2.otp }
            CommonService.forgotPassword2(data).then((res) => {
                if (res.success) {
                    setForgetPassword1(false);
                    setForgetPassword2(false);
                    setForgetPassword3(true);
                } else {
                    swal({ icon: "error", title: res.error });
                }
            });
            return;
        }
    }

    const createNewPassword = () => {
        if (!userData3.password) {
            swal({ icon: "error", title: "Please enter New Password !" });
            return;
        } else {
            const data = { email: userData.email, password: userData3.password }
            CommonService.newPassword(data).then((res) => {
                if (res.success) {
                    swal({ icon: "success", title: res.message });
                    history.goBack()
                } else {
                    swal({ icon: "error", title: res.error });
                }
            });
            return;
        }
    }

    return (
        <section className="account-page-area section-gap-equal">
            <div className="container position-relative">
                <div className="row g-5 justify-content-center">
                    <div className="col-lg-5">
                        {forgetPassword1 == true &&
                            <div className="login-form-box3">
                                <h3 className="title">Forgot Password</h3>
                                <p>Fill email to recover your Password.</p>
                                <div className="row g-5 mb-20 justify-content-center">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Name">Email Address:</label>
                                        <input type="text" name="reg-Name" id="reg-Name" className="input-boarder" onChange={(e) => setUserData({ ...userData, email: e.target.value })} />
                                    </div>

                                    <div className="form-group">
                                        <button type="submit" className="edu-btn btn-medium" id="ajaxButton" onClick={() => sendEmail()}>Verify <i
                                            className="icon-4"></i></button>
                                    </div>
                                </div>
                            </div>
                        }

                        {forgetPassword2 == true &&
                            <div className="login-form-box3">
                                <h3 className="title">Forgot Password</h3>
                                <p>Fill otp number to recover your Password.</p>
                                <div className="row g-5 mb-20 justify-content-center">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Name">OTP Number:</label>
                                        <input type="text" name="reg-Name" id="reg-Name" placeholder="OTP Number" className="input-boarder" onChange={(e) => setUserData2({ ...userData2, otp: e.target.value })} />
                                    </div>

                                    <div className="form-group">
                                        <button type="submit" className="edu-btn btn-medium" id="ajaxButton" onClick={() => confiirmOtp()}>Verify <i
                                            className="icon-4"></i></button>
                                    </div>
                                </div>
                            </div>
                        }

                        {forgetPassword3 == true &&
                            <div className="login-form-box3">
                                <h3 className="title">Recover Password</h3>
                                <p>Fill New Password to recover your Password.</p>
                                <div className="row g-5 mb-20 justify-content-center">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Name">New Password:</label>
                                        <input type="text" name="reg-Name" id="reg-Name" placeholder="New Password" className="input-boarder" onChange={(e) => setUserData3({ ...userData3, password: e.target.value })} />
                                    </div>

                                    <div className="form-group">
                                        <button type="submit" className="edu-btn btn-medium" id="ajaxButton" onClick={() => createNewPassword()}>Verify <i
                                            className="icon-4"></i></button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <ul className="shape-group">
                    <li className="shape-1 scene"><img data-depth="2" src="../assets/images/about/shape-07.png" alt="Shape" />
                    </li>
                    <li className="shape-2 scene"><img data-depth="-2" src="../assets/images/about/shape-13.png"
                        alt="Shape" /></li>
                    <li className="shape-3 scene"><img data-depth="2" src="../assets/images/counterup/shape-02.png"
                        alt="Shape" /></li>
                </ul>
            </div>
        </section>
    );
};

export default ForgetPassword;
