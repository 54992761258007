import React, { useState, useContext, useRef, useEffect } from "react";
import img1 from "../assets/images/about/shape-13.png";
import img2 from "../assets/images/about/shape-15.png";
import img3 from "../assets/images/about/shape-07.png";
import Datetime from "react-datetime";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";
import UserContext from "../context/UserContext";
import "react-toastify/dist/ReactToastify.css";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { Util } from "../../Util";
import swal from "sweetalert";
import { UserDetails } from "../../models/User";
import { MedicalProfile } from "../../models/MedicalProfile";
import { Form, Input, UncontrolledTooltip } from "reactstrap";
import moment from "moment";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { CommonService } from "../../servises/CommonService";
import { CarProfile } from "../../models/CarProfile";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import PayPalSubscriptionButton from "./subscription-payment";
import "react-phone-number-input/style.css";
import PaymentService from "../../servises/PaymentService";
import { SubscriptionDetails } from "../../models/subscription";
import CancelSubscriptionButton from "./cancelSubscription";
import { PersonalRequest } from "../../models/PersonalRequestSubmissions";
import InsuranceService from "../../servises/InsuranceService";
import Modal2 from "../popup/Modal2";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
const Account: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [user, setUser] = useContext(UserContext);
    const [userdata, setUserdata] = useState<UserDetails | undefined>({} as UserDetails);
    const [leadData, setLeadData] = useState<{}>({} as {});
    const leadDataRef = useRef<any>();
    leadDataRef.current = leadData;
    const [isLoading, setIsLoading] = useState(false);
    const [identificationFile, setIdentificationFile] = useState({ preview: "", preview2: "", raw: [] as any });
    const [isDelete] = useState([] as any);
    const [manufactureData, setManufactureData] = useState({ manufacturedYear: "" });
    const manufactureRef = useRef<any>();
    manufactureRef.current = manufactureData;
    const [modal_center, setModalCenter] = React.useState(false);
    const [carProfile, setCarProfile] = useState<CarProfile[]>([]);
    const [offset, setOffset] = useState(1);
    const limit = 10;
    const [count, setCount] = useState(10);
    const [isActivated, setIsActivated] = useState(false);
    const [dataSingle, setDataSingle] = useState<CarProfile>({} as CarProfile);
    const [isOpenData, setIsOpenData] = React.useState(false);
    const [value, setValue] = useState()
    const handleSubscriptionApproval = () => {
        setIsActivated(true);
        fetchSubscriptions(); // Fetch updated subscription data
    };

    const handleCancelSubscription = () => {
        //activate the subscription
        setIsActivated(false);
        fetchSubscriptions(); // Fetch updated subscription data
    };

    function togCenterReview() {
        setModalCenter(!modal_center);
    }

    // format date string where comes from subscription details
    const formatDate = (dateString: string | undefined) => {
        if (!dateString) {
            return "";
        }
        const date = new Date(dateString);
        return date.toLocaleDateString();
    };


    const [personalRequest, setPersonalRequest] = useState<PersonalRequest[]>([]);
    useEffect(() => {
        fetchpersonalRequest();
    }, []);

    const fetchpersonalRequest = () => {
        InsuranceService.getPersonalRequestInfo(user?._id).then(res => {
            if (res.success) {
                setPersonalRequest(res.data);
                setIsLoading(false);
                setIsActivated(!!res.data.length)
            } else {
                setPersonalRequest([]);
                setIsLoading(false);

            }
        });
    };

    const [subscriptions, setSubscriptions] = useState<SubscriptionDetails[]>([]);
    useEffect(() => {
        fetchSubscriptions();
    }, []);

    const fetchSubscriptions = () => {
        PaymentService.getSubscriptions(user?._id).then(res => {
            if (res.success) {
                setSubscriptions(res.data);
                setIsLoading(false);
                setIsActivated(!!res.data.filter((sub: any) => (sub.status == "ACTIVE")).length)
            } else {
                setSubscriptions([]);
                setIsLoading(false);
            }
        });
    };

    useEffect(() => {
        setUserdata(user);
        getAllUsers();
    }, []);

    const getAllUsers = () => {
        CommonService.getCarProfileInfo(limit, 1).then(res => {
            if (res.success) {
                setCarProfile(res.data);
                setCount(res.data?.length);
                setIsLoading(false);
            } else {
                setCarProfile([]);
                setIsLoading(false);
            }
        });
    };

    const seeMore = () => {
        const updatedSkip = offset + 1;

        setOffset(updatedSkip);



        setIsLoading(true);

        CommonService.getCarProfileInfo(10, updatedSkip).then(res => {
            if (res.success) {
                res.data.map((client: any) => {
                    setCarProfile(clients => [...clients, client]);
                });

                setCount(res.data?.length);
            }

            setIsLoading(false);
        });
    };


    useEffect(() => {
        setLeadData({
            ...leadDataRef.current,
            tab: `dashboard`,
            dashboard: "#1293E3",
            general: "#233A6C",
            medical: "#233A6C",
            car: "#233A6C",
            membership: "#233A6C",
        });
    }, []);

    // toggle modal on change

    function toggleModal() {
        setIsModalOpen(!isModalOpen);
    }
    const inputProps = {
        placeholder: "Manufacture Year",
        className: "form-control input-boarder",
    };


    const logout = () => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/signin";
    };

    const setInsuranceBtn = (value: any) => {
        if (value == `dashboard`) {
            setLeadData({
                ...leadDataRef.current,
                tab: `dashboard`,
                dashboard: "#1293E3",
                general: "#233A6C",
                medical: "#233A6C",
                car: "#233A6C",
                membership: "#233A6C",
            });
        } else if (value == `general`) {
            setLeadData({
                ...leadDataRef.current,
                tab: `general`,
                dashboard: "#233A6C",
                general: "#1293E3",
                medical: "#233A6C",
                car: "#233A6C",
                membership: "#233A6C"
            });
        } else if (value == `medical`) {
            setLeadData({
                ...leadDataRef.current,
                tab: `medical`,
                dashboard: "#233A6C",
                general: "#233A6C",
                medical: "#1293E3",
                car: "#233A6C",
                membership: "#233A6C"
            });
        }
        else if (value == `car`) {
            setLeadData({
                ...leadDataRef.current,
                tab: `car`,
                dashboard: "#233A6C",
                general: "#233A6C",
                medical: "#233A6C",
                car: "#1293E3",
                membership: "#233A6C"
            });
        }
        else {
            setLeadData({
                ...leadDataRef.current,
                tab: `membership`,
                dashboard: "#233A6C",
                general: "#233A6C",
                medical: "#233A6C",
                car: "#233A6C",
                membership: "#1293E3"
            });
        }
    };

    const setShowData = (ap: any) => {
        setDataSingle(ap);
        console.log(ap)
        setIsOpenData(!isOpenData);
    }



    const imageTypeRegex = /image\/(png|jpg|jpeg|gif|bmp|tif|tiff|eps)/gm;
    const selectFile = (e: any) => {
        if (e.target.files.length && e.target.files[0].type.match(imageTypeRegex)) {
            const formData: FormData = new FormData();

            for (const file of e.target.files) {
                if (e.target.files) {
                    formData.append("profileImage", file);
                }
            }

            axios
                .post(Util.apiAuthUrl(`upload/profile-photo`), formData, {
                })
                .then((res: any) => {
                    if (res.success == true) {
                        setUser(res.data);
                        swal({ icon: "success", title: "Profile Image Uploded!" });

                    } else {
                        swal({ icon: "error", title: "Failed to upload the profile image!" });
                        return;
                    }
                })
                .catch(error => {
                    if (error) {
                        swal({ icon: "error", title: "API Error! Failed to upload the profile image!" })
                    }
                });

            return false;
        } else {
            toast.error("Only images can be uploaded.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    };


    const handleSubmission = (data: MedicalProfile) => {
        setIsLoading(true);
        CommonService.addMedicalProfileInfo(data).then((res) => {
            if (res.success) {
                setIsLoading(false);
                setUser(res.data as any);
                Swal.fire({ title: "Medical Profile Information Added" });
            } else {
                setIsLoading(false);
                Swal.fire({
                    icon: "error",
                    title: res.error,
                    confirmButtonColor: "#FD7F00",
                });
            }
        });
    };
    const [dateValue, setDateValue] = useState(null);
    function handleDateUpdate(e: any) {
        const dateValue = e.target.value;
        setDateValue(dateValue);
    }
    const db = moment(dateValue || user?.medicalProfile?.dateOfBirth || user?.dateOfBirth).format("YYYY-MM-DD");
    const dateData = useRef<any>();
    dateData.current = db;
    const appointmentFormValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            firstName: "",
            lastName: "",
            dateOfBirth: "",
            patientAddress: "",
            patientPhoneNumber: "",
            patientPostcode: "",
            patientEmail: "",
            patientNHS: "",
            patientOccupation: "",
            patientNextOfKin: "",
            patientGPNameAndContactInfo: "",
            patientMedicalHistory: "",
            patientCurrentMedications: "",
            patientAllergies: "",
            patientVaccinationHistory: "",
            patientLifestyleInfo: "",
            patientFamilyMedicalHistory: "",
        },
        onSubmit: (values, onSubmitProps) => {
            const appointmentObject: MedicalProfile = {
                firstName: values.firstName || user?.medicalProfile?.firstName as any || user?.firstName as any,
                lastName: values.lastName || user?.medicalProfile?.lastName as any || user?.lastName as any,
                dateOfBirth: dateValue || user?.medicalProfile?.dateOfBirth as any || user?.dateOfBirth as any,
                patientAddress: values.patientAddress || user?.medicalProfile?.patientAddress as any || user?.address as any,
                patientPhoneNumber: parseInt(value as any) || user?.medicalProfile?.patientPhoneNumber as any || user?.phoneNumber as any,
                patientPostcode: values.patientPostcode || user?.medicalProfile?.patientPostcode as any || user?.postCode as any,
                patientEmail: values.patientEmail || user?.medicalProfile?.patientEmail as any || user?.email as any,
                patientNHS: values.patientNHS || user?.medicalProfile?.patientNHS as any,
                patientOccupation: values.patientOccupation || user?.medicalProfile?.patientOccupation as any,
                patientNextOfKin: values.patientNextOfKin || user?.medicalProfile?.patientNextOfKin as any,
                patientGPNameAndContactInfo: values.patientGPNameAndContactInfo || user?.medicalProfile?.patientGPNameAndContactInfo as any,
                patientMedicalHistory: values.patientMedicalHistory || user?.medicalProfile?.patientMedicalHistory as any,
                patientCurrentMedications: values.patientCurrentMedications || user?.medicalProfile?.patientCurrentMedications as any,
                patientAllergies: values.patientAllergies || user?.medicalProfile?.patientAllergies as any,
                patientVaccinationHistory: values.patientVaccinationHistory || user?.medicalProfile?.patientVaccinationHistory as any,
                patientLifestyleInfo: values.patientLifestyleInfo || user?.medicalProfile?.patientLifestyleInfo as any,
                patientFamilyMedicalHistory: values.patientFamilyMedicalHistory || user?.medicalProfile?.patientFamilyMedicalHistory as any,
            };
            // onSubmitProps.resetForm();
            handleSubmission(appointmentObject);
        },
    });


    const [dateUserValue, setDateUserValue] = useState(null);
    function handleUserDateUpdate(e: any) {
        const dateValue = e.target.value;
        setDateUserValue(dateValue);
    }
    const dbuser = moment(dateUserValue || user?.dateOfBirth).format("YYYY-MM-DD");
    const dateDataUser = useRef<any>();
    dateDataUser.current = dbuser;

    const profileSubmission = (data: UserDetails) => {
        setIsLoading(true);
        if (identificationFile.preview) {
            CommonService.updateFiles(identificationFile.raw).then(res => {
                if (res.success) {
                    Swal.fire({ title: "General Acount Informations Updated" });

                } else {
                    setIsLoading(false);
                    Swal.fire({
                        icon: "error",
                        title: res.error,
                        confirmButtonColor: "#FD7F00",
                    });
                }
            })
        } else {
            CommonService.updateCustomer(data).then((res) => {
                if (res.success) {
                    setIsLoading(false);
                    setUser(res.data as any);
                    Swal.fire({ title: "General Acount Informations Updated" });
                } else {
                    setIsLoading(false);
                    Swal.fire({
                        icon: "error",
                        title: res.error,
                        confirmButtonColor: "#FD7F00",
                    });
                }
            });
        }
    };

    const userInformationFormValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            firstName: "",
            lastName: "",
            dateOfBirth: "",
            address: "",
            phoneNumber: "",
            postCode: "",
            email: "",
        },
        onSubmit: (values, onSubmitProps) => {
            const informationObject: UserDetails = {
                firstName: values.firstName || user?.firstName as any,
                lastName: values.lastName || user?.lastName as any,
                dateOfBirth: dateUserValue || user?.dateOfBirth as any,
                email: values.email || user?.email as any,
                phoneNumber: parseInt(value as any) || user?.phoneNumber as any,
                postCode: values.postCode || user?.postCode as any,
                address: values.address || user?.address as any,
            };
            // onSubmitProps.resetForm();
            profileSubmission(informationObject);
        },
    });






    const [expirationValue, setExpirationValue] = useState(null);
    function handleExpirationDateUpdate(e: any) {
        const dateValue = e.target.value;
        setExpirationValue(dateValue);
    }
    const expiration = moment(expirationValue || user?.carProfile?.expirationDate).format("YYYY-MM-DD");
    const expirationData = useRef<any>();
    expirationData.current = expiration;
    const carProfileInformationFormValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            makeAndModel: "",
            color: "",
            vehicleIdentificationNumber: "",
            licensePlate: "",
            milage: "",
            nameOfTheRegisteredOwner: "",
            insuranceCompanyName: "",
            policyNumber: "",
            expirationDate: "",
            specialFeaturesOrModifications: "",
            accidentHistory: "",
            warranties: "",
        },
        onSubmit: (values, onSubmitProps) => {
            if (
                isNaN(Number(values.policyNumber))
            ) {
                Swal.fire({
                    icon: "error",
                    title: "Policy Number should have only numerics",
                    confirmButtonColor: "#FD7F00",
                });
            } else {
                const carProfileInformationObject: CarProfile = {
                    makeAndModel: values.makeAndModel || user?.carProfile?.makeAndModel as any,
                    manufacturedYear: manufactureRef.current.manufacturedYear || user?.carProfile?.manufacturedYear as any,
                    color: values.color || user?.carProfile?.color as any,
                    vehicleIdentificationNumber: values.vehicleIdentificationNumber || user?.carProfile?.vehicleIdentificationNumber as any,
                    licensePlate: values.licensePlate || user?.carProfile?.licensePlate as any,
                    milage: values.milage || user?.carProfile?.milage as any,
                    nameOfTheRegisteredOwner: values.nameOfTheRegisteredOwner || user?.carProfile?.nameOfTheRegisteredOwner as any,
                    insuranceCompanyName: values.insuranceCompanyName || user?.carProfile?.insuranceCompanyName as any,
                    policyNumber: parseInt(values.policyNumber) || user?.carProfile?.policyNumber as any,
                    expirationDate: expirationValue || user?.carProfile?.expirationDate as any,
                    specialFeaturesOrModifications: values.specialFeaturesOrModifications || user?.carProfile?.specialFeaturesOrModifications as any,
                    accidentHistory: values.accidentHistory || user?.carProfile?.accidentHistory as any,
                    warranties: values.warranties || user?.carProfile?.warranties as any,

                };
                CommonService.addCarProfileInfo(carProfileInformationObject).then((res) => {
                    if (res.success) {
                        setIsLoading(false);
                        Swal.fire({ title: "Car Profile Informations Updated" });
                        setModalCenter(false);
                        setManufactureData({ ...manufactureRef.current, manufacturedYear: "" })
                        onSubmitProps.resetForm();
                        setExpirationValue(null)
                        getAllUsers();
                    } else {
                        setIsLoading(false);
                        Swal.fire({
                            icon: "error",
                            title: res.error,
                            confirmButtonColor: "#FD7F00",
                        });
                    }
                });
               
            }
        },
    });

    const selectLicenceFile = (e: any) => {
        if (e.target.files.length && e.target.files.length <= 3) {
            setIdentificationFile({
                preview: URL.createObjectURL(e.target.files[0]) as any,
                preview2: e.target.files[0],
                raw: [...e.target.files]
            });
        } else {
            toast.error('You can select maximum 3 files!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }

    const addDeleteArr = (id: any) => {
        if (!isDelete.includes(id))
            isDelete.push(id);
        else
            isDelete.pop(id);
    }


    return (
        <div>
            <div className="edu-breadcrumb-area breadcrumb-style-3">
                <div className="container">
                    <div className="breadcrumb-inner accHegiht"> </div>
                </div>
                <ul className="shape-group">
                    <li className="shape-1">
                        <span></span>
                    </li>
                    <li className="shape-2 scene"><img data-depth="2" src={img1} alt="shape" /></li>
                    <li className="shape-3 scene"><img data-depth="-2" src={img2} alt="shape" /></li>
                    <li className="shape-4">
                        <span></span>
                    </li>
                    <li className="shape-5 scene"><img data-depth="2" src={img3} alt="shape" /></li>
                </ul>
            </div>

            <section className="edu-section-gap course-details-area">
                <div className="container">
                    <div className="row row--30">

                        <div className="col-lg-4">
                            <div className="course-sidebar-3 sidebar-top-position">
                                <div className="edu-course-widget widget-course-summery">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img
                                                src={user?.profileImageId?.url == null || user?.profileImageId?.url == "" || user?.profileImageId?.url == undefined ? img1 : user?.profileImageId?.url}
                                                alt="Profile Image" />
                                            <div
                                                className="cursor-pointer imageFit w-10 h-10 rounded-full absolute bottom-2 right-2 justify-center items-center text-center flex"
                                                style={{
                                                    borderRadius: "9999px",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    backgroundColor: "#DDDDDD",
                                                    cursor: "pointer",
                                                    width: "3.5rem",
                                                    height: "3.5rem",
                                                    display: "flex",
                                                    position: "absolute",
                                                    textAlign: "center",
                                                    bottom: "5px",
                                                    right: "0.7rem",
                                                }}
                                            >
                                                <label
                                                    htmlFor="file-upload-profile"
                                                    className="file-upload relative flex flex-col text-gray-400 z-10  rounded cursor-pointer"
                                                    style={{
                                                        margin: "auto",
                                                        borderRadius: "0.25rem",
                                                        cursor: "pointer",
                                                        display: "flex",
                                                        zIndex: "10",
                                                        position: "relative",
                                                    }}
                                                >
                                                    <svg
                                                        width="24"
                                                        height="18"
                                                        viewBox="0 0 24 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="content-center items-center justify-center relative"
                                                        style={{
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            position: "relative",
                                                        }}
                                                    >
                                                        <path
                                                            d="M12 16C14.7614 16 17 13.7614 17 11C17 8.23858 14.7614 6 12 6C9.23858 6 7 8.23858 7 11C7 13.7614 9.23858 16 12 16Z"
                                                            fill="#FFFFFF"
                                                        />
                                                        <path
                                                            d="M21.9999 2.99999H18.8286C18.4277 2.99999 18.0512 2.84376 17.7676 2.56054L15.9394 0.732421C15.4673 0.260249 14.8393 0 14.1714 0H9.82859C9.16062 0 8.53269 0.260249 8.06052 0.732421L6.23239 2.56054C5.94871 2.84376 5.57226 2.99999 5.17138 2.99999H2.00001C0.896951 2.99999 0 3.89695 0 4.99996V17.9999C0 19.1029 0.896951 19.9999 2.00001 19.9999H22C23.103 19.9999 24 19.103 24 17.9999V4.99996C24 3.89695 23.103 2.99999 21.9999 2.99999ZM12 16.9999C8.69141 16.9999 5.99999 14.3085 5.99999 10.9999C5.99999 7.69138 8.69141 4.99996 12 4.99996C15.3085 4.99996 18 7.69138 18 10.9999C18 14.3085 15.3085 16.9999 12 16.9999ZM20 7.99995C19.4487 7.99995 19 7.55122 19 6.99997C19 6.44872 19.4487 5.99999 20 5.99999C20.5512 5.99999 21 6.44872 21 6.99997C21 7.55122 20.5512 7.99995 20 7.99995Z"
                                                            fill="#FFFFFF"
                                                        />
                                                    </svg>

                                                    <input
                                                        id="file-upload-profile"
                                                        type="file"
                                                        style={{ display: "none" }}
                                                        accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                                                                text/plain, image/doc"
                                                        onChange={selectFile}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <ul className="course-item">
                                                <li>
                                                    <span className="label cursor-pointer" style={{ color: leadDataRef.current.dashboard }} onClick={() => {
                                                        setInsuranceBtn(`dashboard`);
                                                    }}><i className="ri-dashboard-line" style={{ fontSize: "18px" }}></i>DashBoard</span>
                                                </li>
                                                <li>
                                                    <span className="label cursor-pointer" style={{ color: leadDataRef.current.general }} onClick={() => {
                                                        setInsuranceBtn(`general`);
                                                    }}><i className="ri-user-add-line" style={{ fontSize: "18px" }}></i>General main Account-Info</span>
                                                </li>
                                                <li>
                                                    <span className="label cursor-pointer" style={{ color: leadDataRef.current.medical }} onClick={() => {
                                                        setInsuranceBtn(`medical`);
                                                    }}><i className="ri-hospital-line" style={{ fontSize: "18px" }}></i>Medical Profile Information Account</span>
                                                </li>
                                                <li>
                                                    <span className="label cursor-pointer" style={{ color: leadDataRef.current.car }} onClick={() => {
                                                        setInsuranceBtn(`car`);
                                                    }}><i className="ri-roadster-line" style={{ fontSize: "18px" }}></i>Car Profile Information</span>
                                                </li>
                                                <li>
                                                    <span className="label cursor-pointer" style={{ color: leadDataRef.current.membership }} onClick={() => {
                                                        setInsuranceBtn(`membership`);
                                                    }}><i className="ri-star-line" style={{ fontSize: "18px" }}></i>My Membership</span>
                                                </li>

                                            </ul>
                                            <div className="read-more-btn" onClick={() => logout()}>
                                                <a href="#" className="edu-btn">Log Out <i className="ri-logout-box-r-line" style={{ fontSize: "13px" }}></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8">
                            {leadDataRef.current.tab == "dashboard" &&
                                <div className="course-sidebar-3 sidebar-top-position-2">
                                    <div className="page-title">
                                        <h4 className="accWelcom mb-80 mt-5 text-center">Welcome ! {user?.userName}</h4>
                                    </div>
                                    <div className="edu-course-widget widget-course-summery">
                                        <div className="inner">
                                            <div className="content">

                                                <div className="header-category float-right">
                                                    <nav className="mainmenu-nav">
                                                        <ul className="mainmenu">
                                                            <li className="has-droupdown">
                                                                <i className="ri-settings-3-fill" style={{ fontSize: "20px" }}></i>
                                                                <ul className="submenu">
                                                                    <li>  <NavLink to="/customer/profile">Edit Profile</NavLink></li>
                                                                    <li>  <NavLink to="/customer/change-password">Change Password</NavLink></li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div>

                                                <h4 className="acc mb-4 mt-5">MY ACCOUNT</h4>
                                                <span className="mb-3 nameText">{user?.firstName} {user?.lastName}</span>
                                                <div className="ml-20">
                                                    <span className="label" style={{ lineHeight: "40px" }}><i className="ri-user-line" style={{ fontSize: "12px" }}></i><strong className="mb-0 username"> &nbsp; Username : </strong><span className="mb-0">{user?.userName}</span></span>
                                                    <br />
                                                    <span className="label"><i className="ri-mail-send-line" style={{ fontSize: "12px" }}></i><strong className="mb-0 username"> &nbsp; Email : </strong><span className="mb-0">{user?.email}</span></span>
                                                </div>

                                                <hr className="mt-5" />
                                                {!isActivated?(
                                                <div className="header-right mb-20 mt-5" style={{ color: "rgb(18, 147, 227)", fontSize: "16px", fontWeight:"500"}}>
                                                    Subscribe for £5.99 to receive access to our life admin services.
                                                </div>):(<div className="header-right mb-20 mt-5" style={{ color: "rgb(18, 147, 227)", fontSize: "16px", fontWeight:"500"}}>
                                                Your subscription is active and ongoing.
                                                </div>)}
                                                <div className="header-right mb-20 mt-5">
                                                    <ul className="header-action">
                                                        <li className="header-acBtn">
                                                            <div>
                                                                <Button color="primary"
                                                                    className="edu-btn btn-medium"
                                                                    onClick={toggleModal}
                                                                    hidden={isActivated}
                                                                >
                                                                    Subscribe
                                                                </Button>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>    
                                                {/* <div className="header-right mb-3 mt-5">
                                                    <ul className="header-action">
                                                    <li className="header-acBtn">
                                                    <a href="/customer/profile" className="edu-btn btn-medium">Edit Profile</a>
                                                    </li>
                                                        <li className="header-btn header-acBtn">
                                                            <a href="/customer/change-password" className="edu-btn btn-medium">Change Password </a>
                                                        </li>
                                                    </ul>
                                                </div> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {leadDataRef.current.tab == "general" &&
                                <div className="course-sidebar-3 sidebar-top-position-2">
                                    <div className="page-title">
                                        <h4 className="accWelcom mb-80 mt-5 text-center">Welcome ! {user?.userName}</h4>
                                    </div>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            userInformationFormValidation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <div className="edu-course-widget widget-course-summery">
                                            <div className="inner">
                                                <div className="content">
                                                    <h4 className="title mb-20 mt-10">GENERAL MAIN ACCOUNT INFORMATION</h4>
                                                    <h4 className="title mb-30 mt-10">Personal Information:</h4>

                                                    <div className="row g-5 mb-20 justify-content-center">
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-20">
                                                                <label htmlFor="reg-Name">First Name:</label>
                                                                <Input
                                                                    type="text"
                                                                    name="firstName"
                                                                    className="input-boarder"
                                                                    id="firstName"
                                                                    value={
                                                                        userInformationFormValidation.values.firstName || user?.firstName
                                                                    }
                                                                    onChange={
                                                                        userInformationFormValidation.handleChange
                                                                    }
                                                                />

                                                            </div>

                                                            <div className="form-group mb-20">
                                                                <label htmlFor="reg-Name">Last Name:</label>
                                                                <Input
                                                                    type="text"
                                                                    name="lastName"
                                                                    className="input-boarder"
                                                                    id="lastName"
                                                                    value={
                                                                        userInformationFormValidation.values.lastName || user?.lastName
                                                                    }
                                                                    onChange={
                                                                        userInformationFormValidation.handleChange
                                                                    }
                                                                />

                                                            </div>

                                                            <div className="form-group mb-20">
                                                                <label htmlFor="reg-Name">Email Address:</label>
                                                                <Input
                                                                    type="text"
                                                                    name="patientFullName"
                                                                    className="input-boarder"
                                                                    id="patientFullName"
                                                                    value={
                                                                        user?.email
                                                                    }
                                                                    disabled
                                                                />

                                                            </div>

                                                            <div className="form-group mb-20">
                                                                <label htmlFor="reg-Name">Postcode:</label>
                                                                <Input
                                                                    type="text"
                                                                    name="postCode"
                                                                    className="input-boarder"
                                                                    id="postCode"
                                                                    value={
                                                                        userInformationFormValidation.values.postCode || user?.postCode
                                                                    }
                                                                    onChange={
                                                                        userInformationFormValidation.handleChange
                                                                    }
                                                                />

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-20">
                                                                <label htmlFor="log-Phone">Phone Number:</label>

                                                                <PhoneInput
                                                                    placeholder="Phone Number"
                                                                    name="phoneNumber"
                                                                    id="phoneNumber"
                                                                    className="input-boarder"
                                                                    defaultCountry="GB"
                                                                    value={value || `+${user?.phoneNumber?.toString()}`}
                                                                    onChange={(e: any) => setValue(e)}
                                                                />

                                                            </div>

                                                            <div className="form-group mb-20">
                                                                <label htmlFor="log-manufacture">Date of birth:</label>
                                                                <Input
                                                                    type="date"
                                                                    className="form-control"
                                                                    id="formrow-firstname-Input"
                                                                    placeholder="Date of birth"
                                                                    value={dateDataUser.current || ""}
                                                                    onChange={e => handleUserDateUpdate(e)}
                                                                    max={moment().format("YYYY-MM-DD")}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="reg-Address">Address</label>
                                                                <Input
                                                                    type="textarea"
                                                                    name="address"
                                                                    className="input-boarder"
                                                                    id="address"
                                                                    value={
                                                                        userInformationFormValidation.values.address || user?.address
                                                                    }
                                                                    onChange={
                                                                        userInformationFormValidation.handleChange
                                                                    }
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <hr className="mb-30 mt-40" />
                                                    <h4 className="title mb-0  mt-10">Identification and Security Information:</h4>
                                                    <div className="mb-30">could be required for admin services</div>

                                                    <div className="row g-5 mb-20">
                                                        <div className="col-lg-10">
                                                            <div className="form-group">
                                                                <label htmlFor="file-upload1" style={{ border: '1px dashed #808080' }} className="text-left mb-4 file_upload file-upload relative flex flex-col text-gray-400 z-10  border-custom-text-gray-500  rounded cursor-pointer">
                                                                    <div className="flex flex-wrap items-center pl-5 pt-4 pb-4 file_upload_content">
                                                                        <p className="m-0 text-black">&nbsp;&nbsp;A copy of a government-issued ID (e.g. passport, driver's license):</p>
                                                                    </div>

                                                                    <input id="file-upload1"
                                                                        type="file"
                                                                        style={{ display: "none" }}
                                                                        className="file_upload_input mb-4"
                                                                        accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, image/doc"
                                                                        onChange={selectLicenceFile}
                                                                        multiple />
                                                                </label>
                                                            </div>
                                                            <div className="uploads">
                                                                {
                                                                    identificationFile.raw.map((file: any, i: number) => {
                                                                        return (
                                                                            <p className={`hw-attach`} key={i} >
                                                                                <i className='bx bx-paperclip'></i>&nbsp;<span onClick={() => { window.open(URL.createObjectURL(file) as any, "_blank"); }}>{file.name}</span>
                                                                            </p>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>


                                                        <div className="uploads">
                                                            {
                                                                user?.identityValidation && user?.identityValidation.map((upload: any, i: number) => {
                                                                    return (
                                                                        <p className={`hw-attach`} key={i} id="up" >
                                                                            <i className='bx bx-trash delete-icon' id="ico" onClick={() => addDeleteArr(upload._id)} ></i>&nbsp;{upload.originalName}
                                                                            <UncontrolledTooltip target="ico">
                                                                                Delete File
                                                                            </UncontrolledTooltip>
                                                                        </p>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                        <div className="col-lg-4"></div>
                                                    </div>


                                                    <button className="btn colorchangeLog leadMargin edu-btn btn-medium mb-20">
                                                        <div>Submit</div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>

                            }

                            {leadDataRef.current.tab == "medical" &&
                                <div className="course-sidebar-3 sidebar-top-position-2">
                                    <div className="page-title">
                                        <h4 className="accWelcom mb-80 mt-5 text-center">Welcome ! {user?.userName}</h4>
                                    </div>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            appointmentFormValidation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <div className="edu-course-widget widget-course-summery">
                                            <div className="inner">
                                                <div className="content">
                                                    <h4 className="title mb-20 mt-10">MEDICAL PROFILE INFORMATION ACCOUNT</h4>
                                                    <h4 className="title mb-30 mt-10">Personal Information:</h4>

                                                    <div className="row g-5 mb-20 justify-content-center">
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-20">
                                                                <label htmlFor="reg-Name">Patient's First Name:</label>
                                                                <Input
                                                                    type="text"
                                                                    name="firstName"
                                                                    className="input-boarder"
                                                                    id="firstName"
                                                                    value={
                                                                        appointmentFormValidation.values.firstName || user?.medicalProfile?.firstName || user?.firstName
                                                                    }
                                                                    onChange={
                                                                        appointmentFormValidation.handleChange
                                                                    }
                                                                />
                                                            </div>

                                                            <div className="form-group mb-20">
                                                                <label htmlFor="reg-Name">Patient's Last Name:</label>
                                                                <Input
                                                                    type="text"
                                                                    name="lastName"
                                                                    className="input-boarder"
                                                                    id="lastName"
                                                                    value={
                                                                        appointmentFormValidation.values.lastName || user?.medicalProfile?.lastName || user?.lastName
                                                                    }
                                                                    onChange={
                                                                        appointmentFormValidation.handleChange
                                                                    }
                                                                />
                                                            </div>

                                                            <div className="form-group mb-20">
                                                                <label htmlFor="reg-Name">Patient's phone number:</label>
                                                                <PhoneInput
                                                                    className="input-boarder"
                                                                    id="patientPhoneNumber"
                                                                    name="patientPhoneNumber"
                                                                    placeholder="Phone Number"
                                                                    defaultCountry="GB"
                                                                    value={value || user?.medicalProfile?.patientPhoneNumber ? `+${user?.phoneNumber?.toString()}` : undefined}
                                                                    onChange={(e: any) => setValue(e)}
                                                                />


                                                            </div>

                                                            <div className="form-group mb-20">
                                                                <label htmlFor="reg-Name">Postcode:</label>
                                                                <Input
                                                                    type="text"
                                                                    name="patientPostcode"
                                                                    className="input-boarder"
                                                                    id="patientPostcode"
                                                                    value={
                                                                        appointmentFormValidation.values.patientPostcode || user?.medicalProfile?.patientPostcode || user?.postCode
                                                                    }
                                                                    onChange={
                                                                        appointmentFormValidation.handleChange
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="form-group mb-20">
                                                                <label htmlFor="reg-Name">Patient's NHS number:</label>
                                                                <Input
                                                                    type="text"
                                                                    name="patientNHS"
                                                                    className="input-boarder"
                                                                    id="patientNHS"
                                                                    value={
                                                                        appointmentFormValidation.values.patientNHS || user?.medicalProfile?.patientNHS
                                                                    }
                                                                    onChange={
                                                                        appointmentFormValidation.handleChange
                                                                    }

                                                                />
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="reg-Name">Patient's next of kin:</label>
                                                                <Input
                                                                    type="text"
                                                                    name="patientNextOfKin"
                                                                    className="input-boarder"
                                                                    id="patientNextOfKin"
                                                                    value={
                                                                        appointmentFormValidation.values.patientNextOfKin || user?.medicalProfile?.patientNextOfKin
                                                                    }
                                                                    onChange={
                                                                        appointmentFormValidation.handleChange
                                                                    }

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">

                                                            <div className="form-group mb-20">
                                                                <label htmlFor="log-manufacture">Patient's date of birth:</label>
                                                                <Input
                                                                    type="date"
                                                                    className="form-control"
                                                                    id="formrow-firstname-Input"
                                                                    name="dateOfBirth"
                                                                    placeholder="Date of birth"
                                                                    value={dateData.current || ""}
                                                                    onChange={e => handleDateUpdate(e)}
                                                                    max={moment().format("YYYY-MM-DD")}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="form-group mb-20">
                                                                <label htmlFor="log-Phone">Patient's email address:</label>
                                                                <Input
                                                                    type="text"
                                                                    name="patientEmail"
                                                                    className="input-boarder"
                                                                    id="patientEmail"
                                                                    disabled
                                                                    value={
                                                                        appointmentFormValidation.values.patientEmail || user?.medicalProfile?.patientEmail || user?.email
                                                                    }
                                                                    onChange={
                                                                        appointmentFormValidation.handleChange
                                                                    }

                                                                />
                                                            </div>

                                                            <div className="form-group mb-20">
                                                                <label htmlFor="reg-Address">Address</label>
                                                                <Input
                                                                    type="text"
                                                                    name="patientAddress"
                                                                    className="input-boarder"
                                                                    id="patientAddress"
                                                                    value={
                                                                        appointmentFormValidation.values.patientAddress || user?.medicalProfile?.patientAddress || user?.address
                                                                    }
                                                                    onChange={
                                                                        appointmentFormValidation.handleChange
                                                                    }

                                                                />
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="log-Phone">Patient's occupation:</label>
                                                                <Input
                                                                    type="text"
                                                                    name="patientOccupation"
                                                                    className="input-boarder"
                                                                    id="patientOccupation"
                                                                    value={
                                                                        appointmentFormValidation.values.patientOccupation || user?.medicalProfile?.patientOccupation
                                                                    }
                                                                    onChange={
                                                                        appointmentFormValidation.handleChange
                                                                    }

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <hr className="mb-30 mt-40" />
                                                    <h4 className="title mb-30 mt-10">Medical Information</h4>


                                                    <div className="row g-5 mb-20 justify-content-center">
                                                        <div className="col-lg-12">
                                                            <div className="form-group ">
                                                                <label htmlFor="reg-Name">Patient's GP name and contact information</label>
                                                                <Input
                                                                    type="textarea"
                                                                    name="patientGPNameAndContactInfo"
                                                                    className="input-boarder"
                                                                    id="patientGPNameAndContactInfo"
                                                                    value={
                                                                        appointmentFormValidation.values.patientGPNameAndContactInfo || user?.medicalProfile?.patientGPNameAndContactInfo
                                                                    }
                                                                    onChange={
                                                                        appointmentFormValidation.handleChange
                                                                    }

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-20">
                                                                <label htmlFor="reg-Name">Patient's medical history</label>
                                                                <Input
                                                                    type="textarea"
                                                                    name="patientMedicalHistory"
                                                                    className="input-boarder"
                                                                    id="patientMedicalHistory"
                                                                    value={
                                                                        appointmentFormValidation.values.patientMedicalHistory || user?.medicalProfile?.patientMedicalHistory
                                                                    }
                                                                    onChange={
                                                                        appointmentFormValidation.handleChange
                                                                    }

                                                                />
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="reg-Name mb-20">Patient's allergies</label>
                                                                <Input
                                                                    type="textarea"
                                                                    name="patientAllergies"
                                                                    className="input-boarder"
                                                                    id="patientAllergies"
                                                                    value={
                                                                        appointmentFormValidation.values.patientAllergies || user?.medicalProfile?.patientAllergies
                                                                    }
                                                                    onChange={
                                                                        appointmentFormValidation.handleChange
                                                                    }

                                                                />
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="reg-Name mb-20">Patient's lifestyle information (e.g. smoking, alcohol consumption, diet, etc.)</label>
                                                                <Input
                                                                    type="textarea"
                                                                    name="patientLifestyleInfo"
                                                                    className="input-boarder"
                                                                    id="patientLifestyleInfo"
                                                                    value={
                                                                        appointmentFormValidation.values.patientLifestyleInfo || user?.medicalProfile?.patientLifestyleInfo
                                                                    }
                                                                    onChange={
                                                                        appointmentFormValidation.handleChange
                                                                    }

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-20">
                                                                <label htmlFor="reg-Name">Patient's current medications</label>
                                                                <Input
                                                                    type="textarea"
                                                                    name="patientCurrentMedications"
                                                                    className="input-boarder"
                                                                    id="patientCurrentMedications"
                                                                    value={
                                                                        appointmentFormValidation.values.patientCurrentMedications || user?.medicalProfile?.patientCurrentMedications
                                                                    }
                                                                    onChange={
                                                                        appointmentFormValidation.handleChange
                                                                    }

                                                                />
                                                            </div>

                                                            <div className="form-group mb-20">
                                                                <label htmlFor="reg-Name">Patient's vaccination history</label>
                                                                <Input
                                                                    type="textarea"
                                                                    name="patientVaccinationHistory"
                                                                    className="input-boarder"
                                                                    id="patientVaccinationHistory"
                                                                    value={
                                                                        appointmentFormValidation.values.patientVaccinationHistory || user?.medicalProfile?.patientVaccinationHistory
                                                                    }
                                                                    onChange={
                                                                        appointmentFormValidation.handleChange
                                                                    }

                                                                />
                                                            </div>

                                                            <div className="form-group mb-20">
                                                                <label htmlFor="reg-Name">Patient's family medical history</label>
                                                                <Input
                                                                    type="textarea"
                                                                    name="patientFamilyMedicalHistory"
                                                                    className="input-boarder"
                                                                    id="patientFamilyMedicalHistory"
                                                                    value={
                                                                        appointmentFormValidation.values.patientFamilyMedicalHistory || user?.medicalProfile?.patientFamilyMedicalHistory
                                                                    }
                                                                    onChange={
                                                                        appointmentFormValidation.handleChange
                                                                    }

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {isLoading ? (
                                                        <button
                                                            disabled={true}
                                                            className="btn colorchangeLog leadMargin edu-btn btn-medium mb-20"
                                                        >
                                                            <div>Submit</div>
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="submit"
                                                            className="btn colorchangeLog leadMargin edu-btn btn-medium mb-20"
                                                        >
                                                            <div>Submit</div>
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            }

                            {leadDataRef.current.tab == "car" &&
                                <div className="course-sidebar-3 sidebar-top-position-2">
                                    <div className="page-title">
                                        <h4 className="accWelcom mb-80 mt-5 text-center">Welcome ! {user?.userName}</h4>
                                    </div>


                                    <div className="edu-course-widget widget-course-summery">
                                        <div className="inner">
                                            <div className="content">
                                                <button onClick={() => {
                                                    togCenterReview();
                                                }} className="edu-btn btn-medium float-right">Add Car Info</button>
                                                <h4 className="title mb-20 mt-10">CAR PROFILE INFORMATION</h4>
                                                {carProfile !== undefined && carProfile.length > 0 ? (
                                                    <>
                                                        <div className="table-responsive">
                                                            <table className="table cart-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" className="product-title">Details</th>
                                                                        <th scope="col" className="product-title">Model</th>
                                                                        <th scope="col" className="product-price">Manufactured Year </th>
                                                                        <th scope="col" className="product-subtotal">VIN</th>
                                                                        <th scope="col" className="product-subtotal">Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {carProfile &&
                                                                        carProfile.map((us: CarProfile, index: number) => (
                                                                            <tr key={index}>
                                                                                <td className="product-price" data-title="Date">Car no {index + 1}</td>
                                                                                <td className="product-price" data-title="Date">{us?.makeAndModel}</td>
                                                                                <td className="product-price" data-title="Level">{us?.manufacturedYear}</td>
                                                                                <td className="product-price" data-title="Date">{us?.vehicleIdentificationNumber} </td>
                                                                                <td className="product-price" data-title="Level">
                                                                                    <button className="btn btn-primary" onClick={() => setShowData(us)} data-value1={us._id} >View</button>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    <Modal2
                                                                        trigger={isOpenData}
                                                                        setTrigger={setIsOpenData}
                                                                        data={dataSingle}
                                                                    />
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <Row>
                                                            <Col xl="12 mt30 text-center">
                                                                {count >= 10 && (
                                                                    <>
                                                                        <button className="btn btn-warning text-center mt-4" onClick={seeMore}>
                                                                            See More
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </>
                                                ) : (
                                                    <>{isLoading ? <div /> : <div>No Car Informations Available</div>}</>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {leadDataRef.current.tab == "membership" &&
                                <div className="course-sidebar-3 sidebar-top-position-2">
                                    <div className="page-title">
                                        <h4 className="accWelcom mb-80 mt-5 text-center">Welcome ! {user?.userName}</h4>
                                    </div>
                                    <div className="edu-course-widget widget-course-summery">
                                        <div className="inner">
                                            <div className="content">
                                                {!isActivated ? (
                                                    <p>
                                                        <h5>
                                                            <div hidden={isActivated} className="header-right mb-20 mt-5" style={{ color: "rgb(18, 147, 227)", fontSize: "16px", fontWeight:"500"}}>
                                                                In order to have access to life admin services, you must be a member.<br />
                                                                Please Subscribe!
                                                            </div>
                                                        </h5>
                                                    </p>
                                                ) : (
                                                    <div className="header-right mb-20 mt-5" style={{ color: "rgb(18, 147, 227)", fontSize: "16px", fontWeight:"500"}}> Your subscription is active and ongoing.</div>
                                                )}
                                                <hr />
                                                <div className="header-right mb-20 mt-5">
                                                    <ul className="header-action">
                                                        <li className="header-acBtn">
                                                            <div>
                                                                <Button color="primary"
                                                                    className="edu-btn btn-medium"
                                                                    onClick={toggleModal}
                                                                    hidden={isActivated}
                                                                >
                                                                    Subscribe
                                                                </Button>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <h4 className="acc mb-4 mt-5">MY MEMBERSHIPS</h4>

                                                <hr />

                                                <h4 className="acc mb-4 mt-5">Subscription Details</h4>
                                                <div className="table-responsive">

                                                    {subscriptions.length === 0 ? (
                                                        <p>No subscriptions found.</p>
                                                    ) : (
                                                        <table className="table cart-table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className="product-title">status</th>
                                                                    <th scope="col" className="product-price">startDate </th>
                                                                    <th scope="col" className="product-subtotal">Renewal Date</th>
                                                                    <th scope="col" className="product-quantity"> </th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {subscriptions.map((subscription: SubscriptionDetails, index: number) => (
                                                                    <tr key={index}>
                                                                        <td>{subscription?.status}</td>
                                                                        <td>{formatDate(subscription.startDate)}</td>
                                                                        <td>{formatDate(subscription.endDate)}</td>
                                                                        <td> <CancelSubscriptionButton onSuccessCancelSubscription={handleCancelSubscription} subscriptionId={subscription?.subscriptionId} _id={subscription._id} disabled={subscription?.status === "CANCELLED"} /></td>


                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    )}
                                                </div>
                                                <h4 className="acc mb-4 mt-5">Personal Request Payment Details</h4>
                                                {personalRequest.length === 0 ? (
                                                    <p> No Single Payment Found</p>
                                                ) : (
                                                    <div className="table-responsive">
                                                        <table className="table cart-table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className="product-title">Status</th>
                                                                    <th scope="col" className="product-price"> Payment id </th>
                                                                    <th scope="col" className="product-quantity"> Payment Type </th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {personalRequest.map((singlePayment: PersonalRequest, index: number) =>
                                                                    <tr>
                                                                        <td>{singlePayment.status}</td>
                                                                        <td>{singlePayment.paymentId}</td>
                                                                        <td>Single Payment</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <Modal isOpen={modal_center}
                                toggle={() => {
                                    togCenterReview();
                                }} centered >
                                <div className="modal-header">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setModalCenter(false);
                                        }}
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span>&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            carProfileInformationFormValidation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <h4 className="title mb-30 mt-10 font-24 themeColor">Car Information:</h4>
                                        <div className="row g-5 mb-20 justify-content-center">
                                            <div className="col-lg-6">
                                                <div className="form-group mb-20">
                                                    <label htmlFor="reg-Name">Make and model of the car:</label>
                                                    <Input
                                                        type="text"
                                                        name="makeAndModel"
                                                        className="input-boarder"
                                                        id="makeAndModel"
                                                        value={
                                                            carProfileInformationFormValidation.values.makeAndModel || user?.carProfile?.makeAndModel
                                                        }
                                                        onChange={
                                                            carProfileInformationFormValidation.handleChange
                                                        }

                                                    />
                                                </div>

                                                <div className="form-group mb-20">
                                                    <label htmlFor="reg-Name">Color:</label>
                                                    <Input
                                                        type="text"
                                                        name="color"
                                                        className="input-boarder"
                                                        id="color"
                                                        value={
                                                            carProfileInformationFormValidation.values.color || user?.carProfile?.color
                                                        }
                                                        onChange={
                                                            carProfileInformationFormValidation.handleChange
                                                        }

                                                    />
                                                </div>

                                                <div className="form-group mb-20">
                                                    <label htmlFor="reg-Name">Current milage reading:</label>
                                                    <Input
                                                        type="text"
                                                        name="milage"
                                                        className="input-boarder"
                                                        id="milage"
                                                        value={
                                                            carProfileInformationFormValidation.values.milage || user?.carProfile?.milage
                                                        }
                                                        onChange={
                                                            carProfileInformationFormValidation.handleChange
                                                        }

                                                    />
                                                </div>

                                            </div>
                                            <div className="col-lg-6">

                                                <div className="form-group mb-20">
                                                    <label htmlFor="log-manufacture">Year of manufacture:</label>
                                                    <Datetime
                                                        dateFormat="YYYY"
                                                        timeFormat={false}
                                                        inputProps={inputProps}
                                                        closeOnSelect
                                                        value={
                                                            manufactureRef.current.manufacturedYear || user?.carProfile?.manufacturedYear
                                                        }
                                                        onChange={(date: any) =>
                                                            setManufactureData({ ...manufactureRef.current, manufacturedYear: typeof date !== "string" ? date.year().toString() : date })
                                                        }

                                                    />

                                                </div>
                                                <div className="form-group mb-20">
                                                    <label htmlFor="log-identification">VIN (vehicle identification number):</label>
                                                    <Input
                                                        type="text"
                                                        name="vehicleIdentificationNumber"
                                                        className="input-boarder"
                                                        id="vehicleIdentificationNumber"
                                                        value={
                                                            carProfileInformationFormValidation.values.vehicleIdentificationNumber || user?.carProfile?.vehicleIdentificationNumber
                                                        }
                                                        onChange={
                                                            carProfileInformationFormValidation.handleChange
                                                        }

                                                    />
                                                </div>

                                                <div className="form-group mb-20">
                                                    <label htmlFor="log-identification">License Plate:</label>
                                                    <Input
                                                        type="text"
                                                        name="licensePlate"
                                                        className="input-boarder"
                                                        id="licensePlate"
                                                        value={
                                                            carProfileInformationFormValidation.values.licensePlate || user?.carProfile?.licensePlate
                                                        }
                                                        onChange={
                                                            carProfileInformationFormValidation.handleChange
                                                        }

                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <h4 className="title mb-30 mt-10 font-24 themeColor">Ownership Information:</h4>


                                        <div className="row g-5 mb-20 justify-content-center">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label htmlFor="reg-owner">Name of the registered owner:</label>
                                                    <Input
                                                        type="text"
                                                        name="nameOfTheRegisteredOwner"
                                                        className="input-boarder"
                                                        id="nameOfTheRegisteredOwner"
                                                        value={
                                                            carProfileInformationFormValidation.values.nameOfTheRegisteredOwner || user?.carProfile?.nameOfTheRegisteredOwner
                                                        }
                                                        onChange={
                                                            carProfileInformationFormValidation.handleChange
                                                        }

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="mb-30 mt-40" />
                                        <h4 className="title mb-30 mt-10 font-24 themeColor">Insurance Information:</h4>


                                        <div className="row g-5 mb-20 justify-content-center">
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="reg-Insurance ">Insurance company name:</label>
                                                    <Input
                                                        type="text"
                                                        name="insuranceCompanyName"
                                                        className="input-boarder"
                                                        id="insuranceCompanyName"
                                                        value={
                                                            carProfileInformationFormValidation.values.insuranceCompanyName || user?.carProfile?.insuranceCompanyName
                                                        }
                                                        onChange={
                                                            carProfileInformationFormValidation.handleChange
                                                        }

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="reg-Policy">Policy number:</label>
                                                    <Input
                                                        type="number"
                                                        name="policyNumber"
                                                        className="input-boarder"
                                                        id="policyNumber"
                                                        value={
                                                            carProfileInformationFormValidation.values.policyNumber || user?.carProfile?.policyNumber
                                                        }
                                                        onChange={
                                                            carProfileInformationFormValidation.handleChange
                                                        }

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="reg-owner">Expiration date:</label>
                                                    <Input
                                                        type="date"
                                                        className="form-control"
                                                        id="formrow-firstname-Input"
                                                        placeholder="Date of birth"
                                                        value={expirationData.current || ""}
                                                        onChange={e => handleExpirationDateUpdate(e)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <hr className="mb-30 mt-40" />
                                        <h4 className="title mb-30 mt-10 font-24 themeColor">Additional Information:</h4>


                                        <div className="row g-5 mb-20 justify-content-center">
                                            <div className="col-lg-6">
                                                <div className="form-group mb-20">
                                                    <label htmlFor="reg-Name">Any special features or modifications:</label>
                                                    <Input
                                                        type="textarea"
                                                        name="specialFeaturesOrModifications"
                                                        className="input-boarder"
                                                        id="specialFeaturesOrModifications"
                                                        value={
                                                            carProfileInformationFormValidation.values.specialFeaturesOrModifications || user?.carProfile?.specialFeaturesOrModifications
                                                        }
                                                        onChange={
                                                            carProfileInformationFormValidation.handleChange
                                                        }

                                                    />
                                                </div>
                                                <div className="form-group mb-20">
                                                    <label htmlFor="reg-Name">Any warranties:</label>
                                                    <Input
                                                        type="textarea"
                                                        name="warranties"
                                                        className="input-boarder"
                                                        id="warranties"
                                                        value={
                                                            carProfileInformationFormValidation.values.warranties || user?.carProfile?.warranties
                                                        }
                                                        onChange={
                                                            carProfileInformationFormValidation.handleChange
                                                        }

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group mb-20">
                                                    <label htmlFor="reg-Name">Any accident history:</label>
                                                    <Input
                                                        type="textarea"
                                                        name="accidentHistory"
                                                        className="input-boarder"
                                                        id="accidentHistory"
                                                        value={
                                                            carProfileInformationFormValidation.values.accidentHistory || user?.carProfile?.accidentHistory
                                                        }
                                                        onChange={
                                                            carProfileInformationFormValidation.handleChange
                                                        }

                                                    />
                                                </div>
                                            </div>
                                        </div>



                                        {isLoading ? (
                                            <button
                                                disabled={true}
                                                className="btn colorchangeLog leadMargin edu-btn btn-medium mb-20"
                                            >
                                                <div>Submit</div>
                                            </button>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="btn colorchangeLog leadMargin edu-btn btn-medium mb-20"
                                            >
                                                <div>Submit</div>
                                            </button>
                                        )}
                                    </Form>
                                </div>
                            </Modal>
                            <Modal isOpen={isModalOpen} toggle={toggleModal}>
                                <ModalHeader toggle={toggleModal} className="modal-header">
                                </ModalHeader>
                                <ModalBody className="text-center">
                                    <PayPalSubscriptionButton onSuccessSubscription={handleSubscriptionApproval}/>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>

                                </ModalFooter>
                            </Modal>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Account;
