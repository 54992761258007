import moment from "moment";
import React from "react";
import { Col, Row, Modal, Input } from "reactstrap";

const BusinessInsuranceModel: React.FC<{ trigger?: boolean; setTrigger?: any; data: any }> = (props) => {
    const { data } = props;
    return (props.trigger) ? (
        <>
            <Modal isOpen={props.trigger} centered >
                <div className="modal-header">
                    <h5 className="text-center mt-2 mb-0 font-size-20">Customer Name : {data.fulName}</h5>
                    <button
                        type="button"
                        onClick={() => props.setTrigger(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span>&times;</span>
                    </button>
                </div>
                <div className="modal-body mt-4">

                    <Row>
                        <h3 className="title mb-30">Business Information:</h3>

                        <div className="row  mb-20 justify-content-center">
                            <div className="col-lg-6">
                                <div className="form-group mb-20">
                                    <label htmlFor="reg-name">Business name</label>
                                    <Input type="text" name="name" id="business-name"
                                        value={data?.name}
                                        disabled
                                    />

                                </div>

                                <div className="form-group mb-20">
                                    <label htmlFor="reg-number">Business phone number</label>
                                    <Input type="text" name="phone" id="phone"
                                        value={data?.phoneNo}
                                        disabled
                                    />


                                </div>

                                <div className="form-group mb-20">
                                    <label htmlFor="log-email">Business email address</label>
                                    <Input type="text" name="email" id="email"
                                        value={data?.email}
                                        disabled
                                    />


                                </div>

                                <div className="form-group">
                                    <label htmlFor="log-Industry">Business Industry</label>
                                    <Input type="text" name="industry" id="log-Industry"
                                        value={data?.industry}
                                        disabled
                                    />


                                </div>
                            </div>
                            <div className="col-lg-6">

                                <div className="form-group mb-20">
                                    <label htmlFor="reg-number">Business address</label>
                                    <Input type="textarea" name="address" id="log-Driving"
                                        value={data?.address}
                                        disabled
                                    />


                                </div>

                                <div className="form-group mb-20">
                                    <label htmlFor="log-registration">Business registration number</label>
                                    <Input type="text" name="regNum" id="log-registration"
                                        value={data?.regNum}
                                        disabled
                                    />


                                </div>

                                <div className="form-group">
                                    <label htmlFor="log-type">Business type</label>
                                    <Input type="text" name="type" id="log-type"
                                        value={data?.type}
                                        disabled
                                    />

                                </div>
                            </div>
                        </div>

                        <hr className="mb-30 mt-40" />

                        <h3 className="title mb-30">Ownership Information:</h3>

                        <div className="row  mb-20 justify-content-center">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="reg-contact">Names and contact information of all business owners and partners</label>
                                    <Input type="text" name="nameAndContactInfo" id="reg-contact"
                                        value={data?.nameAndContactInfo}
                                        disabled

                                    />


                                </div>
                            </div>
                            <div className="col-lg-6">

                                <div className="form-group">
                                    <label htmlFor="log-Percentage">Percentage of ownership for each owner</label>
                                    <Input type="number" name="ownershipPrecentage" id="log-Percentage"
                                        value={data?.ownershipPrecentage}
                                        disabled
                                    />


                                </div>
                            </div>
                        </div>

                        <hr className="mb-30 mt-40" />
                        <h3 className="title mb-20">Financial Information:</h3>

                        <div className="row mb-20 justify-content-center">
                            <div className="col-lg-6">
                                <div className="form-group mb-20">
                                    <label htmlFor="reg-revenue">Annual revenue</label>
                                    <Input type="number" name="AnnualRevenue" id="reg-revenue"
                                        value={data?.AnnualRevenue}
                                        disabled
                                    />


                                </div>

                                <div className="form-group">
                                    <label htmlFor="reg-employees">Number of employees</label>
                                    <Input type="number" name="numberOfEmployees" id="reg-employees"
                                        value={data?.numberOfEmployees}
                                        disabled
                                    />


                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-20">
                                    <label htmlFor="log-assets">Information about assets, inventory, and property</label>
                                    <Input type="text" name="assestInfo" id="log-assets"
                                        value={data?.assestInfo}
                                        disabled
                                    />

                                </div>

                                <div className="form-group">
                                    <label htmlFor="log-liabilities">Information about debts, liabilities, and credit history</label>
                                    <Input type="text" name="debtsInfo" id="log-liabilities"
                                        value={data?.debtsInfo}
                                        disabled
                                    />


                                </div>
                            </div>
                        </div>

                        <hr className="mb-30 mt-40" />
                        <h3 className="title mb-30">Insurance Information:</h3>

                        <div className="row  mb-20 justify-content-center">
                            <div className="col-lg-6">
                                <div className="form-group mb-20">
                                    <label htmlFor="reg-coverage">Type of insurance coverage needed (e.g. liability, property, workers' compensation, etc.)</label>
                                    <Input type="text" name="insuranceCoverageType" id="reg-coverage"
                                        value={data?.insuranceCoverageType}
                                        disabled
                                    />


                                </div>

                                <div className="form-group mb-20">
                                    <label htmlFor="reg-amount">Deductible amount</label>
                                    <Input type="number" name="deductibleAmount" id="reg-amount"
                                        value={data?.deductibleAmount}
                                        disabled
                                    />


                                </div>

                                <div className="form-group">
                                    <label htmlFor="reg-claims">Information about any prior insurance claims</label>
                                    <Input type="text" name="insuranceClaims" id="reg-claims"
                                        value={data?.insuranceClaims}
                                        disabled
                                    />


                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-20">
                                    <label htmlFor="log-required">Limit of liability required</label>
                                    <Input type="text" name="liabilityLimit" id="log-required"
                                        value={data?.liabilityLimit}
                                        disabled
                                    />


                                </div>

                                <div className="form-group">
                                    <label htmlFor="log-specific">Any specific coverage needs or endorsements</label>
                                    <Input type="text" name="specificEndorsements" id="log-specific"
                                        value={data?.specificEndorsements}
                                        disabled
                                    />


                                </div>
                            </div>
                        </div>

                        <hr className="mb-30 mt-40" />
                        <h3 className="title mb-30">Additional information:</h3>

                        <div className="row  mb-20 justify-content-center">
                            <div className="col-lg-6">
                                <div className="form-group mb-20">
                                    <label htmlFor="reg-security">Information about any safety and security measures in place</label>
                                    <Input type="text" name="securityMeasures" id="reg-security"
                                        value={data?.securityMeasures}
                                        disabled
                                    />


                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="log-about">Information about any safety and security certifications</label>
                                    <Input type="text" name="securityCertifications" id="log-about"
                                        value={data?.securityCertifications}
                                        disabled
                                    />


                                </div>
                            </div>
                        </div>

                    </Row>
                </div>
            </Modal>
        </>
    ) : null;
}

export default BusinessInsuranceModel;