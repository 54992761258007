import React, { useState, useEffect } from "react";
import img1 from "../assets/images/about/shape-13.png";
import img2 from "../assets/images/about/shape-15.png";
import img3 from "../assets/images/about/shape-07.png";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";
import { Util } from "../../Util";
import { AdminService } from "../../servises/AdminService";
import { VehicleInsurance } from "../../models/VehicleInsurance";
import { Row, Col, FormGroup, Input } from "reactstrap";
import moment from "moment";

const AdminCompletedVehicleInsurance: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [offset, setOffset] = useState(1);
    const limit = 10;
    const [vehicleInsurance, setVehicleinsurance] = useState<VehicleInsurance[]>([]);
    const [count, setCount] = useState(10);
    const [isSearchableString, setIsSearchableString] = useState({ searchableString: null });
    const [counts, setCounts] = useState(0);

    useEffect(() => {
        getAllVehicleInsurances();
        getAllPayments();
    }, []);


    const getAllVehicleInsurances = () => {
        const data = {
            vehicleType: "ALL",
            paymentStatus: "COMPLETED",
        };

        setIsLoading(true);
        AdminService.getAllVehicleInsurance(data, limit, 1).then(res => {
            if (res.success) {
                setVehicleinsurance(res.data);
                setCount(res.data?.length);
                setIsLoading(false);
            } else {
                setVehicleinsurance([]);
                setIsLoading(false);
            }
        });
    };

    const getAllPayments = () => {
        AdminService.getAllCompletedPayments().then(res => {
            if (res.success) {
                setCounts(res.data?.vehicleInsuranceCount)
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        });
    };


    const filterDoctorAppointments = (value: any) => {
        const data = {
            vehicleType: value,
            paymentStatus: "COMPLETED",
        };

        setIsLoading(true);
        AdminService.getAllVehicleInsurance(data, limit, 1).then(res => {
            if (res.success) {
                setVehicleinsurance(res.data);
                setCount(res.data?.length);
                setIsLoading(false);
            } else {
                setVehicleinsurance([]);
                setIsLoading(false);
            }
        });
    };


    const seeMore = () => {
        const updatedSkip = offset + 1;

        setOffset(updatedSkip);

        const data = {
            vehicleType: "ALL",
            paymentStatus: "COMPLETED",
        };

        setIsLoading(true);

        AdminService.getAllVehicleInsurance(data, 10, updatedSkip).then(res => {
            if (res.success) {
                setVehicleinsurance(res.data);
            }

            setIsLoading(false);
        });
    };

    const previous = () => {
        const updatedSkip = offset - 1;

        setOffset(updatedSkip);

        const data = {
            vehicleType: "ALL",
            paymentStatus: "COMPLETED"
        };


        setIsLoading(true);

        AdminService.getAllVehicleInsurance(data, 10, updatedSkip).then(res => {
            if (res.success) {
                setVehicleinsurance(res.data);
            }

            setIsLoading(false);
        });
    };

    const searchCustomers = (onload: boolean) => {
        const data = {
            searchableString: isSearchableString.searchableString,
            paymentStatus: "COMPLETED"
        }

        setIsLoading(true);

        AdminService.searchVehicleInsuracnceByAdmin(data, limit, 0).then(res => {
            if (res.success) {
                setVehicleinsurance(res.data.userSet);
                setCounts(res.data.userSet.length);
                setOffset(1);
            }
            setIsLoading(false);
        });
    }



    return (
        <div>
            <div className="edu-breadcrumb-area breadcrumb-style-3">
                <div className="container">
                    <div className="breadcrumb-inner accHegiht">

                    </div>
                </div>
                <ul className="shape-group">
                    <li className="shape-1">
                        <span></span>
                    </li>
                    <li className="shape-2 scene"><img data-depth="2" src={img1} alt="shape" /></li>
                    <li className="shape-3 scene"><img data-depth="-2" src={img2} alt="shape" /></li>
                    <li className="shape-4">
                        <span></span>
                    </li>
                    <li className="shape-5 scene"><img data-depth="2" src={img3} alt="shape" /></li>
                </ul>
            </div>

            <section className="edu-section-gap course-details-area">
                <div className="container">
                    <div className="row row--30">

                        <div className="col-lg-12">
                            <div className="course-sidebar-3 sidebar-top-position">
                                <div className="edu-course-widget widget-course-summery">
                                    <div className="row row--30 mb-5">
                                        <div className="col-lg-7 ">
                                            <h3 className="acc mb-4 pl-2 mt-5">Vehicle Insurance</h3>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="edu-sorting-area">
                                                <div className="sorting-right">
                                                    <div className="edu-sorting">
                                                        <div className="icon"><i className="icon-55"></i></div>
                                                        <select className="edu-select searchTermDrop" onChange={(e) => filterDoctorAppointments(e.target.value)}>
                                                            <option value="ALL" className="options">All</option>
                                                            <option value="CAR" className="options">Car</option>
                                                            <option value="VAN" className="options">Van</option>
                                                            <option value="BIKE" className="options">Bike</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3">
                                     <div className="wrap">
                                                <div className="search">
                                                    <input type="text" className="searchTerm" placeholder="Search Customer" onChange={(e: any) => setIsSearchableString({ ...isSearchableString, searchableString: e.target.value })} />
                                                    <button type="submit" className="searchButton" onClick={() => searchCustomers(false)}>
                                                        <i className="fa fa-search"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {vehicleInsurance !== undefined && vehicleInsurance.length > 0 ? (
                                        <>
                                            <div className="table-responsive">
                                                <table className="table cart-table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="product-title">Customer Name</th>
                                                            <th scope="col" className="product-price">Type</th>
                                                            <th scope="col" className="product-subtotal">Date</th>
                                                            <th scope="col" className="product-subtotal">Payment Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {vehicleInsurance &&
                                                            vehicleInsurance.map((ap: VehicleInsurance, index: number) => (
                                                                <tr key={index}>
                                                                    <td className="product-price" data-title="Level">
                                                                        <div className="d-flex align-items-center mr-2">
                                                                            {!ap?.userId?.profileImageId || ap?.userId?.profileImageId == undefined ? (
                                                                                <div className="avatar-md-table rounded-circle bg-soft card-avatar relative">
                                                                                    <span className="avatar-title text-size-avatr-table text-center-div">{ap?.userId?.firstName?.charAt(0)}</span>
                                                                                </div>
                                                                            ) : (
                                                                                <div >
                                                                                    <div
                                                                                        className="img-thumbnail imageFit rounded-circle imageFit avatar-2xl-table3 profile-user-wid-pro items-center margin_center profileImageShow"
                                                                                        style={{
                                                                                            backgroundImage: `url("${Util.fileURL(ap?.userId?.profileImageId?._id)}")`,
                                                                                            borderRadius: "10px",
                                                                                            position: "relative",
                                                                                        }}
                                                                                    ></div>
                                                                                </div>
                                                                            )}
                                                                            <span className="pl-1">{ap?.userId?.firstName} {ap?.userId?.lastName}</span></div></td>
                                                                    <td className="product-price" data-title="Level">{ap?.vehicleType}</td>
                                                                    <td className="product-price" data-title="Amount">{moment(ap?.createdAt).format('MMMM Do YYYY')}</td>
                                                                    <td className="product-price" data-title="Status">
                                                                        <span className="newtag">{ap?.paymentStatus}</span></td>
                                                                </tr>

                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="pagination">
                                                <button className="prev" onClick={previous} disabled={offset >= 2 ? false : true}>Previous</button>
                                                <div className="page-count">Page {offset}/{Math.ceil(counts / 10)}</div>
                                                <button className="next" onClick={seeMore} disabled={offset === Math.ceil(counts / 10) ? true : false}>Next</button>
                                            </div>
                                        </>
                                    ) : (
                                        <>{isLoading ? <div /> : <div>No Vehicle Insurance</div>}</>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    );
};

export default AdminCompletedVehicleInsurance;
