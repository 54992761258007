import moment from "moment";
import React from "react";
import { Col, Row, Modal, Input } from "reactstrap";

const HomeInsuranceModel: React.FC<{ trigger?: boolean; setTrigger?: any; data: any }> = (props) => {
    const { data } = props;
    return (props.trigger) ? (
        <>
            <Modal isOpen={props.trigger} centered >
                <div className="modal-header">
                <h5 className="text-center mt-2 mb-0 font-size-20">Customer Name : {data.userId?.firstName == undefined ? "-" : data.userId?.firstName}</h5>
                    <button
                        type="button"
                        onClick={() => props.setTrigger(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span>&times;</span>
                    </button>
                </div>
                <div className="modal-body mt-4">

                    <Row>
                        <h3 className="title mb-30 mt-10">Your address</h3>

                        <div className="row  mb-20 justify-content-center">
                            <div className="col-lg-12">
                                <div className="form-group mb-20">
                                    <label htmlFor="reg-name">Address *</label>
                                    <Input type="text" name="address" id="address"
                                        value={data?.address}
                                        disabled
                                    />

                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group mb-20">
                                    <label htmlFor="reg-Postcode">Postcode</label>
                                    <Input type="text" name="postalCode" id="postalCode"
                                        value={data?.postalCode}
                                        disabled
                                    />

                                </div>
                            </div>
                        </div>


                        <h3 className="title mb-30 mt-10">About your property</h3>

                        <div className="row  mb-20 justify-content-center">
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="log-Transmission">What type of property is it?</label>
                                </div>
                                <div className="card-heading mb-2">
                                    <div className="row">
                                        <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="propertyType"
                                                    id="HOUSE"
                                                    value="HOUSE"
                                                    checked={data?.propertyType.includes('HOUSE')}
                                                />


                                                <label className="form-check-label" htmlFor="HOUSE">
                                                    HOUSE
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="propertyType"
                                                    id="FLAT"
                                                    value="FLAT"
                                                    checked={data?.propertyType.includes('FLAT')}

                                                />


                                                <label className="form-check-label" htmlFor="FLAT">
                                                    FLAT
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="propertyType"
                                                    id="BUNGALOW"
                                                    value="BUNGALOW"
                                                    checked={data?.propertyType.includes('BUNGALOW')}
                                                />


                                                <label className="form-check-label" htmlFor="BUNGALOW">
                                                    BUNGALOW
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="propertyType"
                                                    id="TOWN HOUSE"
                                                    value="TOWN HOUSE"
                                                    checked={data?.propertyType.includes('TOWN HOUSE')}
                                                />


                                                <label className="form-check-label" htmlFor="TOWN HOUSE">
                                                    TOWN HOUSE
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="propertyType"
                                                    id="BEDSIT"
                                                    value="BEDSIT"
                                                    checked={data?.propertyType.includes('BEDSIT')}
                                                />


                                                <label className="form-check-label" htmlFor="BEDSIT">
                                                    BEDSIT
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="propertyType"
                                                    id="MAISONETTE"
                                                    value="MAISONETTE"
                                                    checked={data?.propertyType.includes('MAISONETTE')}
                                                />


                                                <label className="form-check-label" htmlFor="MAISONETTE">
                                                    MAISONETTE
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="propertyType"
                                                    id="FARM HOUSE"
                                                    value="FARM HOUSE"
                                                    checked={data?.propertyType.includes('FARM HOUSE')}
                                                />


                                                <label className="form-check-label" htmlFor="FARM HOUSE">
                                                    FARM HOUSE
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="log-Transmission">How many bedrooms does the property have?</label>
                                </div>
                                <div className="card-heading mb-2">
                                    <div className="row">
                                        <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="bedRoomCount"
                                                    id="1"
                                                    value="1"
                                                    checked={data?.bedRoomCount.includes('1')}
                                                />


                                                <label className="form-check-label" htmlFor="1">
                                                    1
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="bedRoomCount"
                                                    id="2"
                                                    value="2"
                                                    checked={data?.bedRoomCount.includes('2')}
                                                />


                                                <label className="form-check-label" htmlFor="2">
                                                    2
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="bedRoomCount"
                                                    id="3"
                                                    value="3"
                                                    checked={data?.bedRoomCount.includes('3')}
                                                />


                                                <label className="form-check-label" htmlFor="3">
                                                    3
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="bedRoomCount"
                                                    id="4"
                                                    value="4"
                                                    checked={data?.bedRoomCount.includes('4')}
                                                />


                                                <label className="form-check-label" htmlFor="4">
                                                    4
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="bedRoomCount"
                                                    id="5"
                                                    value="5"
                                                    checked={data?.bedRoomCount.includes('5')}
                                                />

                                                <label className="form-check-label" htmlFor="5">
                                                    5
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="bedRoomCount"
                                                    id="6"
                                                    value="6"
                                                    checked={data?.bedRoomCount.includes('6')}
                                                />


                                                <label className="form-check-label" htmlFor="6">
                                                    6
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="bedRoomCount"
                                                    id="7"
                                                    value="7"
                                                    checked={data?.bedRoomCount.includes('7')}
                                                />


                                                <label className="form-check-label" htmlFor="7">
                                                    7
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="bedRoomCount"
                                                    id="8"
                                                    value="8"
                                                    checked={data?.bedRoomCount.includes('8')}
                                                />


                                                <label className="form-check-label" htmlFor="8">
                                                    8
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="bedRoomCount"
                                                    id="9"
                                                    value="9"
                                                    checked={data?.bedRoomCount.includes('9')}
                                                />


                                                <label className="form-check-label" htmlFor="9">
                                                    9
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="bedRoomCount"
                                                    id="10"
                                                    value="10"
                                                    checked={data?.bedRoomCount.includes('10')}
                                                />


                                                <label className="form-check-label" htmlFor="10">
                                                    10
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                                <div className="form-group">
                                    <label htmlFor="log-Transmission">How many living rooms?</label>
                                </div>
                                <div className="card-heading mb-2">
                                    <div className="row">
                                        <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="livingRoomCount"
                                                    id="livingRoomCount0"
                                                    value="0"
                                                    checked={data?.livingRoomCount.includes('0')}
                                                />


                                                <label className="form-check-label" htmlFor="livingRoomCount0">
                                                    0
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="livingRoomCount"
                                                    id="livingRoomCount1"
                                                    value="1"
                                                    checked={data?.livingRoomCount.includes('1')}
                                                />


                                                <label className="form-check-label" htmlFor="livingRoomCount1">
                                                    1
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="livingRoomCount"
                                                    id="livingRoomCount2"
                                                    value="2"
                                                    checked={data?.livingRoomCount.includes('2')}
                                                />


                                                <label className="form-check-label" htmlFor="livingRoomCount2">
                                                    2
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="livingRoomCount"
                                                    id="livingRoomCount3"
                                                    value="3"
                                                    checked={data?.livingRoomCount.includes('3')}
                                                />


                                                <label className="form-check-label" htmlFor="livingRoomCount3">
                                                    3+
                                                </label>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="log-Transmission">How many kitchens?</label>
                                </div>
                                <div className="card-heading mb-2">
                                    <div className="row">
                                        <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="kitchenCount"
                                                    id="kitchenCount0"
                                                    value="0"
                                                    checked={data?.kitchenCount.includes('0')}
                                                />


                                                <label className="form-check-label" htmlFor="kitchenCount0">
                                                    0
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="kitchenCount"
                                                    id="kitchenCount1"
                                                    value="1"
                                                    checked={data?.kitchenCount.includes('1')}
                                                />


                                                <label className="form-check-label" htmlFor="kitchenCount1">
                                                    1
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="kitchenCount"
                                                    id="kitchenCount2"
                                                    value="2"
                                                    checked={data?.kitchenCount.includes('2')}
                                                />


                                                <label className="form-check-label" htmlFor="kitchenCount2">
                                                    2
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="kitchenCount"
                                                    id="kitchenCount3"
                                                    value="3"
                                                    checked={data?.kitchenCount.includes('3')}
                                                />


                                                <label className="form-check-label" htmlFor="kitchenCount3">
                                                    3+
                                                </label>
                                            </div>


                                        </div>

                                    </div>
                                </div>


                                <div className="form-group">
                                    <label htmlFor="log-Transmission">How many other rooms?</label>
                                </div>
                                <div className="card-heading mb-2">
                                    <div className="row">
                                        <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="otherRoomsCount"
                                                    id="other1"
                                                    value="1"
                                                    checked={data?.otherRoomsCount.includes('1')}
                                                />


                                                <label className="form-check-label" htmlFor="other1">
                                                    1
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="otherRoomsCount"
                                                    id="other2"
                                                    value="2"
                                                    checked={data?.otherRoomsCount.includes('2')}
                                                />


                                                <label className="form-check-label" htmlFor="other2">
                                                    2
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="otherRoomsCount"
                                                    id="other3"
                                                    value="3"
                                                    checked={data?.otherRoomsCount.includes('3')}
                                                />


                                                <label className="form-check-label" htmlFor="other3">
                                                    3
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="otherRoomsCount"
                                                    id="other4"
                                                    value="4"
                                                    checked={data?.otherRoomsCount.includes('4')}
                                                />


                                                <label className="form-check-label" htmlFor="other4">
                                                    4
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="otherRoomsCount"
                                                    id="other5"
                                                    value="5"
                                                    checked={data?.otherRoomsCount.includes('5')}
                                                />


                                                <label className="form-check-label" htmlFor="other5">
                                                    5
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="otherRoomsCount"
                                                    id="other6"
                                                    value="6"
                                                    checked={data?.otherRoomsCount.includes('6')}
                                                />


                                                <label className="form-check-label" htmlFor="other6">
                                                    6
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="otherRoomsCount"
                                                    id="other7"
                                                    value="7"
                                                    checked={data?.otherRoomsCount.includes('7')}
                                                />


                                                <label className="form-check-label" htmlFor="other7">
                                                    7
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="otherRoomsCount"
                                                    id="other8"
                                                    value="8"
                                                    checked={data?.otherRoomsCount.includes('8')}
                                                />


                                                <label className="form-check-label" htmlFor="other8">
                                                    8
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="otherRoomsCount"
                                                    id="other9"
                                                    value="9"
                                                    checked={data?.otherRoomsCount.includes('9')}
                                                />


                                                <label className="form-check-label" htmlFor="other9">
                                                    9
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="otherRoomsCount"
                                                    id="other10"
                                                    value="10"
                                                    checked={data?.otherRoomsCount.includes('10')}
                                                />

                                                <label className="form-check-label" htmlFor="other10">
                                                    10
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <h3 className="title mb-30 mt-10">Other details</h3>

                        <div className="row  mb-20 justify-content-center">
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="log-Transmission">What type of heating does the property have?</label>
                                </div>
                                <div className="card-heading mb-2">
                                    <div className="row">
                                        <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="heatingType"
                                                    id="GAS/NO HOT WATER TANK"
                                                    value="GAS/NO HOT WATER TANK"
                                                    checked={data?.heatingType.includes('GAS/NO HOT WATER TANK')}
                                                />


                                                <label className="form-check-label" htmlFor="GAS/NO HOT WATER TANK">
                                                    GAS/NO HOT WATER TANK
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="heatingType"
                                                    id="GAS/HOT WATER TANK"
                                                    value="GAS/HOT WATER TANK"
                                                    checked={data?.heatingType.includes('GAS/HOT WATER TANK')}
                                                />


                                                <label className="form-check-label" htmlFor="GAS/HOT WATER TANK">
                                                    GAS/HOT WATER TANK
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="heatingType"
                                                    id="OIL"
                                                    value="OIL"
                                                    checked={data?.heatingType.includes('OIL')}
                                                />


                                                <label className="form-check-label" htmlFor="OIL">
                                                    OIL
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="heatingType"
                                                    id="ELECTRIC"
                                                    value="ELECTRIC"
                                                    checked={data?.heatingType.includes('ELECTRIC')}
                                                />


                                                <label className="form-check-label" htmlFor="ELECTRIC">
                                                    ELECTRIC
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="heatingType"
                                                    id="MULTI_FUEL"
                                                    value="MULTI-FUEL"
                                                    checked={data?.heatingType.includes('MULTI-FUEL')}
                                                />


                                                <label className="form-check-label" htmlFor="MULTI_FUEL">
                                                    MULTI-FUEL
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="heatingType"
                                                    id="OTHER"
                                                    value="OTHER"
                                                    checked={data?.heatingType.includes('OTHER')}
                                                />


                                                <label className="form-check-label" htmlFor="OTHER">
                                                    OTHER
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="heatingType"
                                                    id="SOLID_FUEL"
                                                    value="SOLID_FUEL"
                                                    checked={data?.heatingType.includes('SOLID_FUEL')}
                                                />


                                                <label className="form-check-label" htmlFor="SOLID_FUEL">
                                                    SOLID FUEL
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="heatingType"
                                                    id="NO HEATING"
                                                    value="NO HEATING"
                                                    checked={data?.heatingType.includes('NO HEATING')}
                                                />


                                                <label className="form-check-label" htmlFor="NO HEATING">
                                                    NO HEATING
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="log-Transmission">Is the property used for business?</label>
                                </div>
                                <div className="card-heading mb-2">
                                    <div className="row">
                                        <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                            <div className="form-check check mb-3" style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="checkbox"
                                                    name="NO_BUSINESS_USE"
                                                    id="NO BUSINESS USE"
                                                    value="NO_BUSINESS_USE"

                                                    checked={data?.propertyUseFor.includes('NO_BUSINESS_USE')}
                                                />

                                                <label className="form-check-label checkLable" htmlFor="NO BUSINESS USE">
                                                    NO BUSINESS USE
                                                </label>
                                            </div>


                                            <div className="form-check check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="checkbox"
                                                    name="CLERICAL_ONLY"
                                                    id="CLERICAL ONLY"
                                                    value="CLERICAL_ONLY"

                                                    checked={data?.propertyUseFor.includes('CLERICAL_ONLY')}
                                                />

                                                <label className="form-check-label checkLable" htmlFor="CLERICAL ONLY">
                                                    CLERICAL ONLY
                                                </label>
                                            </div>

                                            <div className="form-check check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="checkbox"
                                                    name="CLERICAL_WITH_VISITORS"
                                                    id="CLERICAL WITH VISITORS"
                                                    value="CLERICAL_WITH_VISITORS"

                                                    checked={data?.propertyUseFor.includes('CLERICAL_WITH_VISITORS')}
                                                />

                                                <label className="form-check-label checkLable" htmlFor="CLERICAL WITH VISITORS">
                                                    CLERICAL WITH VISITORS
                                                </label>
                                            </div>

                                            <div className="form-check check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="checkbox"
                                                    name="OTHER_BUSINESS_USE"
                                                    id="OTHER BUSINESS USE"
                                                    value="OTHER_BUSINESS_USE"
                                                    checked={data?.propertyUseFor.includes('OTHER_BUSINESS_USE')}
                                                />

                                                <label className="form-check-label checkLable" htmlFor="OTHER BUSINESS USE">
                                                    OTHER BUSINESS USE
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="form-group mb-20">
                                    <label htmlFor="reg-Last">Approximately, what year was the property built?</label>
                                    <Input type="number" name="year" id="reg-Last"
                                        value={data?.year}
                                        disabled
                                    />

                                </div>

                                <div className="form-group">
                                    <label htmlFor="log-Doors">Is any part of the property's roof flat?</label>
                                </div>
                                <div className="card-heading mb-2">
                                    <div className="row">
                                        <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="roofFlat"
                                                    id="birth"
                                                    value="YES"
                                                    checked={data?.roofFlat.includes('YES')}
                                                />


                                                <label className="form-check-label" htmlFor="birth">
                                                    YES
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                <Input
                                                    className="form-check-Input"
                                                    type="radio"
                                                    name="roofFlat"
                                                    id="birthNO"
                                                    value="NO"
                                                    checked={data?.roofFlat.includes('NO')}
                                                />

                                                <label className="form-check-label" htmlFor="birthNO">
                                                    NO
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </Row>
                </div>
            </Modal>
        </>
    ) : null;
}

export default HomeInsuranceModel;