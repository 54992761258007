import React, { useState, useContext } from "react";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";
import { Form, FormFeedback, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import UserContext from "../context/UserContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { GasBillObject } from "../../models/GasBill";
import { UtilityService } from "../../servises/UtilityService";
import moment from "moment";
import { UserDetails } from "../../models/User";

const GasBill: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useContext(UserContext);

  const [accountHolder, setAccountHolder] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedOptions([...selectedOptions, value]);
    } else {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    }
  };

  const [userdata, setUserdata] = useState<UserDetails | undefined>({} as UserDetails);

  const autoFill = () => {
    setUserdata(user);
  }




  const gaBillPaymentValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      gb_name: "",
      gb_phone_number: "",
      gb_email: "",
      gb_NIN: "",
      gb_postalCode: "",
      gb_address: "",
      gb_dateofBirth: "",
      gb_propertyType: "",
      gb_energySupplier: "",
      gb_MPRN: "",
      gb_no_of_bedrooms: "",
      gb_move_in_date: "",
      gb_billing_frequency: "",
      gb_proof_of_eligibility: "",
      gbAccountHolderOrNot: "",
      gb_accountHolderName: "",
      gb_signature: "",
    },
    validationSchema: Yup.object({
      // gb_name: Yup.string().required(t<string>("Please Enter Name")),
      // gb_phone_number: Yup.number().required(
      //   t<string>("Please Enter Phone Number")
      // ),
      // gb_email: Yup.string().required(t<string>("Please Enter Email")),
      // gb_postalCode: Yup.string().required(
      //   t<string>("Please Enter postal Code")
      // ),
      // gb_address: Yup.string().required(t<string>("Please Enter the Address")),
      // gb_dateofBirth: Yup.date().required(
      //   t<string>("Please Select the date of birth")
      // ),
      gb_propertyType: Yup.string().required(
        t<string>("Please Enter the property Type")
      ),
      gb_energySupplier: Yup.string().required(
        t<string>("Please Enter the energy Supplier")
      ),
      gb_MPRN: Yup.string().required(t<string>("Please Enter the MPRN Number")),
      gb_no_of_bedrooms: Yup.number().required(
        t<string>("Please Enter the number of bed rooms")
      ),
      gb_move_in_date: Yup.date().required(
        t<string>("Please Select the move in date")
      ),
      gb_billing_frequency: Yup.string().required(
        t<string>("Please Select an Option")
      ),
      gb_signature: Yup.string().required(
        t<string>("Please Enter the signature")
      ),
    }),
    onSubmit: (values, onSubmitProps) => {

      if (accountHolder === false && values.gb_accountHolderName.length === 0) {
        Swal.fire({
          icon: "error",
          title: "account holder name cannot be empty",
          confirmButtonColor: "#FD7F00",
        });
      } else {
        const appointmentObject: GasBillObject = {
          name: values.gb_name ||  (userdata?.firstName + " " + userdata?.lastName),
          phoneNumber: parseInt(values.gb_phone_number) || userdata?.phoneNumber,
          postCode: values.gb_postalCode || userdata?.postCode,
          address: values.gb_address || userdata?.address,
          email: values.gb_email || userdata?.email,
          dateOfBirth: values.gb_dateofBirth || userdata?.dateOfBirth as any,
          nationalInsuranceNumber: values.gb_NIN,
          propertyType: values.gb_propertyType,
          numberOfBedrooms: parseInt(values.gb_no_of_bedrooms),
          energySupplier: values.gb_energySupplier,
          moveInDate: values.gb_move_in_date,
          gasMPRN: values.gb_MPRN,
          paymentMethod: "CREDIT_CARD",
          billingFrequency: values.gb_billing_frequency,
          eligibilityForDiscountsOrBenefits: selectedOptions,
          proofOfEligibility: values.gb_proof_of_eligibility,
          accountHolderOrNot: accountHolder,
          accountHolderName: values.gb_accountHolderName,
          signature: values.gb_signature,
        };

        if (user?.subscriptionStatus === "CANCELLED" || !user?.subscriptionId) {
          Swal.fire({
            icon: "warning",
            title: "Please Subscribe for Recieve the Services",
            confirmButtonText: "Subscribe",
            confirmButtonColor: "#FD7F00",
            showCancelButton: true,
            cancelButtonText: "Cancel",
          }).then((result) => {
            if (result.isConfirmed) {
              // Navigate to subscription page
              window.location.href = "/account";
            }
          });
          return; // Stop further execution
        } else {
          setIsLoading(true);

          UtilityService.payGasBill(appointmentObject).then((res) => {
            if (res.success) {
              setIsLoading(false);
              Swal.fire({ title: "Gas Bill Payment Successful" });
              onSubmitProps.resetForm();
              setUserdata({ firstName: "", lastName: "", phoneNumber: "" as any, postCode: "", address: "", email: "", dateOfBirth: undefined, });
              setAccountHolder(true);
              setSelectedOptions([]);
            } else {
              setIsLoading(false);
              Swal.fire({
                icon: "error",
                title: res.error,
                confirmButtonColor: "#FD7F00",
              });
            }
          });
        }
      }
    },
  });

  return (
    <div className="login-page edu-footer footer-lighten bg-image footer-style-1">
      <section className="account-page-area section-gap-equal1">
        <div className="container-custom position-relative">
          <div className="row g-5 justify-content-center">
            <div className="col-lg-8">

              <div className="login-form-box registration-form">
                <div className="App">
                  <div className="box">
                    <div>
                      <h3 className="title mb-20 mt-10">
                        Personal Information:
                      </h3>
                      <p className="themeColor mb-30 font-16 mt-3">Utilities life admin services help individuals manage their utility services efficiently. They handle tasks such as setting up or transferring utility accounts, arranging bill payments, addressing service issues, monitoring usage, and providing guidance on optimizing energy consumption and cost savings.</p>

                      <button
                        className="btn colorchangeLog edu-btn btn-medium mb-20"
                        type="submit"
                        onClick={autoFill}
                      >AutoFill</button>

                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          gaBillPaymentValidation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="row g-5 mb-20 justify-content-center">
                          <div className="col-lg-6">
                            <div className="form-group mb-20">
                              <label htmlFor="reg-name">Name:</label>
                              <Input
                                type="text"
                                name="gb_name"
                                id="gb_name"
                                value={gaBillPaymentValidation.values.gb_name || userdata?.firstName == undefined ? "" : `${userdata?.firstName} ${userdata?.lastName}`}
                                onChange={gaBillPaymentValidation.handleChange}
                                invalid={
                                  gaBillPaymentValidation.touched.gb_name &&
                                    gaBillPaymentValidation.errors.gb_name
                                    ? true
                                    : false
                                }
                              />

                              {gaBillPaymentValidation.touched.gb_name &&
                                gaBillPaymentValidation.errors.gb_name ? (
                                <FormFeedback type="invalid">
                                  {gaBillPaymentValidation.errors.gb_name}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group mb-20">
                              <label htmlFor="reg-Number">Phone Number:</label>
                              <Input
                                type="number"
                                name="gb_phone_number"
                                id="gb_phone_number"
                                value={
                                  gaBillPaymentValidation.values.gb_phone_number || userdata?.phoneNumber
                                }
                                onChange={gaBillPaymentValidation.handleChange}
                                invalid={
                                  gaBillPaymentValidation.touched
                                    .gb_phone_number &&
                                    gaBillPaymentValidation.errors.gb_phone_number
                                    ? true
                                    : false
                                }
                              />

                              {gaBillPaymentValidation.touched
                                .gb_phone_number &&
                                gaBillPaymentValidation.errors.gb_phone_number ? (
                                <FormFeedback type="invalid">
                                  {
                                    gaBillPaymentValidation.errors
                                      .gb_phone_number
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group mb-20">
                              <label htmlFor="reg-Email">Email:</label>
                              <Input
                                type="text"
                                name="gb_email"
                                id="gb_email"
                                value={gaBillPaymentValidation.values.gb_email || userdata?.email}
                                onChange={gaBillPaymentValidation.handleChange}
                                invalid={
                                  gaBillPaymentValidation.touched.gb_email &&
                                    gaBillPaymentValidation.errors.gb_email
                                    ? true
                                    : false
                                }
                              />

                              {gaBillPaymentValidation.touched.gb_email &&
                                gaBillPaymentValidation.errors.gb_email ? (
                                <FormFeedback type="invalid">
                                  {gaBillPaymentValidation.errors.gb_email}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <label htmlFor="reg-National">
                                National Insurance Number (if applicable):
                              </label>
                              <Input
                                type="text"
                                name="gb_NIN"
                                id="gb_NIN"
                                value={gaBillPaymentValidation.values.gb_NIN}
                                onChange={gaBillPaymentValidation.handleChange}
                                invalid={
                                  gaBillPaymentValidation.touched.gb_NIN &&
                                    gaBillPaymentValidation.errors.gb_NIN
                                    ? true
                                    : false
                                }
                              />

                              {gaBillPaymentValidation.touched.gb_NIN &&
                                gaBillPaymentValidation.errors.gb_NIN ? (
                                <FormFeedback type="invalid">
                                  {gaBillPaymentValidation.errors.gb_NIN}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group mb-20">
                              <label htmlFor="reg-Postcode">Postcode:</label>
                              <Input
                                type="text"
                                name="gb_postalCode"
                                id="gb_postalCode"
                                value={
                                  gaBillPaymentValidation.values.gb_postalCode || userdata?.postCode
                                }
                                onChange={gaBillPaymentValidation.handleChange}
                                invalid={
                                  gaBillPaymentValidation.touched
                                    .gb_postalCode &&
                                    gaBillPaymentValidation.errors.gb_postalCode
                                    ? true
                                    : false
                                }
                              />

                              {gaBillPaymentValidation.touched.gb_postalCode &&
                                gaBillPaymentValidation.errors.gb_postalCode ? (
                                <FormFeedback type="invalid">
                                  {gaBillPaymentValidation.errors.gb_postalCode}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group mb-20">
                              <label htmlFor="log-Address">Address:</label>
                              <Input
                                type="textarea"
                                rows="4"
                                name="gb_address"
                                id="gb_address"
                                value={
                                  gaBillPaymentValidation.values.gb_address || userdata?.address
                                }
                                onChange={gaBillPaymentValidation.handleChange}
                                invalid={
                                  gaBillPaymentValidation.touched.gb_address &&
                                    gaBillPaymentValidation.errors.gb_address
                                    ? true
                                    : false
                                }
                              />
                              {gaBillPaymentValidation.touched.gb_address &&
                                gaBillPaymentValidation.errors.gb_address ? (
                                <FormFeedback type="invalid">
                                  {gaBillPaymentValidation.errors.gb_address}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <label htmlFor="log-Date">Date of Birth:</label>
                              <Input
                                className="form-control"
                                type="date"
                                id="gb_dateofBirth"
                                name="gb_dateofBirth"
                                placeholder="Select Date"
                                onChange={gaBillPaymentValidation.handleChange}
                                onBlur={gaBillPaymentValidation.handleBlur}
                                value={
                                  gaBillPaymentValidation.values.gb_dateofBirth || moment(userdata?.dateOfBirth).format("YYYY-MM-DD")
                                }
                                invalid={
                                  gaBillPaymentValidation.touched
                                    .gb_dateofBirth &&
                                    gaBillPaymentValidation.errors.gb_dateofBirth
                                    ? true
                                    : false
                                }
                              />
                              {gaBillPaymentValidation.touched.gb_dateofBirth &&
                                gaBillPaymentValidation.errors.gb_dateofBirth ? (
                                <FormFeedback type="invalid">
                                  {
                                    gaBillPaymentValidation.errors
                                      .gb_dateofBirth
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <hr className="mb-30 mt-40" />

                        <h3 className="title mb-30">Property Information:</h3>

                        <div className="row g-5 mb-20 justify-content-center">
                          <div className="col-lg-6">
                            <div className="form-group mb-20">
                              <label htmlFor="reg-Property">
                                Property Type:
                              </label>
                              <Input
                                type="text"
                                name="gb_propertyType"
                                id="gb_propertyType"
                                value={
                                  gaBillPaymentValidation.values.gb_propertyType
                                }
                                onChange={gaBillPaymentValidation.handleChange}
                                invalid={
                                  gaBillPaymentValidation.touched
                                    .gb_propertyType &&
                                    gaBillPaymentValidation.errors.gb_propertyType
                                    ? true
                                    : false
                                }
                              />

                              {gaBillPaymentValidation.touched
                                .gb_propertyType &&
                                gaBillPaymentValidation.errors.gb_propertyType ? (
                                <FormFeedback type="invalid">
                                  {
                                    gaBillPaymentValidation.errors
                                      .gb_propertyType
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group mb-20">
                              <label htmlFor="log-Address">
                                Energy supplier at the property:
                              </label>
                              <Input
                                type="text"
                                name="gb_energySupplier"
                                id="gb_energySupplier"
                                value={
                                  gaBillPaymentValidation.values
                                    .gb_energySupplier
                                }
                                onChange={gaBillPaymentValidation.handleChange}
                                invalid={
                                  gaBillPaymentValidation.touched
                                    .gb_energySupplier &&
                                    gaBillPaymentValidation.errors
                                      .gb_energySupplier
                                    ? true
                                    : false
                                }
                              />

                              {gaBillPaymentValidation.touched
                                .gb_energySupplier &&
                                gaBillPaymentValidation.errors
                                  .gb_energySupplier ? (
                                <FormFeedback type="invalid">
                                  {
                                    gaBillPaymentValidation.errors
                                      .gb_energySupplier
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <label htmlFor="log-Address">
                                Meter Point Reference Number (MPRN)
                              </label>
                              <Input
                                type="text"
                                name="gb_MPRN"
                                id="gb_MPRN"
                                value={gaBillPaymentValidation.values.gb_MPRN}
                                onChange={gaBillPaymentValidation.handleChange}
                                invalid={
                                  gaBillPaymentValidation.touched.gb_MPRN &&
                                    gaBillPaymentValidation.errors.gb_MPRN
                                    ? true
                                    : false
                                }
                              />

                              {gaBillPaymentValidation.touched.gb_MPRN &&
                                gaBillPaymentValidation.errors.gb_MPRN ? (
                                <FormFeedback type="invalid">
                                  {gaBillPaymentValidation.errors.gb_MPRN}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group mb-20">
                              <label htmlFor="reg-Council">
                                Number of Bedrooms:
                              </label>
                              <Input
                                type="number"
                                name="gb_no_of_bedrooms"
                                id="gb_no_of_bedrooms"
                                value={
                                  gaBillPaymentValidation.values
                                    .gb_no_of_bedrooms
                                }
                                onChange={gaBillPaymentValidation.handleChange}
                                invalid={
                                  gaBillPaymentValidation.touched
                                    .gb_no_of_bedrooms &&
                                    gaBillPaymentValidation.errors
                                      .gb_no_of_bedrooms
                                    ? true
                                    : false
                                }
                              />

                              {gaBillPaymentValidation.touched
                                .gb_no_of_bedrooms &&
                                gaBillPaymentValidation.errors
                                  .gb_no_of_bedrooms ? (
                                <FormFeedback type="invalid">
                                  {
                                    gaBillPaymentValidation.errors
                                      .gb_no_of_bedrooms
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <label htmlFor="log-Date">Move-in date:</label>
                              <Input
                                className="form-control"
                                type="date"
                                id="gb_move_in_date"
                                name="gb_move_in_date"
                                placeholder="Select Date"
                                onChange={gaBillPaymentValidation.handleChange}
                                onBlur={gaBillPaymentValidation.handleBlur}
                                value={
                                  gaBillPaymentValidation.values.gb_move_in_date
                                }
                                invalid={
                                  gaBillPaymentValidation.touched
                                    .gb_move_in_date &&
                                    gaBillPaymentValidation.errors.gb_move_in_date
                                    ? true
                                    : false
                                }
                              />
                              {gaBillPaymentValidation.touched
                                .gb_move_in_date &&
                                gaBillPaymentValidation.errors.gb_move_in_date ? (
                                <FormFeedback type="invalid">
                                  {
                                    gaBillPaymentValidation.errors
                                      .gb_move_in_date
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <hr className="mb-30 mt-40" />
                        <div className="row g-5 mb-20 justify-content-center">
                          <h3 className="title">Payment Information:</h3>


                          <div className="col-lg-12">
                            <div className="form-group">
                              <label htmlFor="log-drive">
                                Billing frequency:
                              </label>
                            </div>
                            <div className="card-heading mb-2">
                              <div className="row">
                                <div
                                  className="col"
                                  style={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  <div
                                    className="form-check mb-3"
                                    style={{ marginRight: "1.2rem" }}
                                  >
                                    <Input
                                      className="form-check-input"
                                      type="radio"
                                      name="gb_billing_frequency"
                                      id="MONTHLY"
                                      value="MONTHLY"
                                      defaultChecked={
                                        gaBillPaymentValidation.values
                                          .gb_billing_frequency === "MONTHLY"
                                      }
                                      onChange={
                                        gaBillPaymentValidation.handleChange
                                      }
                                      invalid={
                                        gaBillPaymentValidation.touched
                                          .gb_billing_frequency &&
                                          gaBillPaymentValidation.errors
                                            .gb_billing_frequency
                                          ? true
                                          : false
                                      }
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="MONTHLY"
                                    >
                                      Monthly
                                    </label>
                                  </div>

                                  <div
                                    className="form-check mb-3 "
                                    style={{ marginRight: "1.2rem" }}
                                  >
                                    <Input
                                      className="form-check-input"
                                      type="radio"
                                      name="gb_billing_frequency"
                                      id="QUARTERLY"
                                      value="QUARTERLY"
                                      defaultChecked={
                                        gaBillPaymentValidation.values
                                          .gb_billing_frequency === "QUARTERLY"
                                      }
                                      onChange={
                                        gaBillPaymentValidation.handleChange
                                      }
                                      invalid={
                                        gaBillPaymentValidation.touched
                                          .gb_billing_frequency &&
                                          gaBillPaymentValidation.errors
                                            .gb_billing_frequency
                                          ? true
                                          : false
                                      }
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="QUARTERLY"
                                    >
                                      Quarterly
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <hr className="mb-30 mt-40" />
                        <div className="row g-5 mb-20 justify-content-center">
                          <h3 className="title">Additional Information:</h3>
                          <div className="col-lg-7">
                            <div className="form-group">
                              <label htmlFor="log-drive">
                                Are you eligible for any discounts or benefits?
                              </label>
                            </div>
                            <div className="card-heading mb-2">
                              <div className="row">
                                <div
                                  className="col"
                                  style={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  <div
                                    className="form-check check mb-3 "
                                    style={{ marginRight: "1.2rem" }}
                                  >
                                    <Input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="eligible_for_discounts_one"
                                      name="eligible_for_discounts"
                                      onChange={handleCheckboxChange}
                                      checked={selectedOptions.includes('PENSION_CREDIT')}
                                      value="PENSION_CREDIT"
                                    />

                                    <label
                                      className="form-check-label checkLable"
                                      htmlFor="credit"
                                    >
                                      Pension credit
                                    </label>
                                  </div>

                                  <div
                                    className="form-check check mb-3 "
                                    style={{ marginRight: "1.2rem" }}
                                  >
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="eligible_for_discounts_two"
                                      name="eligible_for_discounts"
                                      onChange={handleCheckboxChange}
                                      checked={selectedOptions.includes('INCOME_SUPPORT')}
                                      value="INCOME_SUPPORT"
                                    />

                                    <label
                                      className="form-check-label checkLable"
                                      htmlFor="support"
                                    >
                                      Income support
                                    </label>
                                  </div>

                                  <div className="form-check check mb-3 ">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="eligible_for_discounts_three"
                                      name="eligible_for_discounts"
                                      onChange={handleCheckboxChange}
                                      checked={selectedOptions.includes('JOBSEEKER_ALLOWANCE')}
                                      value="JOBSEEKER_ALLOWANCE"
                                    />

                                    <label
                                      className="form-check-label checkLable"
                                      htmlFor="Allowance"
                                    >
                                      Jobseeker's Allowance
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {selectedOptions ? (
                              <div className="form-group">
                                <label htmlFor="log-provide">
                                  If so, please provide proof of eligibility:
                                </label>
                                <Input
                                  type="textarea"
                                  rows="4"
                                  name="gb_proof_of_eligibility"
                                  id="gb_proof_of_eligibility"
                                  value={
                                    gaBillPaymentValidation.values
                                      .gb_proof_of_eligibility
                                  }
                                  onChange={
                                    gaBillPaymentValidation.handleChange
                                  }
                                  invalid={
                                    gaBillPaymentValidation.touched
                                      .gb_proof_of_eligibility &&
                                      gaBillPaymentValidation.errors
                                        .gb_proof_of_eligibility
                                      ? true
                                      : false
                                  }
                                />
                                {gaBillPaymentValidation.touched
                                  .gb_proof_of_eligibility &&
                                  gaBillPaymentValidation.errors
                                    .gb_proof_of_eligibility ? (
                                  <FormFeedback type="invalid">
                                    {
                                      gaBillPaymentValidation.errors
                                        .gb_proof_of_eligibility
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>
                            ) : (
                              <div> </div>
                            )}
                          </div>

                          <div className="col-lg-5">
                            <div className="form-group">
                              <label htmlFor="log-drive">
                                Are you the account holder?
                              </label>
                            </div>
                            <div className="card-heading mb-2">
                              <div className="row">
                                <div
                                  className="col"
                                  style={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  <div
                                    className="form-check mb-3 "
                                    style={{ marginRight: "1.2rem" }}
                                  >
                                    <Input
                                      className="form-check-input"
                                      type="radio"
                                      name="gbAccountHolderOrNot"
                                      id="YES"
                                      value="YES"
                                      checked={accountHolder}
                                      invalid={
                                        gaBillPaymentValidation.touched
                                          .gbAccountHolderOrNot &&
                                          gaBillPaymentValidation.errors
                                            .gbAccountHolderOrNot
                                          ? true
                                          : false
                                      }
                                      onChange={() => {
                                        setAccountHolder(true);
                                      }}
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="YES"
                                    >
                                      Yes
                                    </label>
                                  </div>

                                  <div className="form-check mb-3 ">
                                    <Input
                                      className="form-check-input"
                                      type="radio"
                                      name="gbAccountHolderOrNot"
                                      id="NO"
                                      value="NO"
                                      invalid={
                                        gaBillPaymentValidation.touched
                                          .gbAccountHolderOrNot &&
                                          gaBillPaymentValidation.errors
                                            .gbAccountHolderOrNot
                                          ? true
                                          : false
                                      }
                                      onChange={() => {
                                        setAccountHolder(false);
                                      }}
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="NO"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {accountHolder ? (
                              <div></div>
                            ) : (
                              <div className="form-group mb-20">
                                <label htmlFor="reg-Postcode">
                                  Account Holder Name:
                                </label>
                                <Input
                                  type="text"
                                  name="gb_accountHolderName"
                                  id="gb_accountHolderName"
                                  value={
                                    gaBillPaymentValidation.values
                                      .gb_accountHolderName
                                  }
                                  onChange={
                                    gaBillPaymentValidation.handleChange
                                  }
                                  invalid={
                                    gaBillPaymentValidation.touched
                                      .gb_accountHolderName &&
                                      gaBillPaymentValidation.errors
                                        .gb_accountHolderName
                                      ? true
                                      : false
                                  }
                                />
                                {gaBillPaymentValidation.touched
                                  .gb_accountHolderName &&
                                  gaBillPaymentValidation.errors
                                    .gb_accountHolderName ? (
                                  <FormFeedback type="invalid">
                                    {
                                      gaBillPaymentValidation.errors
                                        .gb_accountHolderName
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>
                            )}
                            <div className="form-group mb-20">
                              <label htmlFor="reg-Amount">Signature:</label>
                              <Input
                                type="text"
                                name="gb_signature"
                                id="gb_signature"
                                value={
                                  gaBillPaymentValidation.values.gb_signature
                                }
                                onChange={gaBillPaymentValidation.handleChange}
                                invalid={
                                  gaBillPaymentValidation.touched
                                    .gb_signature &&
                                    gaBillPaymentValidation.errors.gb_signature
                                    ? true
                                    : false
                                }
                              />

                              {gaBillPaymentValidation.touched.gb_signature &&
                                gaBillPaymentValidation.errors.gb_signature ? (
                                <FormFeedback type="invalid">
                                  {gaBillPaymentValidation.errors.gb_signature}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <button
                          className="btn colorchangeLog leadMargin edu-btn btn-medium mb-20"
                          type="submit"
                        >
                          <div>Submit</div>
                        </button>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section >
    </div >
  );
};

export default GasBill;
