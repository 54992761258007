import React, { useState, useContext, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import logo from "../assets/images/logo/logo-v1.png";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";

const ServiceLogin: React.FC = () => {

    return (
        <div className="login-page edu-footer footer-lighten bg-image footer-style-1">
            <section className="account-page-area section-gap-equal1">
                <div className="container position-relative">
                    <div className="row g-5 justify-content-center">
                        <div className="col-lg-8">
                            <div className="login-form-box2 registration-form">

                                <div className="App">
                                    <div className="box">
                                        <p className="service-p mt-15">You have to create an account to be able to access the services.</p>

                                        <ul className="header-action mb-15">
                                            <li className="header-btn">
                                                <a href="/signin" className="signinup">Login</a>
                                            </li>
                                            <li className="header-btn">
                                                <a href="/signup" className="signinup">Signup</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div >
    );
};

export default ServiceLogin;
