import { Util } from "../Util";
import { AppResponse } from "../models/Response";
import axios from "axios";
import { DcotorAppointmentRequestDataObject } from "../models/DoctorAppointment";

export class DoctorAppointmentService {
  public static async createADoctorAppointment(
    docotorAppointmentData: DcotorAppointmentRequestDataObject
  ): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("add/appointment");

    const res = await axios.post<any, AppResponse<any>>(
      ep,
      docotorAppointmentData
    );

    return res;
  }
}
