import React, { useState, useContext } from "react";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";
import { Form, FormFeedback, Input } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { TVLicenseObject } from "../../models/TVLicenseObject";
import { UtilityService } from "../../servises/UtilityService";
import UserContext from "../context/UserContext";
import { UserDetails } from "../../models/User";
import moment from "moment";

const TVLicence: React.FC = () => {
  const { t } = useTranslation();
  const [checkedOne, isCheckedOne] = useState(false);
  const [checkedTwo, isCheckedTwo] = useState(false);
  const [checkedThree, isCheckedThree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useContext(UserContext);

  const [concessionEligibilty, setConcessionEligibility] = useState("");
  const [samePersonUsing, setSamePersonUsing] = useState(true);

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const [userdata, setUserdata] = useState<UserDetails | undefined>({} as UserDetails);

  const autoFill = () => {
    setUserdata(user);
  }


  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedOptions([...selectedOptions, value]);
    } else {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    }
  };



  const tvLicensePaymentValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      tvl_name: "",
      tvl_postalCode: "",
      tvl_phone_number: "",
      tvl_address: "",
      tvl_email: "",
      tvl_date_of_birth: "",
      tvl_license_type: "",
      tvl_license_duration: "",
      tvl_eligible_concessions: [],
      tvl_proof_of_eligibility: "",
      tvl_other_user_details: "",
      tvl_signature: "",
    },
    validationSchema: Yup.object({
      // tvl_name: Yup.string().required(t<string>("Please Enter Name")),
      // tvl_postalCode: Yup.string().required(
      //   t<string>("Please Enter postal Code")
      // ),
      // tvl_phone_number: Yup.number().required(
      //   t<string>("Please Enter Phone Number")
      // ),
      // tvl_address: Yup.string().required(t<string>("Please Enter the Address")),
      // tvl_email: Yup.string().required(t<string>("Please Enter Email")),

      // tvl_date_of_birth: Yup.date().required(
      //   t<string>("Please Select the date of birth")
      // ),
      tvl_license_type: Yup.string().required(
        t<string>("Please Enter the License Type")
      ),
      tvl_license_duration: Yup.string().required(
        t<string>("Please Enter the Duration of the License")
      ),
      tvl_signature: Yup.string().required(
        t<string>("Please Enter the Signature")
      ),
    }),
    onSubmit: (values, onSubmitProps) => {
      if (
        concessionEligibilty.length !== 0 &&
        values.tvl_proof_of_eligibility.length === 0
      ) {
        Swal.fire({
          icon: "error",
          title: "proof of eligibilty cannot be empty",
          confirmButtonColor: "#FD7F00",
        });
      } else if (
        samePersonUsing === false &&
        values.tvl_other_user_details.length === 0
      ) {
        Swal.fire({
          icon: "error",
          title: "Other Users Details cannot be empty",
          confirmButtonColor: "#FD7F00",
        });
      } else {
        const dataObject: TVLicenseObject = {
          name: values.tvl_name ||  (userdata?.firstName + " " + userdata?.lastName),
          phoneNumber: parseInt(values.tvl_phone_number) || userdata?.phoneNumber,
          postCode: values.tvl_postalCode || userdata?.postCode,
          address: values.tvl_address || userdata?.address,
          email: values.tvl_email || userdata?.email,
          dateOfBirth: values.tvl_date_of_birth || userdata?.dateOfBirth as any,
          licenseType: values.tvl_license_type,
          licenseDuration: values.tvl_license_duration,
          paymentMethod: "CREDIT_CARD",
          eligibleConcessions: selectedOptions,
          proofOfEligibility: values.tvl_proof_of_eligibility,
          onlyPersonUsingThisLicense: samePersonUsing,
          otherUsersDetails: values.tvl_other_user_details,
          signature: values.tvl_signature,
        };

        if (user?.subscriptionStatus === "CANCELLED" || !user?.subscriptionId) {
          Swal.fire({
            icon: "warning",
            title: "in order to be able receive our services you will have to be subscribe",
            confirmButtonText: "Subscribe",
            confirmButtonColor: "#FD7F00",
            showCancelButton: true,
            cancelButtonText: "Cancel",
          }).then((result) => {
            if (result.isConfirmed) {
              // Navigate to subscription page
              window.location.href = "/account";
            }
          });
          return; // Stop further execution
        } else {
          setIsLoading(true);
    
          UtilityService.payTVLicense(dataObject).then((res) => {
            if (res.success) {
              setIsLoading(false);
              Swal.fire({ title: "TV License Payment Successful" });
              setUserdata({ firstName: "", lastName: "", phoneNumber: "" as any, postCode: "", address: "", email: "", dateOfBirth: undefined, });
              onSubmitProps.resetForm();
              setSelectedOptions([]);
            } else {
              setIsLoading(false);
              Swal.fire({
                icon: "error",
                title: res.error,
                confirmButtonColor: "#FD7F00",
              });
            }
          });
        }
      }
    },
  });

  return (
    <div className="login-page edu-footer footer-lighten bg-image footer-style-1">
      <section className="account-page-area section-gap-equal1">
        <div className="container-custom position-relative">
          <div className="row g-5 justify-content-center">
            <div className="col-lg-8">

              <div className="login-form-box registration-form">
                <div className="App">
                  <div className="box">
                    <div>
                      <h3 className="title mb-20 mt-10">
                        Personal Information:
                      </h3>
                      <p className="themeColor mb-30 font-16 mt-3">TV license life admin services assist individuals in managing their TV licenses effectively. They handle tasks such as license application or renewal, payment reminders</p>

                      <button
                        className="btn colorchangeLog edu-btn btn-medium mb-20"
                        type="submit"
                        onClick={autoFill}
                      >AutoFill</button>

                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          tvLicensePaymentValidation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="row g-5 mb-20 justify-content-center">
                          <div className="col-lg-6">
                            <div className="form-group mb-20">
                              <label htmlFor="reg-name">Name:</label>
                              <Input
                                type="text"
                                name="tvl_name"
                                id="tvl_name"
                                value={
                                  tvLicensePaymentValidation.values.tvl_name ||  userdata?.firstName == undefined ? "" : `${userdata?.firstName} ${userdata?.lastName}`}
                                onChange={
                                  tvLicensePaymentValidation.handleChange
                                }
                                invalid={
                                  tvLicensePaymentValidation.touched.tvl_name &&
                                    tvLicensePaymentValidation.errors.tvl_name
                                    ? true
                                    : false
                                }
                              />

                              {tvLicensePaymentValidation.touched.tvl_name &&
                                tvLicensePaymentValidation.errors.tvl_name ? (
                                <FormFeedback type="invalid">
                                  {tvLicensePaymentValidation.errors.tvl_name}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group mb-20">
                              <label htmlFor="reg-Email">Email:</label>
                              <Input
                                type="text"
                                name="tvl_email"
                                id="tvl_email"
                                value={
                                  tvLicensePaymentValidation.values.tvl_email || userdata?.email
                                }
                                onChange={
                                  tvLicensePaymentValidation.handleChange
                                }
                                invalid={
                                  tvLicensePaymentValidation.touched
                                    .tvl_email &&
                                    tvLicensePaymentValidation.errors.tvl_email
                                    ? true
                                    : false
                                }
                              />

                              {tvLicensePaymentValidation.touched.tvl_email &&
                                tvLicensePaymentValidation.errors.tvl_email ? (
                                <FormFeedback type="invalid">
                                  {tvLicensePaymentValidation.errors.tvl_email}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group mb-20">
                              <label htmlFor="reg-Number">Phone Number:</label>
                              <Input
                                type="number"
                                name="tvl_phone_number"
                                id="tvl_phone_number"
                                value={
                                  tvLicensePaymentValidation.values
                                    .tvl_phone_number || userdata?.phoneNumber
                                }
                                onChange={
                                  tvLicensePaymentValidation.handleChange
                                }
                                invalid={
                                  tvLicensePaymentValidation.touched
                                    .tvl_phone_number &&
                                    tvLicensePaymentValidation.errors
                                      .tvl_phone_number
                                    ? true
                                    : false
                                }
                              />

                              {tvLicensePaymentValidation.touched
                                .tvl_phone_number &&
                                tvLicensePaymentValidation.errors
                                  .tvl_phone_number ? (
                                <FormFeedback type="invalid">
                                  {
                                    tvLicensePaymentValidation.errors
                                      .tvl_phone_number
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <label htmlFor="reg-Vehicle">
                                Date of Birth:
                              </label>
                              <Input
                                className="form-control"
                                type="date"
                                id="tvl_date_of_birth"
                                name="tvl_date_of_birth"
                                placeholder="Select Date"
                                onChange={
                                  tvLicensePaymentValidation.handleChange
                                }
                                onBlur={tvLicensePaymentValidation.handleBlur}
                                value={
                                  tvLicensePaymentValidation.values
                                    .tvl_date_of_birth  || moment(userdata?.dateOfBirth).format("YYYY-MM-DD")
                                }
                                invalid={
                                  tvLicensePaymentValidation.touched
                                    .tvl_date_of_birth &&
                                    tvLicensePaymentValidation.errors
                                      .tvl_date_of_birth
                                    ? true
                                    : false
                                }
                              />
                              {tvLicensePaymentValidation.touched
                                .tvl_date_of_birth &&
                                tvLicensePaymentValidation.errors
                                  .tvl_date_of_birth ? (
                                <FormFeedback type="invalid">
                                  {
                                    tvLicensePaymentValidation.errors
                                      .tvl_date_of_birth
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group mb-20">
                              <label htmlFor="reg-Postcode">Postcode:</label>
                              <Input
                                type="text"
                                name="tvl_postalCode"
                                id="tvl_postalCode"
                                value={
                                  tvLicensePaymentValidation.values
                                    .tvl_postalCode || userdata?.postCode
                                }
                                onChange={
                                  tvLicensePaymentValidation.handleChange
                                }
                                invalid={
                                  tvLicensePaymentValidation.touched
                                    .tvl_postalCode &&
                                    tvLicensePaymentValidation.errors
                                      .tvl_postalCode
                                    ? true
                                    : false
                                }
                              />

                              {tvLicensePaymentValidation.touched
                                .tvl_postalCode &&
                                tvLicensePaymentValidation.errors
                                  .tvl_postalCode ? (
                                <FormFeedback type="invalid">
                                  {
                                    tvLicensePaymentValidation.errors
                                      .tvl_postalCode
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <label htmlFor="log-Address">Address:</label>
                              <Input
                                type="textarea"
                                rows="4"
                                name="tvl_address"
                                id="tvl_address"
                                value={
                                  tvLicensePaymentValidation.values.tvl_address || userdata?.address
                                }
                                onChange={
                                  tvLicensePaymentValidation.handleChange
                                }
                                invalid={
                                  tvLicensePaymentValidation.touched
                                    .tvl_address &&
                                    tvLicensePaymentValidation.errors.tvl_address
                                    ? true
                                    : false
                                }
                              />
                              {tvLicensePaymentValidation.touched.tvl_address &&
                                tvLicensePaymentValidation.errors.tvl_address ? (
                                <FormFeedback type="invalid">
                                  {
                                    tvLicensePaymentValidation.errors
                                      .tvl_address
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <hr className="mb-30 mt-40" />
                        <h3 className="title mb-30">License Information:</h3>

                        <div className="row g-5 mb-20 justify-content-center">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="log-drive">
                                Type of License:
                              </label>
                            </div>
                            <div className="card-heading mb-2">
                              <div className="row">
                                <div className="col">
                                  <div
                                    className="form-check mb-3"
                                    style={{ marginRight: "1.2rem" }}
                                  >
                                    <Input
                                      className="form-check-input"
                                      type="radio"
                                      name="tvl_license_type"
                                      id="COLOR"
                                      value="COLOR"
                                      defaultChecked={
                                        tvLicensePaymentValidation.values
                                          .tvl_license_type === "COLOR"
                                      }
                                      onChange={
                                        tvLicensePaymentValidation.handleChange
                                      }
                                      invalid={
                                        tvLicensePaymentValidation.touched
                                          .tvl_license_type &&
                                          tvLicensePaymentValidation.errors
                                            .tvl_license_type
                                          ? true
                                          : false
                                      }
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="COLOR"
                                    >
                                      Color
                                    </label>
                                  </div>

                                  <div
                                    className="form-check mb-3 "
                                    style={{ marginRight: "1.2rem" }}
                                  >
                                    <Input
                                      className="form-check-input"
                                      type="radio"
                                      name="tvl_license_type"
                                      id="BLACK_AND_WHITE"
                                      value="BLACK_AND_WHITE"
                                      defaultChecked={
                                        tvLicensePaymentValidation.values
                                          .tvl_license_type === "QUARTERLY"
                                      }
                                      onChange={
                                        tvLicensePaymentValidation.handleChange
                                      }
                                      invalid={
                                        tvLicensePaymentValidation.touched
                                          .tvl_license_type &&
                                          tvLicensePaymentValidation.errors
                                            .tvl_license_type
                                          ? true
                                          : false
                                      }
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="BLACK_AND_WHITE"
                                    >
                                      Black and White
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="log-drive">
                                Duration of License:
                              </label>
                            </div>
                            <div className="card-heading mb-2">
                              <div className="row">
                                <div className="col">
                                  <div
                                    className="form-check mb-3"
                                    style={{ marginRight: "1.2rem" }}
                                  >
                                    <Input
                                      className="form-check-input"
                                      type="radio"
                                      name="tvl_license_duration"
                                      id="ONE_YEAR"
                                      value="ONE_YEAR"
                                      defaultChecked={
                                        tvLicensePaymentValidation.values
                                          .tvl_license_duration === "ONE_YEAR"
                                      }
                                      onChange={
                                        tvLicensePaymentValidation.handleChange
                                      }
                                      invalid={
                                        tvLicensePaymentValidation.touched
                                          .tvl_license_duration &&
                                          tvLicensePaymentValidation.errors
                                            .tvl_license_duration
                                          ? true
                                          : false
                                      }
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="ONE_YEAR"
                                    >
                                      01 Year
                                    </label>
                                  </div>

                                  <div
                                    className="form-check mb-3 "
                                    style={{ marginRight: "1.2rem" }}
                                  >
                                    <Input
                                      className="form-check-input"
                                      type="radio"
                                      name="tvl_license_duration"
                                      id="FIVE_YEARS"
                                      value="FIVE_YEARS"
                                      defaultChecked={
                                        tvLicensePaymentValidation.values
                                          .tvl_license_duration === "FIVE_YEARS"
                                      }
                                      onChange={
                                        tvLicensePaymentValidation.handleChange
                                      }
                                      invalid={
                                        tvLicensePaymentValidation.touched
                                          .tvl_license_duration &&
                                          tvLicensePaymentValidation.errors
                                            .tvl_license_duration
                                          ? true
                                          : false
                                      }
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="FIVE_YEARS"
                                    >
                                      05 Years
                                    </label>
                                  </div>
                                  <div
                                    className="form-check mb-3 "
                                    style={{ marginRight: "1.2rem" }}
                                  >
                                    <Input
                                      className="form-check-input"
                                      type="radio"
                                      name="tvl_license_duration"
                                      id="TEN_YEARS"
                                      value="TEN_YEARS"
                                      defaultChecked={
                                        tvLicensePaymentValidation.values
                                          .tvl_license_duration === "TEN_YEARS"
                                      }
                                      onChange={
                                        tvLicensePaymentValidation.handleChange
                                      }
                                      invalid={
                                        tvLicensePaymentValidation.touched
                                          .tvl_license_duration &&
                                          tvLicensePaymentValidation.errors
                                            .tvl_license_duration
                                          ? true
                                          : false
                                      }
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="TEN_YEARS"
                                    >
                                      10 Years
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                        <hr className="mb-30 mt-40" />
                        <div className="row g-5 mb-20 justify-content-center">
                          <h3 className="title">Additional Information:</h3>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="log-drive">
                                Are there any concessions you are eligible for?
                              </label>
                            </div>
                            <div className="card-heading mb-2">
                              <div className="row">
                                <div className="col">
                                  <div
                                    className="form-check check mb-3 "
                                    style={{ marginRight: "1.2rem" }}
                                  >
                                    <Input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="eligible_for_discounts_one"
                                      name="tvl_eligible_concessions"
                                      onChange={handleCheckboxChange}
                                      checked={selectedOptions.includes('OVER_75')}
                                      value="OVER_75"
                                    />

                                    <label
                                      className="form-check-label checkLable"
                                      htmlFor="OVER_75"
                                    >
                                      Over 75
                                    </label>
                                  </div>

                                  <div
                                    className="form-check check mb-3 "
                                    style={{ marginRight: "1.2rem" }}
                                  >
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="eligible_for_discounts_two"
                                      name="tvl_eligible_concessions"
                                      onChange={handleCheckboxChange}
                                      checked={selectedOptions.includes('BLIND_OR_PARTIALLY_SIGHTED')}
                                      value="BLIND_OR_PARTIALLY_SIGHTED"
                                    />

                                    <label
                                      className="form-check-label checkLable"
                                      htmlFor="BLIND_OR_PARTIALLY_SIGHTED"
                                    >
                                      Blind or partially sighted
                                    </label>
                                  </div>

                                  <div className="form-check check mb-3 ">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="eligible_for_discounts_three"
                                      name="tvl_eligible_concessions"
                                      onChange={handleCheckboxChange}
                                      checked={selectedOptions.includes('WAR_PENSIONER')}
                                      value="WAR_PENSIONER"
                                    />

                                    <label
                                      className="form-check-label checkLable"
                                      htmlFor="WAR_PENSIONER"
                                    >
                                      War Pensioner
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="log-drive">
                                Are you the only person watching or recording
                                live TV at this address?
                              </label>
                            </div>
                            <div className="card-heading mb-2">
                              <div className="row">
                                <div
                                  className="col"
                                  style={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  <div
                                    className="form-check mb-3 "
                                    style={{ marginRight: "1.2rem" }}
                                  >
                                    <Input
                                      className="form-check-input"
                                      type="radio"
                                      name="ctb_dispute_council_tax_band"
                                      id="TRUE"
                                      value="TRUE"
                                      checked={samePersonUsing}
                                      onChange={() => {
                                        setSamePersonUsing(true);
                                      }}
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="TRUE"
                                    >
                                      Yes
                                    </label>
                                  </div>

                                  <div className="form-check mb-3 ">
                                    <Input
                                      className="form-check-input"
                                      type="radio"
                                      name="ctb_dispute_council_tax_band"
                                      id="FALSE"
                                      value="FALSE"
                                      onChange={() => {
                                        setSamePersonUsing(false);
                                      }}
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="FALSE"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row g-5 mb-20 justify-content-center">
                          <div className="col-lg-6">
                            <div className="form-group">
                              {checkedOne || checkedTwo || checkedThree ? (
                                <div className="form-group">
                                  <label htmlFor="log-provide">
                                    If you are eligible for a concession, please
                                    provide proof of eligibility:
                                  </label>
                                  <Input
                                    type="textarea"
                                    rows="4"
                                    name="tvl_proof_of_eligibility"
                                    id="tvl_proof_of_eligibility"
                                    value={
                                      tvLicensePaymentValidation.values
                                        .tvl_proof_of_eligibility
                                    }
                                    onChange={
                                      tvLicensePaymentValidation.handleChange
                                    }
                                    invalid={
                                      tvLicensePaymentValidation.touched
                                        .tvl_proof_of_eligibility &&
                                        tvLicensePaymentValidation.errors
                                          .tvl_proof_of_eligibility
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              ) : (
                                <div> </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              {!samePersonUsing ? (
                                <div className="form-group mb-30">
                                  <label htmlFor="reg-provide">
                                    If no, please provide the names and dates of
                                    birth of all other viewers:
                                  </label>
                                  <Input
                                    type="textarea"
                                    rows="4"
                                    name="tvl_other_user_details"
                                    id="tvl_other_user_details"
                                    value={
                                      tvLicensePaymentValidation.values
                                        .tvl_other_user_details
                                    }
                                    onChange={
                                      tvLicensePaymentValidation.handleChange
                                    }
                                    invalid={
                                      tvLicensePaymentValidation.touched
                                        .tvl_other_user_details &&
                                        tvLicensePaymentValidation.errors
                                          .tvl_other_user_details
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </div>
                            <div className="form-group mb-20">
                              <label htmlFor="reg-Amount">Signature:</label>
                              <Input
                                type="text"
                                name="tvl_signature"
                                id="tvl_signature"
                                value={
                                  tvLicensePaymentValidation.values
                                    .tvl_signature
                                }
                                onChange={
                                  tvLicensePaymentValidation.handleChange
                                }
                                invalid={
                                  tvLicensePaymentValidation.touched
                                    .tvl_signature &&
                                    tvLicensePaymentValidation.errors
                                      .tvl_signature
                                    ? true
                                    : false
                                }
                              />

                              {tvLicensePaymentValidation.touched
                                .tvl_signature &&
                                tvLicensePaymentValidation.errors
                                  .tvl_signature ? (
                                <FormFeedback type="invalid">
                                  {
                                    tvLicensePaymentValidation.errors
                                      .tvl_signature
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <button
                          className="btn colorchangeLog leadMargin edu-btn btn-medium mb-20"
                          type="submit"
                        >
                          <div>Submit</div>
                        </button>
                      </Form>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section >
    </div >
  );
};

export default TVLicence;
