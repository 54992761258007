import axios from "axios";
import { AppResponse } from "../models/Response";
import { Util } from "../Util";

export class UtilityService {
  public static async payGasBill(data: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("pay/gas-bill");

    const res = await axios.post<any, AppResponse<any>>(ep, data);

    return res;
  }

  public static async payElectricityBill(data: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("pay/electricity-bill");

    const res = await axios.post<any, AppResponse<any>>(ep, data);

    return res;
  }

  public static async payCouncilTaxBill(data: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("pay/council-tax-bill");

    const res = await axios.post<any, AppResponse<any>>(ep, data);

    return res;
  }

  public static async payTVLicense(data: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("pay/tv-license");

    const res = await axios.post<any, AppResponse<any>>(ep, data);

    return res;
  }

  public static async payWaterBill(data: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("pay/water-bill");

    const res = await axios.post<any, AppResponse<any>>(ep, data);

    return res;
  }
}
