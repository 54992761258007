import moment from "moment";
import React from "react";
import { Col, Row, Modal, Input } from "reactstrap";

const VehicleInsuranceModel: React.FC<{ trigger?: boolean; setTrigger?: any; data: any }> = (props) => {
    const { data } = props;
    return (props.trigger) ? (
        <>
            <Modal isOpen={props.trigger} centered >
                <div className="modal-header">
                    <h5 className="text-center mt-2 mb-0 font-size-20">Customer Name : {data.userId?.firstName == undefined ? "-" : data.userId?.firstName}</h5>
                    <button
                        type="button"
                        onClick={() => props.setTrigger(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span>&times;</span>
                    </button>
                </div>
                <div className="modal-body mt-4">

                    {data?.vehicleType == "CAR" &&
                        <Row>
                            <h3 className="title mb-20 mt-10">01. Car Details</h3>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-8">
                                    <div className="form-group">
                                        <label htmlFor="reg-car">Car Registration Number</label>
                                        <Input type="text" name="regNum" id="regNum"
                                            value={data?.regNum}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="log-Make">Car Make</label>
                                        <Input type="text" name="carMake" id="carMake"
                                            value={data?.carMake}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="row mb-20 justify-content-center">
                                <div className="col-lg-8">
                                    <div className="form-group">
                                        <label htmlFor="reg-Model">Car Model</label>
                                        <Input type="text" name="carModel" id="carModel"
                                            value={data?.carModel}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="log-manufacture">Year of manufacture</label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="yearOfManufacture"
                                            placeholder="Date of birth"
                                            name="yearOfManufacture"
                                            max={moment().format("YYYY-MM-DD")}
                                            value={data?.yearOfManufacture}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-20 justify-content-center">
                                <div className="col-lg-8">
                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Doors</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="doors"
                                                        id="doors-4"
                                                        value="4"
                                                        checked={data?.doors.includes('4')}


                                                    />

                                                    <label className="form-check-label" htmlFor="doors-4">
                                                        4
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="doors"
                                                        id="doors-5"
                                                        checked={data?.doors.includes('5')}
                                                    />

                                                    <label className="form-check-label" htmlFor="doors-5">
                                                        5
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="log-Transmission">Transmission</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="transmission"
                                                        id="MANUAL"
                                                        value="MANUAL"
                                                        checked={data?.transmission.includes('MANUAL')}
                                                    />

                                                    <label className="form-check-label" htmlFor="MANUAL">
                                                        MANUAL
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="transmission"
                                                        id="AUTOMATIC"
                                                        value="AUTOMATIC"
                                                        checked={data?.transmission.includes('AUTOMATIC')}
                                                    />

                                                    <label className="form-check-label" htmlFor="AUTOMATIC">
                                                        AUTOMATIC
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row mb-20 justify-content-center">
                                <div className="col-lg-8">
                                    <div className="form-group">
                                        <label htmlFor="reg-Engine">Engine Size</label>
                                        <Input type="text" name="engineSize" id="reg-Engine"
                                            value={data?.engineSize}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="log-Fuel">Fuel Type</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="fuelType"
                                                        id="fuelType"
                                                        value="PETROL"
                                                        checked={data?.fuelType.includes('PETROL')}

                                                    />

                                                    <label className="form-check-label" htmlFor="fuelType">
                                                        PETROL
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="fuelType"
                                                        id="fuelType1"
                                                        value="DIESEL"
                                                        checked={data?.fuelType.includes('DIESEL')}
                                                    />

                                                    <label className="form-check-label" htmlFor="fuelType1">
                                                        DIESEL
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="log-Insurance-information">Insurance information</label>
                                        <Input type="text" name="insuranceInfo" id="log-Insurance-information"
                                            value={data?.insuranceInfo}
                                            disabled
                                        />
                                    </div>
                                </div>

                            </div>


                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="log-Driving">Driving history</label>
                                        <Input type="textarea" name="drivingHistory" id="log-Driving"
                                            value={data?.drivingHistory}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>


                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30 mt-10">02. Car specification</h3>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-8">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-value">What's the value of the car?</label>
                                        <Input type="number" name="carValue" id="reg-value"
                                            value={data?.carValue}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group ">
                                        <label htmlFor="log-drive">Is the car a right or left-hand drive?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="drivingSide"
                                                        id="LEFT1"
                                                        value="LEFT"
                                                        checked={data?.drivingSide.includes('LEFT')}
                                                    />

                                                    <label className="form-check-label" htmlFor="LEFT1">
                                                        LEFT
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 ">
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="drivingSide"
                                                        id="RIGHT"
                                                        value="RIGHT"
                                                        checked={data?.drivingSide.includes('RIGHT')}
                                                    />

                                                    <label className="form-check-label" htmlFor="RIGHT">
                                                        RIGHT
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-drive">How many seats does the car have?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="seatCount"
                                                        id="1"
                                                        value="1"
                                                        checked={data?.seatCount.includes('1')}
                                                    />

                                                    <label className="form-check-label" htmlFor="1">
                                                        1
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="seatCount"
                                                        id="2"
                                                        value="2"
                                                        checked={data?.seatCount.includes('2')}
                                                    />

                                                    <label className="form-check-label" htmlFor="2">
                                                        2
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="seatCount"
                                                        id="3"
                                                        value="3"
                                                        checked={data?.seatCount.includes('3')}
                                                    />

                                                    <label className="form-check-label" htmlFor="3">
                                                        3
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="seatCount"
                                                        id="4"
                                                        value="4"
                                                        checked={data?.seatCount.includes('4')}
                                                    />

                                                    <label className="form-check-label" htmlFor="4">
                                                        4
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="seatCount"
                                                        id="5"
                                                        value="5"
                                                        checked={data?.seatCount.includes('5')}
                                                    />

                                                    <label className="form-check-label" htmlFor="5">
                                                        5
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="seatCount"
                                                        id="6"
                                                        value="6"
                                                        checked={data?.seatCount.includes('6')}
                                                    />

                                                    <label className="form-check-label" htmlFor="6">
                                                        6
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="seatCount"
                                                        id="7"
                                                        value="7"
                                                        checked={data?.seatCount.includes('7')}
                                                    />

                                                    <label className="form-check-label" htmlFor="7">
                                                        7
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="seatCount"
                                                        id="8"
                                                        value="8"
                                                        checked={data?.seatCount.includes('8')}
                                                    />

                                                    <label className="form-check-label" htmlFor="8">
                                                        8
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="seatCount"
                                                        id="9"
                                                        value="9"
                                                        checked={data?.seatCount.includes('9')}
                                                    />

                                                    <label className="form-check-label" htmlFor="9">
                                                        9
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="seatCount"
                                                        id="10"
                                                        value="10"
                                                        checked={data?.seatCount.includes('10')}
                                                    />

                                                    <label className="form-check-label" htmlFor="10">
                                                        10
                                                    </label>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="log-import">Is the car an import?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-check mb-3 ">
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isCarImport"
                                                        id="STANDARD"
                                                        value="NO - STANDARD UK MODEL"
                                                        checked={data?.isCarImport.includes('NO - STANDARD UK MODEL')}
                                                    />

                                                    <label className="form-check-label" htmlFor="STANDARD">
                                                        NO - STANDARD UK MODEL
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 ">
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isCarImport"
                                                        id="UK"
                                                        value="YES - EURO IMPORT - UK SPEC"
                                                        checked={data?.isCarImport.includes('YES - EURO IMPORT - UK SPEC')}
                                                    />

                                                    <label className="form-check-label" htmlFor="UK">
                                                        YES - EURO IMPORT - UK SPEC
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 ">
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isCarImport"
                                                        id="NON"
                                                        value="YES - EURO IMPORT - NON UK SPEC"
                                                        checked={data?.isCarImport.includes('YES - EURO IMPORT - NON UK SPEC')}
                                                    />

                                                    <label className="form-check-label" htmlFor="NON">
                                                        YES - EURO IMPORT - NON UK SPEC
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 ">
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isCarImport"
                                                        id="JAPENESE"
                                                        value="YES - JAPENESE IMPORT"
                                                        checked={data?.isCarImport.includes('YES - JAPENESE IMPORT')}
                                                    />

                                                    <label className="form-check-label" htmlFor="JAPENESE">
                                                        YES - JAPENESE IMPORT
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 ">
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isCarImport"
                                                        id="US"
                                                        value="YES - US IMPORT"
                                                        checked={data?.isCarImport.includes('YES - US IMPORT')}
                                                    />

                                                    <label className="form-check-label" htmlFor="US">
                                                        YES - US IMPORT
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="log-import">Alarm or Immobiliser</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col">

                                                <div className="form-check mb-3 ">
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="alarmOrImmoblizer"
                                                        id="MANUFACTURER"
                                                        value="YES - MANUFACTURER FITTED"
                                                        checked={data?.alarmOrImmoblizer.includes('YES - MANUFACTURER FITTED')}
                                                    />

                                                    <label className="form-check-label" htmlFor="MANUFACTURER">
                                                        YES - MANUFACTURER FITTED
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 ">
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="alarmOrImmoblizer"
                                                        id="THATCHAM"
                                                        value="YES - THATCHAM DEVICE"
                                                        checked={data?.alarmOrImmoblizer.includes('YES - THATCHAM DEVICE')}
                                                    />

                                                    <label className="form-check-label" htmlFor="THATCHAM">
                                                        YES - THATCHAM DEVICE
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 ">
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="alarmOrImmoblizer"
                                                        id="IMMOBILISER"
                                                        value="YES - OTHER ALARM OR IMMOBILISER"
                                                        checked={data?.alarmOrImmoblizer.includes('YES - OTHER ALARM OR IMMOBILISER')}
                                                    />

                                                    <label className="form-check-label" htmlFor="IMMOBILISER">
                                                        YES - OTHER ALARM OR IMMOBILISER
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 ">
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="alarmOrImmoblizer"
                                                        id="SECURITY"
                                                        value="NO - SECURITY DEVICE"
                                                        checked={data?.alarmOrImmoblizer.includes('NO - SECURITY DEVICE')}
                                                    />

                                                    <label className="form-check-label" htmlFor="SECURITY">
                                                        NO - SECURITY DEVICE
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="log-drive">Does the car have a tracker?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="doesCarHaveTracker"
                                                        id="YES"
                                                        value="YES"
                                                        checked={data?.doesCarHaveTracker.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="YES">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 ">
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="doesCarHaveTracker"
                                                        id="NO"
                                                        value="NO"
                                                        checked={data?.doesCarHaveTracker.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="NO">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30 mt-10">03. Further details</h3>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-8">
                                    <div className="form-group">
                                        <label htmlFor="reg-modifications">Does the car have any modifications?</label>
                                        <select className="form-group f-14"
                                            name="modifications"
                                            value={data?.modifications}
                                            disabled
                                        >
                                            <option value="YES">YES</option>
                                            <option value="NO">NO</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="log-Make">Does the car have a dashcam?</label>
                                        <select className="form-group f-14"
                                            name="doesHaveDashcam"
                                            value={data?.doesHaveDashcam}
                                            disabled
                                        >
                                            <option value="YES">YES</option>
                                            <option value="NO">NO</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <h3 className="title mb-30 mt-10">04. Cover</h3>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-8">
                                    <div className="form-group">
                                        <label htmlFor="log-manufacture">When would you like the cover to begin, within the next 30 days?</label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            name="coverDate"
                                            placeholder="Date of birth"
                                            max={moment().format("YYYY-MM-DD")}
                                            value={data?.whenWouldYouCover}
                                            disabled

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                </div>
                            </div>


                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-8">
                                    <div className="form-group">
                                        <label htmlFor="log-Doors">What level of cover do you need?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="levelOfCover"
                                                        id="COMPREHENSIVE"
                                                        value="COMPREHENSIVE"
                                                        checked={data?.levelOfCover.includes('COMPREHENSIVE')}
                                                    />

                                                    <label className="form-check-label" htmlFor="COMPREHENSIVE">
                                                        COMPREHENSIVE
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="exampleRadioss"
                                                        id="THEFT"
                                                        value="THIRD PARTY, FIRE & THEFT"
                                                        checked={data?.levelOfCover.includes('THIRD PARTY, FIRE & THEFT')}
                                                    />

                                                    <label className="form-check-label" htmlFor="THEFT">
                                                        THIRD PARTY, FIRE & THEFT
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="exampleRadioss"
                                                        id="PARTY"
                                                        value="THIRD PARTY ONLY"
                                                        checked={data?.levelOfCover.includes('THIRD PARTY ONLY')}
                                                    />

                                                    <label className="form-check-label" htmlFor="PARTY">
                                                        THIRD PARTY ONLY
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Choose a voluntary excess</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="voluntaryExcess"
                                                        id="voluntaryExcess"
                                                        value="150"
                                                        checked={data?.voluntaryExcess.includes('150')}
                                                    />

                                                    <label className="form-check-label" htmlFor="voluntaryExcess">
                                                        £150
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="voluntaryExcess"
                                                        id="250"
                                                        value="250"
                                                        checked={data?.voluntaryExcess.includes('250')}
                                                    />

                                                    <label className="form-check-label" htmlFor="250">
                                                        £250
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="voluntaryExcess"
                                                        id="350"
                                                        value="350"
                                                        checked={data?.voluntaryExcess.includes('350')}
                                                    />

                                                    <label className="form-check-label" htmlFor="350">
                                                        £350
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="voluntaryExcess"
                                                        id="0"
                                                        value="0"
                                                        checked={data?.voluntaryExcess.includes('0')}
                                                    />

                                                    <label className="form-check-label" htmlFor="0">
                                                        £0
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="voluntaryExcess"
                                                        id="100"
                                                        value="100"
                                                        checked={data?.voluntaryExcess.includes('100')}
                                                    />

                                                    <label className="form-check-label" htmlFor="100">
                                                        £100
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="voluntaryExcess"
                                                        id="500"
                                                        value="500"
                                                        checked={data?.voluntaryExcess.includes('500')}
                                                    />

                                                    <label className="form-check-label" htmlFor="500">
                                                        £500
                                                    </label>
                                                </div>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="voluntaryExcess"
                                                        id="750"
                                                        value="750"
                                                        checked={data?.voluntaryExcess.includes('750')}
                                                    />

                                                    <label className="form-check-label" htmlFor="750">
                                                        £750
                                                    </label>
                                                </div>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="voluntaryExcess"
                                                        id="10000"
                                                        value="10000"
                                                        checked={data?.voluntaryExcess.includes('10000')}
                                                    />

                                                    <label className="form-check-label" htmlFor="10000">
                                                        £10000
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="log-Doors">How would you want to pay for the car insurance policy?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="payforCarInsurancePolicy"
                                                        id="ANNUAL"
                                                        value="ANNUAL PAYMENT"
                                                        checked={data?.payforCarInsurancePolicy.includes('ANNUAL PAYMENT')}
                                                    />

                                                    <label className="form-check-label" htmlFor="ANNUAL">
                                                        ANNUAL PAYMENT
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="payforCarInsurancePolicy"
                                                        id="MONTHLY"
                                                        value="MONTHLY PAYMENTS"
                                                        checked={data?.payforCarInsurancePolicy.includes('MONTHLY PAYMENTS')}
                                                    />

                                                    <label className="form-check-label" htmlFor="MONTHLY">
                                                        MONTHLY PAYMENTS
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h3 className="title mb-30 mt-10">05. Drivers</h3>
                            <div className="row mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <label htmlFor="log-Make">Title</label>
                                    <select className="form-group f-14 mb-20"
                                        name="title"
                                        id="value={validationStep2.values.doesHaveDashcam}"
                                        value={data?.title}
                                        disabled
                                    >
                                        <option value="MR">MR</option>
                                        <option value="MRS">MRS</option>
                                        <option value="MISS">MISS</option>
                                        <option value="MS">MS</option>
                                        <option value="DR (MALE)">DR (MALE)</option>
                                        <option value="DR (FEMALE)">DR (FEMALE)</option>
                                    </select>
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Name">First Name *</label>
                                        <Input type="text" name="firstName" id="firstName"
                                            value={data?.firstName}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Last">Last Name *</label>
                                        <Input type="text" name="lastName" id="lastName"
                                            value={data?.lastName}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Phone">Phone *</label>
                                        <Input type="text" name="Phone" id="Phone"
                                            value={data?.driverPhone}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Date">Date of birth *</label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            name="dob"
                                            placeholder="Date of birth"
                                            max={moment().format("YYYY-MM-DD")}
                                            value={data?.dob}
                                            disabled
                                        />
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Has the driver lived in the UK since birth? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isBirthPlaceUK"
                                                        id="birth"
                                                        value="YES"
                                                        checked={data?.isBirthPlaceUK.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="birth">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isBirthPlaceUK"
                                                        id="birthNO"
                                                        value="NO"
                                                        checked={data?.isBirthPlaceUK.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="birthNO">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h5 className="title mb-20 mt-10">When did the driver start continuously living in the UK?</h5>
                                    <div className="form-group">
                                        <label htmlFor="month">Month</label>
                                        <Input type="number" name="month" id="month"
                                            value={data?.month}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="reg-Year">Year</label>
                                        <Input type="number" name="year" id="year"
                                            value={data?.year}
                                            disabled
                                        />
                                    </div>



                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Gender</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="gender"
                                                        id="MALE"
                                                        value="MALE"
                                                        checked={data?.gender.includes('MALE')}
                                                    />

                                                    <label className="form-check-label" htmlFor="MALE">
                                                        MALE
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="gender"
                                                        id="FEMALE"
                                                        value="FEMALE"
                                                        checked={data?.gender.includes('FEMALE')}
                                                    />

                                                    <label className="form-check-label" htmlFor="FEMALE">
                                                        FEMALE
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Marital status</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="maritalStatus"
                                                        id="SINGLE"
                                                        value="SINGLE"
                                                        checked={data?.maritalStatus.includes('SINGLE')}
                                                    />

                                                    <label className="form-check-label" htmlFor="SINGLE">
                                                        SINGLE
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="maritalStatus"
                                                        id="MARRIED"
                                                        value="MARRIED"
                                                        checked={data?.maritalStatus.includes('MARRIED')}
                                                    />

                                                    <label className="form-check-label" htmlFor="MARRIED">
                                                        MARRIED
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="maritalStatus"
                                                        id="LIVING"
                                                        value="LIVING WITH PARTNER"
                                                        checked={data?.maritalStatus.includes('LIVING WITH PARTNER')}
                                                    />

                                                    <label className="form-check-label" htmlFor="LIVING">
                                                        LIVING WITH PARTNER
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="maritalStatus"
                                                        id="WIDOWED"
                                                        value="WIDOWED"
                                                        checked={data?.maritalStatus.includes('WIDOWED')}
                                                    />

                                                    <label className="form-check-label" htmlFor="WIDOWED">
                                                        WIDOWED
                                                    </label>
                                                </div>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="exampleRadioss"
                                                        id="CIVIL"
                                                        value="CIVIL PARTNERSHIP"
                                                        checked={data?.maritalStatus.includes('CIVIL PARTNERSHIP')}
                                                    />

                                                    <label className="form-check-label" htmlFor="CIVIL">
                                                        CIVIL PARTNERSHIP
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="maritalStatus"
                                                        id="DIVORCED"
                                                        value="DIVORCED"
                                                        checked={data?.maritalStatus.includes('DIVORCED')}
                                                    />

                                                    <label className="form-check-label" htmlFor="DIVORCED">
                                                        DIVORCED
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="maritalStatus"
                                                        id="SEPERATED"
                                                        value="SEPERATED"
                                                        checked={data?.maritalStatus.includes('SEPERATED')}
                                                    />

                                                    <label className="form-check-label" htmlFor="SEPERATED">
                                                        SEPERATED
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Does the driver own or use another vehicle? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="driverOwnorNot"
                                                        id="YESa"
                                                        value="YES"
                                                        checked={data?.driverOwnorNot.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="YESa">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="driverOwnorNot"
                                                        id="NOs"
                                                        value="NO"
                                                        checked={data?.driverOwnorNot.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="NOs">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-20">Some insurers could offer a discount if the driver uses another vehicle, as they’ll have a better idea of driving habits.</p>

                                </div>
                            </div>


                            <h3 className="title mb-30 mt-10">06. Occupation</h3>

                            <div className="form-group">
                                <label htmlFor="log-Doors">What's the driver's employment status?</label>
                            </div>
                            <div className="card-heading mb-2">
                                <div className="row">
                                    <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <Input
                                                className="form-check-Input"
                                                type="radio"
                                                name="employeementStatus"
                                                id="EMPLOYED"
                                                value="EMPLOYED"
                                                checked={data?.employeementStatus.includes('EMPLOYED')}

                                            />

                                            <label className="form-check-label" htmlFor="EMPLOYED">
                                                EMPLOYED
                                            </label>
                                        </div>

                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <Input
                                                className="form-check-Input"
                                                type="radio"
                                                name="employeementStatus"
                                                id="SELE-EMPLOYED"
                                                value="SELE-EMPLOYED"
                                                checked={data?.employeementStatus.includes('SELE-EMPLOYED')}
                                            />

                                            <label className="form-check-label" htmlFor="SELE-EMPLOYED">
                                                SELE-EMPLOYED
                                            </label>
                                        </div>

                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <Input
                                                className="form-check-Input"
                                                type="radio"
                                                name="employeementStatus"
                                                id="RETIRED"
                                                value="RETIRED"
                                                checked={data?.employeementStatus.includes('RETIRED')}
                                            />

                                            <label className="form-check-label" htmlFor="RETIRED">
                                                RETIRED
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30 mt-10">07. Licence</h3>

                            <div className="form-group">
                                <label htmlFor="log-Doors">What type of license does the driver currently have?</label>
                            </div>
                            <div className="card-heading mb-2">
                                <div className="row">
                                    <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <Input
                                                className="form-check-Input"
                                                type="radio"
                                                name="licenceType"
                                                id="FULL UK-MANUAL"
                                                value="FULL UK-MANUAL"
                                                checked={data?.licenceType.includes('FULL UK-MANUAL')}
                                            />

                                            <label className="form-check-label" htmlFor="FULL UK-MANUAL">
                                                FULL UK-MANUAL
                                            </label>
                                        </div>

                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <Input
                                                className="form-check-Input"
                                                type="radio"
                                                name="licenceType"
                                                id="EEC"
                                                value="FULL EURO (EEC)"
                                                checked={data?.licenceType.includes('FULL EURO (EEC)')}
                                            />

                                            <label className="form-check-label" htmlFor="EEC">
                                                FULL EURO (EEC)
                                            </label>
                                        </div>

                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <Input
                                                className="form-check-Input"
                                                type="radio"
                                                name="licenceType"
                                                id="OPTIONS"
                                                value="SHOW MORE OPTIONS"
                                                checked={data?.licenceType.includes('SHOW MORE OPTIONS')}
                                            />

                                            <label className="form-check-label" htmlFor="OPTIONS">
                                                SHOW MORE OPTIONS
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h3 className="title mb-30 mt-10">08. Further policyholder details</h3>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="reg-number">Phone number</label>
                                        <Input type="number" name="phoneNo" id="phoneNo"
                                            value={data?.phoneNo}
                                            disabled
                                        />
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="reg-Postal">Postal Code</label>
                                        <Input type="text" name="postalCode" id="reg-Postal"
                                            value={data?.postalCode}
                                            disabled
                                        />
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="reg-Address">Address</label>
                                        <Input type="textarea" name="address" id="address"
                                            value={data?.address}
                                            disabled
                                        />
                                    </div>

                                </div>
                            </div>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Is you are a homeowner? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="homeOwner"
                                                        id="homeowner"
                                                        value="YES"
                                                        checked={data?.homeOwner.includes('YES')}

                                                    />

                                                    <label className="form-check-label" htmlFor="homeowner">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="homeOwner"
                                                        id="homeownerNo"
                                                        value="NO"
                                                        checked={data?.homeOwner.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="homeownerNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Will the car be kept at home during the day? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isCarKeptAtHome"
                                                        id="during"
                                                        value="YES"
                                                        checked={data?.isCarKeptAtHome.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="during">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isCarKeptAtHome"
                                                        id="duringNo"
                                                        value="NO"
                                                        checked={data?.isCarKeptAtHome.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="duringNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Doors">By the time the policy begins, will Awais have any children under 16 years old? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="childrenUnderSixteen"
                                                        id="policy"
                                                        value="YES"
                                                        checked={data?.childrenUnderSixteen.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="policy">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="childrenUnderSixteen"
                                                        id="policyNo"
                                                        value="NO"
                                                        checked={data?.childrenUnderSixteen.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="policyNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <label htmlFor="log-policy">By the time the policy begins, how many years of no-claims bonus (NCB) will Awais have? *</label>
                                    <select className="form-group f-14" name="YearsofNoClaimBonus"
                                        value={data?.YearsofNoClaimBonus}
                                        disabled
                                    >
                                        <option value="NO NCB">NO NCB</option>
                                        <option value="1 Year">1 Year</option>
                                        <option value="2 Year">2 Year</option>
                                        <option value="3 Year">3 Year</option>
                                        <option value="4 Year">4 Year</option>
                                        <option value="5 Year">5 Year</option>
                                        <option value="6 Year">6 Year</option>
                                        <option value="7 Year">7 Year</option>
                                        <option value="8 Year">8 Year</option>
                                        <option value="9 Year">9 Year</option>
                                        <option value="10 Year">10 Year</option>
                                        <option value="11 Year">11 Year</option>
                                        <option value="12 Year">12 Year</option>
                                        <option value="13 Year">13 Year</option>
                                        <option value="14 Year">14 Year</option>
                                        <option value="15 Year">15 Year</option>
                                        <option value="No NCB in last 2 years">No NCB in last 2 years</option>
                                    </select>


                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Is Awais's no-claims bonus (NCB) currently protected? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="currentlyProtected"
                                                        id="bonus"
                                                        value="YES"
                                                        checked={data?.currentlyProtected.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="bonus">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="currentlyProtected"
                                                        id="bonusNo"
                                                        value="NO"
                                                        checked={data?.currentlyProtected.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="bonusNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Does the driver own or use another vehicle? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="driverVehicalOrNot"
                                                        id="vehicle"
                                                        value="YES"
                                                        checked={data?.driverVehicalOrNot.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="vehicle">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="driverVehicalOrNot"
                                                        id="vehicleNo"
                                                        value="NO"
                                                        checked={data?.driverVehicalOrNot.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="vehicleNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-drive">How many cars are kept at home?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="11"
                                                        value="1"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('1')}
                                                    />

                                                    <label className="form-check-label" htmlFor="11">
                                                        1
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="12"
                                                        value="2"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('2')}
                                                    />

                                                    <label className="form-check-label" htmlFor="12">
                                                        2
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="13"
                                                        value="3"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('3')}
                                                    />

                                                    <label className="form-check-label" htmlFor="13">
                                                        3
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="14"
                                                        value="4"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('4')}
                                                    />

                                                    <label className="form-check-label" htmlFor="14">
                                                        4
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="15"
                                                        value="5"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('5')}
                                                    />

                                                    <label className="form-check-label" htmlFor="15">
                                                        5
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="16"
                                                        value="6"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('6')}
                                                    />

                                                    <label className="form-check-label" htmlFor="16">
                                                        6
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="17"
                                                        value="7"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('7')}
                                                    />

                                                    <label className="form-check-label" htmlFor="17">
                                                        7
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="18"
                                                        value="8"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('8')}
                                                    />

                                                    <label className="form-check-label" htmlFor="18">
                                                        8
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="19"
                                                        value="9"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('9')}
                                                    />

                                                    <label className="form-check-label" htmlFor="19">
                                                        9
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30 mt-10">Car Usage</h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group ">
                                        <label htmlFor="log-Doors">Has the car been bought yet? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="boghtYet"
                                                        id="bought"
                                                        value="YES"
                                                        checked={data?.boghtYet.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="bought">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="boghtYet"
                                                        id="boughtNo"
                                                        value="NO"
                                                        checked={data?.boghtYet.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="boughtNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-20">
                                        <label htmlFor="log-manufacture">When was the car bought? *</label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            name="boughtDate"
                                            placeholder="Date of birth"
                                            max={moment().format("YYYY-MM-DD")}
                                            value={data?.boughtDate}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-20">
                                        <label htmlFor="log-manufacture">Who is the car registered to? *</label>

                                        <Input type="text" name="carRegisteredTo" id="carRegisteredTo"
                                            value={data?.carRegisteredTo}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="log-manufacture">Who owns the car? *</label>

                                        <Input type="text" name="carOwner" id="carOwner"
                                            value={data?.carOwner}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-Doors">What will the car be used for?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="carUsedFor"
                                                        id="carUsedFor1"
                                                        value="SOCIAL ONLY"
                                                        checked={data?.carUsedFor.includes('SOCIAL ONLY')}
                                                    />

                                                    <label className="form-check-label" htmlFor="carUsedFor1">
                                                        SOCIAL ONLY
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="carUsedFor"
                                                        id="carUsedFor2"
                                                        value="SOCIAL & COMMUTING"
                                                        checked={data?.carUsedFor.includes('SOCIAL & COMMUTING')}
                                                    />

                                                    <label className="form-check-label" htmlFor="carUsedFor2">
                                                        SOCIAL & COMMUTING
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="carUsedFor"
                                                        id="carUsedFor3"
                                                        value="BUSINESS USE"
                                                        checked={data?.carUsedFor.includes('BUSINESS USE')}
                                                    />

                                                    <label className="form-check-label" htmlFor="carUsedFor3">
                                                        BUSINESS USE
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30 mt-10">Claims</h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Have any drivers had any motor accidents or claims in the last 5 years? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="accidentClaims"
                                                        id="accidents"
                                                        value="YES"
                                                        checked={data?.accidentClaims.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="accidents">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="accidentClaims"
                                                        id="accidentsNo"
                                                        value="NO"
                                                        checked={data?.accidentClaims.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="accidentsNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <label htmlFor="log-policy">What type of incident was it? *</label>
                                    <select className="form-group f-14"
                                        name="incidentType"
                                        value={data?.incidentType}
                                        disabled
                                    >
                                        <option value="Accident">Accident</option>
                                        <option value="Windscreen Glass">Windscreen Glass</option>
                                        <option value="Theft">Theft</option>
                                        <option value="Malicious Damage">Malicious Damage</option>
                                        <option value="Strom or flood">Strom or flood</option>
                                        <option value="Fire">Fire</option>
                                    </select>

                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30 mt-10">Medical conditions & disabilities</h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-12">

                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="medicalCondition"
                                                        id="conditions"
                                                        value="YES"
                                                        checked={data?.medicalCondition.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="conditions">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="medicalCondition"
                                                        id="conditionsNo"
                                                        value="NO"
                                                        checked={data?.medicalCondition.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="conditionsNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <label htmlFor="log-policy">Select medical condition *</label>
                                    <select className="form-group f-14"
                                        name="dvlaMedicalConditionsType"
                                        value={data?.dvlaMedicalConditionsType}
                                        disabled
                                    >
                                        <option value="DVLA aware - advised no to drive">DVLA aware - advised no to drive</option>
                                        <option value="DVLA aware - no restrictions">DVLA aware - no restrictions</option>
                                        <option value="DVLA aware - 1yr resticted licence">DVLA aware - 1yr resticted licence</option>
                                        <option value="DVLA aware - 2yr resticted licence">DVLA aware - 2yr resticted licence</option>
                                        <option value="DVLA aware - 3yr resticted licence">DVLA aware - 3yr resticted licence</option>
                                        <option value="DVLA unaware">DVLA unaware</option>
                                    </select>

                                </div>
                            </div>
                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30 mt-10">Medical conditions & disabilities</h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-12">
                                    <label htmlFor="declinedCancelledImposed">Have any of the drivers ever had insurance declined, cancelled or special terms imposed?</label>
                                    <select className="form-group f-14"
                                        name="declinedCancelledImposed"
                                        value={data?.declinedCancelledImposed}
                                        disabled
                                    >
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                            </div>

                        </Row>
                    }

                    {data?.vehicleType == "VAN" &&
                        <Row>
                            <h3 className="title mb-20 mt-10">01. Van Details</h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-12">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Van">Van Make</label>
                                        <Input type="text" name="make" id="reg-Van"
                                            value={data?.make}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Model">Van Model</label>
                                        <Input type="text" name="vanModel" id="reg-Model"
                                            value={data?.vanModel}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="log-manufacture">Year of manufacture</label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            name="manufactureYear"
                                            placeholder="Year of manufacture"
                                            max={moment().format("YYYY-MM-DD")}
                                            value={data?.manufactureYear}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Transmission">Transmission</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="transmission"
                                                        id="exampleRadios1"
                                                        value="MANUAL"
                                                        checked={data?.transmission.includes('MANUAL')}
                                                    />

                                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                                        MANUAL
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="transmission"
                                                        id="exampleRadios2"
                                                        value="AUTOMATIC"
                                                        checked={data?.transmission.includes('AUTOMATIC')}
                                                    />

                                                    <label className="form-check-label" htmlFor="exampleRadios2">
                                                        AUTOMATIC
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="log-Fuel">Fuel Type</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="fuelType"
                                                        id="fuelType"
                                                        value="PETROL"
                                                        checked={data?.fuelType.includes('PETROL')}
                                                    />

                                                    <label className="form-check-label" htmlFor="fuelType">
                                                        PETROL
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="fuelType"
                                                        id="fuelType1"
                                                        value="DIESEL"
                                                        checked={data?.fuelType.includes('DIESEL')}
                                                    />

                                                    <label className="form-check-label" htmlFor="fuelType1">
                                                        DIESEL
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Engine">Engine Size</label>
                                        <Input type="text" name="engineSize" id="reg-Engine"
                                            value={data?.engineSize}
                                            disabled

                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="reg-Gross">Gross Weight (KG)</label>
                                        <Input type="number" name="grossWeight" id="reg-Gross"
                                            value={data?.grossWeight}
                                            disabled
                                        />
                                    </div>

                                </div>
                            </div>


                            <h3 className="title mb-30 mt-10">02. About The Van  </h3>

                            <div className="row mb-20 justify-content-center">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="log-Transmission">Has the van been imported?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isImported"
                                                        id="imported"
                                                        value="YES"
                                                        checked={data?.isImported.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="imported">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isImported"
                                                        id="imported2"
                                                        value="NO"
                                                        checked={data?.isImported.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="imported2">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="log-Transmission">Is the van right-hand drive?</label>
                                    </div>
                                    <div className="card-heading mb-20">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isRightHandDrive"
                                                        id="right"
                                                        value="YES"
                                                        checked={data?.isRightHandDrive.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="right">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isRightHandDrive"
                                                        id="right2"
                                                        value="NO"
                                                        checked={data?.isRightHandDrive.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="right2">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="worth">How Much is the VAN Worth</label>
                                        <Input type="number" name="worth" id="worth"
                                            value={data?.worth}
                                            disabled
                                        />
                                    </div>

                                    <label htmlFor="log-policy">How many seats does the VAN have?</label>
                                    <select className="form-group f-14 mb-20"
                                        name="seats"
                                        value={data?.seats}
                                        disabled
                                    >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>


                                    <div className="form-group">
                                        <label htmlFor="log-Transmission">Does the van have an alarm, immobiliser or tracker?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="immobiliserOrTracker"
                                                        id="FACTORY"
                                                        value="FACTORY FITTED IMMOBILISER"
                                                        checked={data?.immobiliserOrTracker.includes('FACTORY FITTED IMMOBILISER')}
                                                    />

                                                    <label className="form-check-label" htmlFor="FACTORY">
                                                        FACTORY FITTED IMMOBILISER
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="immobiliserOrTracker"
                                                        id="PROFESSIONALLY"
                                                        value="PROFESSIONALLY FITTED IMMOBILISER"
                                                        checked={data?.immobiliserOrTracker.includes('PROFESSIONALLY FITTED IMMOBILISER')}
                                                    />

                                                    <label className="form-check-label" htmlFor="PROFESSIONALLY">
                                                        PROFESSIONALLY FITTED IMMOBILISER
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="immobiliserOrTracker"
                                                        id="THATCHAM"
                                                        value="THATCHAM CATEGORY 1 IMMOBILISER"
                                                        checked={data?.immobiliserOrTracker.includes('THATCHAM CATEGORY 1 IMMOBILISER')}
                                                    />

                                                    <label className="form-check-label" htmlFor="THATCHAM">
                                                        THATCHAM CATEGORY 1 IMMOBILISER
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="immobiliserOrTracker"
                                                        id="TRACKER"
                                                        value="TRACKER"
                                                        checked={data?.immobiliserOrTracker.includes('TRACKER')}
                                                    />

                                                    <label className="form-check-label" htmlFor="TRACKER">
                                                        TRACKER
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="immobiliserOrTracker"
                                                        id="NONE"
                                                        value="NONE"
                                                        checked={data?.immobiliserOrTracker.includes('NONE')}
                                                    />

                                                    <label className="form-check-label" htmlFor="NONE">
                                                        NONE
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Transmission">Do you have a dashcam fitted in your van?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="dashCam"
                                                        id="dashcam"
                                                        value="YES"
                                                        checked={data?.dashCam.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="dashcam">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="dashCam"
                                                        id="dashcam2"
                                                        value="NO"
                                                        checked={data?.dashCam.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="dashcam2">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Transmission">Does the van have any modifications?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="modifications"
                                                        id="modifications"
                                                        value="YES"
                                                        checked={data?.modifications.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="modifications">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="modifications"
                                                        id="modifications2"
                                                        value="NO"
                                                        checked={data?.modifications.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="modifications2">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <label htmlFor="log-policy">How would you describe the van's body type?</label>
                                    <select className="form-group f-14"
                                        name="bodyType"
                                        value={data?.bodyType}
                                        disabled
                                    >
                                        <option value="Van">Van</option>
                                        <option value="Pick-up">Pick-up</option>
                                        <option value="Tipper">Tipper</option>
                                        <option value="Dropside">Dropside</option>
                                        <option value="Van-Referigerated">Van-Referigerated</option>
                                        <option value="Vehicle Transporter">Vehicle Transporter</option>
                                        <option value="Flatbed">Flatbed</option>
                                        <option value="Luton">Luton</option>
                                        <option value="Box van">Box van</option>
                                        <option value="Curtainside van">Curtainside van</option>
                                    </select>

                                </div>
                            </div>


                            <h3 className="title mb-30 mt-10">03. Your details (policy holder)</h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-12">
                                    <label htmlFor="log-Make">Title *</label>
                                    <select className="form-group f-14 mb-20"
                                        name="vanTitle"
                                        value={data?.vanTitle}
                                        disabled
                                    >
                                        <option value="MR">MR</option>
                                        <option value="MRS">MRS</option>
                                        <option value="MISS">MISS</option>
                                        <option value="MS">MS</option>
                                        <option value="DR (MALE)">DR (MALE)</option>
                                    </select>
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Name">First Name </label>
                                        <Input type="text" name="vanfirstName" id="vanfirstName"
                                            value={data?.vanfirstName}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Surname">Surname</label>
                                        <Input type="text" name="sureName" id="reg-Surname"
                                            value={data?.sureName}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Date">Date of birth *</label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Date of birth"
                                            max={moment().format("YYYY-MM-DD")}
                                            name="DOB"
                                            value={data?.DOB}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="reg-Phone">Phone Number*</label>
                                        <Input type="number" name="vanPhoneNo" id="reg-Phone"
                                            value={data?.vanPhoneNo}
                                            disabled
                                        />
                                    </div>

                                </div>
                            </div>


                            <h3 className="title mb-30 mt-10">04. About you</h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Marital status</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="vanMaritalStatus"
                                                        id="SINGLE"
                                                        value="SINGLE"
                                                        checked={data?.vanMaritalStatus.includes('SINGLE')}

                                                    />

                                                    <label className="form-check-label" htmlFor="SINGLE">
                                                        SINGLE
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="vanMaritalStatus"
                                                        id="MARRIED"
                                                        value="MARRIED"
                                                        checked={data?.vanMaritalStatus.includes('MARRIED')}
                                                    />

                                                    <label className="form-check-label" htmlFor="MARRIED">
                                                        MARRIED
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="vanMaritalStatus"
                                                        id="LIVING"
                                                        value="LIVING WITH PARTNER"
                                                        checked={data?.vanMaritalStatus.includes('LIVING WITH PARTNER')}
                                                    />

                                                    <label className="form-check-label" htmlFor="LIVING">
                                                        LIVING WITH PARTNER
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="vanMaritalStatus"
                                                        id="WIDOWED"
                                                        value="WIDOWED"
                                                        checked={data?.vanMaritalStatus.includes('WIDOWED')}
                                                    />

                                                    <label className="form-check-label" htmlFor="WIDOWED">
                                                        WIDOWED
                                                    </label>
                                                </div>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="vanMaritalStatus"
                                                        id="CIVIL"
                                                        value="CIVIL PARTNERSHIP"
                                                        checked={data?.vanMaritalStatus.includes('CIVIL PARTNERSHIP')}
                                                    />

                                                    <label className="form-check-label" htmlFor="CIVIL">
                                                        CIVIL PARTNERSHIP
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="vanMaritalStatus"
                                                        id="DIVORCED"
                                                        value="DIVORCED"
                                                        checked={data?.vanMaritalStatus.includes('DIVORCED')}
                                                    />

                                                    <label className="form-check-label" htmlFor="DIVORCED">
                                                        DIVORCED
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="vanMaritalStatus"
                                                        id="SEPERATED"
                                                        value="SEPERATED"
                                                        checked={data?.vanMaritalStatus.includes('SEPERATED')}
                                                    />

                                                    <label className="form-check-label" htmlFor="SEPERATED">
                                                        SEPERATED
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="log-Transmission">Are you living in UK since birth?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="livingInUkSinceBirth"
                                                        id="since"
                                                        value="YES"
                                                        checked={data?.livingInUkSinceBirth.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="since">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="livingInUkSinceBirth"
                                                        id="since2"
                                                        value="NO"
                                                        checked={data?.livingInUkSinceBirth.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="since2">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="log-Transmission">Do you have any children under 16 years old?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="childUnderSixteen"
                                                        id="under"
                                                        value="YES"
                                                        checked={data?.childUnderSixteen.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="under">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="childUnderSixteen"
                                                        id="under2"
                                                        value="NO"
                                                        checked={data?.childUnderSixteen.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="under2">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <label htmlFor="log-Make">What's your employement status?</label>
                                    <select className="form-group f-14 mb-20"
                                        name="employementStatus"
                                        value={data?.employementStatus}
                                        disabled
                                    >
                                        <option value="Employed">Employed</option>
                                        <option value="Self-employed">Self-employed</option>
                                        <option value="Retired">Retired</option>
                                        <option value="Full-time education (student)">Full-time education (student)</option>
                                        <option value="Unemployed">Unemployed</option>
                                        <option value="House person/parent">House person/parent</option>
                                        <option value="Not employed due to disability">Not employed due to disability</option>
                                        <option value="Independent means">Independent means</option>
                                        <option value="Voluntary">Voluntary</option>
                                    </select>


                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-occupation">What's your main occupation?</label>
                                        <Input type="text" name="occupation" id="reg-occupation"
                                            value={data?.occupation}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-industry">What industry is this occupation in?</label>
                                        <Input type="text" name="industy" id="reg-industry"
                                            value={data?.industy}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Transmission">Do you have another occupation?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="occupation"
                                                        id="occupation"
                                                        value="YES"
                                                        checked={data?.isBirthPlaceUK.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="occupation">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="occupation"
                                                        id="occupation2"
                                                        value="NO"
                                                        checked={data?.isBirthPlaceUK.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="occupation2">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="log-Transmission">Are you a homeowner?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="vanHomeOwner"
                                                        id="homeowner"
                                                        value="YES"
                                                        checked={data?.vanHomeOwner.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="homeowner">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="vanHomeOwner"
                                                        id="homeowner2"
                                                        value="NO"
                                                        checked={data?.vanHomeOwner.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="homeowner2">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-postcode">Please enter a postcode</label>
                                        <Input type="text" name="postCode" id="reg-postcode"
                                            value={data?.postCode}
                                            disabled
                                        />
                                    </div>


                                    <label htmlFor="log-Make">What is the licence type?</label>
                                    <select className="form-group f-14 mb-20" value={data?.licenType}
                                        disabled>
                                        <option value="">Plese choose</option>
                                        <option value="Full Euro (EEC)">Full Euro (EEC)</option>
                                        <option value="UK provisional">UK provisional</option>
                                        <option value="Full UK-automatic">Full UK-automatic</option>
                                        <option value="International licence (none-exchangeable)">International licence (none-exchangeable)</option>
                                        <option value="International licence (exchangeable)">International licence (exchangeable)</option>
                                    </select>

                                    <label htmlFor="log-Make">HOW LONG HAS THE LICENCE BEEN HELD?</label>
                                    <select className="form-group f-14 mb-20"
                                        name="licencePeriod"
                                        value={data?.licencePeriod}
                                        disabled
                                    >
                                        <option value="Less than a year">Less than a year</option>
                                        <option value="1 year">1 year</option>
                                        <option value="2 year">2 year</option>
                                        <option value="3 year">3 year</option>
                                        <option value="4 year">4 year</option>
                                        <option value="5 year">5 year</option>
                                        <option value="5+ year">5+ year</option>
                                    </select>


                                    <div className="form-group">
                                        <label htmlFor="log-Transmission">Would you like to provide your GB driving licence number? Some insurers may provide a discount if you provide this.</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="gbLicenceNumber"
                                                        id="GB"
                                                        value="YES"
                                                        checked={data?.gbLicenceNumber.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="GB">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="gbLicenceNumber"
                                                        id="GB2"
                                                        value="NO"
                                                        checked={data?.gbLicenceNumber.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="GB2">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Transmission">Do you have any additional driving qualifications?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="additionalDrivingQualifications"
                                                        id="qualifications"
                                                        value="YES"
                                                        checked={data?.additionalDrivingQualifications.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="qualifications">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="additionalDrivingQualifications"
                                                        id="qualifications2"
                                                        value="NO"
                                                        checked={data?.additionalDrivingQualifications.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="qualifications2">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="log-Transmission">Do you have any DVLA-reportable medical conditions or disabilities?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="vanMedicalCondition"
                                                        id="DVLA"
                                                        value="YES"
                                                        checked={data?.vanMedicalCondition.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="DVLA">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="vanMedicalCondition"
                                                        id="DVLA2"
                                                        value="NO"
                                                        checked={data?.vanMedicalCondition.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="DVLA2">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <h3 className="title mb-30 mt-10">05. Drivers</h3>
                            <div className="row mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <label htmlFor="log-Make">Title</label>
                                    <select className="form-group f-14 mb-20"
                                        name="title"
                                        id="value={validationStep2.values.doesHaveDashcam}"
                                        value={data?.title}
                                        disabled
                                    >
                                        <option value="MR">MR</option>
                                        <option value="MRS">MRS</option>
                                        <option value="MISS">MISS</option>
                                        <option value="MS">MS</option>
                                        <option value="DR (MALE)">DR (MALE)</option>
                                        <option value="DR (FEMALE)">DR (FEMALE)</option>
                                    </select>
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Name">First Name *</label>
                                        <Input type="text" name="firstName" id="firstName"
                                            value={data?.firstName}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Last">Last Name *</label>
                                        <Input type="text" name="lastName" id="lastName"
                                            value={data?.lastName}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Phone">Phone *</label>
                                        <Input type="text" name="Phone" id="Phone"
                                            value={data?.driverPhone}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Date">Date of birth *</label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            name="dob"
                                            placeholder="Date of birth"
                                            max={moment().format("YYYY-MM-DD")}
                                            value={data?.dob}
                                            disabled
                                        />
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Has the driver lived in the UK since birth? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isBirthPlaceUK"
                                                        id="birth"
                                                        value="YES"
                                                        checked={data?.isBirthPlaceUK.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="birth">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isBirthPlaceUK"
                                                        id="birthNO"
                                                        value="NO"
                                                        checked={data?.isBirthPlaceUK.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="birthNO">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h5 className="title mb-20 mt-10">When did the driver start continuously living in the UK?</h5>
                                    <div className="form-group">
                                        <label htmlFor="month">Month</label>
                                        <Input type="number" name="month" id="month"
                                            value={data?.month}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="reg-Year">Year</label>
                                        <Input type="number" name="year" id="year"
                                            value={data?.year}
                                            disabled
                                        />
                                    </div>



                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Gender</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="gender"
                                                        id="MALE"
                                                        value="MALE"
                                                        checked={data?.gender.includes('MALE')}
                                                    />

                                                    <label className="form-check-label" htmlFor="MALE">
                                                        MALE
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="gender"
                                                        id="FEMALE"
                                                        value="FEMALE"
                                                        checked={data?.gender.includes('FEMALE')}
                                                    />

                                                    <label className="form-check-label" htmlFor="FEMALE">
                                                        FEMALE
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Marital status</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="maritalStatus"
                                                        id="SINGLE"
                                                        value="SINGLE"
                                                        checked={data?.maritalStatus.includes('SINGLE')}
                                                    />

                                                    <label className="form-check-label" htmlFor="SINGLE">
                                                        SINGLE
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="maritalStatus"
                                                        id="MARRIED"
                                                        value="MARRIED"
                                                        checked={data?.maritalStatus.includes('MARRIED')}
                                                    />

                                                    <label className="form-check-label" htmlFor="MARRIED">
                                                        MARRIED
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="maritalStatus"
                                                        id="LIVING"
                                                        value="LIVING WITH PARTNER"
                                                        checked={data?.maritalStatus.includes('LIVING WITH PARTNER')}
                                                    />

                                                    <label className="form-check-label" htmlFor="LIVING">
                                                        LIVING WITH PARTNER
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="maritalStatus"
                                                        id="WIDOWED"
                                                        value="WIDOWED"
                                                        checked={data?.maritalStatus.includes('WIDOWED')}
                                                    />

                                                    <label className="form-check-label" htmlFor="WIDOWED">
                                                        WIDOWED
                                                    </label>
                                                </div>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="exampleRadioss"
                                                        id="CIVIL"
                                                        value="CIVIL PARTNERSHIP"
                                                        checked={data?.maritalStatus.includes('CIVIL PARTNERSHIP')}
                                                    />

                                                    <label className="form-check-label" htmlFor="CIVIL">
                                                        CIVIL PARTNERSHIP
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="maritalStatus"
                                                        id="DIVORCED"
                                                        value="DIVORCED"
                                                        checked={data?.maritalStatus.includes('DIVORCED')}
                                                    />

                                                    <label className="form-check-label" htmlFor="DIVORCED">
                                                        DIVORCED
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="maritalStatus"
                                                        id="SEPERATED"
                                                        value="SEPERATED"
                                                        checked={data?.maritalStatus.includes('SEPERATED')}
                                                    />

                                                    <label className="form-check-label" htmlFor="SEPERATED">
                                                        SEPERATED
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Does the driver own or use another vehicle? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="driverOwnorNot"
                                                        id="YESa"
                                                        value="YES"
                                                        checked={data?.driverOwnorNot.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="YESa">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="driverOwnorNot"
                                                        id="NOs"
                                                        value="NO"
                                                        checked={data?.driverOwnorNot.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="NOs">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-20">Some insurers could offer a discount if the driver uses another vehicle, as they’ll have a better idea of driving habits.</p>

                                </div>
                            </div>


                            <h3 className="title mb-30 mt-10">06. Occupation</h3>

                            <div className="form-group">
                                <label htmlFor="log-Doors">What's the driver's employment status?</label>
                            </div>
                            <div className="card-heading mb-2">
                                <div className="row">
                                    <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <Input
                                                className="form-check-Input"
                                                type="radio"
                                                name="employeementStatus"
                                                id="EMPLOYED"
                                                value="EMPLOYED"
                                                checked={data?.employeementStatus.includes('EMPLOYED')}

                                            />

                                            <label className="form-check-label" htmlFor="EMPLOYED">
                                                EMPLOYED
                                            </label>
                                        </div>

                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <Input
                                                className="form-check-Input"
                                                type="radio"
                                                name="employeementStatus"
                                                id="SELE-EMPLOYED"
                                                value="SELE-EMPLOYED"
                                                checked={data?.employeementStatus.includes('SELE-EMPLOYED')}
                                            />

                                            <label className="form-check-label" htmlFor="SELE-EMPLOYED">
                                                SELE-EMPLOYED
                                            </label>
                                        </div>

                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <Input
                                                className="form-check-Input"
                                                type="radio"
                                                name="employeementStatus"
                                                id="RETIRED"
                                                value="RETIRED"
                                                checked={data?.employeementStatus.includes('RETIRED')}
                                            />

                                            <label className="form-check-label" htmlFor="RETIRED">
                                                RETIRED
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30 mt-10">07. Licence</h3>

                            <div className="form-group">
                                <label htmlFor="log-Doors">What type of license does the driver currently have?</label>
                            </div>
                            <div className="card-heading mb-2">
                                <div className="row">
                                    <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <Input
                                                className="form-check-Input"
                                                type="radio"
                                                name="licenceType"
                                                id="FULL UK-MANUAL"
                                                value="FULL UK-MANUAL"
                                                checked={data?.licenceType.includes('FULL UK-MANUAL')}
                                            />

                                            <label className="form-check-label" htmlFor="FULL UK-MANUAL">
                                                FULL UK-MANUAL
                                            </label>
                                        </div>

                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <Input
                                                className="form-check-Input"
                                                type="radio"
                                                name="licenceType"
                                                id="EEC"
                                                value="FULL EURO (EEC)"
                                                checked={data?.licenceType.includes('FULL EURO (EEC)')}
                                            />

                                            <label className="form-check-label" htmlFor="EEC">
                                                FULL EURO (EEC)
                                            </label>
                                        </div>

                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <Input
                                                className="form-check-Input"
                                                type="radio"
                                                name="licenceType"
                                                id="OPTIONS"
                                                value="SHOW MORE OPTIONS"
                                                checked={data?.licenceType.includes('SHOW MORE OPTIONS')}
                                            />

                                            <label className="form-check-label" htmlFor="OPTIONS">
                                                SHOW MORE OPTIONS
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h3 className="title mb-30 mt-10">08. Further policyholder details</h3>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="reg-number">Phone number</label>
                                        <Input type="number" name="phoneNo" id="phoneNo"
                                            value={data?.phoneNo}
                                            disabled
                                        />
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="reg-Postal">Postal Code</label>
                                        <Input type="text" name="postalCode" id="reg-Postal"
                                            value={data?.postalCode}
                                            disabled
                                        />
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="reg-Address">Address</label>
                                        <Input type="textarea" name="address" id="address"
                                            value={data?.address}
                                            disabled
                                        />
                                    </div>

                                </div>
                            </div>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Is you are a homeowner? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="homeOwner"
                                                        id="homeowner"
                                                        value="YES"
                                                        checked={data?.homeOwner.includes('YES')}

                                                    />

                                                    <label className="form-check-label" htmlFor="homeowner">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="homeOwner"
                                                        id="homeownerNo"
                                                        value="NO"
                                                        checked={data?.homeOwner.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="homeownerNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Will the car be kept at home during the day? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isCarKeptAtHome"
                                                        id="during"
                                                        value="YES"
                                                        checked={data?.isCarKeptAtHome.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="during">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isCarKeptAtHome"
                                                        id="duringNo"
                                                        value="NO"
                                                        checked={data?.isCarKeptAtHome.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="duringNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Doors">By the time the policy begins, will Awais have any children under 16 years old? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="childrenUnderSixteen"
                                                        id="policy"
                                                        value="YES"
                                                        checked={data?.childrenUnderSixteen.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="policy">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="childrenUnderSixteen"
                                                        id="policyNo"
                                                        value="NO"
                                                        checked={data?.childrenUnderSixteen.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="policyNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <label htmlFor="log-policy">By the time the policy begins, how many years of no-claims bonus (NCB) will Awais have? *</label>
                                    <select className="form-group f-14" name="YearsofNoClaimBonus"
                                        value={data?.YearsofNoClaimBonus}
                                        disabled
                                    >
                                        <option value="NO NCB">NO NCB</option>
                                        <option value="1 Year">1 Year</option>
                                        <option value="2 Year">2 Year</option>
                                        <option value="3 Year">3 Year</option>
                                        <option value="4 Year">4 Year</option>
                                        <option value="5 Year">5 Year</option>
                                        <option value="6 Year">6 Year</option>
                                        <option value="7 Year">7 Year</option>
                                        <option value="8 Year">8 Year</option>
                                        <option value="9 Year">9 Year</option>
                                        <option value="10 Year">10 Year</option>
                                        <option value="11 Year">11 Year</option>
                                        <option value="12 Year">12 Year</option>
                                        <option value="13 Year">13 Year</option>
                                        <option value="14 Year">14 Year</option>
                                        <option value="15 Year">15 Year</option>
                                        <option value="No NCB in last 2 years">No NCB in last 2 years</option>
                                    </select>


                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Is Awais's no-claims bonus (NCB) currently protected? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="currentlyProtected"
                                                        id="bonus"
                                                        value="YES"
                                                        checked={data?.currentlyProtected.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="bonus">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="currentlyProtected"
                                                        id="bonusNo"
                                                        value="NO"
                                                        checked={data?.currentlyProtected.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="bonusNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Does the driver own or use another vehicle? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="driverVehicalOrNot"
                                                        id="vehicle"
                                                        value="YES"
                                                        checked={data?.driverVehicalOrNot.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="vehicle">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="driverVehicalOrNot"
                                                        id="vehicleNo"
                                                        value="NO"
                                                        checked={data?.driverVehicalOrNot.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="vehicleNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-drive">How many cars are kept at home?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="11"
                                                        value="1"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('1')}
                                                    />

                                                    <label className="form-check-label" htmlFor="11">
                                                        1
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="12"
                                                        value="2"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('2')}
                                                    />

                                                    <label className="form-check-label" htmlFor="12">
                                                        2
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="13"
                                                        value="3"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('3')}
                                                    />

                                                    <label className="form-check-label" htmlFor="13">
                                                        3
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="14"
                                                        value="4"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('4')}
                                                    />

                                                    <label className="form-check-label" htmlFor="14">
                                                        4
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="15"
                                                        value="5"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('5')}
                                                    />

                                                    <label className="form-check-label" htmlFor="15">
                                                        5
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="16"
                                                        value="6"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('6')}
                                                    />

                                                    <label className="form-check-label" htmlFor="16">
                                                        6
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="17"
                                                        value="7"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('7')}
                                                    />

                                                    <label className="form-check-label" htmlFor="17">
                                                        7
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="18"
                                                        value="8"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('8')}
                                                    />

                                                    <label className="form-check-label" htmlFor="18">
                                                        8
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="19"
                                                        value="9"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('9')}
                                                    />

                                                    <label className="form-check-label" htmlFor="19">
                                                        9
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30 mt-10">Car Usage</h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group ">
                                        <label htmlFor="log-Doors">Has the car been bought yet? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="boghtYet"
                                                        id="bought"
                                                        value="YES"
                                                        checked={data?.boghtYet.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="bought">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="boghtYet"
                                                        id="boughtNo"
                                                        value="NO"
                                                        checked={data?.boghtYet.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="boughtNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-20">
                                        <label htmlFor="log-manufacture">When was the car bought? *</label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            name="boughtDate"
                                            placeholder="Date of birth"
                                            max={moment().format("YYYY-MM-DD")}
                                            value={data?.boughtDate}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-20">
                                        <label htmlFor="log-manufacture">Who is the car registered to? *</label>

                                        <Input type="text" name="carRegisteredTo" id="carRegisteredTo"
                                            value={data?.carRegisteredTo}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="log-manufacture">Who owns the car? *</label>

                                        <Input type="text" name="carOwner" id="carOwner"
                                            value={data?.carOwner}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-Doors">What will the car be used for?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="carUsedFor"
                                                        id="carUsedFor1"
                                                        value="SOCIAL ONLY"
                                                        checked={data?.carUsedFor.includes('SOCIAL ONLY')}
                                                    />

                                                    <label className="form-check-label" htmlFor="carUsedFor1">
                                                        SOCIAL ONLY
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="carUsedFor"
                                                        id="carUsedFor2"
                                                        value="SOCIAL & COMMUTING"
                                                        checked={data?.carUsedFor.includes('SOCIAL & COMMUTING')}
                                                    />

                                                    <label className="form-check-label" htmlFor="carUsedFor2">
                                                        SOCIAL & COMMUTING
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="carUsedFor"
                                                        id="carUsedFor3"
                                                        value="BUSINESS USE"
                                                        checked={data?.carUsedFor.includes('BUSINESS USE')}
                                                    />

                                                    <label className="form-check-label" htmlFor="carUsedFor3">
                                                        BUSINESS USE
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30 mt-10">Claims</h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Have any drivers had any motor accidents or claims in the last 5 years? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="accidentClaims"
                                                        id="accidents"
                                                        value="YES"
                                                        checked={data?.accidentClaims.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="accidents">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="accidentClaims"
                                                        id="accidentsNo"
                                                        value="NO"
                                                        checked={data?.accidentClaims.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="accidentsNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <label htmlFor="log-policy">What type of incident was it? *</label>
                                    <select className="form-group f-14"
                                        name="incidentType"
                                        value={data?.incidentType}
                                        disabled
                                    >
                                        <option value="Accident">Accident</option>
                                        <option value="Windscreen Glass">Windscreen Glass</option>
                                        <option value="Theft">Theft</option>
                                        <option value="Malicious Damage">Malicious Damage</option>
                                        <option value="Strom or flood">Strom or flood</option>
                                        <option value="Fire">Fire</option>
                                    </select>

                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30 mt-10">Medical conditions & disabilities</h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-12">

                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="medicalCondition"
                                                        id="conditions"
                                                        value="YES"
                                                        checked={data?.medicalCondition.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="conditions">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="medicalCondition"
                                                        id="conditionsNo"
                                                        value="NO"
                                                        checked={data?.medicalCondition.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="conditionsNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <label htmlFor="log-policy">Select medical condition *</label>
                                    <select className="form-group f-14"
                                        name="dvlaMedicalConditionsType"
                                        value={data?.dvlaMedicalConditionsType}
                                        disabled
                                    >
                                        <option value="DVLA aware - advised no to drive">DVLA aware - advised no to drive</option>
                                        <option value="DVLA aware - no restrictions">DVLA aware - no restrictions</option>
                                        <option value="DVLA aware - 1yr resticted licence">DVLA aware - 1yr resticted licence</option>
                                        <option value="DVLA aware - 2yr resticted licence">DVLA aware - 2yr resticted licence</option>
                                        <option value="DVLA aware - 3yr resticted licence">DVLA aware - 3yr resticted licence</option>
                                        <option value="DVLA unaware">DVLA unaware</option>
                                    </select>

                                </div>
                            </div>
                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30 mt-10">Medical conditions & disabilities</h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-12">
                                    <label htmlFor="declinedCancelledImposed">Have any of the drivers ever had insurance declined, cancelled or special terms imposed?</label>
                                    <select className="form-group f-14"
                                        name="declinedCancelledImposed"
                                        value={data?.declinedCancelledImposed}
                                        disabled
                                    >
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                            </div>

                        </Row>
                    }

                    {data?.vehicleType == "BIKE" &&
                        <Row>
                            <h3 className="title mb-20 mt-10">01. About You</h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-12">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Van">Title *</label>
                                        <Input type="text" name="bikeTitle" id="reg-Van"
                                            value={data?.bikeTitle}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Model">First name *</label>
                                        <Input type="text" name="bikeFirstName" id="reg-Model"
                                            value={data?.bikeFirstName}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Model">Surname *</label>
                                        <Input type="text" name="sureName" id="reg-Model"
                                            value={data?.sureName}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Model">Email Address *</label>
                                        <Input type="text" name="email" id="reg-Model"
                                            value={data?.email}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Model">Mobile Phone Number *</label>
                                        <Input type="number" name="bikePhoneNo" id="reg-Model"
                                            value={data?.bikePhoneNo}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="log-manufacture">Date of birth *</label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            name="bikeDob"
                                            id="formrow-firstname-Input"
                                            placeholder="Date of birth"
                                            max={moment().format("YYYY-MM-DD")}
                                            value={data?.bikeDob}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Marital status *</label>
                                    </div>
                                    <select className="form-group f-14 mb-20"
                                        name="martialStatus"
                                        value={data?.martialStatus}
                                        disabled

                                    >
                                        <option value="Married">Married</option>
                                        <option value="Common Law/Living with partner">Common Law/Living with partner</option>
                                        <option value="Divorced">Divorced</option>
                                    </select>



                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Engine">Post Code * </label>
                                        <Input type="text" name="postCode" id="reg-Engine"
                                            value={data?.postCode}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Transmission">Home Owner*</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="bikeHomeOwner"
                                                        id="Owner"
                                                        value="YES"
                                                        checked={data?.bikeHomeOwner.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="Owner">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="bikeHomeOwner"
                                                        id="Owner2"
                                                        value="NO"
                                                        checked={data?.bikeHomeOwner.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="Owner2">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group mb-20">
                                        <label htmlFor="log-manufacture">UK resident since *</label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            name="residenceSince"
                                            id="formrow-firstname-Input"
                                            placeholder="Date of birth"
                                            max={moment().format("YYYY-MM-DD")}
                                            value={data?.residenceSince}
                                            disabled
                                        />
                                    </div>




                                    <hr className="mb-30 mt-40" />
                                    <h3 className="title mb-30 mt-10">02.Bike specification  </h3>
                                    <div className="row  mb-20 justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="form-group mb-20">
                                                <label htmlFor="reg-Phone">What's the value of the bike?</label>
                                                <Input type="number" name="value" id="reg-Phone"
                                                    value={data?.value}
                                                    disabled
                                                />
                                            </div>

                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="log-Transmission">Is the bike an import?
                                                </label>
                                            </div>
                                            <div className="card-heading mb-2">
                                                <div className="row">
                                                    <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                            <Input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="isImported"
                                                                id="MODEL"
                                                                value="NO-STANDARD UK MODEL"
                                                                checked={data?.isImported.includes('NO-STANDARD UK MODEL')}
                                                            />

                                                            <label className="form-check-label" htmlFor="MODEL">
                                                                NO - STANDARD UK MODEL
                                                            </label>
                                                        </div>

                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                            <Input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="isImported"
                                                                id="SPEC"
                                                                value="YES - EURO IMPORT-UK SPEC"
                                                                checked={data?.isImported.includes('YES - EURO IMPORT-UK SPEC')}
                                                            />

                                                            <label className="form-check-label" htmlFor="SPEC">
                                                                YES - EURO IMPORT-UK SPEC
                                                            </label>
                                                        </div>

                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                            <Input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="isImported"
                                                                id="EURO"
                                                                value="YES - EURO IMPORT- NON UK SPEC"
                                                                checked={data?.isImported.includes('YES - EURO IMPORT- NON UK SPEC')}
                                                            />

                                                            <label className="form-check-label" htmlFor="EURO">
                                                                YES - EURO IMPORT- NON UK SPEC
                                                            </label>
                                                        </div>

                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                            <Input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="isImported"
                                                                id="JAPANESEIMPORT"
                                                                value="YES - JAPANESE IMPORT"
                                                                checked={data?.isImported.includes('YES - JAPANESE IMPORT')}
                                                            />

                                                            <label className="form-check-label" htmlFor="JAPANESEIMPORT">
                                                                YES - JAPANESE IMPORT
                                                            </label>
                                                        </div>

                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                            <Input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="isImported"
                                                                id="USIMPORT"
                                                                value="YES - US IMPORT"
                                                                checked={data?.isImported.includes('YES - US IMPORT')}
                                                            />

                                                            <label className="form-check-label" htmlFor="USIMPORT">
                                                                YES - US IMPORT
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr className="mb-30 mt-40" />
                                    <h3 className="title mb-30 mt-10">03. Further details</h3>
                                    <div className="row  mb-20 justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="log-Doors">Does the Bike have any modifications?</label>
                                            </div>
                                            <select className="form-group f-14 mb-20"
                                                name="modifications"
                                                value={data?.modifications}
                                                disabled
                                            >
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                            </select>

                                        </div>
                                        <div className="col-lg-6">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h3 className="title mb-30 mt-10">04. Cover</h3>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-8">
                                    <div className="form-group">
                                        <label htmlFor="log-manufacture">When would you like the cover to begin, within the next 30 days?</label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            name="coverDate"
                                            placeholder="Date of birth"
                                            max={moment().format("YYYY-MM-DD")}
                                            value={data?.whenWouldYouCover}
                                            disabled

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                </div>
                            </div>


                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-8">
                                    <div className="form-group">
                                        <label htmlFor="log-Doors">What level of cover do you need?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="levelOfCover"
                                                        id="COMPREHENSIVE"
                                                        value="COMPREHENSIVE"
                                                        checked={data?.levelOfCover.includes('COMPREHENSIVE')}
                                                    />

                                                    <label className="form-check-label" htmlFor="COMPREHENSIVE">
                                                        COMPREHENSIVE
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="exampleRadioss"
                                                        id="THEFT"
                                                        value="THIRD PARTY, FIRE & THEFT"
                                                        checked={data?.levelOfCover.includes('THIRD PARTY, FIRE & THEFT')}
                                                    />

                                                    <label className="form-check-label" htmlFor="THEFT">
                                                        THIRD PARTY, FIRE & THEFT
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="exampleRadioss"
                                                        id="PARTY"
                                                        value="THIRD PARTY ONLY"
                                                        checked={data?.levelOfCover.includes('THIRD PARTY ONLY')}
                                                    />

                                                    <label className="form-check-label" htmlFor="PARTY">
                                                        THIRD PARTY ONLY
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Choose a voluntary excess</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="voluntaryExcess"
                                                        id="voluntaryExcess"
                                                        value="150"
                                                        checked={data?.voluntaryExcess.includes('150')}
                                                    />

                                                    <label className="form-check-label" htmlFor="voluntaryExcess">
                                                        £150
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="voluntaryExcess"
                                                        id="250"
                                                        value="250"
                                                        checked={data?.voluntaryExcess.includes('250')}
                                                    />

                                                    <label className="form-check-label" htmlFor="250">
                                                        £250
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="voluntaryExcess"
                                                        id="350"
                                                        value="350"
                                                        checked={data?.voluntaryExcess.includes('350')}
                                                    />

                                                    <label className="form-check-label" htmlFor="350">
                                                        £350
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="voluntaryExcess"
                                                        id="0"
                                                        value="0"
                                                        checked={data?.voluntaryExcess.includes('0')}
                                                    />

                                                    <label className="form-check-label" htmlFor="0">
                                                        £0
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="voluntaryExcess"
                                                        id="100"
                                                        value="100"
                                                        checked={data?.voluntaryExcess.includes('100')}
                                                    />

                                                    <label className="form-check-label" htmlFor="100">
                                                        £100
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="voluntaryExcess"
                                                        id="500"
                                                        value="500"
                                                        checked={data?.voluntaryExcess.includes('500')}
                                                    />

                                                    <label className="form-check-label" htmlFor="500">
                                                        £500
                                                    </label>
                                                </div>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="voluntaryExcess"
                                                        id="750"
                                                        value="750"
                                                        checked={data?.voluntaryExcess.includes('750')}
                                                    />

                                                    <label className="form-check-label" htmlFor="750">
                                                        £750
                                                    </label>
                                                </div>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="voluntaryExcess"
                                                        id="10000"
                                                        value="10000"
                                                        checked={data?.voluntaryExcess.includes('10000')}
                                                    />

                                                    <label className="form-check-label" htmlFor="10000">
                                                        £10000
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="log-Doors">How would you want to pay for the car insurance policy?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="payforCarInsurancePolicy"
                                                        id="ANNUAL"
                                                        value="ANNUAL PAYMENT"
                                                        checked={data?.payForCarInsurance.includes('ANNUAL PAYMENT')}
                                                    />

                                                    <label className="form-check-label" htmlFor="ANNUAL">
                                                        ANNUAL PAYMENT
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="payforCarInsurancePolicy"
                                                        id="MONTHLY"
                                                        value="MONTHLY PAYMENTS"
                                                        checked={data?.payForCarInsurance.includes('MONTHLY PAYMENTS')}
                                                    />

                                                    <label className="form-check-label" htmlFor="MONTHLY">
                                                        MONTHLY PAYMENTS
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <h3 className="title mb-30 mt-10">05. Drivers</h3>
                            <div className="row mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <label htmlFor="log-Make">Title</label>
                                    <select className="form-group f-14 mb-20"
                                        name="title"
                                        id="value={validationStep2.values.doesHaveDashcam}"
                                        value={data?.title}
                                        disabled
                                    >
                                        <option value="MR">MR</option>
                                        <option value="MRS">MRS</option>
                                        <option value="MISS">MISS</option>
                                        <option value="MS">MS</option>
                                        <option value="DR (MALE)">DR (MALE)</option>
                                        <option value="DR (FEMALE)">DR (FEMALE)</option>
                                    </select>
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Name">First Name *</label>
                                        <Input type="text" name="firstName" id="firstName"
                                            value={data?.firstName}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Last">Last Name *</label>
                                        <Input type="text" name="lastName" id="lastName"
                                            value={data?.lastName}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Phone">Phone *</label>
                                        <Input type="text" name="Phone" id="Phone"
                                            value={data?.driverPhone}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Date">Date of birth *</label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            name="dob"
                                            placeholder="Date of birth"
                                            max={moment().format("YYYY-MM-DD")}
                                            value={data?.dob}
                                            disabled
                                        />
                                    </div>


                                    {/* <div className="form-group">
                                        <label htmlFor="log-Doors">Has the driver lived in the UK since birth? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isBirthPlaceUK"
                                                        id="birth"
                                                        value="YES"
                                                        checked={data?.isBirthPlaceUK.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="birth">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isBirthPlaceUK"
                                                        id="birthNO"
                                                        value="NO"
                                                        checked={data?.isBirthPlaceUK.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="birthNO">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <h5 className="title mb-20 mt-10">When did the driver start continuously living in the UK?</h5>
                                    <div className="form-group">
                                        <label htmlFor="month">Month</label>
                                        <Input type="number" name="month" id="month"
                                            value={data?.month}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="reg-Year">Year</label>
                                        <Input type="number" name="year" id="year"
                                            value={data?.year}
                                            disabled
                                        />
                                    </div>
                                </div>

                                
                                <div className="col-lg-6">
                                    {/* <div className="form-group">
                                        <label htmlFor="log-Doors">Gender</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="gender"
                                                        id="MALE"
                                                        value="MALE"
                                                        checked={data?.gender.includes('MALE')}
                                                    />

                                                    <label className="form-check-label" htmlFor="MALE">
                                                        MALE
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="gender"
                                                        id="FEMALE"
                                                        value="FEMALE"
                                                        checked={data?.gender.includes('FEMALE')}
                                                    />

                                                    <label className="form-check-label" htmlFor="FEMALE">
                                                        FEMALE
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div className="form-group">
                                        <label htmlFor="log-Doors">Marital status</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="maritalStatus"
                                                        id="SINGLE"
                                                        value="SINGLE"
                                                        checked={data?.maritalStatus.includes('SINGLE')}
                                                    />

                                                    <label className="form-check-label" htmlFor="SINGLE">
                                                        SINGLE
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="maritalStatus"
                                                        id="MARRIED"
                                                        value="MARRIED"
                                                        checked={data?.maritalStatus.includes('MARRIED')}
                                                    />

                                                    <label className="form-check-label" htmlFor="MARRIED">
                                                        MARRIED
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="maritalStatus"
                                                        id="LIVING"
                                                        value="LIVING WITH PARTNER"
                                                        checked={data?.maritalStatus.includes('LIVING WITH PARTNER')}
                                                    />

                                                    <label className="form-check-label" htmlFor="LIVING">
                                                        LIVING WITH PARTNER
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="maritalStatus"
                                                        id="WIDOWED"
                                                        value="WIDOWED"
                                                        checked={data?.maritalStatus.includes('WIDOWED')}
                                                    />

                                                    <label className="form-check-label" htmlFor="WIDOWED">
                                                        WIDOWED
                                                    </label>
                                                </div>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="exampleRadioss"
                                                        id="CIVIL"
                                                        value="CIVIL PARTNERSHIP"
                                                        checked={data?.maritalStatus.includes('CIVIL PARTNERSHIP')}
                                                    />

                                                    <label className="form-check-label" htmlFor="CIVIL">
                                                        CIVIL PARTNERSHIP
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="maritalStatus"
                                                        id="DIVORCED"
                                                        value="DIVORCED"
                                                        checked={data?.maritalStatus.includes('DIVORCED')}
                                                    />

                                                    <label className="form-check-label" htmlFor="DIVORCED">
                                                        DIVORCED
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="maritalStatus"
                                                        id="SEPERATED"
                                                        value="SEPERATED"
                                                        checked={data?.maritalStatus.includes('SEPERATED')}
                                                    />

                                                    <label className="form-check-label" htmlFor="SEPERATED">
                                                        SEPERATED
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}


                                    {/* <div className="form-group">
                                        <label htmlFor="log-Doors">Does the driver own or use another vehicle? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="driverOwnorNot"
                                                        id="YESa"
                                                        value="YES"
                                                        checked={data?.driverOwnorNot.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="YESa">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="driverOwnorNot"
                                                        id="NOs"
                                                        value="NO"
                                                        checked={data?.driverOwnorNot.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="NOs">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>


                            <h3 className="title mb-30 mt-10">06. Occupation</h3>

                            <div className="form-group">
                                <label htmlFor="log-Doors">What's the driver's employment status?</label>
                            </div>
                            <div className="card-heading mb-2">
                                <div className="row">
                                    <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <Input
                                                className="form-check-Input"
                                                type="radio"
                                                name="employeementStatus"
                                                id="EMPLOYED"
                                                value="EMPLOYED"
                                                checked={data?.employeementStatus.includes('EMPLOYED')}

                                            />

                                            <label className="form-check-label" htmlFor="EMPLOYED">
                                                EMPLOYED
                                            </label>
                                        </div>

                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <Input
                                                className="form-check-Input"
                                                type="radio"
                                                name="employeementStatus"
                                                id="SELE-EMPLOYED"
                                                value="SELE-EMPLOYED"
                                                checked={data?.employeementStatus.includes('SELE-EMPLOYED')}
                                            />

                                            <label className="form-check-label" htmlFor="SELE-EMPLOYED">
                                                SELE-EMPLOYED
                                            </label>
                                        </div>

                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <Input
                                                className="form-check-Input"
                                                type="radio"
                                                name="employeementStatus"
                                                id="RETIRED"
                                                value="RETIRED"
                                                checked={data?.employeementStatus.includes('RETIRED')}
                                            />

                                            <label className="form-check-label" htmlFor="RETIRED">
                                                RETIRED
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30 mt-10">07. Licence</h3>

                            <div className="form-group">
                                <label htmlFor="log-Doors">What type of license does the driver currently have?</label>
                            </div>
                            <div className="card-heading mb-2">
                                <div className="row">
                                    <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <Input
                                                className="form-check-Input"
                                                type="radio"
                                                name="licenceType"
                                                id="FULL UK-MANUAL"
                                                value="FULL UK-MANUAL"
                                                checked={data?.licenceType.includes('FULL UK-MANUAL')}
                                            />

                                            <label className="form-check-label" htmlFor="FULL UK-MANUAL">
                                                FULL UK-MANUAL
                                            </label>
                                        </div>

                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <Input
                                                className="form-check-Input"
                                                type="radio"
                                                name="licenceType"
                                                id="EEC"
                                                value="FULL EURO (EEC)"
                                                checked={data?.licenceType.includes('FULL EURO (EEC)')}
                                            />

                                            <label className="form-check-label" htmlFor="EEC">
                                                FULL EURO (EEC)
                                            </label>
                                        </div>

                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <Input
                                                className="form-check-Input"
                                                type="radio"
                                                name="licenceType"
                                                id="OPTIONS"
                                                value="SHOW MORE OPTIONS"
                                                checked={data?.licenceType.includes('SHOW MORE OPTIONS')}
                                            />

                                            <label className="form-check-label" htmlFor="OPTIONS">
                                                SHOW MORE OPTIONS
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h3 className="title mb-30 mt-10">08. Further policyholder details</h3>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="reg-number">Phone number</label>
                                        <Input type="number" name="phoneNo" id="phoneNo"
                                            value={data?.phoneNo}
                                            disabled
                                        />
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="reg-Postal">Postal Code</label>
                                        <Input type="text" name="postalCode" id="reg-Postal"
                                            value={data?.postalCode}
                                            disabled
                                        />
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="reg-Address">Address</label>
                                        <Input type="textarea" name="address" id="address"
                                            value={data?.address}
                                            disabled
                                        />
                                    </div>

                                </div>
                            </div>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Is you are a homeowner? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="homeOwner"
                                                        id="homeowner"
                                                        value="YES"
                                                        checked={data?.homeOwner.includes('YES')}

                                                    />

                                                    <label className="form-check-label" htmlFor="homeowner">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="homeOwner"
                                                        id="homeownerNo"
                                                        value="NO"
                                                        checked={data?.homeOwner.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="homeownerNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Will the car be kept at home during the day? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isCarKeptAtHome"
                                                        id="during"
                                                        value="YES"
                                                        checked={data?.isCarKeptAtHome.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="during">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="isCarKeptAtHome"
                                                        id="duringNo"
                                                        value="NO"
                                                        checked={data?.isCarKeptAtHome.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="duringNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Doors">By the time the policy begins, will Awais have any children under 16 years old? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="childrenUnderSixteen"
                                                        id="policy"
                                                        value="YES"
                                                        checked={data?.childrenUnderSixteen.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="policy">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="childrenUnderSixteen"
                                                        id="policyNo"
                                                        value="NO"
                                                        checked={data?.childrenUnderSixteen.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="policyNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <label htmlFor="log-policy">By the time the policy begins, how many years of no-claims bonus (NCB) will Awais have? *</label>
                                    <select className="form-group f-14" name="YearsofNoClaimBonus"
                                        value={data?.YearsofNoClaimBonus}
                                        disabled
                                    >
                                        <option value="NO NCB">NO NCB</option>
                                        <option value="1 Year">1 Year</option>
                                        <option value="2 Year">2 Year</option>
                                        <option value="3 Year">3 Year</option>
                                        <option value="4 Year">4 Year</option>
                                        <option value="5 Year">5 Year</option>
                                        <option value="6 Year">6 Year</option>
                                        <option value="7 Year">7 Year</option>
                                        <option value="8 Year">8 Year</option>
                                        <option value="9 Year">9 Year</option>
                                        <option value="10 Year">10 Year</option>
                                        <option value="11 Year">11 Year</option>
                                        <option value="12 Year">12 Year</option>
                                        <option value="13 Year">13 Year</option>
                                        <option value="14 Year">14 Year</option>
                                        <option value="15 Year">15 Year</option>
                                        <option value="No NCB in last 2 years">No NCB in last 2 years</option>
                                    </select>


                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Is Awais's no-claims bonus (NCB) currently protected? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="currentlyProtected"
                                                        id="bonus"
                                                        value="YES"
                                                        checked={data?.currentlyProtected.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="bonus">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="currentlyProtected"
                                                        id="bonusNo"
                                                        value="NO"
                                                        checked={data?.currentlyProtected.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="bonusNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Does the driver own or use another vehicle? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="driverVehicalOrNot"
                                                        id="vehicle"
                                                        value="YES"
                                                        checked={data?.driverVehicalOrNot.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="vehicle">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="driverVehicalOrNot"
                                                        id="vehicleNo"
                                                        value="NO"
                                                        checked={data?.driverVehicalOrNot.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="vehicleNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-drive">How many cars are kept at home?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="11"
                                                        value="1"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('1')}
                                                    />

                                                    <label className="form-check-label" htmlFor="11">
                                                        1
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="12"
                                                        value="2"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('2')}
                                                    />

                                                    <label className="form-check-label" htmlFor="12">
                                                        2
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="13"
                                                        value="3"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('3')}
                                                    />

                                                    <label className="form-check-label" htmlFor="13">
                                                        3
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="14"
                                                        value="4"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('4')}
                                                    />

                                                    <label className="form-check-label" htmlFor="14">
                                                        4
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="15"
                                                        value="5"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('5')}
                                                    />

                                                    <label className="form-check-label" htmlFor="15">
                                                        5
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="16"
                                                        value="6"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('6')}
                                                    />

                                                    <label className="form-check-label" htmlFor="16">
                                                        6
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="17"
                                                        value="7"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('7')}
                                                    />

                                                    <label className="form-check-label" htmlFor="17">
                                                        7
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="18"
                                                        value="8"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('8')}
                                                    />

                                                    <label className="form-check-label" htmlFor="18">
                                                        8
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="NoOfCarsKeptAtHome"
                                                        id="19"
                                                        value="9"
                                                        checked={data?.NoOfCarsKeptAtHome.includes('9')}
                                                    />

                                                    <label className="form-check-label" htmlFor="19">
                                                        9
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30 mt-10">Car Usage</h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group ">
                                        <label htmlFor="log-Doors">Has the car been bought yet? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="boghtYet"
                                                        id="bought"
                                                        value="YES"
                                                        checked={data?.boghtYet.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="bought">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="boghtYet"
                                                        id="boughtNo"
                                                        value="NO"
                                                        checked={data?.boghtYet.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="boughtNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-20">
                                        <label htmlFor="log-manufacture">When was the car bought? *</label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            name="boughtDate"
                                            placeholder="Date of birth"
                                            max={moment().format("YYYY-MM-DD")}
                                            value={data?.boughtDate}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-20">
                                        <label htmlFor="log-manufacture">Who is the car registered to? *</label>

                                        <Input type="text" name="carRegisteredTo" id="carRegisteredTo"
                                            value={data?.carRegisteredTo}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="log-manufacture">Who owns the car? *</label>

                                        <Input type="text" name="carOwner" id="carOwner"
                                            value={data?.carOwner}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-Doors">What will the car be used for?</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="carUsedFor"
                                                        id="carUsedFor1"
                                                        value="SOCIAL ONLY"
                                                        checked={data?.carUsedFor.includes('SOCIAL ONLY')}
                                                    />

                                                    <label className="form-check-label" htmlFor="carUsedFor1">
                                                        SOCIAL ONLY
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="carUsedFor"
                                                        id="carUsedFor2"
                                                        value="SOCIAL & COMMUTING"
                                                        checked={data?.carUsedFor.includes('SOCIAL & COMMUTING')}
                                                    />

                                                    <label className="form-check-label" htmlFor="carUsedFor2">
                                                        SOCIAL & COMMUTING
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="carUsedFor"
                                                        id="carUsedFor3"
                                                        value="BUSINESS USE"
                                                        checked={data?.carUsedFor.includes('BUSINESS USE')}
                                                    />

                                                    <label className="form-check-label" htmlFor="carUsedFor3">
                                                        BUSINESS USE
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30 mt-10">Claims</h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="log-Doors">Have any drivers had any motor accidents or claims in the last 5 years? *</label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="accidentClaims"
                                                        id="accidents"
                                                        value="YES"
                                                        checked={data?.accidentClaims.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="accidents">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="accidentClaims"
                                                        id="accidentsNo"
                                                        value="NO"
                                                        checked={data?.accidentClaims.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="accidentsNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <label htmlFor="log-policy">What type of incident was it? *</label>
                                    <select className="form-group f-14"
                                        name="incidentType"
                                        value={data?.incidentType}
                                        disabled
                                    >
                                        <option value="Accident">Accident</option>
                                        <option value="Windscreen Glass">Windscreen Glass</option>
                                        <option value="Theft">Theft</option>
                                        <option value="Malicious Damage">Malicious Damage</option>
                                        <option value="Strom or flood">Strom or flood</option>
                                        <option value="Fire">Fire</option>
                                    </select>

                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30 mt-10">Medical conditions & disabilities</h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-12">

                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="medicalCondition"
                                                        id="conditions"
                                                        value="YES"
                                                        checked={data?.medicalCondition.includes('YES')}
                                                    />

                                                    <label className="form-check-label" htmlFor="conditions">
                                                        YES
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                    <Input
                                                        className="form-check-Input"
                                                        type="radio"
                                                        name="medicalCondition"
                                                        id="conditionsNo"
                                                        value="NO"
                                                        checked={data?.medicalCondition.includes('NO')}
                                                    />

                                                    <label className="form-check-label" htmlFor="conditionsNo">
                                                        NO
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <label htmlFor="log-policy">Select medical condition *</label>
                                    <select className="form-group f-14"
                                        name="dvlaMedicalConditionsType"
                                        value={data?.dvlaMedicalConditionsType}
                                        disabled
                                    >
                                        <option value="DVLA aware - advised no to drive">DVLA aware - advised no to drive</option>
                                        <option value="DVLA aware - no restrictions">DVLA aware - no restrictions</option>
                                        <option value="DVLA aware - 1yr resticted licence">DVLA aware - 1yr resticted licence</option>
                                        <option value="DVLA aware - 2yr resticted licence">DVLA aware - 2yr resticted licence</option>
                                        <option value="DVLA aware - 3yr resticted licence">DVLA aware - 3yr resticted licence</option>
                                        <option value="DVLA unaware">DVLA unaware</option>
                                    </select>

                                </div>
                            </div>
                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30 mt-10">Medical conditions & disabilities</h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-12">
                                    <label htmlFor="declinedCancelledImposed">Have any of the drivers ever had insurance declined, cancelled or special terms imposed?</label>
                                    <select className="form-group f-14"
                                        name="declinedCancelledImposed"
                                        value={data?.declinedCancelledImposed}
                                        disabled
                                    >
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                            </div>

                        </Row>
                    }
                </div>
            </Modal>
        </>
    ) : null;
}

export default VehicleInsuranceModel;