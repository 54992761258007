import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import PaymentService from "../../servises/PaymentService";
import { CommonService } from "../../servises/CommonService";
import React, { useContext } from "react";
import UserContext from "../context/UserContext";
import { User } from "../../models/User";
import Swal from "sweetalert2";
import { environment } from "../../environment/environment";


interface PayPalSubscriptionButtonProps {
  onSuccessSubscription: () => void
}

const PayPalSubscriptionButton: React.FC<PayPalSubscriptionButtonProps> = ({onSuccessSubscription}) => {

  const [user, setUser] = useContext(UserContext);
  const handleSubscriptionApproval = async (data: any, actions: any) => {

    const subscription = await actions.subscription.get();
    const { payer_id, create_time, status, next_billing_time, id } = subscription;
 

    // Accessing subscription properties
    const payerId = subscription.subscriber.payer_id;
    const endDate = subscription.billing_info.next_billing_time;

    interface subscriptionDetails {

      userId: string;
      payerId: string;
      startDate: Date;
      endDate: string;
      status: string;
      subscriptionId: string;
    }

    // Prepare the subscription details object

    const subscriptionDetails: subscriptionDetails = {
      userId: user?._id || "",
      payerId: payerId,
      startDate: new Date(),
      endDate: endDate,
      status: status,
      subscriptionId: id,

    };

    const userDetails: User = {
      subscriptionId: id,
      subscriptionStatus:status
      
    };

    try {
      // Pass the subscription details to the backend
      const response = await PaymentService.submitSubscriptionDetails(subscriptionDetails);
      console.log("Backend response:", response);
      onSuccessSubscription()
      // Alert the user and log the subscription data
      Swal.fire(
        'You are all set',
        'Your subscription is now active!',
        'success'
      )
     
      const responseCustomer = await CommonService.updateCustomer(userDetails);
      setUser({ ...user, subscriptionStatus: "ACTIVE" });
    } catch (error) {
      console.error("Error submitting subscription details:", error);
      // Handle the error accordingly (e.g., show an error message to the user)
    }


  };

const clientID = environment.PAYPAL_CLIENT_ID
const subPlanID = environment.SUBSCRIPTION_PLAN_ID
  return (
    <PayPalScriptProvider
      options={{
        "client-id": clientID,
        components: "buttons",
        intent: "subscription",
        vault: true,
      }}
    >
      <PayPalButtons
        createSubscription={async (data, actions) => {
          const orderId = await actions.subscription.create({
            plan_id: subPlanID,
          });
          return orderId;
        }}

        onApprove={handleSubscriptionApproval}

// hide card payment button
        
        style={{
          label: "subscribe",
          // layout: "horizontal",
          shape: "rect",
        //   tagline: false,
        //   height: 40,
          // color: "gold",
        }}
        
      />
    </PayPalScriptProvider>
  );
};

export default PayPalSubscriptionButton;

