import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import img1 from "../assets/images/about/shape-13.png";
import img2 from "../assets/images/about/shape-15.png";
import img3 from "../assets/images/about/shape-07.png";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";
import swal from "sweetalert";
import { UserDetails } from "../../models/User";
import { AdminService } from "../../servises/AdminService";
import { ParameterTypes } from "../../utils/ParameterTypes";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import "react-phone-number-input/style.css";

const AdminEditUser: React.FC = () => {
    const { customerId } = useParams<ParameterTypes>();
    const [userdata, setUserdata] = useState<UserDetails>({} as UserDetails);
    const [value, setValue] = useState();
    useEffect(() => {
        AdminService.getCustomerById(customerId).then((res) => {
            setUserdata(res.data);
        });
    }, []);

    const updateClientDetails = () => {

        if (!userdata?.firstName) {
            swal({ icon: "error", title: "Please enter First Name !" });
            return;
        } else if (!userdata?.userName) {
            swal({ icon: "error", title: "Please enter User Name !" });
            return;
        } else if (!userdata?.surName) {
            swal({ icon: "error", title: "Please enter Sur Name !" });
            return;
        } else if (!userdata?.gender) {
            swal({ icon: "error", title: "Please select your Gender !" });
            return;
        } else if (!userdata?.phoneNumber) {
            swal({ icon: "error", title: "Phone number is required !" });
            return;
        } else {

            const updatedUser: UserDetails = {
                firstName: userdata?.firstName,
                lastName: userdata?.lastName,
                userName: userdata?.userName,
                surName: userdata?.surName,
                gender: userdata?.gender,
                phoneNumber: parseInt(value as any) || userdata?.phoneNumber,
                address: userdata?.address,

            };

            AdminService.updateCustomerByAdmin(updatedUser, customerId).then((res) => {
                if (res.success) {
                    swal({ icon: "success", title: res.message });
                    setUserdata(res.data);
                } else {
                    swal({ icon: "error", title: res.error });
                }
            });
            return;
        }
    };

    return (
        <div>
            <div className="edu-breadcrumb-area breadcrumb-style-3">
                <div className="container">
                    <div className="breadcrumb-inner accHegiht">

                    </div>
                </div>
                <ul className="shape-group">
                    <li className="shape-1">
                        <span></span>
                    </li>
                    <li className="shape-2 scene"><img data-depth="2" src={img1} alt="shape" /></li>
                    <li className="shape-3 scene"><img data-depth="-2" src={img2} alt="shape" /></li>
                    <li className="shape-4">
                        <span></span>
                    </li>
                    <li className="shape-5 scene"><img data-depth="2" src={img3} alt="shape" /></li>
                </ul>
            </div>

            <section className="edu-section-gap course-details-area">
                <div className="container">
                    <div className="row row--30">

                        <div className="col-lg-12">
                            <div className="course-sidebar-3 sidebar-top-position">
                                <div className="edu-course-widget widget-course-summery">
                                    <h3 className="title mb-20 mt-10">Profile info</h3>
                                    <div className="row g-5 mb-20 justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label htmlFor="reg-Name">First Name</label>
                                                <input type="text" name="reg-Name" className="input-boarder" id="reg-Name" value={userdata?.firstName || ""}
                                                    onChange={e => setUserdata({ ...userdata, firstName: e.target.value })} />
                                            </div>

                                            <div className="form-group mb-4">
                                                <label htmlFor="reg-Name">Last Name</label>
                                                <input type="text" name="reg-Name" className="input-boarder" id="reg-Name" value={userdata?.lastName || ""}
                                                    onChange={e => setUserdata({ ...userdata, lastName: e.target.value })} />
                                            </div>

                                            <div className="form-group mb-4">
                                                <label htmlFor="reg-Name">User Name</label>
                                                <input type="text" name="reg-Name" className="input-boarder" id="reg-Name" value={userdata?.userName || ""}
                                                    onChange={e => setUserdata({ ...userdata, userName: e.target.value })} />
                                            </div>

                                            <div className="form-group mb-4">
                                                <label htmlFor="reg-Name">Email</label>
                                                <input type="text" name="reg-Name" className="input-boarder" id="reg-Name" value={userdata?.email || ""}
                                                    disabled />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="log-GENDER">Gender</label>
                                            </div>
                                            <div className="card-heading mb-2">
                                                <div className="row">
                                                    <div className="col" style={{ display: "flex", flexWrap: "wrap" }}>
                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                            <input
                                                                className="form-check-input input-boarder"
                                                                type="radio"
                                                                name="exampleRadioss"
                                                                id="exampleRadios1"
                                                                value="Female"
                                                                checked={userdata?.gender == "Female"}
                                                                onChange={e => setUserdata({ ...userdata, gender: e.target.value })}
                                                            />

                                                            <label className="form-check-label" htmlFor="exampleRadios1">
                                                                Female
                                                            </label>
                                                        </div>

                                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                            <input
                                                                className="form-check-input input-boarder"
                                                                type="radio"
                                                                name="exampleRadioss"
                                                                id="exampleRadios2"
                                                                value="Male"
                                                                checked={userdata?.gender == "Male"}
                                                                onChange={e => setUserdata({ ...userdata, gender: e.target.value })}
                                                            />

                                                            <label className="form-check-label" htmlFor="exampleRadios2">
                                                                Male
                                                            </label>
                                                        </div>

                                                        <div className="form-check mb-3">
                                                            <input
                                                                className="form-check-input input-boarder"
                                                                type="radio"
                                                                name="exampleRadioss"
                                                                id="exampleRadios3"
                                                                value="Other"
                                                                checked={userdata?.gender == "Other"}
                                                                onChange={e => setUserdata({ ...userdata, gender: e.target.value })}
                                                            />
                                                            <label className="form-check-label" htmlFor="exampleRadios3">
                                                                Other
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label htmlFor="log-Phone">Surname</label>
                                                <input type="text" name="log-Phone" className="input-boarder" id="log-Phone" value={userdata?.surName || ""}
                                                    onChange={e => setUserdata({ ...userdata, surName: e.target.value })} />
                                            </div>

                                            <div className="form-group mb-4">
                                                <label htmlFor="reg-Name ">Phone Number</label>

                                                <PhoneInput
                                                    placeholder="Phone Number"
                                                    name="phoneNumber"
                                                    id="phoneNumber"
                                                    className="input-boarder"
                                                    defaultCountry="GB"
                                                    value={value || `+${userdata?.phoneNumber?.toString()}`}
                                                    onChange={(e: any) => setValue(e)}
                                                />

                                            </div>


                                            <div className="form-group mb-4">
                                                <label htmlFor="reg-Name">Address</label>
                                                <textarea name="reg-Name" id="reg-Name" value={userdata?.address || ""}
                                                    onChange={e => setUserdata({ ...userdata, address: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-8"></div>
                                            <div className="col-lg-4">
                                                <div className=" float-right">
                                                    <ul className="header-action ">
                                                        <li className="header-btn ">
                                                            <button className="edu-btn btn-medium edu-btnColor" onClick={() => updateClientDetails()}>Update Profile</button>
                                                        </li>
                                                        <li className="header-btn">
                                                            <a href="/dashboard" className="cancel-btn btn-medium">Cancel </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AdminEditUser;
