import axios from "axios";
import { AppResponse } from "../models/Response";
import { Util } from "../Util";

export class PenaltyChargesService {
  public static async payParkingTicket(data: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("pay/parking-ticket");

    const res = await axios.post<any, AppResponse<any>>(ep, data);

    return res;
  }

  public static async paySpeedingTicket(data: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("pay/speeding-ticket");

    const res = await axios.post<any, AppResponse<any>>(ep, data);

    return res;
  }

  public static async payCongestionCharges(
    data: any
  ): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("pay/congestion-charge");

    const res = await axios.post<any, AppResponse<any>>(ep, data);

    return res;
  }
}
