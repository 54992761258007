import React, { useState } from "react";
import PaymentService from "../../servises/PaymentService";
import "../assets/css/app.css";
import Swal from "sweetalert2";
import { useContext } from "react";
import UserContext from "../context/UserContext";
import { User } from "../../models/User";
import { CommonService } from "../../servises/CommonService";

interface CancelSubscriptionButtonProps {
  subscriptionId?: string;
  _id:string|undefined
  disabled?: boolean;
  onSuccessCancelSubscription: () => void
}

const CancelSubscriptionButton: React.FC<CancelSubscriptionButtonProps> = ({ onSuccessCancelSubscription, subscriptionId,_id,disabled = false,}) => {

  const [user, setUser] = useContext(UserContext);
  const [isDisabled, setIsDisabled] = useState<boolean>(disabled);

  const handleCancelSubscription = async () => {

    const userDetails :User ={
      subscriptionStatus:"CANCELLED",
    };
    
    
    try {
      Swal.fire ({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, cancel it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'Canceled!',
            'Your Subscription Plan has been Canceled.',
            'success'
            )
            // disable the button to prevent multiple clicks
            setIsDisabled(true);
            const response = await PaymentService.cancelSubscription(_id as any,subscriptionId);
            console.log("Backend response:", response);
            onSuccessCancelSubscription()
            
            const responseCustomer = await CommonService.updateCustomer(userDetails);
            setUser({ ...user, subscriptionStatus: "CANCELLED" });
          }
        })
    } catch (error) {
      console.error("Error canceling subscription:", error);
      // Handle the error or show an error message
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to cancel subscription. Please try again.",
      });
    }
  };

  return <button className="edu-btn btn-medium" onClick={handleCancelSubscription} disabled={isDisabled} >Cancel Subscription</button>;
};

export default CancelSubscriptionButton;
