import React, { useState, useContext } from "react";
import "../assets/css/home.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/app.css";
import "../assets/css/icomoon.css";
import { useTranslation } from "react-i18next";
import UserContext from "../context/UserContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Form, FormFeedback, Input } from "reactstrap";
import { CouncilTaxBillObject } from "../../models/CouncilTaxBIllObject";
import { UtilityService } from "../../servises/UtilityService";
import moment from "moment";
import { UserDetails } from "../../models/User";

const CounsilTaxBills: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [financialSupportStatus, setFinancialSupportStatus] = useState(true);
  const [disputeCouncilTaxBandStatus, setDisputeCouncilTaxBandStatus] =
    useState(true);

  const [userdata, setUserdata] = useState<UserDetails | undefined>({} as UserDetails);

  const autoFill = () => {
    setUserdata(user);
  }


  const CouncilTaxBillsValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      ctb_name: "",
      ctb_phone_number: "",
      ctb_email: "",
      ctb_postalCode: "",
      ctb_address: "",
      ctb_property_postalCode: "",
      ctb_tax_band: "",
      ctb_property_address: "",
      ctb_council_tax_rate: "",
      ctb_discount_or_exemptions: "",
      ctb_due_amount: "",
      ctb_due_date: "",
      ctb_financial_support: "YES",
      ctb_dispute_council_tax_band: "TRUE",
      ctb_financial_support_details: "",
      ctb_dispute_council_tax_band_details: "",
      ctb_signature: "",
    },
    validationSchema: Yup.object({
      // ctb_name: Yup.string().required(t<string>("Please Enter Name")),
      // ctb_phone_number: Yup.number().required(
      //   t<string>("Please Enter Phone Number")
      // ),
      // ctb_email: Yup.string().required(t<string>("Please Enter Email")),
      // ctb_postalCode: Yup.string().required(
      //   t<string>("Please Enter Postal Code")
      // ),
      // ctb_address: Yup.string().required(t<string>("Please Enter the Address")),
      // ctb_property_postalCode: Yup.string().required(
      //   t<string>("Please Enter proprtey Postal Code")
      // ),
      ctb_tax_band: Yup.string().required(
        t<string>("Please Enter the property Tax band")
      ),
      ctb_property_address: Yup.string().required(
        t<string>("Please Enter the property Address")
      ),
      ctb_council_tax_rate: Yup.number().required(
        t<string>("Please Enter the property tax rate")
      ),
      ctb_due_amount: Yup.number().required(
        t<string>("Please Enter the due amount")
      ),
      ctb_due_date: Yup.date().required(t<string>("Please Enter the due date")),
      ctb_financial_support: Yup.string().required(
        t<string>("Please Select an Option")
      ),
      ctb_dispute_council_tax_band: Yup.boolean().required(
        t<string>("Please Select an Option")
      ),
      ctb_signature: Yup.string().required(
        t<string>("Please Enter the signature")
      ),
    }),
    onSubmit: (values, onSubmitProps) => {
      if (
        financialSupportStatus === true &&
        values.ctb_financial_support_details.length === 0
      ) {
        Swal.fire({
          icon: "error",
          title: "financial Support Details cannot be empty",
          confirmButtonColor: "#FD7F00",
        });
      } else if (
        disputeCouncilTaxBandStatus === true &&
        values.ctb_dispute_council_tax_band_details.length === 0
      ) {
        Swal.fire({
          icon: "error",
          title: "dispute Council Tax band Details cannot be empty",
          confirmButtonColor: "#FD7F00",
        });
      } else {
        const appointmentObject: CouncilTaxBillObject = {
          name: values.ctb_name ||  (userdata?.firstName + " " + userdata?.lastName),
          phoneNumber: parseInt(values.ctb_phone_number) || userdata?.phoneNumber,
          postCode: values.ctb_postalCode || userdata?.postCode,
          address: values.ctb_address || userdata?.address,
          propertypostalCode: values.ctb_property_postalCode || userdata?.postCode,
          email: values.ctb_email || userdata?.email,
          propertyAddress: values.ctb_property_address,
          councilTaxBand: values.ctb_tax_band,
          councilTaxRate: values.ctb_council_tax_rate,
          discountsOrExemptions: values.ctb_discount_or_exemptions,
          dueAmount: parseInt(values.ctb_due_amount),
          dueDate: values.ctb_due_date,
          paymentMethod: "DEBIT_CARD",
          financialSupport: financialSupportStatus,
          financialSupportDetails: values.ctb_financial_support_details,
          disputeCouncilTaxBand: disputeCouncilTaxBandStatus,
          disputeCouncilTaxBandDetails:
            values.ctb_dispute_council_tax_band_details,
          signature: values.ctb_signature,
        };


        if (user?.subscriptionStatus === "CANCELLED" || !user?.subscriptionId) {
          Swal.fire({
            icon: "warning",
            title: "in order to be able receive our services you will have to be subscribe",
            confirmButtonText: "Subscribe",
            confirmButtonColor: "#FD7F00",
            showCancelButton: true,
            cancelButtonText: "Cancel",
          }).then((result) => {
            if (result.isConfirmed) {
              // Navigate to subscription page
              window.location.href = "/account";
            }
          });
          return; // Stop further execution
        } else {
          setIsLoading(true);

          UtilityService.payCouncilTaxBill(appointmentObject).then((res) => {
            if (res.success) {
              setIsLoading(false);
              Swal.fire({ title: "Council Tax Bill Payment Successful" });
              onSubmitProps.resetForm();
              setUserdata({ firstName: "", lastName: "", phoneNumber: "" as any, postCode: "", address: "", email: "", dateOfBirth: undefined, });
            } else {
              setIsLoading(false);
              Swal.fire({
                icon: "error",
                title: res.error,
                confirmButtonColor: "#FD7F00",
              });
            }
          });
        }
      }
    },
  });

  return (
    <div className="login-page edu-footer footer-lighten bg-image footer-style-1">
      <section className="account-page-area section-gap-equal1">
        <div className="container-custom position-relative">
          <div className="row g-5 justify-content-center">
            <div className="col-lg-8">

              <div className="login-form-box registration-form">
                <div className="App">
                  <div className="box">
                    <div>
                      <h3 className="title mb-20 mt-10">
                        Personal Information:
                      </h3>

                      <p className="themeColor mb-30 font-16 mt-3">Council tax bill life admin services support individuals in managing their council tax obligations. They handle tasks such as understanding council tax bands and rates, arranging bill payments.</p>

                      <button
                        className="btn colorchangeLog edu-btn btn-medium mb-20"
                        type="submit"
                        onClick={autoFill}
                      >AutoFill</button>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          CouncilTaxBillsValidation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="row g-5 mb-20 justify-content-center">
                          <div className="col-lg-6">
                            <div className="form-group mb-20">
                              <label htmlFor="reg-name">Name:</label>
                              <Input
                                type="text"
                                name="ctb_name"
                                id="ctb_name"
                                value={
                                  CouncilTaxBillsValidation.values.ctb_name ||  userdata?.firstName == undefined ? "" : `${userdata?.firstName} ${userdata?.lastName}`}
                                onChange={
                                  CouncilTaxBillsValidation.handleChange
                                }
                                invalid={
                                  CouncilTaxBillsValidation.touched.ctb_name &&
                                    CouncilTaxBillsValidation.errors.ctb_name
                                    ? true
                                    : false
                                }
                              />

                              {CouncilTaxBillsValidation.touched.ctb_name &&
                                CouncilTaxBillsValidation.errors.ctb_name ? (
                                <FormFeedback type="invalid">
                                  {CouncilTaxBillsValidation.errors.ctb_name}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group mb-20">
                              <label htmlFor="reg-Number">Phone Number:</label>
                              <Input
                                type="number"
                                name="ctb_phone_number"
                                id="ctb_phone_number"
                                value={
                                  CouncilTaxBillsValidation.values
                                    .ctb_phone_number || userdata?.phoneNumber
                                }
                                onChange={
                                  CouncilTaxBillsValidation.handleChange
                                }
                                invalid={
                                  CouncilTaxBillsValidation.touched
                                    .ctb_phone_number &&
                                    CouncilTaxBillsValidation.errors
                                      .ctb_phone_number
                                    ? true
                                    : false
                                }
                              />

                              {CouncilTaxBillsValidation.touched
                                .ctb_phone_number &&
                                CouncilTaxBillsValidation.errors
                                  .ctb_phone_number ? (
                                <FormFeedback type="invalid">
                                  {
                                    CouncilTaxBillsValidation.errors
                                      .ctb_phone_number
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <label htmlFor="reg-Email">Email:</label>
                              <Input
                                type="text"
                                name="ctb_email"
                                id="ctb_email"
                                value={
                                  CouncilTaxBillsValidation.values.ctb_email || userdata?.email
                                }
                                onChange={
                                  CouncilTaxBillsValidation.handleChange
                                }
                                invalid={
                                  CouncilTaxBillsValidation.touched.ctb_email &&
                                    CouncilTaxBillsValidation.errors.ctb_email
                                    ? true
                                    : false
                                }
                              />

                              {CouncilTaxBillsValidation.touched.ctb_email &&
                                CouncilTaxBillsValidation.errors.ctb_email ? (
                                <FormFeedback type="invalid">
                                  {CouncilTaxBillsValidation.errors.ctb_email}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group mb-20">
                              <label htmlFor="reg-Postcode">Postcode:</label>
                              <Input
                                type="text"
                                name="ctb_postalCode"
                                id="ctb_postalCode"
                                value={
                                  CouncilTaxBillsValidation.values
                                    .ctb_postalCode || userdata?.postCode
                                }
                                onChange={
                                  CouncilTaxBillsValidation.handleChange
                                }
                                invalid={
                                  CouncilTaxBillsValidation.touched
                                    .ctb_postalCode &&
                                    CouncilTaxBillsValidation.errors
                                      .ctb_postalCode
                                    ? true
                                    : false
                                }
                              />

                              {CouncilTaxBillsValidation.touched
                                .ctb_postalCode &&
                                CouncilTaxBillsValidation.errors
                                  .ctb_postalCode ? (
                                <FormFeedback type="invalid">
                                  {
                                    CouncilTaxBillsValidation.errors
                                      .ctb_postalCode
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <label htmlFor="log-Address">Address:</label>
                              <Input
                                type="textarea"
                                rows="4"
                                name="ctb_address"
                                id="ctb_address"
                                value={
                                  CouncilTaxBillsValidation.values.ctb_address || userdata?.address
                                }
                                onChange={
                                  CouncilTaxBillsValidation.handleChange
                                }
                                invalid={
                                  CouncilTaxBillsValidation.touched
                                    .ctb_address &&
                                    CouncilTaxBillsValidation.errors.ctb_address
                                    ? true
                                    : false
                                }
                              />
                              {CouncilTaxBillsValidation.touched.ctb_address &&
                                CouncilTaxBillsValidation.errors.ctb_address ? (
                                <FormFeedback type="invalid">
                                  {CouncilTaxBillsValidation.errors.ctb_address}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <hr className="mb-30 mt-40" />
                        <h3 className="title mb-30">Property Information:</h3>

                        <div className="row g-5 mb-20 justify-content-center">
                          <div className="col-lg-6">
                            <div className="form-group mb-20">
                              <label htmlFor="reg-Property">
                                Property Post Code:
                              </label>
                              <Input
                                type="text"
                                name="ctb_property_postalCode"
                                id="ctb_property_postalCode"
                                value={
                                  CouncilTaxBillsValidation.values
                                    .ctb_property_postalCode
                                }
                                onChange={
                                  CouncilTaxBillsValidation.handleChange
                                }
                                invalid={
                                  CouncilTaxBillsValidation.touched
                                    .ctb_property_postalCode &&
                                    CouncilTaxBillsValidation.errors
                                      .ctb_property_postalCode
                                    ? true
                                    : false
                                }
                              />

                              {CouncilTaxBillsValidation.touched
                                .ctb_property_postalCode &&
                                CouncilTaxBillsValidation.errors
                                  .ctb_property_postalCode ? (
                                <FormFeedback type="invalid">
                                  {
                                    CouncilTaxBillsValidation.errors
                                      .ctb_property_postalCode
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <label htmlFor="log-Address">
                                Property Address:
                              </label>
                              <Input
                                type="textarea"
                                rows="4"
                                name="ctb_property_address"
                                id="ctb_property_address"
                                value={
                                  CouncilTaxBillsValidation.values
                                    .ctb_property_address
                                }
                                onChange={
                                  CouncilTaxBillsValidation.handleChange
                                }
                                invalid={
                                  CouncilTaxBillsValidation.touched
                                    .ctb_property_address &&
                                    CouncilTaxBillsValidation.errors
                                      .ctb_property_address
                                    ? true
                                    : false
                                }
                              />
                              {CouncilTaxBillsValidation.touched
                                .ctb_property_address &&
                                CouncilTaxBillsValidation.errors
                                  .ctb_property_address ? (
                                <FormFeedback type="invalid">
                                  {
                                    CouncilTaxBillsValidation.errors
                                      .ctb_property_address
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group mb-20">
                              <label htmlFor="reg-Council">
                                Council Tax Band:
                              </label>
                              <Input
                                type="text"
                                name="ctb_tax_band"
                                id="ctb_tax_band"
                                value={
                                  CouncilTaxBillsValidation.values.ctb_tax_band
                                }
                                onChange={
                                  CouncilTaxBillsValidation.handleChange
                                }
                                invalid={
                                  CouncilTaxBillsValidation.touched
                                    .ctb_tax_band &&
                                    CouncilTaxBillsValidation.errors.ctb_tax_band
                                    ? true
                                    : false
                                }
                              />

                              {CouncilTaxBillsValidation.touched.ctb_tax_band &&
                                CouncilTaxBillsValidation.errors.ctb_tax_band ? (
                                <FormFeedback type="invalid">
                                  {
                                    CouncilTaxBillsValidation.errors
                                      .ctb_tax_band
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group mb-20">
                              <label htmlFor="reg-Tax">Council Tax Rate:</label>
                              <Input
                                type="number"
                                name="ctb_council_tax_rate"
                                id="ctb_council_tax_rate"
                                value={
                                  CouncilTaxBillsValidation.values
                                    .ctb_council_tax_rate
                                }
                                onChange={
                                  CouncilTaxBillsValidation.handleChange
                                }
                                invalid={
                                  CouncilTaxBillsValidation.touched
                                    .ctb_council_tax_rate &&
                                    CouncilTaxBillsValidation.errors
                                      .ctb_council_tax_rate
                                    ? true
                                    : false
                                }
                              />

                              {CouncilTaxBillsValidation.touched
                                .ctb_council_tax_rate &&
                                CouncilTaxBillsValidation.errors
                                  .ctb_council_tax_rate ? (
                                <FormFeedback type="invalid">
                                  {
                                    CouncilTaxBillsValidation.errors
                                      .ctb_council_tax_rate
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <label htmlFor="reg-Discounts">
                                Discounts or Exemptions:
                              </label>
                              <Input
                                type="text"
                                name="ctb_discount_or_exemptions"
                                id="ctb_discount_or_exemptions"
                                value={
                                  CouncilTaxBillsValidation.values
                                    .ctb_discount_or_exemptions
                                }
                                onChange={
                                  CouncilTaxBillsValidation.handleChange
                                }
                                invalid={
                                  CouncilTaxBillsValidation.touched
                                    .ctb_discount_or_exemptions &&
                                    CouncilTaxBillsValidation.errors
                                      .ctb_discount_or_exemptions
                                    ? true
                                    : false
                                }
                              />

                              {CouncilTaxBillsValidation.touched
                                .ctb_discount_or_exemptions &&
                                CouncilTaxBillsValidation.errors
                                  .ctb_discount_or_exemptions ? (
                                <FormFeedback type="invalid">
                                  {
                                    CouncilTaxBillsValidation.errors
                                      .ctb_discount_or_exemptions
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <hr className="mb-30 mt-40" />
                        <div className="row g-5 mb-20 justify-content-center">
                          <h3 className="title">Payment Information:</h3>
                          <div className="col-lg-6">
                            <div className="form-group mb-20">
                              <label htmlFor="reg-Amount">Amount Due:</label>
                              <Input
                                type="number"
                                name="ctb_due_amount"
                                id="ctb_due_amount"
                                value={
                                  CouncilTaxBillsValidation.values
                                    .ctb_due_amount
                                }
                                onChange={
                                  CouncilTaxBillsValidation.handleChange
                                }
                                invalid={
                                  CouncilTaxBillsValidation.touched
                                    .ctb_due_amount &&
                                    CouncilTaxBillsValidation.errors
                                      .ctb_due_amount
                                    ? true
                                    : false
                                }
                              />

                              {CouncilTaxBillsValidation.touched
                                .ctb_due_amount &&
                                CouncilTaxBillsValidation.errors
                                  .ctb_due_amount ? (
                                <FormFeedback type="invalid">
                                  {
                                    CouncilTaxBillsValidation.errors
                                      .ctb_due_amount
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group mb-20">
                              <label htmlFor="reg-Date">Date Due:</label>
                              <Input
                                className="form-control"
                                type="date"
                                id="ctb_due_date"
                                name="ctb_due_date"
                                placeholder="Select Date"
                                onChange={
                                  CouncilTaxBillsValidation.handleChange
                                }
                                onBlur={CouncilTaxBillsValidation.handleBlur}
                                value={
                                  CouncilTaxBillsValidation.values.ctb_due_date
                                }
                                invalid={
                                  CouncilTaxBillsValidation.touched
                                    .ctb_due_date &&
                                    CouncilTaxBillsValidation.errors.ctb_due_date
                                    ? true
                                    : false
                                }
                              />
                              {CouncilTaxBillsValidation.touched.ctb_due_date &&
                                CouncilTaxBillsValidation.errors.ctb_due_date ? (
                                <FormFeedback type="invalid">
                                  {
                                    CouncilTaxBillsValidation.errors
                                      .ctb_due_date
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <hr className="mb-30 mt-40" />
                        <div className="row g-5 mb-20 justify-content-center">
                          <h3 className="title">Additional Information:</h3>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="log-drive">
                                Financial Support:
                              </label>
                            </div>
                            <div className="card-heading mb-2">
                              <div className="row">
                                <div
                                  className="col"
                                  style={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  <div
                                    className="form-check mb-3 "
                                    style={{ marginRight: "1.2rem" }}
                                  >
                                    <Input
                                      className="form-check-input"
                                      type="radio"
                                      name="ctb_financial_support"
                                      id="YES"
                                      value="YES"
                                      checked={financialSupportStatus}
                                      invalid={
                                        CouncilTaxBillsValidation.touched
                                          .ctb_financial_support &&
                                          CouncilTaxBillsValidation.errors
                                            .ctb_financial_support
                                          ? true
                                          : false
                                      }
                                      onChange={() => {
                                        setFinancialSupportStatus(true);
                                      }}
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="YES"
                                    >
                                      Yes
                                    </label>
                                  </div>

                                  <div className="form-check mb-3 ">
                                    <Input
                                      className="form-check-input"
                                      type="radio"
                                      name="ctb_financial_support"
                                      id="NO"
                                      value="NO"
                                      invalid={
                                        CouncilTaxBillsValidation.touched
                                          .ctb_financial_support &&
                                          CouncilTaxBillsValidation.errors
                                            .ctb_financial_support
                                          ? true
                                          : false
                                      }
                                      onChange={() => {
                                        setFinancialSupportStatus(false);
                                      }}
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="NO"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                                {financialSupportStatus ? (
                                  <div className="form-group mb-20">
                                    <label htmlFor="reg-Amount">
                                      Financial Support Details
                                    </label>
                                    <Input
                                      type="text"
                                      name="ctb_financial_support_details"
                                      id="ctb_financial_support_details"
                                      value={
                                        CouncilTaxBillsValidation.values
                                          .ctb_financial_support_details
                                      }
                                      onChange={
                                        CouncilTaxBillsValidation.handleChange
                                      }
                                    />
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="log-drive">
                                Dispute Council Tax Band:
                              </label>
                            </div>
                            <div className="card-heading mb-2">
                              <div className="row">
                                <div
                                  className="col"
                                  style={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  <div
                                    className="form-check mb-3 "
                                    style={{ marginRight: "1.2rem" }}
                                  >
                                    <Input
                                      className="form-check-input"
                                      type="radio"
                                      name="ctb_dispute_council_tax_band"
                                      id="TRUE"
                                      value="TRUE"
                                      checked={disputeCouncilTaxBandStatus}
                                      invalid={
                                        CouncilTaxBillsValidation.touched
                                          .ctb_dispute_council_tax_band &&
                                          CouncilTaxBillsValidation.errors
                                            .ctb_dispute_council_tax_band
                                          ? true
                                          : false
                                      }
                                      onChange={() => {
                                        setDisputeCouncilTaxBandStatus(true);
                                      }}
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="TRUE"
                                    >
                                      Yes
                                    </label>
                                  </div>

                                  <div className="form-check mb-3 ">
                                    <Input
                                      className="form-check-input"
                                      type="radio"
                                      name="ctb_dispute_council_tax_band"
                                      id="FALSE"
                                      value="FALSE"
                                      invalid={
                                        CouncilTaxBillsValidation.touched
                                          .ctb_dispute_council_tax_band &&
                                          CouncilTaxBillsValidation.errors
                                            .ctb_dispute_council_tax_band
                                          ? true
                                          : false
                                      }
                                      onChange={() => {
                                        setDisputeCouncilTaxBandStatus(false);
                                      }}
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="FALSE"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                                {disputeCouncilTaxBandStatus ? (
                                  <div className="form-group mb-20">
                                    <label htmlFor="reg-Amount">
                                      Dispute Council Tax Band Details
                                    </label>
                                    <Input
                                      type="text"
                                      name="ctb_dispute_council_tax_band_details"
                                      id="ctb_dispute_council_tax_band_details"
                                      value={
                                        CouncilTaxBillsValidation.values
                                          .ctb_dispute_council_tax_band_details
                                      }
                                      onChange={
                                        CouncilTaxBillsValidation.handleChange
                                      }
                                    />
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                                <div className="form-group mb-20">
                                  <label htmlFor="reg-Amount">Signature:</label>
                                  <Input
                                    type="text"
                                    name="ctb_signature"
                                    id="ctb_signature"
                                    value={
                                      CouncilTaxBillsValidation.values
                                        .ctb_signature
                                    }
                                    onChange={
                                      CouncilTaxBillsValidation.handleChange
                                    }
                                    invalid={
                                      CouncilTaxBillsValidation.touched
                                        .ctb_signature &&
                                        CouncilTaxBillsValidation.errors
                                          .ctb_signature
                                        ? true
                                        : false
                                    }
                                  />

                                  {CouncilTaxBillsValidation.touched
                                    .ctb_signature &&
                                    CouncilTaxBillsValidation.errors
                                      .ctb_signature ? (
                                    <FormFeedback type="invalid">
                                      {
                                        CouncilTaxBillsValidation.errors
                                          .ctb_signature
                                      }
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <button
                          className="btn colorchangeLog leadMargin edu-btn btn-medium mb-20"
                          type="submit"
                        >
                          <div>Submit</div>
                        </button>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CounsilTaxBills;
