import moment from "moment";
import React from "react";
import { Col, Row, Modal, Input } from "reactstrap";

const DoctorAppointmentsModel: React.FC<{ trigger?: boolean; setTrigger?: any; data: any }> = (props) => {
    const { data } = props;
    return (props.trigger) ? (
        <>
            <Modal isOpen={props.trigger} centered >
                <div className="modal-header">
                    <h5 className="text-center mt-2 mb-0 font-size-20">Patient Name : {data.patientName}</h5>
                    <button
                        type="button"
                        onClick={() => props.setTrigger(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span>&times;</span>
                    </button>
                </div>
                <div className="modal-body mt-4">

                    <Row>
                        <h3 className="title mb-20 mt-10">
                            Patient Information:
                        </h3>

                        <div className="row  mb-0 justify-content-center">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="reg-name">
                                        Patient's full name:
                                    </label>
                                    <Input
                                        type="text"
                                        name="regname"
                                        id="regname"
                                        value={data?.patientName}
                                        disabled
                                    />


                                </div>
                                <p className="mb-20">
                                    As it appears on the original appointment.
                                </p>

                                <div className="form-group mb-20">
                                    <label htmlFor="reg-address">
                                        Patient's email address:
                                    </label>
                                    <Input
                                        type="text"
                                        name="reg_email_address"
                                        id="reg_email_address"
                                        value={data?.patientEmail}
                                        disabled
                                    />

                                </div>

                                <div className="form-group">
                                    <label htmlFor="reg-number">
                                        Patient's phone number:
                                    </label>

                                    <Input
                                        type="number"
                                        placeholder=""
                                        name="patient_phone_number"
                                        id="patient_phone_number"
                                        value={data?.patientPhoneNumber}
                                        disabled
                                    />

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="log-drive">
                                        Preferred Method of communication:
                                    </label>
                                </div>
                                <div className="card-heading mb-2">
                                    <div className="row">
                                        <div className="col">
                                            <div
                                                className="form-check mb-3 "
                                                style={{ marginRight: "1.2rem" }}
                                            >
                                                <Input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="method_of_communication"
                                                    id="Number"
                                                    value="PHONE"
                                                    checked={data?.preferredMethodOfCommunication.includes('PHONE')}
                                                />

                                                <label
                                                    className="form-check-label"
                                                    htmlFor="Number"
                                                >
                                                    Phone Number
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 ">
                                                <Input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="method_of_communication"
                                                    id="Email"
                                                    value="EMAIL"
                                                    checked={data?.preferredMethodOfCommunication.includes('EMAIL')}
                                                />

                                                <label
                                                    className="form-check-label"
                                                    htmlFor="Email"
                                                >
                                                    Email Address
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="mb-30 mt-40" />

                        <h3 className="title mb-30">Appointment Details:</h3>

                        <div className="row  mb-20 justify-content-center">
                            <div className="col-lg-6">
                                <div className="form-group mb-20">
                                    <label htmlFor="log-Location">
                                        Location of the original Appointment (Name of
                                        the clinic)
                                    </label>

                                    <Input
                                        type="text"
                                        name="appointment_location"
                                        id="appointment_location"
                                        value={data?.appointmentLocation}
                                        disabled
                                    />

                                </div>

                                <div className="form-group mb-20">
                                    <label htmlFor="log-Location">
                                        Preferred date and time for the appointment:
                                    </label>

                                    <Input
                                        className="form-control"
                                        id="example-date-input"
                                        name="appointmentDate"
                                        placeholder="Select Date"
                                        value={ moment(data?.appointmentDate).format("YYYY-MM-DD")}
                                        disabled
                                    />

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="log-rearrangement">
                                        Reason for rearrangement
                                    </label>
                                    <Input
                                        type="textarea"
                                        rows="4"
                                        name="reasonToReschedule"
                                        id="reasonToReschedule"
                                        value={data?.reasonForReschedule}
                                        disabled
                                    />

                                </div>
                                <p className="mb-20">
                                    A brief explanation of why you need to
                                    reschedule the appointment.
                                </p>
                            </div>
                        </div>

                        <hr className="mb-30 mt-40" />

                        <h3 className="title mb-30">
                            Additional Information:
                        </h3>

                        <div className="row mb-20 justify-content-center">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="reg-revenue">
                                        Preferred date and time: you would like to
                                        reschedule the appointment for
                                    </label>
                                    <Input
                                        className="form-control"
                                        id="rescheduledDate"
                                        name="rescheduledDate"
                                        placeholder="Select Date"
                                        value={ moment(data?.rescheduleDate).format("YYYY-MM-DD")}
                                        disabled
                                    />

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="log-drive">
                                        Additional Charges
                                    </label>
                                </div>
                                <div className="card-heading mb-2">
                                    <div className="row">
                                        <div
                                            className="col"
                                            style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                            }}
                                        >
                                            <div
                                                className="form-check mb-3 "
                                                style={{ marginRight: "1.2rem" }}
                                            >
                                                <Input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="addtionalCharges"
                                                    id="YES"
                                                    value="YES"
                                                    checked={data?.addtionalCharges?.includes('YES')}
                                                />

                                                <label
                                                    className="form-check-label"
                                                    htmlFor="YES"
                                                >
                                                    YES
                                                </label>
                                            </div>

                                            <div className="form-check mb-3 ">
                                                <Input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="addtionalCharges"
                                                    id="NO"
                                                    value="NO"
                                                    checked={data?.addtionalCharges?.includes('NO')}
                                                />

                                                <label
                                                    className="form-check-label"
                                                    htmlFor="NO"
                                                >
                                                    NO
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </Row>
                </div>
            </Modal>
        </>
    ) : null;
}

export default DoctorAppointmentsModel;