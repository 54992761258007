import moment from "moment";
import React from "react";
import { Col, Row, Modal, Input } from "reactstrap";
import img1 from "../assets/images/about/shape-13.png";
const UtilitiesModal: React.FC<{ trigger?: boolean; setTrigger?: any; data: any }> = (props) => {
    const { data } = props;
    return (props.trigger) ? (
        <>
            <Modal isOpen={props.trigger} centered >
                <div className="modal-header">
                    <h5 className="text-center mt-2 mb-0 font-size-20">Customer Name : {data.userId?.firstName == undefined ? "-" : data.userId?.firstName}</h5>
                    <button
                        type="button"
                        onClick={() => props.setTrigger(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span>&times;</span>
                    </button>
                </div>
                <div className="modal-body mt-4">
                    {data?.utilityType == "WATER" &&
                        <Row>
                            <h3 className="title mb-20 mt-10">
                                Personal Information:
                            </h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-name">Name:</label>
                                        <Input
                                            type="text"
                                            name="wb_name"
                                            id="wb_name"
                                            value={data?.name}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Email">Email:</label>
                                        <Input
                                            type="text"
                                            name="wb_email"
                                            id="wb_email"
                                            value={data?.email}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Number">Phone Number:</label>
                                        <Input
                                            type="number"
                                            name="wb_phone_number"
                                            id="wb_phone_number"
                                            value={data?.phoneNumber}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="reg-Vehicle">
                                            Date of Birth:
                                        </label>
                                        <Input
                                            className="form-control"
                                            type="date"
                                            id="wb_date_of_birth"
                                            name="wb_date_of_birth"
                                            placeholder="Select Date"
                                            value={moment(data?.dateOfBirth).format("YYYY-MM-DD")}
                                            disabled
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Postcode">Postcode:</label>
                                        <Input
                                            type="text"
                                            name="wb_postalCode"
                                            id="wb_postalCode"
                                            value={data?.postCode}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Address">Address:</label>
                                        <Input
                                            type="textarea"
                                            rows="4"
                                            name="wb_address"
                                            id="wb_address"
                                            value={data?.address}
                                            disabled
                                        />

                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30">Property Information:</h3>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Property">
                                            Property Type:
                                        </label>
                                        <Input
                                            type="text"
                                            name="wb_property_type"
                                            id="wb_property_type"
                                            value={data?.propertyType}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Move">Move-in date:</label>
                                        <Input
                                            className="form-control"
                                            type="date"
                                            id="wb_move_in_date"
                                            name="wb_move_in_date"
                                            placeholder="Select Date"
                                            value={moment(data?.moveInDate).format("YYYY-MM-DD")}
                                            disabled
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Bedrooms">
                                            Number of Bedrooms:
                                        </label>
                                        <Input
                                            type="number"
                                            name="wb_number_of_bedrooms"
                                            id="wb_number_of_bedrooms"
                                            value={data?.numberOfBedrooms}
                                            disabled
                                        />


                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="reg-Water">
                                            Water meter Point Reference Number (MPRN):
                                        </label>
                                        <Input
                                            type="text"
                                            name="wb_MPRN"
                                            id="wb_MPRN"
                                            value={data?.waterMPRN}
                                            disabled
                                        />


                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30">Payment Information:</h3>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="log-drive">
                                            Billing frequency:
                                        </label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div
                                                className="col"
                                                style={{ display: "flex", flexWrap: "wrap" }}
                                            >
                                                <div
                                                    className="form-check mb-3"
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="wb_billing_frequency"
                                                        id="MONTHLY"
                                                        value="MONTHLY"
                                                        checked={data?.billingFrequency.includes('MONTHLY')}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="MONTHLY"
                                                    >
                                                        Monthly
                                                    </label>
                                                </div>

                                                <div
                                                    className="form-check mb-3 "
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="wb_billing_frequency"
                                                        id="QUARTERLY"
                                                        value="QUARTERLY"
                                                        checked={data?.billingFrequency.includes('QUARTERLY')}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="QUARTERLY"
                                                    >
                                                        Quarterly
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <div className="row  mb-20 justify-content-center">
                                <h3 className="title ">Additional Information:</h3>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-drive">
                                            Are you eligible for any discounts or benefits?
                                        </label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div
                                                className="col"
                                                style={{ display: "flex", flexWrap: "wrap" }}
                                            >
                                                <div
                                                    className="form-check check mb-3 "
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="eligible_for_discounts_one"
                                                        name="eligible_for_discounts"
                                                        checked={data?.eligibilityForDiscountsOrBenefits.includes('PENSION_CREDIT')}
                                                        value="PENSION_CREDIT"
                                                    />

                                                    <label
                                                        className="form-check-label checkLable"
                                                        htmlFor="credit"
                                                    >
                                                        Pension credit
                                                    </label>
                                                </div>

                                                <div
                                                    className="form-check check mb-3 "
                                                    style={{ marginRight: "1.2rem" }}
                                                >

                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="eligible_for_discounts_two"
                                                        name="eligible_for_discounts"
                                                        checked={data?.eligibilityForDiscountsOrBenefits.includes('INCOME_SUPPORT')}
                                                        value="INCOME_SUPPORT"
                                                    />

                                                    <label
                                                        className="form-check-label checkLable"
                                                        htmlFor="support"
                                                    >
                                                        Income support
                                                    </label>
                                                </div>

                                                <div className="form-check check mb-3 ">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="eligible_for_discounts_three"
                                                        name="eligible_for_discounts"
                                                        checked={data?.eligibilityForDiscountsOrBenefits.includes('JOBSEEKER_ALLOWANCE')}
                                                        value="JOBSEEKER_ALLOWANCE"
                                                    />

                                                    <label
                                                        className="form-check-label checkLable"
                                                        htmlFor="Allowance"
                                                    >
                                                        Jobseeker's Allowance
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="form-group">
                                            <label htmlFor="log-provide">
                                                If yes, please provide proof of eligibility:
                                            </label>
                                            <Input
                                                type="textarea"
                                                rows="4"
                                                name="wb_proof_of_eligibilty"
                                                id="wb_proof_of_eligibilty"
                                                value={data?.proofOfEligibility}
                                                disabled
                                            />

                                        </div>

                                    </div>

                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-drive">
                                            Are you the account holder?
                                        </label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div
                                                className="col"
                                                style={{ display: "flex", flexWrap: "wrap" }}
                                            >
                                                <div
                                                    className="form-check mb-3 "
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gbAccountHolderOrNot"
                                                        id="YES"
                                                        value="YES"
                                                        checked={data?.accountHolderOrNot == true}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="YES"
                                                    >
                                                        Yes
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 ">
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gbAccountHolderOrNot"
                                                        id="NO"
                                                        value="NO"
                                                        checked={data?.accountHolderOrNot == false}

                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="NO"
                                                    >
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="form-group mb-30">
                                            <label htmlFor="reg-Postcode">
                                                If no, please provide the name of the
                                                account holder:
                                            </label>
                                            <Input
                                                type="text"
                                                name="wb_account_holder_name"
                                                id="wb_account_holder_name"
                                                value={data?.accountHolderName}
                                                disabled
                                            />

                                        </div>
                                    </div>
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Amount">Signature:</label>
                                        <Input
                                            type="text"
                                            name="wb_signature"
                                            id="wb_signature"
                                            value={data?.signature}
                                            disabled
                                        />


                                    </div>
                                </div>


                            </div>

                        </Row>
                    }

                    {data?.utilityType == "COUNCIL_TAX_BILLS" &&
                        <Row>
                            <h3 className="title mb-20 mt-10">
                                Personal Information:
                            </h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-name">Name:</label>
                                        <Input
                                            type="text"
                                            name="ctb_name"
                                            id="ctb_name"
                                            value={data?.name}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Number">Phone Number:</label>
                                        <Input
                                            type="number"
                                            name="ctb_phone_number"
                                            id="ctb_phone_number"
                                            value={data?.phoneNumber}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="reg-Email">Email:</label>
                                        <Input
                                            type="text"
                                            name="ctb_email"
                                            id="ctb_email"
                                            value={data?.email}
                                            disabled
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Postcode">Postcode:</label>
                                        <Input
                                            type="text"
                                            name="ctb_postalCode"
                                            id="ctb_postalCode"
                                            value={data?.postCode}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Address">Address:</label>
                                        <Input
                                            type="textarea"
                                            rows="4"
                                            name="ctb_address"
                                            id="ctb_address"
                                            value={data?.address}
                                            disabled
                                        />

                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30">Property Information:</h3>

                            <div className="row mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Property">
                                            Property Post Code:
                                        </label>
                                        <Input
                                            type="text"
                                            name="ctb_property_postalCode"
                                            id="ctb_property_postalCode"
                                            value={data?.propertypostalCode}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Address">
                                            Property Address:
                                        </label>
                                        <Input
                                            type="textarea"
                                            rows="4"
                                            name="ctb_property_address"
                                            id="ctb_property_address"
                                            value={data?.propertyAddress}
                                            disabled
                                        />

                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Council">
                                            Council Tax Band:
                                        </label>
                                        <Input
                                            type="text"
                                            name="ctb_tax_band"
                                            id="ctb_tax_band"
                                            value={data?.councilTaxBand}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Tax">Council Tax Rate:</label>
                                        <Input
                                            type="number"
                                            name="ctb_council_tax_rate"
                                            id="ctb_council_tax_rate"
                                            value={data?.councilTaxRate}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="reg-Discounts">
                                            Discounts or Exemptions:
                                        </label>
                                        <Input
                                            type="text"
                                            name="ctb_discount_or_exemptions"
                                            id="ctb_discount_or_exemptions"
                                            value={data?.discountsOrExemptions}
                                            disabled
                                        />


                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <div className="row  mb-20 justify-content-center">
                                <h3 className="title">Payment Information:</h3>
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Amount">Amount Due:</label>
                                        <Input
                                            type="number"
                                            name="ctb_due_amount"
                                            id="ctb_due_amount"
                                            value={data?.dueAmount}
                                            disabled
                                        />


                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Date">Date Due:</label>
                                        <Input
                                            className="form-control"
                                            type="date"
                                            id="ctb_due_date"
                                            name="ctb_due_date"
                                            placeholder="Select Date"
                                            value={moment(data?.dueDate).format("YYYY-MM-DD")}
                                            disabled
                                        />

                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <div className="row  mb-20 justify-content-center">
                                <h3 className="title">Additional Information:</h3>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-drive">
                                            Financial Support:
                                        </label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div
                                                className="col"
                                                style={{ display: "flex", flexWrap: "wrap" }}
                                            >
                                                <div
                                                    className="form-check mb-3 "
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="ctb_financial_support"
                                                        id="YES"
                                                        value="YES"
                                                        checked={data?.financialSupport == true}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="YES"
                                                    >
                                                        Yes
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 ">
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="ctb_financial_support"
                                                        id="NO"
                                                        value="NO"
                                                        checked={data?.financialSupport == false}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="NO"
                                                    >
                                                        No
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="form-group mb-20">
                                                <label htmlFor="reg-Amount">
                                                    Financial Support Details
                                                </label>
                                                <Input
                                                    type="text"
                                                    name="ctb_financial_support_details"
                                                    id="ctb_financial_support_details"
                                                    value={data?.financialSupportDetails}
                                                    disabled
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-drive">
                                            Dispute Council Tax Band:
                                        </label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div
                                                className="col"
                                                style={{ display: "flex", flexWrap: "wrap" }}
                                            >
                                                <div
                                                    className="form-check mb-3 "
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="ctb_dispute_council_tax_band"
                                                        id="YES"
                                                        value="YES"
                                                        checked={data?.disputeCouncilTaxBand == true}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="YES"
                                                    >
                                                        Yes
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 ">
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="ctb_dispute_council_tax_band"
                                                        id="NO"
                                                        value="NO"
                                                        checked={data?.disputeCouncilTaxBand == false}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="NO"
                                                    >
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group mb-20">
                                                <label htmlFor="reg-Amount">
                                                    Dispute Council Tax Band Details
                                                </label>
                                                <Input
                                                    type="text"
                                                    name="ctb_dispute_council_tax_band_details"
                                                    id="ctb_dispute_council_tax_band_details"
                                                    value={data?.disputeCouncilTaxBandDetails}
                                                    disabled
                                                />
                                            </div>
                                            <div className="form-group mb-20">
                                                <label htmlFor="reg-Amount">Signature:</label>
                                                <Input
                                                    type="text"
                                                    name="ctb_signature"
                                                    id="ctb_signature"
                                                    value={data?.signature}
                                                    disabled
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    }



                    {data?.utilityType == "TV_LICENSE" &&
                        <Row>
                            <h3 className="title mb-20 mt-10">
                                Personal Information:
                            </h3>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-name">Name:</label>
                                        <Input
                                            type="text"
                                            name="tvl_name"
                                            id="tvl_name"
                                            value={data?.name}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Email">Email:</label>
                                        <Input
                                            type="text"
                                            name="tvl_email"
                                            id="tvl_email"
                                            value={data?.email}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Number">Phone Number:</label>
                                        <Input
                                            type="number"
                                            name="tvl_phone_number"
                                            id="tvl_phone_number"
                                            value={data?.phoneNumber}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="reg-Vehicle">
                                            Date of Birth:
                                        </label>
                                        <Input
                                            className="form-control"
                                            type="date"
                                            id="tvl_date_of_birth"
                                            name="tvl_date_of_birth"
                                            placeholder="Select Date"
                                            value={moment(data?.dateOfBirth).format("YYYY-MM-DD")}
                                            disabled
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Postcode">Postcode:</label>
                                        <Input
                                            type="text"
                                            name="tvl_postalCode"
                                            id="tvl_postalCode"
                                            value={data?.postCode}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Address">Address:</label>
                                        <Input
                                            type="textarea"
                                            rows="4"
                                            name="tvl_address"
                                            id="tvl_address"
                                            value={data?.address}
                                            disabled
                                        />

                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <h3 className="title mb-30">License Information:</h3>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-drive">
                                            Type of License:
                                        </label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col">
                                                <div
                                                    className="form-check mb-3"
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="tvl_license_type"
                                                        id="COLOR"
                                                        value="COLOR"
                                                        checked={data?.licenseType.includes('COLOR')}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="COLOR"
                                                    >
                                                        Color
                                                    </label>
                                                </div>

                                                <div
                                                    className="form-check mb-3 "
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="tvl_license_type"
                                                        id="BLACK_AND_WHITE"
                                                        value="BLACK_AND_WHITE"
                                                        checked={data?.licenseType.includes('BLACK_AND_WHITE')}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="BLACK_AND_WHITE"
                                                    >
                                                        Black and White
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-drive">
                                            Duration of License:
                                        </label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col">
                                                <div
                                                    className="form-check mb-3"
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="tvl_license_duration"
                                                        id="ONE_YEAR"
                                                        value="ONE_YEAR"
                                                        checked={data?.licenseDuration.includes('ONE_YEAR')}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="ONE_YEAR"
                                                    >
                                                        01 Year
                                                    </label>
                                                </div>

                                                <div
                                                    className="form-check mb-3 "
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="tvl_license_duration"
                                                        id="FIVE_YEARS"
                                                        value="FIVE_YEARS"
                                                        checked={data?.licenseDuration.includes('FIVE_YEARS')}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="FIVE_YEARS"
                                                    >
                                                        05 Years
                                                    </label>
                                                </div>
                                                <div
                                                    className="form-check mb-3 "
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="tvl_license_duration"
                                                        id="TEN_YEARS"
                                                        value="TEN_YEARS"
                                                        checked={data?.licenseDuration.includes('TEN_YEARS')}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="TEN_YEARS"
                                                    >
                                                        10 Years
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <hr className="mb-30 mt-40" />
                            <div className="row  mb-20 justify-content-center">
                                <h3 className="title">Additional Information:</h3>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-drive">
                                            Are there any concessions you are eligible for?
                                        </label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div className="col">
                                                <div
                                                    className="form-check check mb-3 "
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="eligible_for_discounts_one"
                                                        name="tvl_eligible_concessions"

                                                        checked={data?.eligibleConcessions.includes('OVER_75')}
                                                        value="OVER_75"
                                                    />

                                                    <label
                                                        className="form-check-label checkLable"
                                                        htmlFor="OVER_75"
                                                    >
                                                        Over 75
                                                    </label>
                                                </div>

                                                <div
                                                    className="form-check check mb-3 "
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="eligible_for_discounts_two"
                                                        name="tvl_eligible_concessions"

                                                        checked={data?.eligibleConcessions.includes('BLIND_OR_PARTIALLY_SIGHTED')}
                                                        value="BLIND_OR_PARTIALLY_SIGHTED"
                                                    />

                                                    <label
                                                        className="form-check-label checkLable"
                                                        htmlFor="BLIND_OR_PARTIALLY_SIGHTED"
                                                    >
                                                        Blind or partially sighted
                                                    </label>
                                                </div>

                                                <div className="form-check check mb-3 ">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="eligible_for_discounts_three"
                                                        name="tvl_eligible_concessions"

                                                        checked={data?.eligibleConcessions.includes('WAR_PENSIONER')}
                                                        value="WAR_PENSIONER"
                                                    />

                                                    <label
                                                        className="form-check-label checkLable"
                                                        htmlFor="WAR_PENSIONER"
                                                    >
                                                        War Pensioner
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="log-drive">
                                            Are you the only person watching or recording
                                            live TV at this address?
                                        </label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div
                                                className="col"
                                                style={{ display: "flex", flexWrap: "wrap" }}
                                            >
                                                <div
                                                    className="form-check mb-3 "
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="ctb_dispute_council_tax_band"
                                                        id="YES"
                                                        value="YES"
                                                        checked={data?.disputeCouncilTaxBand == true}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="YES"
                                                    >
                                                        Yes
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 ">
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="ctb_dispute_council_tax_band"
                                                        id="NO"
                                                        value="NO"
                                                        checked={data?.disputeCouncilTaxBand == false}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="NO"
                                                    >
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group">

                                        <div className="form-group">
                                            <label htmlFor="log-provide">
                                                If you are eligible for a concession, please
                                                provide proof of eligibility:
                                            </label>
                                            <Input
                                                type="textarea"
                                                rows="4"
                                                name="tvl_proof_of_eligibility"
                                                id="tvl_proof_of_eligibility"
                                                value={data?.proofOfEligibility}
                                                disabled
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <div className="form-group mb-30">
                                            <label htmlFor="reg-provide">
                                                If no, please provide the names and dates of
                                                birth of all other viewers:
                                            </label>
                                            <Input
                                                type="textarea"
                                                rows="4"
                                                name="tvl_other_user_details"
                                                id="tvl_other_user_details"
                                                value={data?.otherUsersDetails}
                                                disabled
                                            />
                                        </div>

                                    </div>
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Amount">Signature:</label>
                                        <Input
                                            type="text"
                                            name="tvl_signature"
                                            id="tvl_signature"
                                            value={data?.signature}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                        </Row>
                    }

                    {data?.utilityType == "ELECTRICITY" &&
                        <Row>
                            <h3 className="title mb-20 mt-10">
                                Personal Information:
                            </h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-name">Name:</label>
                                        <Input
                                            type="text"
                                            name="gb_name"
                                            id="gb_name"
                                            value={data?.name}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Number">Phone Number:</label>
                                        <Input
                                            type="number"
                                            name="gb_phone_number"
                                            id="gb_phone_number"
                                            value={data?.phoneNumber}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Email">Email:</label>
                                        <Input
                                            type="text"
                                            name="gb_email"
                                            id="gb_email"
                                            value={data?.email}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="reg-National">
                                            National Insurance Number (if applicable):
                                        </label>
                                        <Input
                                            type="text"
                                            name="gb_NIN"
                                            id="gb_NIN"
                                            value={data?.nationalInsuranceNumber}
                                            disabled
                                        />


                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Postcode">Postcode:</label>
                                        <Input
                                            type="text"
                                            name="gb_postalCode"
                                            id="gb_postalCode"
                                            value={data?.postCode}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="log-Address">Address:</label>
                                        <Input
                                            type="textarea"
                                            rows="4"
                                            name="gb_address"
                                            id="gb_address"
                                            value={data?.address}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Date">Date of Birth:</label>
                                        <Input
                                            className="form-control"
                                            type="date"
                                            id="gb_dateofBirth"
                                            name="gb_dateofBirth"
                                            placeholder="Select Date"
                                            value={moment(data?.dateOfBirth).format("YYYY-MM-DD")}
                                            disabled

                                        />

                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />

                            <h3 className="title mb-30">Property Information:</h3>

                            <div className="row mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Property">
                                            Property Type:
                                        </label>
                                        <Input
                                            type="text"
                                            name="gb_propertyType"
                                            id="gb_propertyType"
                                            value={data?.propertyType}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="log-Address">
                                            Energy supplier at the property:
                                        </label>
                                        <Input
                                            type="text"
                                            name="gb_energySupplier"
                                            id="gb_energySupplier"
                                            value={data?.energySupplier}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Address">
                                            Electricity Supply Number (ESN)
                                        </label>
                                        <Input
                                            type="text"
                                            name="gb_ESN"
                                            id="gb_ESN"
                                            value={data?.ESN}
                                            disabled
                                        />


                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Council">
                                            Number of Bedrooms:
                                        </label>
                                        <Input
                                            type="number"
                                            name="gb_no_of_bedrooms"
                                            id="gb_no_of_bedrooms"
                                            value={data?.numberOfBedrooms}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Date">Move-in date:</label>
                                        <Input
                                            className="form-control"
                                            type="date"
                                            id="gb_move_in_date"
                                            name="gb_move_in_date"
                                            placeholder="Select Date"
                                            value={moment(data?.moveInDate).format("YYYY-MM-DD")}
                                            disabled
                                        />

                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <div className="row  mb-20 justify-content-center">
                                <h3 className="title">Payment Information:</h3>

                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="log-drive">
                                            Billing frequency:
                                        </label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div
                                                className="col"
                                                style={{ display: "flex", flexWrap: "wrap" }}
                                            >
                                                <div
                                                    className="form-check mb-3"
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gb_billing_frequency"
                                                        id="MONTHLY"
                                                        value="MONTHLY"
                                                        checked={data?.billingFrequency.includes('MONTHLY')}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="MONTHLY"
                                                    >
                                                        Monthly
                                                    </label>
                                                </div>

                                                <div
                                                    className="form-check mb-3 "
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gb_billing_frequency"
                                                        id="QUARTERLY"
                                                        value="QUARTERLY"
                                                        checked={data?.billingFrequency.includes('QUARTERLY')}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="QUARTERLY"
                                                    >
                                                        Quarterly
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <div className="row  mb-20 justify-content-center">
                                <h3 className="title">Additional Information:</h3>
                                <div className="col-lg-7">
                                    <div className="form-group">
                                        <label htmlFor="log-drive">
                                            Are you eligible for any discounts or benefits?
                                        </label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div
                                                className="col"
                                                style={{ display: "flex", flexWrap: "wrap" }}
                                            >
                                                <div
                                                    className="form-check check mb-3 "
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="eligible_for_discounts_one"
                                                        name="eligible_for_discounts"
                                                        checked={data?.eligibilityForDiscountsOrBenefits.includes('PENSION_CREDIT')}
                                                        value="PENSION_CREDIT"
                                                    />

                                                    <label
                                                        className="form-check-label checkLable"
                                                        htmlFor="credit"
                                                    >
                                                        Pension credit
                                                    </label>
                                                </div>

                                                <div
                                                    className="form-check check mb-3 "
                                                    style={{ marginRight: "1.2rem" }}
                                                >

                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="eligible_for_discounts_two"
                                                        name="eligible_for_discounts"
                                                        checked={data?.eligibilityForDiscountsOrBenefits.includes('INCOME_SUPPORT')}
                                                        value="INCOME_SUPPORT"
                                                    />

                                                    <label
                                                        className="form-check-label checkLable"
                                                        htmlFor="support"
                                                    >
                                                        Income support
                                                    </label>
                                                </div>

                                                <div className="form-check check mb-3 ">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="eligible_for_discounts_three"
                                                        name="eligible_for_discounts"
                                                        checked={data?.eligibilityForDiscountsOrBenefits.includes('JOBSEEKER_ALLOWANCE')}
                                                        value="JOBSEEKER_ALLOWANCE"
                                                    />

                                                    <label
                                                        className="form-check-label checkLable"
                                                        htmlFor="Allowance"
                                                    >
                                                        Jobseeker's Allowance
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-provide">
                                            If so, please provide proof of eligibility:
                                        </label>
                                        <Input
                                            type="textarea"
                                            rows="4"
                                            name="gb_proof_of_eligibility"
                                            id="gb_proof_of_eligibility"
                                            value={data?.proofOfEligibility}
                                            disabled
                                        />

                                    </div>

                                </div>

                                <div className="col-lg-5">
                                    <div className="form-group">
                                        <label htmlFor="log-drive">
                                            Are you the account holder?
                                        </label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div
                                                className="col"
                                                style={{ display: "flex", flexWrap: "wrap" }}
                                            >
                                                <div
                                                    className="form-check mb-3 "
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gbAccountHolderOrNot"
                                                        id="YES"
                                                        value="YES"
                                                        checked={data?.accountHolderOrNot == true}

                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="YES"
                                                    >
                                                        Yes
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 ">
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gbAccountHolderOrNot"
                                                        id="NO"
                                                        value="NO"
                                                        checked={data?.accountHolderOrNot == false}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="NO"
                                                    >
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Postcode">
                                            Account Holder Name:
                                        </label>
                                        <Input
                                            type="text"
                                            name="gb_accountHolderName"
                                            id="gb_accountHolderName"
                                            value={data?.accountHolderName}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Amount">Signature:</label>
                                        <Input
                                            type="text"
                                            name="gb_signature"
                                            id="gb_signature"
                                            value={data?.signature}
                                            disabled
                                        />


                                    </div>
                                </div>
                            </div>

                        </Row>
                    }

                    {data?.utilityType == "GAS" &&
                        <Row>
                            <h3 className="title mb-20 mt-10">
                                Personal Information:
                            </h3>
                            <div className="row  mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-name">Name:</label>
                                        <Input
                                            type="text"
                                            name="gb_name"
                                            id="gb_name"
                                            value={data?.name}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Number">Phone Number:</label>
                                        <Input
                                            type="number"
                                            name="gb_phone_number"
                                            id="gb_phone_number"
                                            value={data?.phoneNumber}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Email">Email:</label>
                                        <Input
                                            type="text"
                                            name="gb_email"
                                            id="gb_email"
                                            value={data?.email}
                                            disabled
                                        />


                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="reg-National">
                                            National Insurance Number (if applicable):
                                        </label>
                                        <Input
                                            type="text"
                                            name="gb_NIN"
                                            id="gb_NIN"
                                            value={data?.nationalInsuranceNumber}
                                            disabled
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Postcode">Postcode:</label>
                                        <Input
                                            type="number"
                                            name="gb_postalCode"
                                            id="gb_postalCode"
                                            value={data?.postCode}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="log-Address">Address:</label>
                                        <Input
                                            type="textarea"
                                            rows="4"
                                            name="gb_address"
                                            id="gb_address"
                                            value={data?.address}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Date">Date of Birth:</label>
                                        <Input
                                            className="form-control"
                                            type="date"
                                            id="gb_dateofBirth"
                                            name="gb_dateofBirth"
                                            placeholder="Select Date"
                                            value={moment(data?.dateOfBirth).format("YYYY-MM-DD")}
                                            disabled
                                        />

                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />

                            <h3 className="title mb-30">Property Information:</h3>

                            <div className="row mb-20 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Property">
                                            Property Type:
                                        </label>
                                        <Input
                                            type="text"
                                            name="gb_propertyType"
                                            id="gb_propertyType"
                                            value={data?.propertyType}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="log-Address">
                                            Energy supplier at the property:
                                        </label>
                                        <Input
                                            type="text"
                                            name="gb_energySupplier"
                                            id="gb_energySupplier"
                                            value={data?.energySupplier}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Address">
                                            Meter Point Reference Number (MPRN)
                                        </label>
                                        <Input
                                            type="text"
                                            name="gb_MPRN"
                                            id="gb_MPRN"
                                            value={data?.gasMPRN}
                                            disabled
                                        />

                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Council">
                                            Number of Bedrooms:
                                        </label>
                                        <Input
                                            type="number"
                                            name="gb_no_of_bedrooms"
                                            id="gb_no_of_bedrooms"
                                            value={data?.numberOfBedrooms}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-Date">Move-in date:</label>
                                        <Input
                                            className="form-control"
                                            type="date"
                                            id="gb_move_in_date"
                                            name="gb_move_in_date"
                                            placeholder="Select Date"
                                            value={moment(data?.moveInDate).format("YYYY-MM-DD")}
                                            disabled
                                        />

                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <div className="row mb-20 justify-content-center">
                                <h3 className="title">Payment Information:</h3>


                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="log-drive">
                                            Billing frequency:
                                        </label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div
                                                className="col"
                                                style={{ display: "flex", flexWrap: "wrap" }}
                                            >
                                                <div
                                                    className="form-check mb-3"
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gb_billing_frequency"
                                                        id="MONTHLY"
                                                        value="MONTHLY"
                                                        checked={data?.billingFrequency.includes('MONTHLY')}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="MONTHLY"
                                                    >
                                                        Monthly
                                                    </label>
                                                </div>

                                                <div
                                                    className="form-check mb-3 "
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gb_billing_frequency"
                                                        id="QUARTERLY"
                                                        value="QUARTERLY"
                                                        checked={data?.billingFrequency.includes('QUARTERLY')}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="QUARTERLY"
                                                    >
                                                        Quarterly
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr className="mb-30 mt-40" />
                            <div className="row mb-20 justify-content-center">
                                <h3 className="title">Additional Information:</h3>
                                <div className="col-lg-7">
                                    <div className="form-group">
                                        <label htmlFor="log-drive">
                                            Are you eligible for any discounts or benefits?
                                        </label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div
                                                className="col"
                                                style={{ display: "flex", flexWrap: "wrap" }}
                                            >
                                                <div
                                                    className="form-check check mb-3 "
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="eligible_for_discounts_one"
                                                        name="eligible_for_discounts"
                                                        checked={data?.eligibilityForDiscountsOrBenefits.includes('PENSION_CREDIT')}
                                                        value="PENSION_CREDIT"
                                                    />

                                                    <label
                                                        className="form-check-label checkLable"
                                                        htmlFor="credit"
                                                    >
                                                        Pension credit
                                                    </label>
                                                </div>

                                                <div
                                                    className="form-check check mb-3 "
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="eligible_for_discounts_two"
                                                        name="eligible_for_discounts"
                                                        checked={data?.eligibilityForDiscountsOrBenefits.includes('INCOME_SUPPORT')}
                                                        value="INCOME_SUPPORT"
                                                    />

                                                    <label
                                                        className="form-check-label checkLable"
                                                        htmlFor="support"
                                                    >
                                                        Income support
                                                    </label>
                                                </div>

                                                <div className="form-check check mb-3 ">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="eligible_for_discounts_three"
                                                        name="eligible_for_discounts"
                                                        checked={data?.eligibilityForDiscountsOrBenefits.includes('JOBSEEKER_ALLOWANCE')}
                                                        value="JOBSEEKER_ALLOWANCE"
                                                    />

                                                    <label
                                                        className="form-check-label checkLable"
                                                        htmlFor="Allowance"
                                                    >
                                                        Jobseeker's Allowance
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="log-provide">
                                            If so, please provide proof of eligibility:
                                        </label>
                                        <Input
                                            type="textarea"
                                            rows="4"
                                            name="gb_proof_of_eligibility"
                                            id="gb_proof_of_eligibility"
                                            value={data?.proofOfEligibility}
                                            disabled
                                        />

                                    </div>

                                </div>

                                <div className="col-lg-5">
                                    <div className="form-group">
                                        <label htmlFor="log-drive">
                                            Are you the account holder?
                                        </label>
                                    </div>
                                    <div className="card-heading mb-2">
                                        <div className="row">
                                            <div
                                                className="col"
                                                style={{ display: "flex", flexWrap: "wrap" }}
                                            >
                                                <div
                                                    className="form-check mb-3 "
                                                    style={{ marginRight: "1.2rem" }}
                                                >
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gbAccountHolderOrNot"
                                                        id="YES"
                                                        value="YES"
                                                        checked={data?.accountHolderOrNot == true}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="YES"
                                                    >
                                                        Yes
                                                    </label>
                                                </div>

                                                <div className="form-check mb-3 ">
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gbAccountHolderOrNot"
                                                        id="NO"
                                                        value="NO"
                                                        checked={data?.accountHolderOrNot == false}

                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="NO"
                                                    >
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Postcode">
                                            Account Holder Name:
                                        </label>
                                        <Input
                                            type="text"
                                            name="gb_accountHolderName"
                                            id="gb_accountHolderName"
                                            value={data?.accountHolderName}
                                            disabled
                                        />

                                    </div>

                                    <div className="form-group mb-20">
                                        <label htmlFor="reg-Amount">Signature:</label>
                                        <Input
                                            type="text"
                                            name="gb_signature"
                                            id="gb_signature"
                                            value={data?.signature}
                                            disabled
                                        />


                                    </div>
                                </div>
                            </div>

                        </Row>
                    }
                </div>
            </Modal>
        </>
    ) : null;
}

export default UtilitiesModal;