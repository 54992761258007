import axios, { AxiosResponse } from "axios";
import { AppResponse } from "../models/Response";
import { Util } from "../Util";
import { SubscriptionDetails } from "../models/subscription";


export default class PaymentService {
  public static async submitPaymentDetails(data: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("paymentDetails");

    const res = await axios.post<any, AppResponse<any>>(ep, data);

    return res;
  }
  public static async submitSubscriptionDetails(data: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("subscription");

    const res = await axios.post<any, AppResponse<any>>(ep, data);

    return res;
  }


  public static async getSubscriptions(userId: string | undefined ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`subscriptions/${userId}`);
    return await axios.get<Partial<SubscriptionDetails>, AppResponse<any>>(url);
}

public static async cancelSubscription(userId: object | undefined, subscriptionId: string | undefined = ''): Promise<AppResponse<any>> {
  const ep = Util.apiAuthUrl(`subscriptions/cancel/${subscriptionId}/${userId}`);
  const res = await axios.post<any, AppResponse<any>>(ep);
  return res;
}



}